export default {
    setAppState(state, payload) {
        state.appState = payload;
    },
    promoCodes(state, payload) {
        state.promoCodes = payload;
    },
    resortPromoCodes(state, payload) {
        state.resortPromoCodes = payload;
    },
    bookingDateLimits(state, payload) {
        state.bookingDateLimits = payload;
    },
    bookingExcludeResorts(state, payload) {
        state.bookingExcludeResorts = payload;
    },

    // Menus
    createMenus(state, menu) {
        state.menus.data.push(menu);
    },

    setMenus(state, payload) {
        state.menus = payload;
    },

    updateMenus(state, { menu, data }) {
        state.menus.data.splice(state.menus.data.indexOf(menu), 1, data);
    },

    deleteMenus(state, menu) {
        state.menus.data.splice(state.menus.data.indexOf(menu), 1);
    },

    // BookingCodeTriggers
    createBookingCodeTriggers(state, bookingCodeTrigger) {
        state.bookingCodeTriggers.data.push(bookingCodeTrigger);
    },

    setBookingCodeTriggers(state, payload) {
        state.bookingCodeTriggers = payload;
    },

    updateBookingCodeTriggers(state, { bookingCodeTrigger, data }) {
        state.bookingCodeTriggers.data.splice(
            state.bookingCodeTriggers.data.indexOf(bookingCodeTrigger),
            1,
            data
        );
    },

    deleteBookingCodeTriggers(state, bookingCodeTrigger) {
        state.bookingCodeTriggers.data.splice(
            state.bookingCodeTriggers.data.indexOf(bookingCodeTrigger),
            1
        );
    },

    // Brands
    createBrands(state, brand) {
        state.brands.data.push(brand);
    },

    setBrands(state, payload) {
        state.brands = payload;
    },

    updateBrands(state, { brand, data }) {
        state.brands.data.splice(state.brands.data.indexOf(brand), 1, data);
    },

    deleteBrands(state, brand) {
        state.brands.data.splice(state.brands.data.indexOf(brand), 1);
    },

    // Clocks
    createClocks(state, clock) {
        state.clocks.data.push(clock);
    },

    setClocks(state, payload) {
        state.clocks = payload;
    },

    updateClocks(state, { clock, data }) {
        state.clocks.data.splice(state.clocks.data.indexOf(clock), 1, data);
    },

    deleteClocks(state, clock) {
        state.clocks.data.splice(state.clocks.data.indexOf(clock), 1);
    },

    // Posts
    createPosts(state, post) {
        state.posts.data.push(post);
    },

    setPosts(state, payload) {
        state.posts = payload;
    },

    updatePosts(state, { post, data }) {
        state.posts.data.splice(state.posts.data.indexOf(post), 1, data);
    },

    deletePosts(state, post) {
        state.posts.data.splice(state.posts.data.indexOf(post), 1);
    },

    // Resorts
    createResorts(state, resort) {
        state.resorts.data.push(resort);
    },

    setResorts(state, payload) {
        state.resorts = payload;
    },

    updateResorts(state, { resort, data }) {
        state.resorts.data.splice(state.resorts.data.indexOf(resort), 1, data);
    },

    deleteResorts(state, resort) {
        state.resorts.data.splice(state.resorts.data.indexOf(resort), 1);
    },

    // Specials
    createSpecials(state, special) {
        state.specials.data.push(special);
    },

    setSpecials(state, payload) {
        state.specials = payload;
    },

    appendSpecial(state, payload) {
        state.specials.data.push(payload);
    },

    setSpecialsForPage(state, payload) {
        state.specialsForPage = payload;
    },

    updateSpecials(state, { special, data }) {
        state.specials.data.splice(
            state.specials.data.indexOf(special),
            1,
            data
        );
    },

    deleteSpecials(state, special) {
        state.specials.data.splice(state.specials.data.indexOf(special), 1);
    },

    // Property Hub
    setSite(state, payload) {
        state.propertyHub.site = payload;
    },

    setMenuLink(state, payload) {
        state.propertyHub.menuLink = payload;
    },

    setEsMenuLink(state, payload) {
        state.propertyHub.esMenuLink = payload;
    },

    setRestaurants(state, payload) {
        state.restaurants = payload;
    },

    setContent(state, payload) {
        state.propertyHub.content = payload;
    },

    setContentAsMenu(state, payload) {
        state.propertyHub.contentAsMenu = payload;
    },

    setPromos(state, payload) {
        state.propertyHub.promos[payload.location] = payload.data;
    },

    setEvents(state, payload) {
        state.propertyHub.events = payload;
    },

    setCurrentLanguage(state, payload) {
        state.propertyHub.currentLanguage = payload;
    },
};

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <div v-if="localized">
            <hero-image
                :image="
                    localized.image ||
                    '/imgs/property-hub/couple-at-bar.png'
                "
            ></hero-image>

            <div
                v-if="localized.logo"
                class="relative z-10 -mt-12 flex justify-center"
            >
                <div
                    class="relative z-10 w-32 h-32 rounded-full overflow-hidden bg-white"
                >
                    <img
                        :src="
                            localized.logo
                        "
                        class="object-contain w-full h-full"
                    />
                </div>
                <div
                    class="absolute z-0 w-32 h-32 mt-2 rounded-full overflow-hidden"
                    :style="{
                        backgroundColor: propertyHub.site.colors.secondary,
                    }"
                ></div>
            </div>

            <div class="pt-4 px-5 pb-8 text-gray-700 space-y-6">
                <div>
                    <div
                        class="font-bold text-2xl mt-2 mb-1 text-center"
                        :style="{ color: propertyHub.site.colors.primary }"
                    >
                        {{
                            localized.title
                        }}
                    </div>

                    <div
                        v-if="localized.type"
                        class="text-center font-medium text-sm"
                        :style="{ color: propertyHub.site.colors.primary }"
                    >
                        {{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['cuisine']
                        }}
                        {{
                            localized.type
                        }}
                    </div>

                    <menu-links-slider
                        :restaurant-slug="
                            localized.slug
                        "
                        :menus="
                            localized.menus
                        "
                    />

                    <div
                        v-if="
                            localized.links
                                .length > 0
                        "
                        class="my-6 flex justify-start items-center space-x-3 snap-x overflow-x-scroll scrollbar-hide md:scrollbar-default"
                        :class="{
                            'justify-center':
                                localized
                                    .links <= 1,
                            'justify-start':
                                localized
                                    .links > 1,
                        }"
                    >
                        <button
                            v-for="(link, key) in restaurant.locale[
                                propertyHub.currentLanguage
                            ].links"
                            :key="key"
                            class="text-abs-white snap-center whitespace-nowrap rounded-full border border-gray-300 text-sm py-1 px-4 uppercase"
                            :style="{
                                'background-color':
                                    propertyHub.site.colors.primary,
                            }"
                            @click="clickedLink(link)"
                        >
                            {{ link.linktext }}
                        </button>
                    </div>

                    <div class="text-sm leading-relaxed whitespace-pre-line"
                    v-html="markdown(localized
                        .content)"
                    >
                    </div>
                </div>

                <div
                    class="text-sm"
                    v-if="
                        localized.hours
                            .breakfast.time ||
                        localized.hours
                            .lunch.time ||
                        localized.hours
                            .dinner.time ||
                        localized.hours
                            .lounge.time
                    "
                >
                    <span :style="{ color: propertyHub.site.colors.secondary }">
                        {{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['hours']
                        }}
                    </span>
                    <p
                        v-if="
                            localized.hours
                                .breakfast.time
                        "
                    >
                        <span class="font-bold">{{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['breakfast']
                        }}</span
                        ><br />
                        {{
                            localized.hours
                                .breakfast.time
                        }}
                        <br />
                        {{
                            breakfastDays
                        }}
                    </p>
                    <p
                        v-if="
                            localized.hours
                                .lunch.time
                        "
                    >
                        <span class="font-bold">{{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['lunch']
                        }}</span
                        ><br />
                        {{
                            localized.hours
                                .lunch.time
                        }}
                        <br />
                        {{
                            lunchDays
                        }}
                    </p>
                    <p
                        v-if="
                            localized.hours
                                .dinner.time
                        "
                    >
                        <span class="font-bold">{{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['dinner']
                        }}</span
                        ><br />
                        {{
                            localized.hours
                                .dinner.time
                        }}
                        <br />
                        {{
                            dinnerDays
                        }}
                    </p>
                    <p
                        v-if="
                            localized.hours
                                .lounge.time
                        "
                    >
                        {{
                            localized.hours
                                .lounge.time
                        }}
                        <br />
                        {{
                            loungeDays
                        }}
                    </p>
                </div>

                <div
                    class="text-sm"
                    v-if="
                        localized
                            .dress_code
                    "
                >
                    <span :style="{ color: propertyHub.site.colors.secondary }">
                        {{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['attire']
                        }}
                    </span>
                    <div
                        v-html="
                            localized
                                .dress_code
                        "
                    ></div>
                </div>

                <div
                    class="text-sm"
                    v-if="localized.notes"
                >
                    <span
                        class="leading-relaxed"
                        :style="{ color: propertyHub.site.colors.secondary }"
                    >
                        {{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['notes']
                        }}
                    </span>
                    <div
                        v-html="
                            markdown(localized.notes)
                        "
                        class="leading-relaxed"
                    ></div>
                </div>

                <div
                    class="text-sm"
                    v-if="
                        localized.extension
                    "
                >
                    <span
                        class="leading-relaxed"
                        :style="{ color: propertyHub.site.colors.secondary }"
                    >
                        {{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['extension']
                        }}
                    </span>
                    <div
                        v-html="
                            localized
                                .extension
                        "
                        class="leading-relaxed"
                    ></div>
                </div>

                <button
                    v-if="
                        localized
                            .reservations_url
                    "
                    class="text-abs-white snap-center whitespace-nowrap w-full rounded-full border border-gray-300 text-sm py-1 px-4 uppercase"
                    :style="{
                        'background-color': propertyHub.site.colors.primary,
                    }"
                    @click="displayReservationsModal"
                >
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'reservations'
                        ]
                    }}
                </button>

                <div
                    class="text-sm"
                    v-if="
                        localized.dressCode
                    "
                >
                    <span :style="{ color: propertyHub.site.colors.secondary }">
                        {{
                            propertyHub.localizations[
                                propertyHub.currentLanguage
                            ]['dress-code']
                        }}
                    </span>
                    <div
                        class="leading-relaxed"
                        v-html="
                            localized
                                .dressCode
                        "
                    ></div>
                </div>
                <div
                    v-if="
                        localized.gallery
                            .length
                    "
                    class="mt-4"
                >
                    <div
                        v-for="(image, id) in restaurant.locale[
                            propertyHub.currentLanguage
                        ].gallery"
                        :key="id"
                    >
                        <img
                            v-devise-image="{ image: image }"
                            class="w-full mt-5"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!restaurant && !loading">
            <div
                class="text-center px-8 mt-8"
                :style="{ color: propertyHub.site.colors.primary }"
            >
                <div class="text-xl font-extrabold">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'no-restaurants-found'
                        ]
                    }}
                </div>
                <div class="mt-4">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'click-back-ins-for-restaurants'
                        ]
                    }}
                </div>
            </div>
        </div>
        <div
            v-if="showReservationsModal"
            class="fixed left-0 top-0 h-screen w-full z-100"
            @click="hideReservationsModal"
        >
            <div
                class="relative mx-auto w-full h-full"
            >
                <div
                    class="relative p-1 pt-[40px] w-full h-full"
                    :style="{
                        'background-color': propertyHub.site.colors.primary,
                    }"
                >
                    <div
                        class="absolute top-0 left-0 text-white uppercase mt-2 ml-4"
                    >
                        Reservations
                    </div>
                    <div
                        class="absolute top-0 right-0 text-white uppercase py-2 px-4"
                        @click="hideReservationsModal"
                    >
                        <close-icon />
                    </div>
                    <iframe
                        :src="
                            localized
                                .reservations_url
                        "
                        style="width: 100%; height: 100%"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CloseIcon from 'vue-feather-icons/icons/XIcon';

const MarkdownIt = require('markdown-it');
const md = new MarkdownIt().disable(['link', 'image']);

export default {
    name: 'RestaurantView',

    components: {
        CloseIcon,
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
        MenuLinksSlider: () => import('./components/MenuLinksSlider.vue'),
    },

    data() {
        return {
            loading: true,
            hitRockBottom: false,
            showReservationsModal: false,
            restaurant: null,
        };
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),

        ...mapGetters(['getRestaurantFromSlug']),

        localized() {
            if (this.restaurant) {
                return this.restaurant.locale[
                    this.propertyHub.currentLanguage
                ];
            }
            return null;
        },
        breakfastDays(){
            return this.formatDow(this.localized.hours.breakfast.days)
        },
        lunchDays(){
            return this.formatDow(this.localized.hours.lunch.days)
        },
        dinnerDays(){
            return this.formatDow(this.localized.hours.dinner.days)
        },
        loungeDays(){
            return this.formatDow(this.localized.hours.lounge.days)
        }
    },

    mounted() {
        this.requestRetrieveRestaurants();
    },

    methods: {
        ...mapActions(['getRestaurants', 'sendPageView']),

        formatDow(days) {
            if (days.length === 7) {
                return this.propertyHub.localizations[
                    this.propertyHub.currentLanguage
                ]['Everyday'];
            }

            if (days.length === 1) {
                return days[0];
            }

            if (days.length > 1) {
                const targetOrder = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
                days.sort((a, b) => targetOrder.indexOf(a) - targetOrder.indexOf(b));
                days = Object.assign([], days);
                
                if (this.daysAreConsecutive(days)) {
                    const start =
                        this.propertyHub.localizations[
                            this.propertyHub.currentLanguage
                        ][days[0]];
                    const end =
                        this.propertyHub.localizations[
                            this.propertyHub.currentLanguage
                        ][days[days.length - 1]];
                    return start + ' - ' + end;
                } else {
                    var text = '';
                    for (var key in days) {
                        text +=
                            this.propertyHub.localizations[
                                this.propertyHub.currentLanguage
                            ][days[key]];
                        if (key < days.length - 2) {
                            text += ', ';
                        } else if (key == days.length - 2) {
                            text += ' & ';
                        }
                    }
                    return text;
                }
            }

            return '';
        },

        daysAreConsecutive(days) {
            const all = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'];
            const indexes = [];
            for (var key in days) {
                indexes.push(all.indexOf(days[key]));
            }
            indexes.sort();
            const differenceAry = indexes.slice(1).map(function (n, i) {
                return n - indexes[i];
            });
            return differenceAry.every((value) => value === 1);
        },

        clickedLink(link) {
            if (link.type === 'Playa Safe Stay') {
                return this.$router.push('/playa-safe-stay');
            }

            if (link.type === 'Make Reservation' || link.type === 'Link Off') {
                const firstChar = Array.from(link.linkurl)[0];
                if (firstChar === '/') {
                    // if the url starts with / then it's internal
                    return this.$router.push(link.linkurl);
                } else {
                    return window.open(link.linkurl, '_blank');
                }
            }
            if (link.type === 'Click To Call') {
                return window.open(`tel:${link.linkurl}`, '_blank');
            }
            if (link.type === 'Download File') {
                const url = window.location.pathname;
                const filename = url.substring(url.lastIndexOf('/') + 1);
                return this.downloadFile(link.linkurl, filename);
            }
            if (link.type === 'Content') {
                const content = this.getContentFromId(link.pageId);
                if (
                    content &&
                    content.locale &&
                    content.locale[this.propertyHub.currentLanguage]
                ) {
                    return this.$router.push(
                        `/property-hub/info/${
                            content.locale[this.propertyHub.currentLanguage]
                                .slug
                        }`
                    );
                }
            }
            if (link.linkurl) {
                return this.$router.push(link.linkurl);
            }
        },

        requestRetrieveRestaurants() {
            this.loading = true;
            this.getRestaurants()
                .then(() => {
                    this.restaurant = this.getRestaurantFromSlug(
                        this.$route.params.slug
                    );

                    const restName =
                        this.restaurant.locale[this.propertyHub.currentLanguage]
                            .title;
                    document.title =
                        this.propertyHub.site.name + ' ' + restName;
                    this.sendPageView(
                        this.propertyHub.site.name + ' ' + restName
                    );
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        displayReservationsModal() {
            this.showReservationsModal = true;
        },

        hideReservationsModal() {
            this.showReservationsModal = false;
        },
        markdown(str) {
            return md.render(str);
        },
    },
};
</script>

<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: 'DeviseResortAdmin',
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ 'devisephp-interface/src/components/admin/workflow/Workflow'
            ),
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: 'resort-main-menu',
                    component: 'workflow-menu',
                    message:
                        'Cool! We are working on <strong>Resorts</strong>.<br> What would you like to do?',
                    options: [
                        {
                            label: 'Create a Resort',
                            nextStep: 'create-resort',
                        },
                        {
                            label: 'Edit a Resort',
                            nextStep: 'search-for-resort',
                            stepAfterNext: 'edit-resort',
                        },
                    ],
                },
                {
                    key: 'create-resort',
                    component: 'devise-resort-create',
                    message:
                        'Fill out the fields below and create the new resort. Name is required.',
                    end: true,
                },
                {
                    key: 'edit-resort-content',
                    component: 'devise-resort-edit',
                    message: `Time to change things up! Let's edit this resort.<br> What would you like to edit?`,
                    end: true,
                },
                {
                    key: 'edit-resort-triggers',
                    component: 'devise-resort-edit-triggers',
                    message: `Time to change things up! Let's edit these triggers.<br> What would you like to edit?`,
                    end: true,
                },
                {
                    key: 'edit-resort',
                    component: 'workflow-menu',
                    message: `Time to change things up! Let's edit these resort triggers.`,
                    options: [
                        {
                            label: 'Edit Main Content',
                            nextStep: 'edit-resort-content',
                            stepAfterNext: 'edit-resort',
                        },
                        {
                            label: 'Edit Triggers',
                            nextStep: 'edit-resort-triggers',
                            stepAfterNext: 'edit-resort',
                        },
                        {
                            label: 'Return to beginning',
                            end: true,
                        },
                    ],
                },
                {
                    key: 'search-for-resort',
                    component: 'workflow-search',
                    message:
                        'Use the form below to search for the resort you wish to edit',
                    apiendpoint: '/api/devise/resorts/search',
                    allRecordsApiendpoint: '/api/devise/resorts/',
                    searchPropertyName: 'searchterm',
                    resultsDisplayFields: [
                        {
                            label: 'Name',
                            field: 'name',
                        },
                        {
                            label: 'City',
                            field: 'city',
                        },
                        {
                            label: 'Country',
                            field: 'country',
                        },
                        {
                            label: 'URL',
                            field: 'url_partial',
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <iframe
            ref="reswindow"
            class="w-full h-full"
            src="https://zilaracapcana.resortsbyhyatt.com/?seating"
        ></iframe>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'CabanasView',
    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
    },
    mounted: function () {
        document.title = this.propertyHub.site.name + ' Cabanas';
        this.sendPageView(this.propertyHub.site.name + ' Cabanas');
        try {
            const iframe = this.$refs.reswindow;
            iframe.onload = function () {
                iframe.contentWindow.window.document
                    .getElementById('footer')
                    .remove();
            };
        } catch (e) {
            console.log(e);
        }
    },
    methods: {
        ...mapActions(['sendPageView']),
    },
    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
    },
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <iframe
            class="w-full h-full"
            src="https://na.spatime.com/pdrhcc23302/28862912/spa-service/service-group"
        ></iframe>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'SpaReservationsView',
    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
    },
    mounted: function () {
        document.title = this.propertyHub.site.name + ' Spa Reservations';
        this.sendPageView(this.propertyHub.site.name + ' Spa Reservations');
    },
    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
    },
    methods: {
        ...mapActions(['sendPageView']),
    },
};
</script>

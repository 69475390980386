var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white min-h-screen"},[_c('PropertyHubNavigation',{ref:"navigation",staticClass:"relative top-0 w-full z-20",attrs:{"display-mode":"bar"}}),_c('div',[_c('hero-image',{attrs:{"image":'/imgs/property-hub/dietary-dishes-header.jpeg'}}),_c('div',{staticClass:"mt-4 mx-2"},[_c('div',{ref:"title",staticClass:"text-xl text-center",style:({
                    color: _vm.propertyHub.site.colors.primary,
                })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'pinpoint-perfect-meal' ])+" ")]),_c('p',{staticClass:"mt-2 text-sm text-center"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'dietary-dishes-description' ])+" ")]),_c('p',{staticClass:"text-md text-center text-gray-500"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'select-diet-below' ])+" ")])])],1),_c('div',{staticClass:"flex flex-wrap justify-center w-full z-10 bg-white px-4"},[_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'w-1/2': _vm.selectedDr === null,
                'bg-grey-lightest': _vm.selectedDr === 'gluten-free',
            },on:{"click":function($event){return _vm.changeRestriction('gluten-free')}}},[_c('gluten-free',{attrs:{"w":_vm.iconHeight,"h":_vm.iconHeight}})],1),_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'w-1/2': _vm.selectedDr === null,
                'bg-grey-lightest': _vm.selectedDr === 'keto',
            },on:{"click":function($event){return _vm.changeRestriction('keto')}}},[_c('keto',{attrs:{"w":_vm.iconHeight,"h":_vm.iconHeight}})],1),_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'w-1/2': _vm.selectedDr === null,
                'bg-grey-lightest': _vm.selectedDr === 'vegan',
            },on:{"click":function($event){return _vm.changeRestriction('vegan')}}},[_c('vegan',{attrs:{"w":_vm.iconHeight,"h":_vm.iconHeight}})],1),_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'w-1/2': _vm.selectedDr === null,
                'bg-grey-lightest': _vm.selectedDr === 'vegetarian',
            },on:{"click":function($event){return _vm.changeRestriction('vegetarian')}}},[_c('vegetarian',{attrs:{"w":_vm.iconHeight,"h":_vm.iconHeight}})],1)]),_c('div',{staticClass:"my-8 flex flex-wrap justify-center w-full z-10 bg-white fixed left-0",class:{
            hidden: _vm.titleVisible,
        },staticStyle:{"top":"25px"}},[_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'bg-grey-lightest': _vm.selectedDr === 'gluten-free',
            },on:{"click":function($event){return _vm.changeRestriction('gluten-free')}}},[_c('gluten-free',{attrs:{"w":80,"h":80}})],1),_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'bg-grey-lightest': _vm.selectedDr === 'keto',
            },on:{"click":function($event){return _vm.changeRestriction('keto')}}},[_c('keto',{attrs:{"w":80,"h":80}})],1),_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'bg-grey-lightest': _vm.selectedDr === 'vegan',
            },on:{"click":function($event){return _vm.changeRestriction('vegan')}}},[_c('vegan',{attrs:{"w":80,"h":80}})],1),_c('div',{staticClass:"cursor-pointer flex justify-center",class:{
                'bg-grey-lightest': _vm.selectedDr === 'vegetarian',
            },on:{"click":function($event){return _vm.changeRestriction('vegetarian')}}},[_c('vegetarian',{attrs:{"w":80,"h":80}})],1)]),(_vm.selectedDr !== null && _vm.filteredRestaurants.length > 0)?_c('div',{staticClass:"flex flex-col p-4"},_vm._l((_vm.filteredRestaurants),function(restaurant){return _c('div',{key:restaurant.id},[_c('div',{staticClass:"mx-4 mt-6 font-bold text-2xl mb-1 text-center",style:({ color: _vm.propertyHub.site.colors.primary })},[_vm._v(" "+_vm._s(restaurant.title)+" ")]),_c('div',{staticClass:"p-4 md:p-8 border-t-2"},[_vm._l((restaurant.menus),function(menu,h){return _c('div',{key:h},[_c('div',{staticClass:"font-bold",style:({
                            color: _vm.propertyHub.site.colors.primary,
                        })},[_vm._v(" "+_vm._s(menu.name)+" ")]),_vm._l((menu.sections),function(section,i){return _c('div',{key:i},[_c('div',{staticClass:"mt-8 uppercase font-bold"},[_vm._v(" "+_vm._s(section.title)+" ")]),_vm._l((section.items),function(item,j){return _c('div',{key:j,staticClass:"mb-4"},[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(item.title)+" "),(item.dr.includes('gluten-free'))?_c('gluten-free',{staticClass:"inline",attrs:{"show-text":false,"w":40,"h":40}}):_vm._e(),(item.dr.includes('keto'))?_c('keto',{staticClass:"inline",attrs:{"show-text":false,"w":40,"h":40}}):_vm._e(),(item.dr.includes('vegan'))?_c('vegan',{staticClass:"inline",attrs:{"show-text":false,"w":40,"h":40}}):_vm._e(),(item.dr.includes('vegetarian'))?_c('vegetarian',{staticClass:"inline",attrs:{"show-text":false,"w":40,"h":40}}):_vm._e()],1),_c('div',[_vm._v(_vm._s(item.desc))])])})],2)})],2)}),_c('button',{staticClass:"mt-4 text-abs-white snap-center whitespace-nowrap rounded-full border border-gray-300 text-sm py-1 px-4 uppercase",style:({
                        'background-color': _vm.propertyHub.site.colors.primary,
                    }),on:{"click":function($event){return _vm.navigateTo(restaurant)}}},[(_vm.propertyHub.currentLanguage === 'en')?_c('span',[_vm._v(" View "+_vm._s(restaurant.title)+" Details ")]):_vm._e(),(_vm.propertyHub.currentLanguage === 'es')?_c('span',[_vm._v(" Ver Detalles de "+_vm._s(restaurant.title)+" ")]):_vm._e()])],2)])}),0):_vm._e(),(
            _vm.selectedDr !== null &&
            _vm.filteredRestaurants.length === 0 &&
            !_vm.loading
        )?_c('div',{staticClass:"flex flex-col p-4 pb-48"},[_c('div',{staticClass:"mx-4 mt-6 font-bold text-2xl mb-1 text-center",style:({ color: _vm.propertyHub.site.colors.primary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'no-meals-available' ])+" ")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white min-h-screen"},[_c('PropertyHubNavigation',{ref:"navigation",staticClass:"relative top-0 w-full z-20",attrs:{"display-mode":"bar"}}),_c('transition',{attrs:{"enter-active-class":"transition-all duration-500","enter-class":"opacity-0","enter-to-class":"opacity-1","leave-active-class":"transition-all duration-500","leave-class":"opacity-1","leave-to-class":"opacity-0","mode":"out-in"}},[(_vm.event)?_c('div',[_c('hero-image',{attrs:{"image":_vm.event.locale[_vm.propertyHub.currentLanguage].image ||
                    '/imgs/property-hub/couple-at-bar.png'}}),_c('div',{staticClass:"pt-4 px-5 pb-8 text-gray-700 space-y-6"},[_c('div',[_c('div',{staticClass:"font-bold text-lg mb-1",style:({ color: _vm.propertyHub.site.colors.primary })},[_vm._v(" "+_vm._s(_vm.event.locale[_vm.propertyHub.currentLanguage].title)+" ")]),_c('div',{staticClass:"text-sm mb-4",style:({
                            color: _vm.propertyHub.site.colors.secondary,
                        })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ][ _vm.event.locale[_vm.propertyHub.currentLanguage] .category ])+" ")]),_c('div',{staticClass:"text-sm leading-relaxed",domProps:{"innerHTML":_vm._s(
                            _vm.event.locale[_vm.propertyHub.currentLanguage]
                                .content
                        )}})]),_c('div',{staticClass:"text-sm"},[_c('span',{style:({
                            color: _vm.propertyHub.site.colors.secondary,
                        })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['event-time'])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.formatTime(_vm.event.start_date))+" - "+_vm._s(_vm.formatTime(_vm.event.end_date))+" ")])]),(
                        _vm.event.locale[_vm.propertyHub.currentLanguage].location
                    )?_c('div',{staticClass:"text-sm"},[_c('span',{style:({
                            color: _vm.propertyHub.site.colors.secondary,
                        })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['location'])+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.event.locale[_vm.propertyHub.currentLanguage] .location)+" ")])]):_vm._e(),(
                        _vm.event.locale[_vm.propertyHub.currentLanguage].links
                            .length
                    )?_c('div',{staticClass:"text-sm"},[_c('div',{staticClass:"mb-3",style:({
                            color: _vm.propertyHub.site.colors.secondary,
                        })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['associated-links'])+" ")]),_c('div',{staticClass:"mt-4 flex justify-start items-center space-x-3 snap-x px-5 overflow-x-scroll scrollbar-hide md:scrollbar-default"},_vm._l((_vm.event.locale[
                                _vm.propertyHub.currentLanguage
                            ].links),function(link,key){return _c('button',{key:key,staticClass:"text-abs-white snap-center rounded-full border border-gray-300 text-sm py-1 px-4 uppercase",style:({
                                'background-color': _vm.propertyHub.site.colors.primary,
                            }),on:{"click":function($event){return _vm.clickedLink(link)}}},[_vm._v(" "+_vm._s(link.linktext)+" ")])}),0)]):_vm._e(),(
                        _vm.event.locale[_vm.propertyHub.currentLanguage].gallery
                            .length
                    )?_c('div',{staticClass:"mt-4"},_vm._l((_vm.event.locale[
                            _vm.propertyHub.currentLanguage
                        ].gallery),function(image,id){return _c('div',{key:id},[_c('img',{directives:[{name:"devise-image",rawName:"v-devise-image",value:({ image: image }),expression:"{ image: image }"}],staticClass:"w-full mt-5"})])}),0):_vm._e()])],1):(_vm.loading)?_c('loading-stuff',{key:"loader"}):_c('div',{key:"no-events",staticClass:"flex justify-center px-8 text-center mt-12 text-xl text-gray-700 italic font-serif"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'unable-to-find-event' ])+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <div>
            <hero-image
                :image="'/imgs/property-hub/dietary-dishes-header.jpeg'"
            ></hero-image>

            <div class="mt-4 mx-2">
                <div
                    class="text-xl text-center"
                    ref="title"
                    :style="{
                        color: propertyHub.site.colors.primary,
                    }"
                >
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'pinpoint-perfect-meal'
                        ]
                    }}
                </div>
                <p class="mt-2 text-sm text-center">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'dietary-dishes-description'
                        ]
                    }}
                </p>
                <p class="text-md text-center text-gray-500">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'select-diet-below'
                        ]
                    }}
                </p>
            </div>
        </div>

        <div class="flex flex-wrap justify-center w-full z-10 bg-white px-4">
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'w-1/2': selectedDr === null,
                    'bg-grey-lightest': selectedDr === 'gluten-free',
                }"
                @click="changeRestriction('gluten-free')"
            >
                <gluten-free :w="iconHeight" :h="iconHeight"></gluten-free>
            </div>
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'w-1/2': selectedDr === null,
                    'bg-grey-lightest': selectedDr === 'keto',
                }"
                @click="changeRestriction('keto')"
            >
                <keto :w="iconHeight" :h="iconHeight"></keto>
            </div>
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'w-1/2': selectedDr === null,
                    'bg-grey-lightest': selectedDr === 'vegan',
                }"
                @click="changeRestriction('vegan')"
            >
                <vegan :w="iconHeight" :h="iconHeight"></vegan>
            </div>
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'w-1/2': selectedDr === null,
                    'bg-grey-lightest': selectedDr === 'vegetarian',
                }"
                @click="changeRestriction('vegetarian')"
            >
                <vegetarian :w="iconHeight" :h="iconHeight"></vegetarian>
            </div>
        </div>

        <!-- hidden fixed menu -->
        <div
            class="my-8 flex flex-wrap justify-center w-full z-10 bg-white fixed left-0"
            style="top: 25px"
            :class="{
                hidden: titleVisible,
            }"
        >
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'bg-grey-lightest': selectedDr === 'gluten-free',
                }"
                @click="changeRestriction('gluten-free')"
            >
                <gluten-free :w="80" :h="80"></gluten-free>
            </div>
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'bg-grey-lightest': selectedDr === 'keto',
                }"
                @click="changeRestriction('keto')"
            >
                <keto :w="80" :h="80"></keto>
            </div>
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'bg-grey-lightest': selectedDr === 'vegan',
                }"
                @click="changeRestriction('vegan')"
            >
                <vegan :w="80" :h="80"></vegan>
            </div>
            <div
                class="cursor-pointer flex justify-center"
                :class="{
                    'bg-grey-lightest': selectedDr === 'vegetarian',
                }"
                @click="changeRestriction('vegetarian')"
            >
                <vegetarian :w="80" :h="80"></vegetarian>
            </div>
        </div>

        <div
            class="flex flex-col p-4"
            v-if="selectedDr !== null && filteredRestaurants.length > 0"
        >
            <div v-for="restaurant in filteredRestaurants" :key="restaurant.id">
                <div
                    class="mx-4 mt-6 font-bold text-2xl mb-1 text-center"
                    :style="{ color: propertyHub.site.colors.primary }"
                >
                    {{ restaurant.title }}
                </div>
                <div class="p-4 md:p-8 border-t-2">
                    <div v-for="(menu, h) in restaurant.menus" :key="h">
                        <div
                            class="font-bold"
                            :style="{
                                color: propertyHub.site.colors.primary,
                            }"
                        >
                            {{ menu.name }}
                        </div>
                        <div v-for="(section, i) in menu.sections" :key="i">
                            <div class="mt-8 uppercase font-bold">
                                {{ section.title }}
                            </div>
                            <div
                                v-for="(item, j) in section.items"
                                :key="j"
                                class="mb-4"
                            >
                                <span class="underline"
                                    >{{ item.title }}

                                    <gluten-free
                                        v-if="item.dr.includes('gluten-free')"
                                        class="inline"
                                        :show-text="false"
                                        :w="40"
                                        :h="40"
                                    ></gluten-free>
                                    <keto
                                        v-if="item.dr.includes('keto')"
                                        class="inline"
                                        :show-text="false"
                                        :w="40"
                                        :h="40"
                                    ></keto>
                                    <vegan
                                        v-if="item.dr.includes('vegan')"
                                        class="inline"
                                        :show-text="false"
                                        :w="40"
                                        :h="40"
                                    ></vegan>
                                    <vegetarian
                                        v-if="item.dr.includes('vegetarian')"
                                        class="inline"
                                        :show-text="false"
                                        :w="40"
                                        :h="40"
                                    ></vegetarian>
                                </span>
                                <div>{{ item.desc }}</div>
                            </div>
                        </div>
                    </div>

                    <button
                        class="mt-4 text-abs-white snap-center whitespace-nowrap rounded-full border border-gray-300 text-sm py-1 px-4 uppercase"
                        :style="{
                            'background-color': propertyHub.site.colors.primary,
                        }"
                        @click="navigateTo(restaurant)"
                    >
                        <span v-if="propertyHub.currentLanguage === 'en'">
                            View
                            {{ restaurant.title }}
                            Details
                        </span>
                        <span v-if="propertyHub.currentLanguage === 'es'">
                            Ver Detalles de
                            {{ restaurant.title }}
                        </span>
                    </button>
                </div>
            </div>
        </div>
        <div
            class="flex flex-col p-4 pb-48"
            v-if="
                selectedDr !== null &&
                filteredRestaurants.length === 0 &&
                !loading
            "
        >
            <div
                class="mx-4 mt-6 font-bold text-2xl mb-1 text-center"
                :style="{ color: propertyHub.site.colors.primary }"
            >
                {{
                    propertyHub.localizations[propertyHub.currentLanguage][
                        'no-meals-available'
                    ]
                }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'DietaryDishesView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
        GlutenFree: () => import('../../components/icons/GlutenFree.vue'),
        Keto: () => import('../../components/icons/Keto.vue'),
        Vegan: () => import('../../components/icons/Vegan.vue'),
        Vegetarian: () => import('../../components/icons/Vegetarian.vue'),
    },

    data() {
        return {
            loading: true,
            restaurants: [],
            selectedDr: null,
            titleVisible: true,
            interval: null,
        };
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
        iconHeight() {
            return this.selectedDr ? 80 : 130;
        },
        filteredRestaurants() {
            const filtered = [];
            const restaurants = JSON.parse(JSON.stringify(this.restaurants));
            for (let i in restaurants) {
                let r = Object.assign(
                    {
                        id: restaurants[i].id,
                        slug: restaurants[i].slug,
                    },
                    restaurants[i].locale[this.propertyHub.currentLanguage]
                );
                if (this.selectedDr) {
                    for (let j = r.menus.length - 1; j >= 0; j--) {
                        for (
                            let k = r.menus[j].sections.length - 1;
                            k >= 0;
                            k--
                        ) {
                            for (
                                let l = r.menus[j].sections[k].items.length - 1;
                                l >= 0;
                                l--
                            ) {
                                if (
                                    !r.menus[j].sections[k].items[
                                        l
                                    ].dr.includes(this.selectedDr)
                                ) {
                                    r.menus[j].sections[k].items.splice(l, 1);
                                }
                            }
                            if (r.menus[j].sections[k].items.length === 0) {
                                r.menus[j].sections.splice(k, 1);
                            }
                        }
                        if (r.menus[j].sections.length === 0) {
                            r.menus.splice(j, 1);
                        }
                    }
                }
                if (r.menus.length > 0) {
                    filtered.push(r);
                }
            }
            return filtered;
        },
    },
    beforeDestroy() {
        if (this.interval) {
            window.clearInterval(this.interval);
            this.interval = null;
        }
    },
    mounted() {
        document.title = this.propertyHub.site.name + ' Dietary Dishes';
        this.sendPageView(this.propertyHub.site.name + ' Dietary Dishes');

        this.requestRetrieveRestaurants();
        this.runVisibilityInterval();
    },

    methods: {
        ...mapActions(['getDietaryRestrictiveRestaurants', 'sendPageView']),

        navigateTo(restaurant) {
            this.selectedRestaurant = restaurant;

            setTimeout(() => {
                this.$router.push(`/property-hub/dining/${restaurant.slug}`);
            }, 500);
        },

        requestRetrieveRestaurants() {
            this.loading = true;
            this.getDietaryRestrictiveRestaurants().then((response) => {
                this.loading = false;
                this.restaurants = response.data.data;
            });
        },

        changeRestriction(dr) {
            this.$refs.title.scrollIntoView({
                behavior: 'smooth',
            });
            this.selectedDr = dr;
        },
        runVisibilityInterval() {
            this.interval = setInterval(() => {
                var bounding = this.$refs.title.getBoundingClientRect();
                if (
                    bounding.top >= -20 &&
                    bounding.left >= 0 &&
                    bounding.right <=
                        (window.innerWidth ||
                            document.documentElement.clientWidth) &&
                    bounding.bottom <=
                        (window.innerHeight ||
                            document.documentElement.clientHeight)
                ) {
                    this.titleVisible = true;
                } else {
                    this.titleVisible = false;
                }
            });
        },
    },
};
</script>

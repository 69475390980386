<template>
    <div class="relative w-full">
        <swiper
            ref="mySwiper"
            :slides-per-view="1"
            :loop="true"
            :pagination="{
                clickable: true,
            }"
            :centered-slides="false"
            :navigation="true"
            :modules="modules"
            @activeIndexChange="onActiveIndexChange"
        >
            <slot></slot>
        </swiper>

        <div
            v-if="numberOfSlides > 1"
            class="absolute z-10 inset-0 pointer-events-none flex justify-between items-center"
        >
            <button class="pointer-events-auto" @click="prev">
                <icon-left-arrow
                    :color="arrowColor"
                    :w="50"
                    :h="50"
                ></icon-left-arrow>
            </button>
            <button class="pointer-events-auto" @click="next">
                <icon-right-arrow
                    :color="arrowColor"
                    :w="50"
                    :h="50"
                ></icon-right-arrow>
            </button>
        </div>

        <div
            v-if="numberOfSlides > 1 && pagination"
            class="absolute z-10 inset-0 pointer-events-none flex justify-center items-end"
        >
            <div class="pb-8 flex space-x-2">
                <div
                    v-for="n in numberOfSlides"
                    :key="n"
                    class="w-2 h-2 rounded-full"
                    :class="{
                        'bg-white':
                            n === activeIndex || (activeIndex === 0 && n === 1),
                        'bg-white opacity-50':
                            n !== activeIndex && (activeIndex !== 0 || n !== 1),
                    }"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import { Navigation, Pagination } from 'swiper';
import { SwiperCore, Swiper } from 'swiper-vue2';

// Import Swiper styles
import 'swiper/css';

SwiperCore.use([Navigation, Pagination]);

export default {
    name: 'GeneralSwiper',

    components: {
        Swiper,
    },

    props: {
        pagination: {
          type: Boolean,
          default: true,
        },
        arrowColor: {
            type: String,
            default: 'white',
        },
    },

    data() {
        return {
            swiper: null,
            activeIndex: 1,
            modules: [Pagination, Navigation],
        };
    },

    computed: {
        numberOfSlides() {
            if (this.swiper && this.swiper.slides) {
                return this.swiper.slides.length - 2;
            }
            return 0;
        },
    },

    mounted() {
        this.updateOnInit();
    },

    methods: {
        updateOnInit() {
            setTimeout(() => {
                if (this.$refs.mySwiper && this.$refs.mySwiper.swiperRef) {
                    this.$refs.mySwiper.swiperRef.update();
                    this.swiper = this.$refs.mySwiper.swiperRef;
                } else {
                    this.updateOnInit();
                }
            }, 1000);
        },

        next() {
            this.swiper.slideNext();
        },

        prev() {
            this.swiper.slidePrev();
        },

        goToSlide(index) {
            this.swiper.slideTo(index);
        },

        onActiveIndexChange(swiper) {
            if (swiper) {
                const index = swiper.activeIndex % this.numberOfSlides;
                if (index) {
                    this.activeIndex = index;
                } else {
                    this.activeIndex = this.numberOfSlides;
                }
            }
        },
    },
};
</script>

<style scoped>
.swiper-slide {
    width: auto;
    height: auto;
}
</style>

<style>
.swiper-wrapper {
    @apply items-stretch;
}
</style>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <div v-if="restaurant && !loading">
            <hero-image
                :image="
                    restaurant.locale[propertyHub.currentLanguage].image ||
                    '/imgs/property-hub/bruna-branco-t8hTmte4O_g-unsplash.jpg'
                "
            ></hero-image>

            <div class="px-5">
                <menu-links-slider
                    :restaurant-slug="
                        restaurant.locale[propertyHub.currentLanguage].slug
                    "
                    :menus="
                        restaurant.locale[propertyHub.currentLanguage].menus
                    "
                />
            </div>

            <div class="pt-4 px-5 pb-8 text-gray-700" v-if="menuFromRestaurant">
                <div>
                    <div
                        class="font-bold text-lg mb-6"
                        :style="{ color: propertyHub.site.colors.primary }"
                    >
                        {{
                            restaurant.locale[propertyHub.currentLanguage].title
                        }}
                        -
                        {{ menuFromRestaurant.name }}
                    </div>
                </div>

                <template
                    v-if="
                        menu &&
                        menu.locale &&
                        menu.locale[propertyHub.currentLanguage]
                    "
                >
                    <div
                        v-for="(section, sectionkey) in menu.locale[
                            propertyHub.currentLanguage
                        ].sections"
                        :key="sectionkey"
                        class="relative pb-8"
                    >
                        <div
                            class="text-2xl uppercase my-4 mx-2 font-bold run-through text-center w-full"
                            :style="{ color: propertyHub.site.colors.primary }"
                        >
                            <span>{{ section.title }}</span>
                        </div>

                        <div class="mt-3">
                            <div
                                v-for="(item, itemKey) in section.items"
                                :key="itemKey"
                                class="mb-6"
                            >
                                <div class="flex">
                                    <div
                                        class="w-full text-sm uppercase flex justify-between"
                                    >
                                        <div class="flex space-x-4">
                                            <div>
                                                <div>
                                                    <span
                                                        class="font-bold text-md text-gray-700"
                                                        >{{ item.title }}
                                                        <gluten-free
                                                            v-if="
                                                                item.dr.includes(
                                                                    'gluten-free'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></gluten-free>
                                                        <low-cal
                                                            v-if="
                                                                item.dr.includes(
                                                                    'low-cal'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></low-cal>
                                                        <low-carb
                                                            v-if="
                                                                item.dr.includes(
                                                                    'low-carb'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></low-carb>
                                                        <keto
                                                            v-if="
                                                                item.dr.includes(
                                                                    'keto'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></keto>
                                                        <vegan
                                                            v-if="
                                                                item.dr.includes(
                                                                    'vegan'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></vegan>
                                                        <vegetarian
                                                            v-if="
                                                                item.dr.includes(
                                                                    'vegetarian'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></vegetarian>
                                                        <has-shellfish
                                                            v-if="
                                                                item.dr.includes(
                                                                    'has-shellfish'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></has-shellfish>
                                                        <has-nuts
                                                            v-if="
                                                                item.dr.includes(
                                                                    'has-nuts'
                                                                )
                                                            "
                                                            class="inline"
                                                            :show-text="false"
                                                            :w="40"
                                                            :h="40"
                                                        ></has-nuts>
                                                    </span>
                                                </div>
                                                <div
                                                    class="mt-2 text-sm normal-case"
                                                >
                                                    {{ item.desc }}
                                                </div>
                                            </div>
                                        </div>
                                        <span class="mr-8">{{
                                            item.price
                                        }}</span>
                                    </div>
                                </div>
                            </div>

                            <div
                                v-for="(
                                    subSection, subSectionkey
                                ) in section.sections"
                                :key="'sub' + subSectionkey"
                                class="relative pb-8"
                            >
                                <div
                                    class="text-xl uppercase my-4"
                                    :style="{
                                        color: propertyHub.site.colors.primary,
                                    }"
                                >
                                    <span>{{ subSection.title }}</span>
                                </div>

                                <div class="mt-3">
                                    <div
                                        v-for="(
                                            item, itemKey
                                        ) in subSection.items"
                                        :key="itemKey"
                                        class="mb-6"
                                    >
                                        <div class="flex">
                                            <div
                                                class="w-full text-sm uppercase flex justify-between"
                                            >
                                                <div class="flex space-x-4">
                                                    <div>
                                                        <div>
                                                            <span
                                                                class="font-bold text-md text-gray-700"
                                                                >{{
                                                                    item.title
                                                                }}
                                                                <gluten-free
                                                                    v-if="
                                                                        item.dr.includes(
                                                                            'gluten-free'
                                                                        )
                                                                    "
                                                                    class="inline"
                                                                    :show-text="
                                                                        false
                                                                    "
                                                                    :w="40"
                                                                    :h="40"
                                                                ></gluten-free>
                                                                <keto
                                                                    v-if="
                                                                        item.dr.includes(
                                                                            'keto'
                                                                        )
                                                                    "
                                                                    class="inline"
                                                                    :show-text="
                                                                        false
                                                                    "
                                                                    :w="40"
                                                                    :h="40"
                                                                ></keto>
                                                                <vegan
                                                                    v-if="
                                                                        item.dr.includes(
                                                                            'vegan'
                                                                        )
                                                                    "
                                                                    class="inline"
                                                                    :show-text="
                                                                        false
                                                                    "
                                                                    :w="40"
                                                                    :h="40"
                                                                ></vegan>
                                                                <vegetarian
                                                                    v-if="
                                                                        item.dr.includes(
                                                                            'vegetarian'
                                                                        )
                                                                    "
                                                                    class="inline"
                                                                    :show-text="
                                                                        false
                                                                    "
                                                                    :w="40"
                                                                    :h="40"
                                                                ></vegetarian>
                                                                <has-shellfish
                                                                    v-if="
                                                                        item.dr.includes(
                                                                            'has-shellfish'
                                                                        )
                                                                    "
                                                                    class="inline"
                                                                    :show-text="
                                                                        false
                                                                    "
                                                                    :w="40"
                                                                    :h="40"
                                                                ></has-shellfish>
                                                                <has-nuts
                                                                    v-if="
                                                                        item.dr.includes(
                                                                            'has-nuts'
                                                                        )
                                                                    "
                                                                    class="inline"
                                                                    :show-text="
                                                                        false
                                                                    "
                                                                    :w="40"
                                                                    :h="40"
                                                                ></has-nuts>
                                                            </span>
                                                        </div>
                                                        <div
                                                            class="mt-2 text-sm normal-case"
                                                        >
                                                            {{ item.desc }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <span class="mr-8">{{
                                                    item.price
                                                }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <div
                    v-if="
                        menu.locale &&
                        menu.locale[propertyHub.currentLanguage] &&
                        menu.locale[propertyHub.currentLanguage]
                            .specialtysection.items.length > 0
                    "
                    class="mt-3"
                >
                    <div
                        class="text-2xl uppercase mb-8 mx-2 font-bold run-through text-center w-full"
                        :style="{
                            color: propertyHub.site.colors.primary,
                        }"
                    >
                        <span>
                            {{
                                propertyHub.localizations[
                                    propertyHub.currentLanguage
                                ]['specialty-items']
                            }}</span
                        >
                    </div>
                    <div
                        v-for="(item, itemKey) in menu.locale[
                            propertyHub.currentLanguage
                        ].specialtysection.items"
                        :key="itemKey"
                        class="my-12"
                    >
                        <div class="w-full my-4">
                            <img
                                v-if="item.image"
                                :src="item.image"
                                :alt="`Picture of ${item.title}`"
                                class="object-fill w-full md:w-2/3 h-full mx-auto"
                            />
                        </div>
                        <h2
                            class="my-8 px-6 md:px-12 text-center font-cursive-allison font-light text-gray-600 text-[4rem] leading-[0.75em]"
                        >
                            {{ item.title }}
                        </h2>
                        <div class="mt-2 px-0 md:px-32 text-sm text-center">
                            {{ item.desc }}
                            <br />
                            <span class="block mt-4 font-bold">{{
                                item.price
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!restaurant && !loading">
            <div
                class="text-center px-8 mt-8"
                :style="{ color: propertyHub.site.colors.primary }"
            >
                <div class="text-xl font-extrabold">
                    We couldn't find what you were looking for.
                </div>
                <div class="mt-4">
                    Click the back arrow above or the navigation below to find
                    the restaurant you are looking for
                </div>
            </div>
        </div>

        <p class="px-4 italic text-sm">
            {{
                propertyHub.localizations[propertyHub.currentLanguage][
                    'food-prep-disclaimer'
                ]
            }}
            <br />
            {{
                propertyHub.localizations[propertyHub.currentLanguage][
                    'raw-food-prep-disclaimer'
                ]
            }}
        </p>

        <div class="spacer h-16"></div>
        <div
            v-if="dietaryRestrictionsOnThisMenu.length > 0"
            class="fixed left-0 bottom-0 flex mx-auto flex-wrap justify-center w-full z-10 bg-white"
        >
            <div>
                <gluten-free
                    v-if="dietaryRestrictionsOnThisMenu.includes('gluten-free')"
                    :w="80"
                    :h="80"
                ></gluten-free>
            </div>
            <div>
                <keto
                    v-if="dietaryRestrictionsOnThisMenu.includes('keto')"
                    :w="80"
                    :h="80"
                ></keto>
            </div>
            <div>
                <vegan
                    v-if="dietaryRestrictionsOnThisMenu.includes('vegan')"
                    :w="80"
                    :h="80"
                ></vegan>
            </div>
            <div>
                <vegetarian
                    v-if="dietaryRestrictionsOnThisMenu.includes('vegetarian')"
                    :w="80"
                    :h="80"
                ></vegetarian>
            </div>
            <div>
                <has-shellfish
                    v-if="
                        dietaryRestrictionsOnThisMenu.includes('has-shellfish')
                    "
                    :w="80"
                    :h="80"
                ></has-shellfish>
            </div>
            <div>
                <has-nuts
                    v-if="dietaryRestrictionsOnThisMenu.includes('has-nuts')"
                    :w="80"
                    :h="80"
                ></has-nuts>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: 'MenuView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
        GlutenFree: () => import('../../components/icons/GlutenFree.vue'),
        LowCal: () => import('../../components/icons/LowCal.vue'),
        LowCarb: () => import('../../components/icons/LowCarb.vue'),
        Keto: () => import('../../components/icons/Keto.vue'),
        Vegan: () => import('../../components/icons/Vegan.vue'),
        Vegetarian: () => import('../../components/icons/Vegetarian.vue'),
        HasShellfish: () => import('../../components/icons/HasShellfish.vue'),
        HasNuts: () => import('../../components/icons/HasNuts.vue'),
        MenuLinksSlider: () => import('./components/MenuLinksSlider.vue'),
    },

    data() {
        return {
            loading: true,
            restaurant: null,
            menu: {},
            menuId: null,
        };
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),

        ...mapGetters(['getMenuFromSlug', 'getRestaurantFromSlug']),

        menuFromRestaurant() {
            if (
                this.restaurant &&
                this.restaurant.locale &&
                this.restaurant.locale[this.propertyHub.currentLanguage]
            ) {
                return this.restaurant.locale[this.propertyHub.currentLanguage]
                    .menus[this.menuId];
            }
            return null;
        },
        dietaryRestrictionsOnThisMenu() {
            var all = [];
            if (
                this.menu &&
                this.menu.locale &&
                this.menu.locale.hasOwnProperty(
                    this.propertyHub.currentLanguage
                )
            ) {
                for (let i in this.menu.locale[this.propertyHub.currentLanguage]
                    .sections) {
                    const section =
                        this.menu.locale[this.propertyHub.currentLanguage]
                            .sections[i];
                    for (let j in section.items) {
                        all = [...all, ...section.items[j].dr];
                    }
                    // sub sections
                    for (let l in section.sections) {
                        const subSection = section.sections[l];
                        for (let m in subSection.items) {
                            all = [...all, ...subSection.items[m].dr];
                        }
                    }
                }
            }
            return all;
        },
    },

    watch: {
        $route(to) {
            this.menuId = to.params.menu;
        },
    },

    created() {
        this.$watch(
            () => this.$route.params,
            () => {
                this.menuId = this.$route.params.menu;
                this.requestRetrieveRestaurants();
            }
        );
        this.menuId = this.$route.params.menu;
        this.requestRetrieveRestaurants();
    },

    methods: {
        ...mapActions(['getRestaurants', 'getRestaurantMenu', 'sendPageView']),

        requestRetrieveRestaurants() {
            this.loading = true;
            this.getRestaurants()
                .then(() => {
                    this.restaurant = this.getRestaurantFromSlug(
                        this.$route.params.slug
                    );
                    this.requestRetrieveMenu();
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        requestRetrieveMenu() {
            this.loading = true;
            if (this.menuFromRestaurant) {
                this.getRestaurantMenu(this.menuFromRestaurant.id)
                    .then((menu) => {
                        this.menu = menu;

                        const menuName =
                            this.restaurant.locale[
                                this.propertyHub.currentLanguage
                            ].title +
                            ' - ' +
                            this.menu.name;
                        document.title =
                            this.propertyHub.site.name + ' ' + menuName;
                        this.sendPageView(
                            this.propertyHub.site.name + ' ' + menuName
                        );
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
    },
};
</script>

<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
export default {
    name: "DeviseContentSectionAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "content-section-main-menu",
                    apiendpoint: "/api/devise/content-sections",
                    component: "workflow-real-index",
                    message:
                        "Cool! We are working on <strong>Content Sections</strong>.<br> What would you like to do?",
                    columns: [
                        {
                            key: "label",
                            label: "Title"
                        }
                    ],
                    links: [
                        {
                            label: "Create a Content Section",
                            nextStep: "create-content-section"
                        }
                    ],
                    actions: [
                        {
                            key: "edit",
                            label: "Edit",
                            nextStep: "edit-content-section"
                        },
                        {
                            key: "delete",
                            label: "Remove",
                            nextStep: "delete-content-section"
                        }
                    ]
                },
                {
                    key: "create-content-section",
                    component: "devise-content-section-create",
                    message:
                        "Fill out the fields below and create the new content section. Name and Special Name fields are required.",
                    end: true
                },
                {
                    key: "edit-content-section",
                    component: "devise-content-section-edit",
                    message: `Time to change things up! Let's edit this content section.<br> What would you like to edit?`,
                    end: true
                },
                {
                    key: "delete-content-section",
                    component: "devise-content-section-delete",
                    message:
                        "This will permanently delete this content section from the system. Are you sure you want to do this?",
                    end: true
                }
            ]
        };
    }
};
</script>

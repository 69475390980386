const getters = {
    // App State
    appState: (state) => state.appState,

    airports: (state) => state.airports,

    // Current Resort and Brand
    currentResortAndBrand: (state, getters) => {
        if (
            getters['devise/currentPage'] &&
            typeof getters['devise/currentPage'].site !== 'undefined'
        ) {
            const resort =
                getters['devise/currentPage'].site.data.currentResort;
            const brand = state.brands.data.find(
                (brand) => brand.id === resort.brand_id
            );
            return {
                resort,
                brand,
            };
        }
    },
    promoCodes: (state) => state.promoCodes,
    resortPromoCodes: (state) => state.resortPromoCodes,
    bookingDateLimits: (state) => state.bookingDateLimits,

    // Menu
    menus: (state) => state.menus,

    // Specific menu based on id
    menu: (state) => (id) =>
        state.menus.data.find((menu) => menu.id === parseInt(id)),

    // Booking Code Triggers
    bookingCodeTriggers: (state) => state.bookingCodeTriggers,
    bookingCodeTrigger: (state) => (id) =>
        state.bookingCodeTriggers.data.find(
            (bookingCodeTrigger) => bookingCodeTrigger.id === parseInt(id)
        ),

    // Brand
    brands: (state) => state.brands,
    brand: (state) => (id) =>
        state.brands.data.find((brand) => brand.id === parseInt(id)),

    // Clocks
    clocks: (state) => state.clocks,
    clock: (state) => (id) =>
        state.clocks.data.find((clock) => clock.id === parseInt(id)),

    // Posts
    posts: (state) => state.posts,
    post: (state) => (id) =>
        state.posts.data.find((post) => post.id === parseInt(id)),

    // Resort
    resorts: (state) => state.resorts,

    // Specific resort based on id
    resort: (state) => (id) =>
        state.resorts.data.find((resort) => resort.id === parseInt(id)),

    // Special
    specials: (state) => state.specials,

    specialsForPage: (state) => state.specialsForPage,

    // Specific special based on id
    special: (state) => (id) =>
        state.specials.data.find((special) => special.id === parseInt(id)),

    // Property Hub
    getRestaurantFromSlug: (state) => (slug) => {
        if (state.restaurants.length > 0) {
            const restaurant = state.restaurants.find((r) => {
                for (const locale in r.locale) {
                    if (r.locale[locale].slug === slug) {
                        return true;
                    }
                }
            });
            return restaurant;
        }
    },

    getMenuFromSlug: (state) => (slug, menuKey) => {
        let translation = null;
        state.restaurants.find((restaurant) => {
            for (const locale in restaurant.locale) {
                if (restaurant.locale[locale].slug === slug) {
                    translation = locale;
                    return true;
                }
            }
        });

        return translation.menus[menuKey];
    },

    getContentFromSlug: (state) => (slug) => {
        if (state.propertyHub.content.length > 0) {
            const content = state.propertyHub.content.find((content) => {
                for (const locale in content.locale) {
                    if (content.locale[locale].slug === slug) {
                        return true;
                    }
                }
            });
            return content;
        }
    },

    getContentFromId: (state) => (id) => {
        if (state.propertyHub.content.length > 0) {
            return state.propertyHub.content.find(
                (content) => content.id === id
            );
        }

        return null;
    },

    getNextRestaurant: (state) => (currentRestaurantSlug) => {
        const currentRestaurantKey = state.restaurants.findIndex(
            (restaurant) => restaurant.slug === currentRestaurantSlug
        );
        if (currentRestaurantKey === state.restaurants.length - 1) {
            return state.restaurants[0];
        } else {
            return state.restaurants[currentRestaurantKey + 1];
        }
    },
};

export default getters;

<template>
    <div
        class="dvs-relative dvs-z-50 dvs-flex dvs-justify-center dvs-ml-8 dvs-pointer-events-auto"
        style="max-width:300px;"
    >
        <div
            class="dvs-bg-white dvs-rounded dvs-shadow-lg dvs-p-8 dvs-text text-grey-darkest"
        >
            <div class="text-md font-bold mb-3">Refresh Route Cache</div>
            <p class="text-sm">
                Use this button to refresh the route cache on the servers.
            </p>

            <button
                class="dvs-btn dvs-btn-primary dvs-btn-sm"
                @click="requestRefresh"
            >
                Refresh Cache
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "RouteCacheRefreshAdmin",

    methods: {
        requestRefresh() {
            window.axios.post(
                "https://mothership.app/deployments/playa-routes-refresh"
            );
        }
    }
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <transition
            enter-active-class="transition-all duration-500"
            enter-class="opacity-0"
            enter-to-class="opacity-1"
            leave-active-class="transition-all duration-500"
            leave-class="opacity-1"
            leave-to-class="opacity-0"
            mode="out-in"
        >
            <div v-if="event">
                <hero-image
                    :image="
                        event.locale[propertyHub.currentLanguage].image ||
                        '/imgs/property-hub/couple-at-bar.png'
                    "
                ></hero-image>

                <div class="pt-4 px-5 pb-8 text-gray-700 space-y-6">
                    <div>
                        <div
                            class="font-bold text-lg mb-1"
                            :style="{ color: propertyHub.site.colors.primary }"
                        >
                            {{
                                event.locale[propertyHub.currentLanguage].title
                            }}
                        </div>

                        <div
                            class="text-sm mb-4"
                            :style="{
                                color: propertyHub.site.colors.secondary,
                            }"
                        >
                            {{
                                propertyHub.localizations[
                                    propertyHub.currentLanguage
                                ][
                                    event.locale[propertyHub.currentLanguage]
                                        .category
                                ]
                            }}
                        </div>

                        <div
                            class="text-sm leading-relaxed"
                            v-html="
                                event.locale[propertyHub.currentLanguage]
                                    .content
                            "
                        ></div>
                    </div>

                    <div class="text-sm">
                        <span
                            :style="{
                                color: propertyHub.site.colors.secondary,
                            }"
                        >
                            {{
                                propertyHub.localizations[
                                    propertyHub.currentLanguage
                                ]['event-time']
                            }}
                        </span>
                        <div>
                            {{ formatTime(event.start_date) }} -
                            {{ formatTime(event.end_date) }}
                        </div>
                    </div>

                    <div
                        class="text-sm"
                        v-if="
                            event.locale[propertyHub.currentLanguage].location
                        "
                    >
                        <span
                            :style="{
                                color: propertyHub.site.colors.secondary,
                            }"
                        >
                            {{
                                propertyHub.localizations[
                                    propertyHub.currentLanguage
                                ]['location']
                            }}
                        </span>
                        <div>
                            {{
                                event.locale[propertyHub.currentLanguage]
                                    .location
                            }}
                        </div>
                    </div>

                    <div
                        v-if="
                            event.locale[propertyHub.currentLanguage].links
                                .length
                        "
                        class="text-sm"
                    >
                        <div
                            class="mb-3"
                            :style="{
                                color: propertyHub.site.colors.secondary,
                            }"
                        >
                            {{
                                propertyHub.localizations[
                                    propertyHub.currentLanguage
                                ]['associated-links']
                            }}
                        </div>

                        <div
                            class="mt-4 flex justify-start items-center space-x-3 snap-x px-5 overflow-x-scroll scrollbar-hide md:scrollbar-default"
                        >
                            <button
                                v-for="(link, key) in event.locale[
                                    propertyHub.currentLanguage
                                ].links"
                                :key="key"
                                class="text-abs-white snap-center rounded-full border border-gray-300 text-sm py-1 px-4 uppercase"
                                :style="{
                                    'background-color': propertyHub.site.colors.primary,
                                }"
                                @click="clickedLink(link)"
                            >
                                {{ link.linktext }}
                            </button>
                        </div>
                    </div>
                    <div
                        v-if="
                            event.locale[propertyHub.currentLanguage].gallery
                                .length
                        "
                        class="mt-4"
                    >
                        <div
                            v-for="(image, id) in event.locale[
                                propertyHub.currentLanguage
                            ].gallery"
                            :key="id"
                        >
                            <img
                                v-devise-image="{ image: image }"
                                class="w-full mt-5"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <loading-stuff v-else-if="loading" key="loader" />

            <div
                v-else
                class="flex justify-center px-8 text-center mt-12 text-xl text-gray-700 italic font-serif"
                key="no-events"
            >
                {{
                    propertyHub.localizations[propertyHub.currentLanguage][
                        'unable-to-find-event'
                    ]
                }}
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
    name: 'EventsView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        LoadingStuff: () => import('./components/LoadingStuff.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
    },

    data() {
        return {
            loading: true,
            event: null,
        };
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
            api: (state) => state.devise.api,
        }),
        ...mapGetters(['getEventFromSlug', 'getContentFromId']),
    },

    mounted() {
        this.requestRetrieveEvents();
    },

    methods: {
        ...mapActions(['getPropertyHubEvents', 'sendPageView']),

        formatTime(date) {
            return dayjs(date).format('h:mm a');
        },

        requestRetrieveEvents() {
            this.loading = true;

            window.axios
                .get(`${this.api.baseUrl}events/by-slug`, {
                    params: {
                        slug: this.$route.params.slug,
                    },
                })
                .then((response) => {
                    this.event = response.data.data;

                    document.title =
                        this.propertyHub.site.name +
                        ' ' +
                        this.event.locale[this.propertyHub.currentLanguage]
                            .title;
                    this.sendPageView(
                        this.propertyHub.site.name +
                            ' ' +
                            this.event.locale[this.propertyHub.currentLanguage]
                                .title
                    );
                })
                .finally(() => {
                    this.loading = false;
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        },


        clickedLink(link) {
            if (link.type === 'Playa Safe Stay') {
                return this.$router.push('/playa-safe-stay');
            }

            if (link.type === 'Make Reservation' || link.type === 'Link Off') {
                const firstChar = Array.from(link.linkurl)[0];
                if (firstChar === '/') {
                    // if the url starts with / then it's internal
                    return this.$router.push(link.linkurl);
                } else {
                    return window.open(link.linkurl, '_blank');
                }
            }
            if (link.type === 'Click To Call') {
                return window.open(`tel:${link.linkurl}`, '_blank');
            }
            if (link.type === 'Download File') {
                const url = window.location.pathname;
                const filename = url.substring(url.lastIndexOf('/') + 1);
                return this.downloadFile(link.linkurl, filename);
            }
            if (link.type === 'Content') {
                const content = this.getContentFromId(link.pageId);
                if (
                    content &&
                    content.locale &&
                    content.locale[this.propertyHub.currentLanguage]
                ) {
                    return this.$router.push(
                        `/property-hub/info/${
                            content.locale[this.propertyHub.currentLanguage]
                                .slug
                        }`
                    );
                }
            }
            if (link.linkurl) {
                return this.$router.push(link.linkurl);
            }
        },
    },
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <iframe
            class="mt-12"
            src="https://zilaracapcana.resortsbyhyatt.com/?seating"
        ></iframe>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'CabanasView',
    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
    },
    mounted: function () {
        document.title = this.propertyHub.site.name + ' Dining Reservations';
        this.sendPageView(this.propertyHub.site.name + ' Dining Reservations');
    },
    methods: {
        ...mapActions(['sendPageView']),
    },
    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
    },
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <PromotionSlider class="mt-8" location="Dining" />

        <div
            class="mx-4 mt-6 font-bold text-2xl mb-1 text-center"
            :style="{ color: propertyHub.site.colors.primary }"
        >
            <span v-if="propertyHub.currentLanguage === 'en'">
                Restaurants
            </span>
            <span v-if="propertyHub.currentLanguage === 'es'">
                Restaurantes
            </span>
        </div>
        <div class="flex flex-col space-y-4 p-4">
            <div
                v-for="restaurant in restaurantSection"
                :key="restaurant.id"
                @click="navigateTo(restaurant)"
                class="cursor-pointer rounded-3xl overflow-hidden"
            >
                <div
                    v-if="restaurant.locale[propertyHub.currentLanguage]"
                    class="relative w-full overflow-hidden transition-all duration-1000 ease-out"
                >
                    <img
                        :src="
                            restaurant.locale[propertyHub.currentLanguage]
                                .image || '/imgs/property-hub/couple-at-bar.png'
                        "
                        alt=""
                        class="object-cover w-full h-full"
                    />

                    <button
                        class="absolute right-0 left-0 bottom-0 flex justify-between items-center px-6 py-3 text-white text-left uppercase font-light text-sm tracking-wide transition-opacity duration-500"
                        :style="{
                            backgroundColor: propertyHub.site.colors.primary,
                        }"
                    >
                        <div>
                            <div class="font-medium text-sm">
                                {{
                                    restaurant.locale[
                                        propertyHub.currentLanguage
                                    ].title
                                }}
                            </div>
                            <div class="font-medium text-xs opacity-75">
                                {{
                                    restaurant.locale[
                                        propertyHub.currentLanguage
                                    ].type
                                }}
                            </div>
                        </div>
                        <div
                            class="rounded-full flex items-center justify-center border-2 border-white"
                        >
                            <svg
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
        <div
            class="mx-4 mt-6 font-bold text-2xl mb-1 text-center"
            :style="{ color: propertyHub.site.colors.primary }"
        >
            <span v-if="propertyHub.currentLanguage === 'en'">
                Bars & Lounges
            </span>
            <span v-if="propertyHub.currentLanguage === 'es'">
                Bares y Lounges
            </span>
        </div>
        <div class="flex flex-col space-y-4 p-4">
            <div
                v-for="restaurant in barsSection"
                :key="restaurant.id"
                @click="navigateTo(restaurant)"
                class="cursor-pointer rounded-3xl overflow-hidden"
            >
                <div
                    v-if="restaurant.locale[propertyHub.currentLanguage]"
                    class="relative w-full overflow-hidden transition-all duration-1000 ease-out"
                >
                    <img
                        :src="
                            restaurant.locale[propertyHub.currentLanguage]
                                .image || '/imgs/property-hub/couple-at-bar.png'
                        "
                        alt=""
                        class="object-cover w-full h-full"
                    />

                    <button
                        class="absolute right-0 left-0 bottom-0 flex justify-between items-center px-6 py-3 text-white text-left uppercase font-light text-sm tracking-wide transition-opacity duration-500"
                        :style="{
                            backgroundColor: propertyHub.site.colors.primary,
                        }"
                    >
                        <div>
                            <div class="font-medium text-sm">
                                {{
                                    restaurant.locale[
                                        propertyHub.currentLanguage
                                    ].title
                                }}
                            </div>
                            <div class="font-medium text-xs opacity-75">
                                {{
                                    restaurant.locale[
                                        propertyHub.currentLanguage
                                    ].type
                                }}
                            </div>
                        </div>
                        <div
                            class="rounded-full flex items-center justify-center border-2 border-white"
                        >
                            <svg
                                class="w-6 h-6"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    name: 'DiningView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        PromotionSlider: () => import('./components/PromotionSlider.vue'),
    },

    data() {
        return {
            selectedRestaurant: null,
        };
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
            restaurants: 'restaurants',
        }),
        restaurantSection() {
            return this.restaurants.filter((restaurant) => {
                // i think we can lean on english for this. it will always be there
                return restaurant.locale['en'].section === 'Restaurant';
            });
        },
        barsSection() {
            return this.restaurants.filter((restaurant) => {
                // i think we can lean on english for this. it will always be there
                return restaurant.locale['en'].section === 'Bars and Lounges';
            });
        },
    },

    mounted() {
        document.title = this.propertyHub.site.name + ' Dining, Drinks & Menus';
        this.sendPageView(
            this.propertyHub.site.name + ' Dining, Drinks & Menus'
        );

        this.requestRetrieveRestaurants();
    },

    methods: {
        ...mapActions(['getRestaurants', 'sendPageView']),

        navigateTo(restaurant) {
            this.selectedRestaurant = restaurant;

            setTimeout(() => {
                this.$router.push(
                    `/property-hub/dining/${
                        restaurant.locale[this.propertyHub.currentLanguage].slug
                    }`
                );
            }, 500);
        },

        requestRetrieveRestaurants() {
            this.loading = true;
            this.getRestaurants().finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

<template>
    <div class="relative w-full">
        <button
            class="pointer-events-auto absolute top-0 right-0 h-16 w-16 flex justify-center items-center transform-gpu duration-200 ease-out z-10"
            @click="toggleMenu"
            :style="{
                color: propertyHub.site.colors.primary,
            }"
        >
            <menu-icon class="h-6 w-6"></menu-icon>
        </button>

        <PropertyHubNavigation
            ref="navigation"
            display-mode="hidden"
            class="absolute top-0 w-full z-20"
        />

        <img
            :src="site.homeImage"
            alt=""
            class="absolute inset-0 object-cover w-full h-full"
        />

        <div
            class="relative text-white pointer-events-none flex flex-col items-center justify-center"
        >
            <div class="text-center mt-6">
                <div class="relative flex justify-center">
                    <img :src="site.homeLogo" :alt="site.name" class="w-3/5" />
                </div>
            </div>
        </div>

        <button
            class="fixed bottom-12 rounded-full absolute-center-x font-light px-6 text-xl flex justify-center text-abs-white items-center border-4"
            :style="{
                backgroundColor: propertyHub.site.colors.secondary,
                borderColor: propertyHub.site.colors.secondary,
            }"
            @click="toggleMenu"
        >
            {{
                propertyHub.currentLanguage === 'en' ? 'WELCOME' : 'BIENVENIDO'
            }}
        </button>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import PropertyHubNavigation from './PropertyHubNavigation.vue';
import MenuIcon from 'vue-feather-icons/icons/MenuIcon';

export default {
    name: 'HomeView',

    components: {
        PropertyHubNavigation,
        MenuIcon,
    },

    computed: {
        ...mapState({
            site: (state) => state.propertyHub.site,
            propertyHub: (state) => state.propertyHub,
        }),
    },
    mounted: function () {
        document.title = this.propertyHub.site.name + ' Home';
        this.sendPageView(this.propertyHub.site.name + ' Home');
    },
    methods: {
        ...mapActions(['sendPageView']),
        toggleMenu() {
            this.$refs.navigation.toggleMenu();
        },
    },
};
</script>

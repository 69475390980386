<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: "DeviseClockAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "clock-main-menu",
                    component: "workflow-menu",
                    message:
                        "Cool! We are working on <strong>Clocks</strong>.<br> What would you like to do?",
                    options: [
                        {
                            label: "Create a Clock",
                            nextStep: "create-clock"
                        },
                        {
                            label: "Edit a Clock",
                            nextStep: "search-for-clock",
                            stepAfterNext: "edit-clock"
                        },
                        {
                            label: "Remove a Clock",
                            nextStep: "search-for-clock",
                            stepAfterNext: "delete-clock"
                        }
                    ]
                },
                {
                    key: "create-clock",
                    component: "devise-clock-create",
                    message:
                        "Fill out the fields below and create the new clock. Name and Special Name fields are required.",
                    end: true
                },
                {
                    key: "edit-clock",
                    component: "devise-clock-edit",
                    message: `Time to change things up! Let's edit this clock.<br> What would you like to edit?`,
                    end: true
                },
                {
                    key: "delete-clock",
                    component: "devise-clock-delete",
                    message:
                        "This will permanently delete this clock from the system. Are you sure you want to do this?",
                    end: true
                },
                {
                    key: "search-for-clock",
                    component: "workflow-search",
                    message:
                        "Use the form below to search for the clock you wish to edit",
                    apiendpoint: "/api/devise/clocks/search",
                    searchPropertyName: "searchterm",
                    resultsDisplayFields: [
                        {
                            label: "Name",
                            field: "name"
                        },
                        {
                            label: "Specials Title",
                            field: "specials_title"
                        },
                        {
                            label: "End Date",
                            field: "end_date"
                        }
                    ]
                }
            ]
        };
    }
};
</script>

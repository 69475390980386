<template>
    <div :class="{ 'h-16': displayMode === 'bar' }" ref="menuContainer">
        <div
            ref="menu"
            class="fixed pointer-events-auto top-0 left-0 h-0 w-full flex flex-col items-center"
            :class="{ 'h-16': displayMode === 'bar' }"
            :style="{
                backgroundColor: propertyHub.site.colors.primary,
            }"
            style="width: inherit"
        >
            <div
                class="max-w-3xl w-full flex flex-col text-white overflow-hidden"
            >
                <div class="flex flex-row">
                    <button
                        class="flex-none m-5 w-6 h-6 rounded-full flex justify-center items-center border-2 border-white"
                        :class="{ 'opacity-0': displayMode === 'hidden' }"
                        @click="goBack"
                    >
                        <svg
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <div class="grow ml-2 mt-4 w-3/5">
                        <img
                            @click="navigateTo('/property-hub/')"
                            :src="site.logo"
                            :alt="site.name"
                            class="mx-auto"
                            style="max-height: 40px"
                        />
                    </div>

                    <button
                        class="flex-none pointer-events-auto h-16 w-16 flex justify-center text-abs-white items-center"
                        @click="toggleMenu"
                    >
                        <menu-icon v-if="!menuOpen" class="w-6 h-6"></menu-icon>
                        <x-icon v-else class="h-6 w-6"></x-icon>
                    </button>
                </div>

                <div
                    ref="scrollable"
                    class="overflow-scroll hide-scrollbar"
                >
                    <div
                        class="text-center my-4"
                        v-if="propertyHub.site.localized"
                    >
                        <span
                            @click="changeLanguage('en')"
                            class="mx-2 underline-offset-4 inline-block"
                            :class="{
                                underline: propertyHub.currentLanguage === 'en',
                                'opacity-50':
                                    propertyHub.currentLanguage !== 'en',
                            }"
                            >ENGLISH</span
                        >
                        |
                        <span
                            @click="changeLanguage('es')"
                            class="mx-2 underline-offset-4 inline-block"
                            :class="{
                                underline: propertyHub.currentLanguage === 'es',
                                'opacity-50':
                                    propertyHub.currentLanguage !== 'es',
                            }"
                            >ESPAÑOL</span
                        >
                    </div>

                    <PromotionSlider class="mt-4 pb-0" location="Menu" />

                    <div
                        ref="menuItems"
                        class="grid grid-cols-3 gap-2 p-4 opacity-0 pb-8"
                        style="grid-template-rows: auto 1fr"
                        :style="{
                            backgroundColor: propertyHub.site.colors.primary,
                        }"
                    >
                        <button
                            v-for="(menuItem, key) in menuItems"
                            :key="key"
                            class="px-2 py-3 flex flex-col justify-between items-center rounded-xl text-white"
                            style="height: 6.4rem"
                            :style="{
                                backgroundColor:
                                    propertyHub.site.colors.secondary,
                            }"
                            @click="navigateTo(menuItem.path)"
                        >
                            <div class="w-10 h-10 overflow-hidden rounded-lg">
                                <img
                                    :src="menuItem.icon"
                                    alt="Menu Icon"
                                    class="w-full h-full object-cover"
                                />
                            </div>
                            <div class="text-[10px] uppercase">
                                {{ menuItem.name }}
                            </div>
                        </button>
                    </div>

                    <div
                        class="text-center text-xs uppercase pb-16"
                        v-if="menuLink.enabled"
                    >
                        <a
                            v-if="propertyHub.currentLanguage === 'en'"
                            :href="menuLink.href"
                            :target="menuLink.target"
                            class="px-3 py-1 underline text-sm"
                        >
                            {{ menuLink.text }}
                        </a>
                        <a
                            v-if="propertyHub.currentLanguage === 'es'"
                            :href="esMenuLink.href"
                            :target="esMenuLink.target"
                            class="px-3 py-1 underline text-sm"
                        >
                            {{ esMenuLink.text }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MenuIcon from 'vue-feather-icons/icons/MenuIcon';
import XIcon from 'vue-feather-icons/icons/XIcon';
import { timeline } from 'motion';
import { mapState, mapActions } from 'vuex';

export default {
    name: 'PropertyHubNavigation',

    props: {
        displayMode: {
            type: String,
            default: 'hidden',
        },
    },
    components: {
        MenuIcon,
        XIcon,
        PromotionSlider: () => import('./components/PromotionSlider.vue'),
    },
    data() {
        return {
            menuOpen: false,
            animationDuration: 0.7,
        };
    },

    computed: {
        ...mapState({
            site: (state) => state.propertyHub.site,
            menuLink: (state) => state.propertyHub.menuLink,
            esMenuLink: (state) => state.propertyHub.esMenuLink,
            propertyHub: (state) => state.propertyHub,
        }),

        height() {
            return window.innerHeight;
        },

        languageIndex() {
            if (
                this.propertyHub.content &&
                this.propertyHub.content[0] &&
                this.propertyHub.content[0].translations
            ) {
                return this.propertyHub.content[0].translations.findIndex(
                    (translation) =>
                        translation.language.code ===
                        this.propertyHub.currentLanguage
                );
            }

            return null;
        },

        isHomepage() {
            return this.$route.name === 'property-hub-index';
        },

        menuItems() {
            const menu = [
                {
                    name:
                        this.propertyHub.currentLanguage === 'en'
                            ? 'Dining, Drinks & Menus'
                            : 'Restaurantes, Bebidas y Menús',
                    path: '/property-hub/dining',
                    icon: `/imgs/property-hub/icons/restaurants.svg`,
                },
                {
                    name:
                        this.propertyHub.currentLanguage === 'en'
                            ? 'Hotel Information'
                            : 'INFORMACIÓN DEL HOTEL',
                    path: '/property-hub/info',
                    icon: `/imgs/property-hub/icons/information.svg`,
                },
                {
                    name:
                        this.propertyHub.currentLanguage === 'en'
                            ? ' Activities & Entertainment'
                            : 'Actividades y Entretenimiento',
                    path: '/property-hub/events',
                    icon: `/imgs/property-hub/icons/events.svg`,
                },
            ];

            if (this.propertyHub.site.key === 'hilton-yucatan-playa-del-carmen') {
                menu.pop();
            }

            if (
                deviseSettings.$page.site.data &&
                deviseSettings.$page.site.data.mainMenu
            ) {
                const menuItems =
                    this.propertyHub.currentLanguage === 'en'
                        ? deviseSettings.$page.site.data.mainMenu.menu_items
                        : deviseSettings.$page.site.data.mainMenu[
                              this.propertyHub.currentLanguage
                          ].menu_items;

                for (const menuItem in menuItems) {
                    if (Object.hasOwnProperty.call(menuItems, menuItem)) {
                        const item = menuItems[menuItem];

                        const newItem = this.propertyHub.content.find(
                            (content) => content.id === item.page_id
                        );
                        let icon = `/imgs/property-hub/icons/${item.data.icon}.svg`;
                        if (item.data.icon === 'roomservice') {
                            icon = '/imgs/property-hub/icons/room-service.png';
                        }
                        if (item.data.icon === 'spa') {
                            icon = '/imgs/property-hub/icons/spa.png';
                        }
                        if (item.data.icon === 'vitamar') {
                            icon = '/imgs/property-hub/icons/vitamar.png';
                        }
                        if (item.data.icon === 'zen-zilara') {
                            icon = '/imgs/property-hub/icons/zen-zilara.png';
                        }
                        if (item.data.icon === 'zen-ziva') {
                            icon = '/imgs/property-hub/icons/zen-ziva.png';
                        }
                        if (newItem) {
                            if (
                                newItem.locale.hasOwnProperty(
                                    this.propertyHub.currentLanguage
                                )
                            ) {
                                menu.push({
                                    name: newItem.locale[
                                        this.propertyHub.currentLanguage
                                    ].title,
                                    path: `/property-hub/info/${
                                        newItem.locale[
                                            this.propertyHub.currentLanguage
                                        ].slug
                                    }`,
                                    icon: icon,
                                });
                            }
                        } else {
                            menu.push({
                                name: item.name,
                                path: item.url,
                                icon: icon,
                            });
                        }
                    }
                }
                deviseSettings.$page.site.data.mainMenu.menuItems;
            }

            return menu;
        },
    },

    mounted() {
        this.requestRetrieveContents();
    },

    methods: {
        ...mapActions(['getContent', 'setCurrentLanguage']),
        toggleMenu() {
            this.menuOpen = !this.menuOpen;

            if (this.menuOpen) {
                // Animate the menu
                // If the menu is closed

                this.$emit('openMenu');
                const sequence = [
                    // Slide the menu open
                    [
                        this.$refs.menu,
                        { height: screen.height + 'px' },
                        { easing: 'ease-out' },
                    ],

                    // Fade in the menu
                    [
                        this.$refs.menuItems,
                        { opacity: 1 },
                        { easing: 'ease-out' },
                    ],
                ];
                timeline(sequence, {
                    duration: this.animationDuration,
                });
            } else {
                // If the menu is already open
                const sequence = [
                    // Fade out the menu
                    [
                        this.$refs.menuItems,
                        { opacity: 0 },
                        { easing: 'ease-out' },
                    ],

                    // Slide the menu closed
                    [
                        this.$refs.menu,
                        { height: this.displayMode === 'hidden' ? 0 : '64px' },
                        { at: '-1', easing: 'ease-out' },
                    ],
                ];
                timeline(sequence, {
                    duration: this.animationDuration / 2,
                });
                this.$emit('closeMenu');
                this.$refs.scrollable.scrollTop = 0;
            }
        },

        navigateTo(path) {
            if (path) {
                if (path.includes('http')) {
                    window.open(path, '_blank');
                } else {
                    this.$router.push(path);
                    this.toggleMenu();
                }
            }
        },

        goBack() {
            this.$router.back();
        },

        requestRetrieveContents() {
            this.loading = true;
            this.getContent().finally(() => {
                this.loading = false;
            });
        },

        changeLanguage(language) {
            this.propertyHub.currentLanguage = language;
            this.toggleMenu();
        },
    },
};
</script>

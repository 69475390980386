<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
export default {
    name: 'DevisePromoSectionAdmin',
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ 'devisephp-interface/src/components/admin/workflow/Workflow'
            ),
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: 'promotion-main-menu',
                    apiendpoint: '/api/devise/promos',
                    component: 'workflow-real-index',
                    message:
                        'Cool! We are working on <strong>Promotions</strong>.<br> What would you like to do?',
                    columns: [
                        {
                            key: 'settings',
                            label: 'Title',
                            format: (record) => {
                                if (
                                    record.locale &&
                                    record.locale.en &&
                                    record.locale.en.settings &&
                                    record.locale.en.settings.title
                                ) {
                                    return record.locale.en.settings.title;
                                }
                                return '-';
                            },
                        },
                        {
                            key: 'repeats_on',
                            label: 'Repeats On',
                            format: (record) => {
                                if (record.repeats === 1) {
                                    const days = [
                                        'sunday',
                                        'monday',
                                        'tuesday',
                                        'wednesday',
                                        'thursday',
                                        'friday',
                                        'saturday',
                                    ];
                                    const shortDays = [
                                        'Su',
                                        'M',
                                        'Tu',
                                        'W',
                                        'Th',
                                        'F',
                                        'Sa',
                                    ];

                                    // Find which days it repeats on and add a formatted
                                    // string to repeatInstances
                                    let repeatInstances = [];
                                    days.forEach((day) => {
                                        if (
                                            record.repeat_settings &&
                                            record.repeat_settings
                                                .repeat_days &&
                                            record.repeat_settings.repeat_days[
                                                `repeats_${day}`
                                            ] &&
                                            record.repeat_settings.repeat_days[
                                                `repeats_${day}`
                                            ] === true
                                        ) {
                                            repeatInstances.push(
                                                shortDays[days.indexOf(day)]
                                            );
                                        }
                                    });

                                    // Return the final formatted string
                                    return `${
                                        record.repeat_frequency
                                    } on ${repeatInstances.join(', ')}`;
                                }
                                return '-';
                            },
                        },
                    ],
                    links: [
                        {
                            label: 'Create a Promotion',
                            nextStep: 'create-promotion',
                        },
                    ],
                    actions: [
                        {
                            key: 'edit',
                            label: 'Edit',
                            nextStep: 'edit-promotion',
                        },
                        {
                            key: 'delete',
                            label: 'Remove',
                            nextStep: 'delete-promotion',
                        },
                    ],
                },
                {
                    key: 'create-promotion',
                    component: 'devise-promotion-create',
                    message:
                        'Fill out the fields below and create the new Promotion. Name and Special Name fields are required.',
                    end: true,
                },
                {
                    key: 'edit-promotion',
                    component: 'devise-promotion-edit',
                    message: `Time to change things up! Let's edit this Promotion.<br> What would you like to edit?`,
                    end: true,
                },
                {
                    key: 'delete-promotion',
                    component: 'devise-promotion-delete',
                    message:
                        'This will permanently delete this Promotion from the system. Are you sure you want to do this?',
                    end: true,
                },
            ],
        };
    },
};
</script>

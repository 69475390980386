var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white min-h-screen"},[_c('PropertyHubNavigation',{ref:"navigation",staticClass:"relative top-0 w-full z-20",attrs:{"display-mode":"bar"}}),(_vm.localized)?_c('div',[_c('hero-image',{attrs:{"image":_vm.localized.image ||
                '/imgs/property-hub/couple-at-bar.png'}}),(_vm.localized.logo)?_c('div',{staticClass:"relative z-10 -mt-12 flex justify-center"},[_c('div',{staticClass:"relative z-10 w-32 h-32 rounded-full overflow-hidden bg-white"},[_c('img',{staticClass:"object-contain w-full h-full",attrs:{"src":_vm.localized.logo}})]),_c('div',{staticClass:"absolute z-0 w-32 h-32 mt-2 rounded-full overflow-hidden",style:({
                    backgroundColor: _vm.propertyHub.site.colors.secondary,
                })})]):_vm._e(),_c('div',{staticClass:"pt-4 px-5 pb-8 text-gray-700 space-y-6"},[_c('div',[_c('div',{staticClass:"font-bold text-2xl mt-2 mb-1 text-center",style:({ color: _vm.propertyHub.site.colors.primary })},[_vm._v(" "+_vm._s(_vm.localized.title)+" ")]),(_vm.localized.type)?_c('div',{staticClass:"text-center font-medium text-sm",style:({ color: _vm.propertyHub.site.colors.primary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['cuisine'])+" "+_vm._s(_vm.localized.type)+" ")]):_vm._e(),_c('menu-links-slider',{attrs:{"restaurant-slug":_vm.localized.slug,"menus":_vm.localized.menus}}),(
                        _vm.localized.links
                            .length > 0
                    )?_c('div',{staticClass:"my-6 flex justify-start items-center space-x-3 snap-x overflow-x-scroll scrollbar-hide md:scrollbar-default",class:{
                        'justify-center':
                            _vm.localized
                                .links <= 1,
                        'justify-start':
                            _vm.localized
                                .links > 1,
                    }},_vm._l((_vm.restaurant.locale[
                            _vm.propertyHub.currentLanguage
                        ].links),function(link,key){return _c('button',{key:key,staticClass:"text-abs-white snap-center whitespace-nowrap rounded-full border border-gray-300 text-sm py-1 px-4 uppercase",style:({
                            'background-color':
                                _vm.propertyHub.site.colors.primary,
                        }),on:{"click":function($event){return _vm.clickedLink(link)}}},[_vm._v(" "+_vm._s(link.linktext)+" ")])}),0):_vm._e(),_c('div',{staticClass:"text-sm leading-relaxed whitespace-pre-line",domProps:{"innerHTML":_vm._s(_vm.markdown(_vm.localized
                    .content))}})],1),(
                    _vm.localized.hours
                        .breakfast.time ||
                    _vm.localized.hours
                        .lunch.time ||
                    _vm.localized.hours
                        .dinner.time ||
                    _vm.localized.hours
                        .lounge.time
                )?_c('div',{staticClass:"text-sm"},[_c('span',{style:({ color: _vm.propertyHub.site.colors.secondary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['hours'])+" ")]),(
                        _vm.localized.hours
                            .breakfast.time
                    )?_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['breakfast']))]),_c('br'),_vm._v(" "+_vm._s(_vm.localized.hours .breakfast.time)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.breakfastDays)+" ")]):_vm._e(),(
                        _vm.localized.hours
                            .lunch.time
                    )?_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['lunch']))]),_c('br'),_vm._v(" "+_vm._s(_vm.localized.hours .lunch.time)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.lunchDays)+" ")]):_vm._e(),(
                        _vm.localized.hours
                            .dinner.time
                    )?_c('p',[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['dinner']))]),_c('br'),_vm._v(" "+_vm._s(_vm.localized.hours .dinner.time)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.dinnerDays)+" ")]):_vm._e(),(
                        _vm.localized.hours
                            .lounge.time
                    )?_c('p',[_vm._v(" "+_vm._s(_vm.localized.hours .lounge.time)+" "),_c('br'),_vm._v(" "+_vm._s(_vm.loungeDays)+" ")]):_vm._e()]):_vm._e(),(
                    _vm.localized
                        .dress_code
                )?_c('div',{staticClass:"text-sm"},[_c('span',{style:({ color: _vm.propertyHub.site.colors.secondary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['attire'])+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
                        _vm.localized
                            .dress_code
                    )}})]):_vm._e(),(_vm.localized.notes)?_c('div',{staticClass:"text-sm"},[_c('span',{staticClass:"leading-relaxed",style:({ color: _vm.propertyHub.site.colors.secondary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['notes'])+" ")]),_c('div',{staticClass:"leading-relaxed",domProps:{"innerHTML":_vm._s(
                        _vm.markdown(_vm.localized.notes)
                    )}})]):_vm._e(),(
                    _vm.localized.extension
                )?_c('div',{staticClass:"text-sm"},[_c('span',{staticClass:"leading-relaxed",style:({ color: _vm.propertyHub.site.colors.secondary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['extension'])+" ")]),_c('div',{staticClass:"leading-relaxed",domProps:{"innerHTML":_vm._s(
                        _vm.localized
                            .extension
                    )}})]):_vm._e(),(
                    _vm.localized
                        .reservations_url
                )?_c('button',{staticClass:"text-abs-white snap-center whitespace-nowrap w-full rounded-full border border-gray-300 text-sm py-1 px-4 uppercase",style:({
                    'background-color': _vm.propertyHub.site.colors.primary,
                }),on:{"click":_vm.displayReservationsModal}},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'reservations' ])+" ")]):_vm._e(),(
                    _vm.localized.dressCode
                )?_c('div',{staticClass:"text-sm"},[_c('span',{style:({ color: _vm.propertyHub.site.colors.secondary })},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[ _vm.propertyHub.currentLanguage ]['dress-code'])+" ")]),_c('div',{staticClass:"leading-relaxed",domProps:{"innerHTML":_vm._s(
                        _vm.localized
                            .dressCode
                    )}})]):_vm._e(),(
                    _vm.localized.gallery
                        .length
                )?_c('div',{staticClass:"mt-4"},_vm._l((_vm.restaurant.locale[
                        _vm.propertyHub.currentLanguage
                    ].gallery),function(image,id){return _c('div',{key:id},[_c('img',{directives:[{name:"devise-image",rawName:"v-devise-image",value:({ image: image }),expression:"{ image: image }"}],staticClass:"w-full mt-5"})])}),0):_vm._e()])],1):_vm._e(),(!_vm.restaurant && !_vm.loading)?_c('div',[_c('div',{staticClass:"text-center px-8 mt-8",style:({ color: _vm.propertyHub.site.colors.primary })},[_c('div',{staticClass:"text-xl font-extrabold"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'no-restaurants-found' ])+" ")]),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'click-back-ins-for-restaurants' ])+" ")])])]):_vm._e(),(_vm.showReservationsModal)?_c('div',{staticClass:"fixed left-0 top-0 h-screen w-full z-100",on:{"click":_vm.hideReservationsModal}},[_c('div',{staticClass:"relative mx-auto w-full h-full"},[_c('div',{staticClass:"relative p-1 pt-[40px] w-full h-full",style:({
                    'background-color': _vm.propertyHub.site.colors.primary,
                })},[_c('div',{staticClass:"absolute top-0 left-0 text-white uppercase mt-2 ml-4"},[_vm._v(" Reservations ")]),_c('div',{staticClass:"absolute top-0 right-0 text-white uppercase py-2 px-4",on:{"click":_vm.hideReservationsModal}},[_c('close-icon')],1),_c('iframe',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.localized
                            .reservations_url}})])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: "DeviseBookingCodeAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "fact-sheet-main-menu",
                    component: "workflow-menu",
                    message:
                        "Cool! We are working on<br><strong>Fact Sheets</strong>.<br> What would you like to do?",
                    options: [
                        {
                            label: "Create a Fact Sheet",
                            nextStep: "create-fact-sheet"
                        },
                        {
                            label: "Edit a Fact Sheet",
                            nextStep: "search-for-fact-sheet",
                            stepAfterNext: "edit-fact-sheet"
                        },
                        {
                            label: "Remove a Fact Sheet",
                            nextStep: "search-for-fact-sheet",
                            stepAfterNext: "delete-fact-sheet"
                        }
                    ]
                },
                {
                    key: "create-fact-sheet",
                    component: "devise-fact-sheet-create",
                    message:
                        "Fill out the fields below and create the new trigger. Code Value is required.",
                    end: true
                },
                {
                    key: "edit-fact-sheet",
                    component: "devise-fact-sheet-edit",
                    message: `Time to change things up! Let's edit this trigger.<br> What would you like to edit?`,
                    end: true
                },
                {
                    key: "delete-fact-sheet",
                    component: "devise-fact-sheet-delete",
                    message:
                        "This will permanently delete this trigger from the system. Are you sure you want to do this?",
                    end: true
                },
                {
                    key: "search-for-fact-sheet",
                    component: "workflow-search",
                    message:
                        "Use the form below to search for the trigger you wish to edit",
                    apiendpoint: "/api/devise/fact-sheets",
                    allRecordsApiendpoint:
                        "/api/devise/fact-sheets",
                    searchPropertyName: "searchterm",
                    resultsDisplayFields: [
                        {
                            label: "Label",
                            field: "resort"
                        },
                        {
                            label: "Group",
                            field: "group"
                        },
                    ]
                }
            ]
        };
    }
};
</script>

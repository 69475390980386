import commonUtils from 'devisephp-interface/src/vuex/utils/common';
import dayjs from 'dayjs';

const actions = {
    // App State
    // Payload must be structured:
    // {
    //   property: 'mobileMenuOpen',
    //   value: false
    // }
    setAppStateProperty(context, payload) {
        const newAppState = context.state.appState;
        newAppState[payload.property] = payload.value;
        context.commit('setAppState', newAppState);
    },

    // BookingCodeTriggers
    getBookingCodeTriggers(context) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}booking-code-triggers/`
                )
                .then((response) => {
                    context.commit('setBookingCodeTriggers', response);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createBookingCodeTrigger(context, bookingCodeTrigger) {
        return new Promise((resolve) => {
            window.axios
                .post(
                    `${context.state.devise.api.baseUrl}booking-code-triggers/`,
                    bookingCodeTrigger
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: 'Trigger has been created.',
                    });
                    context.commit('createBookingCodeTriggers', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateBookingCodeTrigger(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    // eslint-disable-next-line max-len
                    `${context.state.devise.api.baseUrl}booking-code-triggers/${payload.bookingCodeTrigger.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: 'Trigger has been saved.',
                    });
                    context.commit('updateBookingCodeTriggers', {
                        bookingCodeTrigger: payload.bookingCodeTrigger,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deleteBookingCodeTrigger(context, bookingCodeTrigger) {
        return new Promise((resolve) => {
            window.axios
                .delete(
                    `${context.state.devise.api.baseUrl}booking-code-triggers/${bookingCodeTrigger.id}`
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: 'Trigger has been deleted.',
                    });
                    context.commit(
                        'deleteBookingCodeTriggers',
                        bookingCodeTrigger
                    );
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Brands
    getBrands(context) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}brands/`)
                .then((response) => {
                    context.commit('setBrands', response);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createBrand(context, brand) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}brands/`, brand)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${brand.name} has been created.`,
                    });
                    context.commit('createBrands', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateBrand(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}brands/${payload.brand.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.name} has been saved.`,
                    });
                    context.commit('updateBrands', {
                        brand: payload.brand,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Clocks
    getClocks(context, language) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}clocks?langage=${language}`
                )
                .then((response) => {
                    context.commit('setClocks', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createClock(context, clock) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}clocks/`, clock)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${clock.name} has been created.`,
                    });
                    context.commit('createClocks', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateClock(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}clocks/${payload.clock.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.name} has been saved.`,
                    });
                    context.commit('updateClocks', {
                        clock: payload.clock,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deleteClock(context, clock) {
        return new Promise((resolve) => {
            window.axios
                .delete(`${context.state.devise.api.baseUrl}clocks/${clock.id}`)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${clock.name} has been deleted.`,
                    });
                    context.commit('deleteClocks', clock);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Events
    getEvents(context, language) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}event?langage=${language}`
                )
                .then((response) => {
                    context.commit('setEvents', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createEvent(context, event) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}event/`, event)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${event.name} has been created.`,
                    });
                    context.commit('createEvents', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateEvent(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}event/${payload.event.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.name} has been saved.`,
                    });
                    context.commit('updateEvents', {
                        event: payload.event,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deleteEvent(context, event) {
        return new Promise((resolve) => {
            window.axios
                .delete(`${context.state.devise.api.baseUrl}event/${event.id}`)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${event.name} has been deleted.`,
                    });
                    context.commit('deleteEvents', event);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Posts
    getPosts(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}posts?category=${payload.type}&language_id=${payload.language}`
                )
                .then((response) => {
                    context.commit('setPosts', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createPost(context, post) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}posts/`, post)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${post.title} has been created.`,
                    });
                    context.commit('createPosts', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updatePost(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}posts/${payload.post.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.title} has been saved.`,
                    });
                    context.commit('updatePosts', {
                        post: payload.post,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deletePost(context, post) {
        return new Promise((resolve) => {
            window.axios
                .delete(`${context.state.devise.api.baseUrl}posts/${post.id}`)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${post.title} has been deleted.`,
                    });
                    context.commit('deletePosts', post);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Menus
    getMenus(context) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}menus/`)
                .then((response) => {
                    context.commit('setMenus', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createMenu(context, menu) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}menus/`, menu)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${menu.name} has been created.`,
                    });
                    context.commit('createMenus', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateMenu(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}menus/${payload.menu.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.name} has been saved.`,
                    });
                    context.commit('updateMenus', {
                        menu: payload.menu,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deleteMenu(context, menu) {
        return new Promise((resolve) => {
            window.axios
                .delete(`${context.state.devise.api.baseUrl}menus/${menu.id}`)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${menu.name} has been deleted.`,
                    });
                    context.commit('deleteMenus', menu);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Resorts
    getResorts(context) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}resorts/`)
                .then((response) => {
                    context.commit('setResorts', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },
    getResortsList(context) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}resorts/list-for-site`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createResort(context, resort) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}resorts/`, resort)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${resort.name} has been created.`,
                    });
                    context.commit('createResorts', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateResort(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}resorts/${payload.resort.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.name} has been saved.`,
                    });
                    context.commit('updateResorts', {
                        resort: payload.resort,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deleteResort(context, resort) {
        return new Promise((resolve) => {
            window.axios
                .delete(
                    `${context.state.devise.api.baseUrl}resorts/${resort.id}`
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${resort.name} has been deleted.`,
                    });
                    context.commit('deleteResorts', resort);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Specials
    getSpecial(context, specialId) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}specials/${specialId}`)
                .then((response) => {
                    context.commit('appendSpecial', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    getSpecials(context, filters) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl
                    }specials/?${commonUtils.buildFilterParams(filters)}`
                )
                .then((response) => {
                    context.commit('setSpecials', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    searchSpecials(context, search) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}specials/search`, {
                    params: { term: search },
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    createSpecial(context, special) {
        return new Promise((resolve) => {
            window.axios
                .post(`${context.state.devise.api.baseUrl}specials/`, special)
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${special.name} has been created.`,
                    });
                    context.commit('createSpecials', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    updateSpecial(context, payload) {
        return new Promise((resolve) => {
            window.axios
                .put(
                    `${context.state.devise.api.baseUrl}specials/${payload.special.id}`,
                    payload.data
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${payload.data.name} has been saved.`,
                    });
                    context.commit('updateSpecials', {
                        special: payload.special,
                        data: response.data,
                    });
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    deleteSpecial(context, special) {
        return new Promise((resolve) => {
            window.axios
                .delete(
                    `${context.state.devise.api.baseUrl}specials/${special.id}`
                )
                .then((response) => {
                    window.deviseSettings.$bus.$emit('showMessage', {
                        title: 'Success!',
                        message: `${special.name} has been deleted.`,
                    });
                    context.commit('deleteSpecials', special);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    getSpecialsForPage(context, pageId) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}specials/for-page/${pageId}`
                )
                .then((response) => {
                    context.commit('setSpecialsForPage', response.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    // Property Hub Restaurants
    getRestaurants(context) {
        if(context.state.restaurants.length === 0){
            return new Promise((resolve) => {
                window.axios
                    .get(`${context.state.devise.api.baseUrl}restaurants/all`)
                    .then((response) => {
                        context.commit('setRestaurants', response.data.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        window.deviseSettings.$bus.$emit('showError', error);
                    });
            }).catch((error) => {
                window.deviseSettings.$bus.$emit('showError', error);
            });
        }
    },
    getDietaryRestrictiveRestaurants(context) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}restaurants/dietary-dishes`
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    getRestaurantMenu(context, menuId) {
        return new Promise((resolve) => {
            window.axios
                .get(
                    `${context.state.devise.api.baseUrl}restaurant-menus/${menuId}`
                )
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    getNextRestaurant: (state) => (currentRestaurantSlug) => {
        const currentRestaurantKey = state.restaurants.findIndex(
            (restaurant) => restaurant.slug === currentRestaurantSlug
        );
        if (currentRestaurantKey === state.restaurants.length - 1) {
            return state.restaurants[0];
        } else {
            return state.restaurants[currentRestaurantKey + 1];
        }
    },

    // Property Hub Content
    getContent(context) {
        if(context.state.propertyHub.content.length === 0){
            return new Promise((resolve) => {
                window.axios
                    .get(`${context.state.devise.api.baseUrl}content-sections/all`)
                    .then((response) => {
                        context.commit('setContent', response.data.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        window.deviseSettings.$bus.$emit('showError', error);
                    });
            }).catch((error) => {
                window.deviseSettings.$bus.$emit('showError', error);
            });
        }
    },
    getContentAsMenu(context) {
        if(context.state.propertyHub.contentAsMenu.length === 0){
            return new Promise((resolve) => {
                window.axios
                    .get(`${context.state.devise.api.baseUrl}content-sections/menu`)
                    .then((response) => {
                        context.commit('setContentAsMenu', response.data.data);
                        resolve(response);
                    })
                    .catch((error) => {
                        window.deviseSettings.$bus.$emit('showError', error);
                    });
            }).catch((error) => {
                window.deviseSettings.$bus.$emit('showError', error);
            });
        }
    },
    getPromos(context, location) {
        if(!context.state.propertyHub.promos.hasOwnProperty(location)){
            return new Promise((resolve) => {
                window.axios
                    .get(
                        `${context.state.devise.api.baseUrl}promos/by-location`,
                        {
                            params: {
                                location: location,
                            },
                        }
                    )
                    .then((response) => {
                        context.commit('setPromos', {data: response.data.data, location: location});
                        resolve(response);
                    })
                    .catch((error) => {
                        window.deviseSettings.$bus.$emit('showError', error);
                    });
            }).catch((error) => {
                window.deviseSettings.$bus.$emit('showError', error);
            });
        }
    },

    // Property Hub Events
    getPropertyHubEvents(context, day) {
        return new Promise((resolve) => {
            const date = dayjs(day).format('YYYY-MM-DD');
            window.axios
                .get(`${context.state.devise.api.baseUrl}events/all`, {
                    params: {
                        day: date,
                    },
                })
                .then((response) => {
                    context.commit('setEvents', response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit('showError', error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit('showError', error);
        });
    },

    sendPageView(context, title) {
        try {
            const path = window.location.hash.replace('#', '');
            dataLayer.push({
                event: context.state.propertyHub.site.pageViewKey,
                pageUrl: 'https://' + window.location.hostname + path,
                pageTitle: title,
            });
        } catch (e) {
            console.log(e);
        }
    },

    // UserRoles
    getUserRoles(context) {
        return new Promise((resolve) => {
            window.axios
                .get(`${context.state.devise.api.baseUrl}user-roles/`)
                .then((response) => {
                    resolve(response.data.data);
                })
                .catch((error) => {
                    window.deviseSettings.$bus.$emit("showError", error);
                });
        }).catch((error) => {
            window.deviseSettings.$bus.$emit("showError", error);
        });
    },

    setCurrentLanguage(context, language) {
        context.commit('setCurrentLanguage', language);
    },
};

export default actions;

<script>
import IconLeftArrow from "./icons/IconLeftArrow";
import IconRightArrow from "./icons/IconRightArrow";

export default {
    name: "GeneralSlider",
    components: {
        IconLeftArrow,
        IconRightArrow
    },
    props: {
        devise: {
            type: Object
        }
    },
    computed: {
        isCentered() {
            if (this.devise.center) {
                return this.devise.center.checked;
            }

            return false;
        }
    }
};
</script>

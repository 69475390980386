import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

// root state object.
// each Vuex instance is just a single state tree.
const state = {
    appState: {
        mobileMenuOpen: false,
    },
    adminMenu: [
        {
            icon: 'CreateIcon',
            routeName: 'devise-page-editor',
        },
        {
            icon: 'ImageIcon',
            label: 'Media',
            routeName: 'media-manager',
            permissions: 'manage slices',
        },
        {
            icon: 'CogIcon',
            label: 'Settings',
            routeName: 'devise-settings',
            permissions: 'manage settings',
            menu: [
                {
                    label: 'Playa Settings',
                    permissions: 'manage general settings',
                    children: [
                        {
                            label: 'Specials',
                            routeName: 'devise-specials-admin',
                            permissions: 'manage specials',
                        },
                        {
                            label: 'Menus',
                            routeName: 'devise-menus-admin',
                            permissions: 'manage menus',
                        },
                        {
                            label: 'Booking Code Triggers',
                            routeName: 'devise-booking-code-triggers-admin',
                            permissions: 'manage bct',
                        },
                    ],
                },
                {
                    label: 'Property Hub',
                    permissions: 'manage property hub',
                    children: [
                        {
                            label: 'Events',
                            routeName: 'devise-events-admin',
                            permissions: 'manage property hub',
                        },
                        {
                            label: 'Content Sections',
                            routeName: 'devise-content-sections-admin',
                            permissions: 'manage property hub',
                        },
                        {
                            label: 'Promotions',
                            routeName: 'devise-promotions-admin',
                            permissions: 'manage property hub',
                        },
                        {
                            label: 'Restaurants',
                            routeName: 'devise-restaurants-admin',
                            permissions: 'manage property hub',
                        },
                    ],
                },
                {
                    label: 'Publications',
                    permissions: 'manage general settings',
                    children: [
                        {
                            label: 'Press Releases / Blog Posts',
                            routeName: 'devise-posts-admin',
                            permissions: 'manage general settings',
                        },
                        {
                            label: 'Playa Cares Posts',
                            routeName: 'devise-playacares-posts-admin',
                            permissions: 'manage playa cares posts',
                        },
                        {
                            label: 'Event Posts',
                            routeName: 'devise-event-posts-admin',
                            permissions: 'manage playa cares events',
                        },
                    ],
                },
                {
                    label: 'Branding',
                    permissions: 'access branding',
                    children: [
                        {
                            label: 'Brands',
                            routeName: 'devise-brands-admin',
                            permissions: 'manage general settings',
                        },
                        {
                            label: 'Resorts',
                            routeName: 'devise-resorts-admin',
                            permissions: 'manage resorts',
                        },
                        {
                            label: 'Fact Sheets',
                            routeName: 'devise-fact-sheets-admin',
                            permissions: 'manage resorts',
                        },
                    ],
                },
                {
                    label: 'Site Settings',
                    permissions: 'manage site settings',
                    children: [
                        {
                            label: 'Pages',
                            routeName: 'devise-pages-admin',
                            permissions: 'manage pages',
                        },
                        {
                            label: 'Users',
                            routeName: 'devise-users-playa-admin',
                            permissions: 'manage users',
                        },
                        {
                            label: 'Global Meta',
                            routeName: 'devise-meta-manage',
                            permissions: 'manage meta',
                        },
                        {
                            label: 'Sites',
                            routeName: 'devise-sites-admin',
                            permissions: 'manage sites',
                        },
                        {
                            label: 'Languages',
                            routeName: 'devise-languages-manage',
                            permissions: 'manage languages',
                        },
                        {
                            label: '301 Redirects',
                            routeName: 'devise-redirects-admin',
                            permissions: 'manage redirects',
                        },
                        {
                            label: 'Route Refresh',
                            routeName: 'devise-route-refresh-admin',
                            permissions: 'manage redirects',
                        },
                    ],
                },
            ],
        },
    ],
    bookingCodeTriggers: {
        data: [],
    },
    brands: {
        data: [],
    },
    clocks: {
        data: [],
    },
    menus: {
        data: [],
    },
    posts: {
        data: [],
    },
    resorts: {
        data: [],
    },
    specials: {
        data: [],
    },

    specialsForPage: [],
    promoCodes: {
        promo: null,
        coupon: null,
        rate: null,
        promo_reservhotel: null,
        rate_reservhotel: null,
        group: null,
        configcode: 'PlayaWebsite',
        themecode: 'PlayaWebsite',
        currency: 'USD',
    },
    resortPromoCodes: [],
    bookingDateLimits: {
        min: null,
        max: null,
    },
    bookingExcludeResorts: '',
    propertyHub: {
        site: {
            colors: {},
        },
        currentLanguage: 'en',
        menuLink: {},
        esMenuLink: {},
        content: [],
        contentAsMenu: [],
        events: [],
        localizations: {
            en: {
                'pinpoint-the-perfect-meal': 'Pinpoint the perfect meal...',
                'dietary-dishes-description':
                    "Enjoy the views and the foods that fuel you while on vacation. We've taken the guesswork out of finding a dish that works for your diet.",
                'select-diet-below': 'Select your dietary preference below:',
                'no-meals-available': 'No Meals Available',
                'event-time': 'Event Time:',
                location: 'Location:',
                'associated-links': 'Associated Links:',
                'unable-to-find-event':
                    'We were unable to load this event. Please try again later.',
                'viewing-events-for': 'Viewing events for',
                'more-info': 'More Info',
                'no-events-for-date': 'No events for this date',
                'food-prep-disclaimer':
                    '*Food prepared in our restaurant may contain the following ingredients: milk, eggs, wheat, peanuts, and tree nuts. If you have a food allergy, please notify your server.',
                'raw-food-prep-disclaimer':
                    "*Raw ingredients are carefully handled; however, their ingestion remains at the diner's discretion.",
                cuisine: 'Cuisine:',
                hours: 'Hours:',
                attire: 'Attire:',
                notes: 'Notes:',
                extension: 'Extension:',
                reservations: 'Make Reservation',
                'dress-code': 'Dress Code:',
                'no-restaurants-found': 'No restaurants found at this address.',
                'click-back-ins-for-restaurants':
                    'Click the back arrow above or the navigation below to find the restaurant you are looking for',
                'unable-to-find-resource':
                    'We could not find what you were looking for.',
                'click-back-arrow-ins':
                    'Click the back arrow above or the navigation below to find the content you are looking for',
                'specialty-items': 'Specialty Items',
                breakfast: 'Breakfast',
                lunch: 'Lunch',
                dinner: 'Dinner',
                Day: 'Day Activities',
                Night: 'Night Activities',
                Wellness: 'Wellness',
                Everyday: 'Everyday',
                Mon: 'Mon',
                Tues: 'Tues',
                Wed: 'Wed',
                Thurs: 'Thurs',
                Fri: 'Fri',
                Sat: 'Sat',
                Sun: 'Sun',
                'events-note':
                    'Please note that activities and locations are subject to change, due to weather conditions.',
            },
            es: {
                'pinpoint-the-perfect-meal': 'Encuentra la comida perfecta...',
                'dietary-dishes-description':
                    'Disfruta de las vistas y las comidas que te alimentan mientras estás de vacaciones. Hemos eliminado los problemas de encontrar un plato perfecto para tu dieta.',
                'select-diet-below':
                    'Selecciona tu preferencia dietética a continuación:',
                'no-meals-available': 'No Hay Comidas Disponibles',
                'event-time': 'Horario del Evento:',
                location: 'Ubicación:',
                'associated-links': 'Links Asociados:',
                'unable-to-find-event':
                    'No pudimos cargar este evento. Por favor, inténtelo de nuevo más tarde.',
                'viewing-events-for': 'Ver eventos para',
                'more-info': 'Más info',
                'no-events-for-date': 'No hay eventos para esta fecha',
                'food-prep-disclaimer':
                    '*La comida preparada en nuestro restaurante puede contener lo siguiente ingredientes: leche, huevos, trigo, maní y nueces de árbol. Si usted tiene una alergia a los alimentos, por favor notifique a su mesero.',
                'raw-food-prep-disclaimer':
                    '*Los ingredientes crudos se manejan con cuidado; sin embargo, su ingesta queda a criterio del comensal.',
                cuisine: 'Tipo de Comida:',
                hours: 'Horarios:',
                attire: 'Código de Vestimenta:',
                notes: 'Notas:',
                extension: 'Extensión:',
                reservations: 'Reservaciones',
                'dress-code': 'Código de Vestimenta:',
                'no-restaurants-found':
                    'No se encontraron restaurantes en esta dirección.',
                'click-back-ins-for-restaurants':
                    'Haz clic en la flecha hacia atrás o en la navegación a continuación para encontrar el restaurante que buscas',
                'unable-to-find-resource':
                    'No pudimos encontrar lo que estabas buscando.',
                'click-back-arrow-ins':
                    'Haz clic en la flecha hacia atrás o en la navegación a continuación para encontrar el restaurante que buscas',
                'specialty-items': 'Especialidades',
                breakfast: 'Desayuno',
                lunch: 'Comida',
                dinner: 'Cena',
                Day: 'Actividades de Día',
                Night: 'Actividades de Noche',
                Wellness: 'Bienestar',
                Everyday: 'Diario',
                Mon: 'Lun',
                Tues: 'Mar',
                Wed: 'Mie',
                Thurs: 'Jue',
                Fri: 'Vie',
                Sat: 'Sab',
                Sun: 'Dom',
                'events-note':
                    'Tenga en cuenta que las actividades y los lugares están sujetos a cambios debido a las condiciones climáticas.',
            },
        },
        promos: {},
    },
    restaurants: [],

    airports: [
        { code: 'AAB', name: 'Arrabury, AU (AAB)' },
        { code: 'AAC', name: 'Al Arish, EG (AAC)' },
        { code: 'AAG', name: 'Arapoti, BR (AAG)' },
        { code: 'AAH', name: 'Aachen, DE (AAH)' },
        { code: 'AAI', name: 'Arraias, BR (AAI)' },
        { code: 'AAL', name: 'Aalborg Airport, DK (AAL)' },
        { code: 'AAM', name: 'Mala Mala, ZA (AAM)' },
        { code: 'AAN', name: 'Al Ain, AE (AAN)' },
        { code: 'AAO', name: 'Anaco, VE (AAO)' },
        { code: 'AAQ', name: 'Anapa, RU (AAQ)' },
        { code: 'AAR', name: 'Aarhus Tirstrup Airport, DK (AAR)' },
        { code: 'AAS', name: 'Apalapsili, ID (AAS)' },
        { code: 'AAT', name: 'Altay, CN (AAT)' },
        { code: 'AAV', name: 'Alah, PH (AAV)' },
        { code: 'AAX', name: 'Araxa, BR (AAX)' },
        { code: 'AAZ', name: 'Quetzaltenango, GT (AAZ)' },
        { code: 'ABA', name: 'Abakan, RU (ABA)' },
        { code: 'ABB', name: 'Abingdon, GB (ABB)' },
        { code: 'ABC', name: 'Albacete, ES (ABC)' },
        { code: 'ABE', name: 'Allentown/Bethlehem/Easton, US (ABE)' },
        { code: 'ABG', name: 'Abingdon, AU (ABG)' },
        { code: 'ABH', name: 'Alpha, AU (ABH)' },
        { code: 'ABI', name: 'Abilene Municipal Apt, US (ABI)' },
        { code: 'ABM', name: 'Bamaga, AU (ABM)' },
        { code: 'ABQ', name: 'Albuquerque, US (ABQ)' },
        { code: 'ABR', name: 'Aberdeen, US (ABR)' },
        { code: 'ABS', name: 'Abu Simbel, EG (ABS)' },
        { code: 'ABT', name: 'Al-Baha, SA (ABT)' },
        { code: 'ABU', name: 'Atambua, ID (ABU)' },
        { code: 'ABX', name: 'Albury, AU (ABX)' },
        { code: 'ABY', name: 'Albany Dougherty County Apt, US (ABY)' },
        { code: 'ABZ', name: 'Aberdeen, GB (ABZ)' },
        { code: 'ACA', name: 'Acapulco, MX (ACA)' },
        { code: 'ACD', name: 'Acandi, CO (ACD)' },
        { code: 'ACE', name: 'Lanzarote, ES (ACE)' },
        { code: 'ACH', name: 'Altenrhein, CH (ACH)' },
        { code: 'ACI', name: 'Alderney, GB (ACI)' },
        { code: 'ACK', name: 'Nantucket, US (ACK)' },
        { code: 'ACL', name: 'Aguaclara, CO (ACL)' },
        { code: 'ACM', name: 'Arica, CO (ACM)' },
        { code: 'ACO', name: 'Ascona, CH (ACO)' },
        { code: 'ACR', name: 'Araracuara, CO (ACR)' },
        { code: 'ACS', name: 'Achinsk, RU (ACS)' },
        { code: 'ACT', name: 'Waco Municipal Apt, US (ACT)' },
        { code: 'ACU', name: 'Achutupo, PA (ACU)' },
        { code: 'ACV', name: 'Arcata/Eureka, US (ACV)' },
        { code: 'ACX', name: 'Xingyi, CN (ACX)' },
        { code: 'ACY', name: 'Atlantic City International, US (ACY)' },
        { code: 'ADA', name: 'Adana, TR (ADA)' },
        { code: 'ADB', name: 'Izmir Adnan Menderes Apt, TR (ADB)' },
        { code: 'ADF', name: 'Adiyaman, TR (ADF)' },
        { code: 'ADH', name: 'Aldan, RU (ADH)' },
        { code: 'ADL', name: 'Adelaide Airport, AU (ADL)' },
        { code: 'ADN', name: 'Andes, CO (ADN)' },
        { code: 'ADO', name: 'Andamooka, AU (ADO)' },
        { code: 'ADQ', name: 'Kodiak Apt, US (ADQ)' },
        { code: 'ADV', name: 'Andover, GB (ADV)' },
        { code: 'ADX', name: 'St Andrews, GB (ADX)' },
        { code: 'ADY', name: 'Alldays, ZA (ADY)' },
        { code: 'ADZ', name: 'San Andres Island, CO (ADZ)' },
        { code: 'AED', name: 'South Florida - All Airports, US (AED)' },
        { code: 'AEG', name: 'Aek Godang, ID (AEG)' },
        { code: 'AEI', name: 'Algeciras, ES (AEI)' },
        { code: 'AEJ', name: 'San Francisco - All Airports, US (AEJ)' },
        { code: 'AEP', name: 'Buenos Aires Aeroparque J. Newbery, AR (AEP)' },
        { code: 'AER', name: 'Adler/Sochi, RU (AER)' },
        { code: 'AES', name: 'Aalesund, NO (AES)' },
        { code: 'AEX', name: 'Alexandria International Apt, US (AEX)' },
        { code: 'AEY', name: 'Akureyri, IS (AEY)' },
        { code: 'AFA', name: 'San Rafael, AR (AFA)' },
        { code: 'AFD', name: 'Port Alfred, ZA (AFD)' },
        { code: 'AFI', name: 'Amalfi, CO (AFI)' },
        { code: 'AFL', name: 'Alta Floresta, BR (AFL)' },
        { code: 'AFY', name: 'Afyon, TR (AFY)' },
        { code: 'AGB', name: 'Munich Augsburg Apt, DE (AGB)' },
        { code: 'AGD', name: 'Anggi, ID (AGD)' },
        { code: 'AGE', name: 'Wangerooge, DE (AGE)' },
        { code: 'AGF', name: 'Agen, FR (AGF)' },
        { code: 'AGH', name: 'Angelholm/Helsingborg Angelholm Apt, SE (AGH)' },
        { code: 'AGJ', name: 'Aguni, JP (AGJ)' },
        { code: 'AGP', name: 'Malaga, ES (AGP)' },
        { code: 'AGQ', name: 'Agrinion, GR (AGQ)' },
        { code: 'AGR', name: 'Agra, IN (AGR)' },
        { code: 'AGS', name: 'Augusta Bush Field, US (AGS)' },
        { code: 'AGT', name: 'Ciudad Del Este, PY (AGT)' },
        { code: 'AGU', name: 'Aguascalientes, MX (AGU)' },
        { code: 'AGV', name: 'Acarigua, VE (AGV)' },
        { code: 'AGW', name: 'Agnew, AU (AGW)' },
        { code: 'AGX', name: 'Agatti Island, IN (AGX)' },
        { code: 'AGY', name: 'Argyle Downs, AU (AGY)' },
        { code: 'AGZ', name: 'Aggeneys, ZA (AGZ)' },
        { code: 'AHA', name: 'Okinawa Naha Afb, JP (AHA)' },
        { code: 'AHB', name: 'Abha, SA (AHB)' },
        { code: 'AHI', name: 'Amahai, ID (AHI)' },
        { code: 'AHL', name: 'Aishalton, GY (AHL)' },
        { code: 'AHN', name: 'Athens, US (AHN)' },
        { code: 'AHO', name: 'Alghero, IT (AHO)' },
        { code: 'AHS', name: 'Ahuas, HN (AHS)' },
        { code: 'AHZ', name: "Alpe D'Huez, FR (AHZ)" },
        { code: 'AIF', name: 'Assis, BR (AIF)' },
        { code: 'AIL', name: 'Ailigandi, PA (AIL)' },
        { code: 'AIR', name: 'Aripuana, BR (AIR)' },
        { code: 'AJA', name: 'Ajaccio, FR (AJA)' },
        { code: 'AJF', name: 'Jouf, SA (AJF)' },
        { code: 'AJI', name: 'Agri, TR (AJI)' },
        { code: 'AJL', name: 'Aizawl, IN (AJL)' },
        { code: 'AJR', name: 'Arvidsjaur, SE (AJR)' },
        { code: 'AJU', name: 'Aracaju, BR (AJU)' },
        { code: 'AKA', name: 'Ankang, CN (AKA)' },
        { code: 'AKD', name: 'Akola, IN (AKD)' },
        { code: 'AKH', name: 'Al Kharj, SA (AKH)' },
        { code: 'AKJ', name: 'Asahikawa, JP (AKJ)' },
        { code: 'AKL', name: 'Auckland International Apt, NZ (AKL)' },
        { code: 'AKN', name: 'King Salmon, US (AKN)' },
        { code: 'AKP', name: 'Anaktuvuk Pass, US (AKP)' },
        { code: 'AKQ', name: 'Astraksetra, ID (AKQ)' },
        { code: 'AKU', name: 'Aksu, CN (AKU)' },
        { code: 'AKV', name: 'Akulivik, CA (AKV)' },
        { code: 'ALB', name: 'Albany International Airport, US (ALB)' },
        { code: 'ALC', name: 'Alicante, ES (ALC)' },
        { code: 'ALD', name: 'Alerta, PE (ALD)' },
        { code: 'ALF', name: 'Alta, NO (ALF)' },
        { code: 'ALH', name: 'Albany, AU (ALH)' },
        { code: 'ALJ', name: 'Alexander Bay, ZA (ALJ)' },
        { code: 'ALL', name: 'Albenga, IT (ALL)' },
        { code: 'ALO', name: 'Waterloo, US (ALO)' },
        { code: 'ALQ', name: 'Alegrete, BR (ALQ)' },
        { code: 'ALR', name: 'Alexandra, NZ (ALR)' },
        { code: 'ALS', name: 'Alamosa, US (ALS)' },
        { code: 'ALT', name: 'Alenquer, BR (ALT)' },
        { code: 'ALW', name: 'Walla Walla, US (ALW)' },
        { code: 'ALY', name: 'Alexandria El Nozha Airport, EG (ALY)' },
        { code: 'AMA', name: 'Amarillo International Apt, US (AMA)' },
        { code: 'AMD', name: 'Ahmedabad, IN (AMD)' },
        { code: 'AMI', name: 'Mataram, ID (AMI)' },
        { code: 'AMJ', name: 'Almenara, BR (AMJ)' },
        { code: 'AML', name: 'Puerto Armuellas, PA (AML)' },
        { code: 'AMQ', name: 'Ambon, ID (AMQ)' },
        { code: 'AMS', name: 'Amsterdam, NL (AMS)' },
        { code: 'AMT', name: 'Amata, AU (AMT)' },
        { code: 'AMV', name: 'Amderma, RU (AMV)' },
        { code: 'AMX', name: 'Ammaroo, AU (AMX)' },
        { code: 'AMZ', name: 'Ardmore, NZ (AMZ)' },
        { code: 'ANC', name: 'Anchorage International Apt, US (ANC)' },
        { code: 'ANE', name: 'Angers Marce Airport, FR (ANE)' },
        { code: 'ANF', name: 'Antofagasta, CL (ANF)' },
        { code: 'ANG', name: 'Angouleme, FR (ANG)' },
        { code: 'ANI', name: 'Aniak, US (ANI)' },
        { code: 'ANK', name: 'Ankara Etimesgut Apt, TR (ANK)' },
        { code: 'ANR', name: 'Antwerp Deurne Airport, BE (ANR)' },
        { code: 'ANS', name: 'Andahuaylas, PE (ANS)' },
        { code: 'ANT', name: 'St Anton, AT (ANT)' },
        { code: 'ANU', name: 'Antigua, AG (ANU)' },
        { code: 'ANX', name: 'Andenes, NO (ANX)' },
        { code: 'ANZ', name: 'Angus Downs, AU (ANZ)' },
        { code: 'AOC', name: 'Altenburg, DE (AOC)' },
        { code: 'AOE', name: 'Eskisehir, TR (AOE)' },
        { code: 'AOG', name: 'Anshan, CN (AOG)' },
        { code: 'AOI', name: 'Ancona, IT (AOI)' },
        { code: 'AOJ', name: 'Aomori, JP (AOJ)' },
        { code: 'AOK', name: 'Karpathos, GR (AOK)' },
        { code: 'AOL', name: 'Paso De Los Libres, AR (AOL)' },
        { code: 'AOO', name: 'Altoona, US (AOO)' },
        { code: 'AOR', name: 'Alor Setar, MY (AOR)' },
        { code: 'AOT', name: 'Aosta, IT (AOT)' },
        { code: 'APB', name: 'Apolo, BO (APB)' },
        { code: 'APE', name: 'San Juan Aposento, PE (APE)' },
        { code: 'API', name: 'Apiay, CO (API)' },
        { code: 'APN', name: 'Alpena, US (APN)' },
        { code: 'APO', name: 'Apartado, CO (APO)' },
        { code: 'APQ', name: 'Arapiraca, BR (APQ)' },
        { code: 'APS', name: 'Anapolis, BR (APS)' },
        { code: 'APU', name: 'Apucarana, BR (APU)' },
        { code: 'APX', name: 'Arapongas, BR (APX)' },
        { code: 'APY', name: 'Alto Parnaiba, BR (APY)' },
        { code: 'APZ', name: 'Zapala, AR (APZ)' },
        { code: 'AQA', name: 'Araraquara, BR (AQA)' },
        { code: 'AQB', name: 'Quiche, GT (AQB)' },
        { code: 'AQG', name: 'Anqing, CN (AQG)' },
        { code: 'AQI', name: 'Qaisumah, SA (AQI)' },
        { code: 'AQM', name: 'Ariquemes, BR (AQM)' },
        { code: 'AQP', name: 'Arequipa, PE (AQP)' },
        { code: 'ARD', name: 'Alor Island, ID (ARD)' },
        { code: 'ARE', name: 'Arecibo, PR (ARE)' },
        { code: 'ARF', name: 'Acaricuara, CO (ARF)' },
        { code: 'ARH', name: 'Arkhangelsk, RU (ARH)' },
        { code: 'ARI', name: 'Arica, CL (ARI)' },
        { code: 'ARJ', name: 'Arso, ID (ARJ)' },
        { code: 'ARM', name: 'Armidale, AU (ARM)' },
        { code: 'ARN', name: 'Stockholm Arlanda Apt, SE (ARN)' },
        { code: 'ARO', name: 'Arboletas, CO (ARO)' },
        { code: 'ARQ', name: 'Arauquita, CO (ARQ)' },
        { code: 'ARR', name: 'Alto Rio Senguerr, AR (ARR)' },
        { code: 'ARS', name: 'Aragarcas, BR (ARS)' },
        { code: 'ART', name: 'Watertown, US (ART)' },
        { code: 'ARU', name: 'Aracatuba, BR (ARU)' },
        { code: 'ARW', name: 'Arad, RO (ARW)' },
        { code: 'ARY', name: 'Ararat, AU (ARY)' },
        { code: 'ASC', name: 'Ascension, BO (ASC)' },
        { code: 'ASD', name: 'Andros Town, BS (ASD)' },
        { code: 'ASE', name: 'Aspen, US (ASE)' },
        { code: 'ASF', name: 'Astrakhan, RU (ASF)' },
        { code: 'ASG', name: 'Ashburton, NZ (ASG)' },
        { code: 'ASJ', name: 'Amami O Shima, JP (ASJ)' },
        { code: 'ASP', name: 'Alice Springs Airport, AU (ASP)' },
        { code: 'ASR', name: 'Kayseri, TR (ASR)' },
        { code: 'ASU', name: 'Asuncion, PY (ASU)' },
        { code: 'ASW', name: 'Aswan, EG (ASW)' },
        { code: 'ATA', name: 'Anta, PE (ATA)' },
        { code: 'ATC', name: "Arthur'S Town, BS (ATC)" },
        { code: 'ATF', name: 'Ambato, EC (ATF)' },
        {
            code: 'ATH',
            name: 'Athens Eleftherios Venizelos Intl.Apt, GR (ATH)',
        },
        { code: 'ATI', name: 'Artigas, UY (ATI)' },
        { code: 'ATL', name: 'Atlanta, US (ATL)' },
        { code: 'ATM', name: 'Altamira, BR (ATM)' },
        { code: 'ATQ', name: 'Amritsar, IN (ATQ)' },
        { code: 'ATW', name: 'Appleton, US (ATW)' },
        { code: 'ATY', name: 'Watertown, US (ATY)' },
        { code: 'ATZ', name: 'Assiut, EG (ATZ)' },
        { code: 'AUA', name: 'Aruba, AW (AUA)' },
        { code: 'AUB', name: 'Itauba, BR (AUB)' },
        { code: 'AUC', name: 'Arauca, CO (AUC)' },
        { code: 'AUD', name: 'Augustus Downs, AU (AUD)' },
        { code: 'AUE', name: 'Abu Rudeis, EG (AUE)' },
        { code: 'AUF', name: 'Auxerre, FR (AUF)' },
        { code: 'AUG', name: 'Augusta, US (AUG)' },
        { code: 'AUH', name: 'Abu Dhabi International Apt, AE (AUH)' },
        { code: 'AUK', name: 'Alakanuk, US (AUK)' },
        { code: 'AUR', name: 'Aurillac, FR (AUR)' },
        { code: 'AUS', name: 'Austin-Bergstrom International Apt, US (AUS)' },
        { code: 'AUT', name: 'Atauro, ID (AUT)' },
        { code: 'AUU', name: 'Aurukun Mission, AU (AUU)' },
        { code: 'AUX', name: 'Araguaina, BR (AUX)' },
        { code: 'AVA', name: 'An Shun, CN (AVA)' },
        { code: 'AVB', name: 'Aviano, IT (AVB)' },
        { code: 'AVF', name: 'Avoriaz, FR (AVF)' },
        { code: 'AVG', name: 'Auvergne, AU (AVG)' },
        { code: 'AVI', name: 'Ciego De Avila, CU (AVI)' },
        { code: 'AVL', name: 'Asheville, US (AVL)' },
        { code: 'AVN', name: 'Avignon Caumont Airport, FR (AVN)' },
        {
            code: 'AVP',
            name: 'Wilkes-Barre Scranton International Apt, US (AVP)',
        },
        { code: 'AVV', name: 'Melbourne Avalon Airport, AU (AVV)' },
        { code: 'AWK', name: 'Wake Island, UM (AWK)' },
        { code: 'AWN', name: 'Alton Downs, AU (AWN)' },
        { code: 'AWP', name: 'Austral Downs, AU (AWP)' },
        { code: 'AXA', name: 'Anguilla, AI (AXA)' },
        { code: 'AXC', name: 'Aramac, AU (AXC)' },
        { code: 'AXD', name: 'Alexandroupolis, GR (AXD)' },
        { code: 'AXE', name: 'Xanxere, BR (AXE)' },
        { code: 'AXL', name: 'Alexandria, AU (AXL)' },
        { code: 'AXM', name: 'Armenia, CO (AXM)' },
        { code: 'AXP', name: 'Spring Point, BS (AXP)' },
        { code: 'AXT', name: 'Akita, JP (AXT)' },
        { code: 'AYA', name: 'Ayapel, CO (AYA)' },
        { code: 'AYC', name: 'Ayacucho, CO (AYC)' },
        { code: 'AYD', name: 'Alroy Downs, AU (AYD)' },
        { code: 'AYG', name: 'Yaguara, CO (AYG)' },
        { code: 'AYH', name: 'Alconbury, GB (AYH)' },
        { code: 'AYI', name: 'Yari, CO (AYI)' },
        { code: 'AYL', name: 'Anthony Lagoon, AU (AYL)' },
        { code: 'AYN', name: 'Anyang, CN (AYN)' },
        { code: 'AYO', name: 'Ayolas, PY (AYO)' },
        { code: 'AYP', name: 'Ayacucho, PE (AYP)' },
        { code: 'AYQ', name: 'Ayers Rock, AU (AYQ)' },
        { code: 'AYR', name: 'Ayr, AU (AYR)' },
        { code: 'AYT', name: 'Antalya, TR (AYT)' },
        { code: 'AYW', name: 'Ayawasi, ID (AYW)' },
        { code: 'AZI', name: 'Abu Dhabi Bateen Apt, AE (AZI)' },
        { code: 'AZO', name: 'Kalamazoo, US (AZO)' },
        { code: 'AZS', name: 'Samana, DO (AZS)' },
        { code: 'AZT', name: 'Zapatoca, CO (AZT)' },
        { code: 'BAC', name: 'Barranca De Upia, CO (BAC)' },
        { code: 'BAE', name: 'Barcelonnette, FR (BAE)' },
        { code: 'BAG', name: 'Baguio, PH (BAG)' },
        { code: 'BAI', name: 'Buenos Aires, CR (BAI)' },
        { code: 'BAL', name: 'Batman, TR (BAL)' },
        { code: 'BAQ', name: 'Barranquilla, CO (BAQ)' },
        { code: 'BAT', name: 'Barretos, BR (BAT)' },
        { code: 'BAU', name: 'Bauru, BR (BAU)' },
        { code: 'BAV', name: 'Baotou, CN (BAV)' },
        { code: 'BAX', name: 'Barnaul, RU (BAX)' },
        { code: 'BAY', name: 'Baia Mare, RO (BAY)' },
        { code: 'BAZ', name: 'Barcelos, BR (BAZ)' },
        { code: 'BBA', name: 'Balmaceda, CL (BBA)' },
        { code: 'BBE', name: 'Big Bell, AU (BBE)' },
        { code: 'BBH', name: 'Barth, DE (BBH)' },
        { code: 'BBI', name: 'Bhubaneswar, IN (BBI)' },
        { code: 'BBJ', name: 'Bitburg, DE (BBJ)' },
        { code: 'BBK', name: 'Kasane, BW (BBK)' },
        { code: 'BBN', name: 'Bario, MY (BBN)' },
        { code: 'BBP', name: 'Bembridge, GB (BBP)' },
        { code: 'BBQ', name: 'Barbuda, AG (BBQ)' },
        { code: 'BBR', name: 'Basse Terre, GP (BBR)' },
        { code: 'BBS', name: 'Blackbushe, GB (BBS)' },
        { code: 'BBU', name: 'Bucharest Baneasa Apt, RO (BBU)' },
        { code: 'BCA', name: 'Baracoa, CU (BCA)' },
        { code: 'BCD', name: 'Bacolod, PH (BCD)' },
        { code: 'BCG', name: 'Bemichi, GY (BCG)' },
        { code: 'BCI', name: 'Barcaldine, AU (BCI)' },
        { code: 'BCK', name: 'Bolwarra, AU (BCK)' },
        { code: 'BCL', name: 'Barra Colorado, CR (BCL)' },
        { code: 'BCM', name: 'Bacau, RO (BCM)' },
        { code: 'BCN', name: 'Barcelona Apt, ES (BCN)' },
        { code: 'BCR', name: 'Boca Do Acre, BR (BCR)' },
        { code: 'BCV', name: 'Belmopan, BZ (BCV)' },
        { code: 'BCX', name: 'Beloreck, RU (BCX)' },
        { code: 'BCZ', name: 'Bickerton Island, AU (BCZ)' },
        { code: 'BDA', name: 'Bermuda International, BM (BDA)' },
        { code: 'BDB', name: 'Bundaberg, AU (BDB)' },
        { code: 'BDC', name: 'Barra Do Corda, BR (BDC)' },
        { code: 'BDD', name: 'Badu Island, AU (BDD)' },
        { code: 'BDJ', name: 'Banjarmasin, ID (BDJ)' },
        { code: 'BDL', name: 'Hartford Bradley International Apt, US (BDL)' },
        { code: 'BDM', name: 'Bandirma, TR (BDM)' },
        { code: 'BDO', name: 'Bandung, ID (BDO)' },
        { code: 'BDQ', name: 'Vadodara, IN (BDQ)' },
        { code: 'BDS', name: 'Brindisi, IT (BDS)' },
        { code: 'BDU', name: 'Bardufoss, NO (BDU)' },
        { code: 'BDW', name: 'Bedford Downs, AU (BDW)' },
        { code: 'BEB', name: 'Benbecula, GB (BEB)' },
        { code: 'BEE', name: 'Beagle Bay, AU (BEE)' },
        { code: 'BEF', name: 'Bluefields, NI (BEF)' },
        { code: 'BEJ', name: 'Berau, ID (BEJ)' },
        { code: 'BEK', name: 'Rae Bareli, IN (BEK)' },
        { code: 'BEL', name: 'Belem, BR (BEL)' },
        { code: 'BEO', name: 'Newcastle Belmont Apt, AU (BEO)' },
        { code: 'BEP', name: 'Bellary, IN (BEP)' },
        { code: 'BEQ', name: 'Bury St Edmunds, GB (BEQ)' },
        { code: 'BES', name: 'Brest, FR (BES)' },
        { code: 'BET', name: 'Bethel Municipal Apt, US (BET)' },
        { code: 'BEU', name: 'Bedourie, AU (BEU)' },
        { code: 'BEX', name: 'Benson, GB (BEX)' },
        { code: 'BFA', name: 'Bahia Negra, PY (BFA)' },
        { code: 'BFC', name: 'Bloomfield, AU (BFC)' },
        { code: 'BFD', name: 'Bradford, US (BFD)' },
        { code: 'BFE', name: 'Bielefeld, DE (BFE)' },
        { code: 'BFF', name: 'Scottsbluff, US (BFF)' },
        { code: 'BFH', name: 'Curitiba Bacacheri, BR (BFH)' },
        { code: 'BFI', name: 'Seattle Boeing Field, US (BFI)' },
        { code: 'BFL', name: 'Bakersfield, US (BFL)' },
        { code: 'BFN', name: 'Bloemfontein, ZA (BFN)' },
        { code: 'BFQ', name: 'Bahia Pinas, PA (BFQ)' },
        { code: 'BFS', name: 'Belfast International, IE (BFS)' },
        { code: 'BFS', name: 'Belfast International Apt, GB (BFS)' },
        { code: 'BFU', name: 'Bengbu, CN (BFU)' },
        { code: 'BGA', name: 'Bucaramanga, CO (BGA)' },
        { code: 'BGC', name: 'Braganca, PT (BGC)' },
        { code: 'BGI', name: 'Barbados, BB (BGI)' },
        { code: 'BGJ', name: 'Borgarfjordur Eystri, IS (BGJ)' },
        { code: 'BGK', name: 'Big Creek, BZ (BGK)' },
        { code: 'BGM', name: 'Binghamton, US (BGM)' },
        { code: 'BGN', name: 'Brueggen, DE (BGN)' },
        { code: 'BGO', name: 'Bergen, NO (BGO)' },
        { code: 'BGR', name: 'Bangor, US (BGR)' },
        { code: 'BGV', name: 'Bento Goncalves, BR (BGV)' },
        { code: 'BGX', name: 'Bage, BR (BGX)' },
        { code: 'BGY', name: 'Milan Orio Al Serio Apt, IT (BGY)' },
        { code: 'BGZ', name: 'Braga, PT (BGZ)' },
        { code: 'BHA', name: 'Bahia De Caraquez, EC (BHA)' },
        { code: 'BHB', name: 'Bar Harbor, US (BHB)' },
        { code: 'BHD', name: 'Belfast City Apt, GB (BHD)' },
        { code: 'BHD', name: 'George Best Belfast, IE (BHD)' },
        { code: 'BHE', name: 'Blenheim, NZ (BHE)' },
        { code: 'BHF', name: 'Bahia Cupica, CO (BHF)' },
        { code: 'BHG', name: 'Brus Laguna, HN (BHG)' },
        { code: 'BHH', name: 'Bisha, SA (BHH)' },
        { code: 'BHI', name: 'Bahia Blanca, AR (BHI)' },
        { code: 'BHJ', name: 'Bhuj, IN (BHJ)' },
        { code: 'BHM', name: 'Birmingham, US (BHM)' },
        { code: 'BHO', name: 'Bhopal, IN (BHO)' },
        { code: 'BHQ', name: 'Broken Hill, AU (BHQ)' },
        { code: 'BHS', name: 'Bathurst, AU (BHS)' },
        { code: 'BHT', name: 'Brighton Downs, AU (BHT)' },
        { code: 'BHU', name: 'Bhavnagar, IN (BHU)' },
        { code: 'BHX', name: 'Birmingham International Airport, GB (BHX)' },
        { code: 'BHY', name: 'Beihai, CN (BHY)' },
        { code: 'BIA', name: 'Bastia, FR (BIA)' },
        { code: 'BID', name: 'Block Island, US (BID)' },
        { code: 'BIK', name: 'Biak, ID (BIK)' },
        { code: 'BIL', name: 'Billings, US (BIL)' },
        { code: 'BIM', name: 'Bimini International Apt, BS (BIM)' },
        { code: 'BIO', name: 'Bilbao, ES (BIO)' },
        { code: 'BIP', name: 'Bulimba, AU (BIP)' },
        { code: 'BIQ', name: 'Biarritz, FR (BIQ)' },
        { code: 'BIS', name: 'Bismarck, US (BIS)' },
        { code: 'BIU', name: 'Bildudalur, IS (BIU)' },
        { code: 'BIW', name: 'Billiluna, AU (BIW)' },
        { code: 'BIY', name: 'Bisho, ZA (BIY)' },
        { code: 'BJD', name: 'Bakkafjordur, IS (BJD)' },
        { code: 'BJF', name: 'Batsfjord, NO (BJF)' },
        { code: 'BJG', name: 'Bolaang, ID (BJG)' },
        { code: 'BJI', name: 'Bemidji, US (BJI)' },
        { code: 'BJK', name: 'Benjina, ID (BJK)' },
        { code: 'BJO', name: 'Bermejo, BO (BJO)' },
        { code: 'BJP', name: 'Braganca Paulista, BR (BJP)' },
        { code: 'BJV', name: 'Bodrum Milas Airport, TR (BJV)' },
        { code: 'BJW', name: 'Bajawa, ID (BJW)' },
        { code: 'BJX', name: 'Leon/Guanajuato, MX (BJX)' },
        { code: 'BJZ', name: 'Badajoz, ES (BJZ)' },
        { code: 'BKA', name: 'Moscow Bykovo Apt, RU (BKA)' },
        { code: 'BKB', name: 'Bikaner, IN (BKB)' },
        { code: 'BKI', name: 'Kota Kinabalu, MY (BKI)' },
        { code: 'BKM', name: 'Bakalalan, MY (BKM)' },
        { code: 'BKP', name: 'Barkly Downs, AU (BKP)' },
        { code: 'BKQ', name: 'Blackall, AU (BKQ)' },
        { code: 'BKS', name: 'Bengkulu, ID (BKS)' },
        { code: 'BKW', name: 'Beckley, US (BKW)' },
        { code: 'BLA', name: 'Barcelona, VE (BLA)' },
        { code: 'BLB', name: 'Balboa, PA (BLB)' },
        { code: 'BLE', name: 'Borlange/Falun, SE (BLE)' },
        { code: 'BLG', name: 'Belaga, MY (BLG)' },
        { code: 'BLI', name: 'Bellingham, US (BLI)' },
        { code: 'BLK', name: 'Blackpool, GB (BLK)' },
        { code: 'BLL', name: 'Billund, DK (BLL)' },
        { code: 'BLN', name: 'Benalla, AU (BLN)' },
        { code: 'BLO', name: 'Blonduos, IS (BLO)' },
        { code: 'BLP', name: 'Bellavista, PE (BLP)' },
        { code: 'BLQ', name: 'Bologna, IT (BLQ)' },
        { code: 'BLR', name: 'Bangalore, IN (BLR)' },
        { code: 'BLS', name: 'Bollon, AU (BLS)' },
        { code: 'BLT', name: 'Blackwater, AU (BLT)' },
        { code: 'BLV', name: 'Belleville, US (BLV)' },
        { code: 'BLX', name: 'Belluno, IT (BLX)' },
        { code: 'BLY', name: 'Belmullet, IE (BLY)' },
        { code: 'BMA', name: 'Stockholm Bromma Apt, SE (BMA)' },
        { code: 'BME', name: 'Broome, AU (BME)' },
        { code: 'BMI', name: 'Bloomington-Normal, US (BMI)' },
        { code: 'BMJ', name: 'Baramita, GY (BMJ)' },
        { code: 'BMK', name: 'Borkum, DE (BMK)' },
        { code: 'BMP', name: 'Brampton Island, AU (BMP)' },
        { code: 'BMR', name: 'Baltrum, DE (BMR)' },
        { code: 'BMS', name: 'Brumado, BR (BMS)' },
        { code: 'BMU', name: 'Bima, ID (BMU)' },
        { code: 'BNA', name: 'Nashville, US (BNA)' },
        { code: 'BNE', name: 'Brisbane, AU (BNE)' },
        { code: 'BNK', name: 'Ballina, AU (BNK)' },
        { code: 'BNN', name: 'Bronnoysund, NO (BNN)' },
        { code: 'BNQ', name: 'Baganga, PH (BNQ)' },
        { code: 'BNS', name: 'Barinas, VE (BNS)' },
        { code: 'BNU', name: 'Blumenau, BR (BNU)' },
        { code: 'BNX', name: 'Banja Luka, BA (BNX)' },
        { code: 'BOC', name: 'Bocas Del Toro, PA (BOC)' },
        { code: 'BOD', name: 'Bordeaux Airport, FR (BOD)' },
        { code: 'BOG', name: 'Bogota, CO (BOG)' },
        { code: 'BOH', name: 'Bournemouth, GB (BOH)' },
        { code: 'BOI', name: 'Boise, US (BOI)' },
        { code: 'BOL', name: 'Bally Kelly, GB (BOL)' },
        { code: 'BOM', name: 'Mumbai, IN (BOM)' },
        { code: 'BOO', name: 'Bodo, NO (BOO)' },
        { code: 'BOR', name: 'Belfort, FR (BOR)' },
        { code: 'BOS', name: 'Boston Logan International Apt, US (BOS)' },
        { code: 'BOU', name: 'Bourges, FR (BOU)' },
        { code: 'BOX', name: 'Borroloola, AU (BOX)' },
        { code: 'BPG', name: 'Barra Do Garcas, BR (BPG)' },
        { code: 'BPH', name: 'Bislig, PH (BPH)' },
        { code: 'BPN', name: 'Balikpapan, ID (BPN)' },
        { code: 'BPS', name: 'Porto Seguro, BR (BPS)' },
        { code: 'BPT', name: 'Beaumont Jefferson County Apt, US (BPT)' },
        { code: 'BPU', name: 'Beppu, JP (BPU)' },
        { code: 'BPX', name: 'Bangda, CN (BPX)' },
        { code: 'BQA', name: 'Baler, PH (BQA)' },
        { code: 'BQB', name: 'Busselton, AU (BQB)' },
        { code: 'BQH', name: 'London Biggin Hill Apt, GB (BQH)' },
        { code: 'BQK', name: 'Brunswick Glynco Jetport, US (BQK)' },
        { code: 'BQL', name: 'Boulia, AU (BQL)' },
        { code: 'BQN', name: 'Aguadilla, PR (BQN)' },
        { code: 'BQQ', name: 'Barra, BR (BQQ)' },
        { code: 'BQS', name: 'Blagoveschensk, RU (BQS)' },
        { code: 'BQT', name: 'Brest, BY (BQT)' },
        { code: 'BQU', name: 'Port Elizabeth, VC (BQU)' },
        { code: 'BQW', name: 'Balgo Hills, AU (BQW)' },
        { code: 'BRA', name: 'Barreiras, BR (BRA)' },
        { code: 'BRB', name: 'Barreirinhas, BR (BRB)' },
        { code: 'BRC', name: 'San Carlos De Bariloche, AR (BRC)' },
        { code: 'BRD', name: 'Brainerd, US (BRD)' },
        { code: 'BRE', name: 'Bremen, DE (BRE)' },
        { code: 'BRF', name: 'Bradford, GB (BRF)' },
        { code: 'BRI', name: 'Bari, IT (BRI)' },
        { code: 'BRJ', name: 'Bright, AU (BRJ)' },
        { code: 'BRK', name: 'Bourke, AU (BRK)' },
        { code: 'BRM', name: 'Barquisimeto, VE (BRM)' },
        { code: 'BRN', name: 'Berne Belp, CH (BRN)' },
        { code: 'BRO', name: 'Brownsville, US (BRO)' },
        { code: 'BRQ', name: 'Brno Turany Apt, CZ (BRQ)' },
        { code: 'BRR', name: 'Barra, GB (BRR)' },
        { code: 'BRS', name: 'Bristol, GB (BRS)' },
        { code: 'BRT', name: 'Bathurst Island, AU (BRT)' },
        { code: 'BRU', name: 'Brussels National Airport, BE (BRU)' },
        { code: 'BRV', name: 'Bremerhaven, DE (BRV)' },
        {
            code: 'BRW',
            name: 'Barrow Wiley Post/Will Rogers Memorial, US (BRW)',
        },
        { code: 'BRX', name: 'Barahona, DO (BRX)' },
        { code: 'BSB', name: 'Brasilia, BR (BSB)' },
        { code: 'BSC', name: 'Bahia Solano, CO (BSC)' },
        { code: 'BSD', name: 'Baoshan, CN (BSD)' },
        { code: 'BSE', name: 'Sematan, MY (BSE)' },
        { code: 'BSH', name: 'Brighton, GB (BSH)' },
        { code: 'BSJ', name: 'Bairnsdale, AU (BSJ)' },
        { code: 'BSL', name: 'Basel, CH (BSL)' },
        { code: 'BSO', name: 'Basco, PH (BSO)' },
        { code: 'BSS', name: 'Balsas, BR (BSS)' },
        { code: 'BTD', name: 'Brunette Downs, AU (BTD)' },
        { code: 'BTH', name: 'Batam, ID (BTH)' },
        { code: 'BTI', name: 'Barter Island, US (BTI)' },
        { code: 'BTJ', name: 'Banda Aceh, ID (BTJ)' },
        { code: 'BTK', name: 'Bratsk, RU (BTK)' },
        { code: 'BTM', name: 'Butte, US (BTM)' },
        { code: 'BTR', name: 'Baton Rouge Ryan Apt, US (BTR)' },
        { code: 'BTS', name: 'Bratislava M.R.Stefanik Apt, SK (BTS)' },
        { code: 'BTU', name: 'Bintulu, MY (BTU)' },
        { code: 'BTV', name: 'Burlington, US (BTV)' },
        { code: 'BTW', name: 'Batu Licin, ID (BTW)' },
        { code: 'BTX', name: 'Betoota, AU (BTX)' },
        { code: 'BTZ', name: 'Bursa, TR (BTZ)' },
        { code: 'BUC', name: 'Burketown, AU (BUC)' },
        { code: 'BUD', name: 'Budapest, HU (BUD)' },
        { code: 'BUF', name: 'Buffalo, US (BUF)' },
        { code: 'BUI', name: 'Bokondini, ID (BUI)' },
        { code: 'BUN', name: 'Buenaventura, CO (BUN)' },
        { code: 'BUP', name: 'Bhatinda, IN (BUP)' },
        { code: 'BUR', name: 'Burbank, US (BUR)' },
        { code: 'BUT', name: 'Burtonwood, GB (BUT)' },
        { code: 'BUV', name: 'Bella Union, UY (BUV)' },
        { code: 'BUW', name: 'Baubau, ID (BUW)' },
        { code: 'BUY', name: 'Bunbury, AU (BUY)' },
        { code: 'BVA', name: 'Paris Beauvais-Tille Airport, FR (BVA)' },
        { code: 'BVB', name: 'Boa Vista, BR (BVB)' },
        { code: 'BVE', name: 'Brive-La-Gaillarde, FR (BVE)' },
        { code: 'BVG', name: 'Berlevag, NO (BVG)' },
        { code: 'BVH', name: 'Vilhena, BR (BVH)' },
        { code: 'BVI', name: 'Birdsville, AU (BVI)' },
        { code: 'BVK', name: 'Huacaraje, BO (BVK)' },
        { code: 'BVL', name: 'Baures, BO (BVL)' },
        { code: 'BVM', name: 'Belmonte, BR (BVM)' },
        { code: 'BVS', name: 'Breves, BR (BVS)' },
        { code: 'BVU', name: 'Beluga, US (BVU)' },
        { code: 'BVW', name: 'Batavia Downs, AU (BVW)' },
        { code: 'BVZ', name: 'Beverley Springs, AU (BVZ)' },
        { code: 'BWB', name: 'Barrow Island, AU (BWB)' },
        { code: 'BWE', name: 'Braunschweig, DE (BWE)' },
        { code: 'BWF', name: 'Barrow-In-Furness, GB (BWF)' },
        { code: 'BWH', name: 'Butterworth, MY (BWH)' },
        {
            code: 'BWI',
            name: 'Baltimore Washington International Apt, US (BWI)',
        },
        { code: 'BWK', name: 'Bol, HR (BWK)' },
        { code: 'BWO', name: 'Balakovo, RU (BWO)' },
        { code: 'BWQ', name: 'Brewarrina, AU (BWQ)' },
        { code: 'BWT', name: 'Burnie, AU (BWT)' },
        { code: 'BWU', name: 'Bankstown, AU (BWU)' },
        { code: 'BWW', name: 'Cayo Las Brujas, CU (BWW)' },
        { code: 'BWY', name: 'Woodbridge, GB (BWY)' },
        { code: 'BXB', name: 'Babo, ID (BXB)' },
        { code: 'BXD', name: 'Bade, ID (BXD)' },
        { code: 'BXF', name: 'Belburn, AU (BXF)' },
        { code: 'BXG', name: 'Bendigo, AU (BXG)' },
        { code: 'BXM', name: 'Batom, ID (BXM)' },
        { code: 'BXN', name: 'Bodrum Imsik Airport, TR (BXN)' },
        { code: 'BXO', name: 'Buochs, CH (BXO)' },
        { code: 'BXT', name: 'Bontang, ID (BXT)' },
        { code: 'BXU', name: 'Butuan, PH (BXU)' },
        { code: 'BXV', name: 'Breiddalsvik, IS (BXV)' },
        { code: 'BYB', name: 'Dibaa, OM (BYB)' },
        { code: 'BYC', name: 'Yacuiba, BO (BYC)' },
        { code: 'BYM', name: 'Bayamo, CU (BYM)' },
        { code: 'BYO', name: 'Bonito, BR (BYO)' },
        { code: 'BYQ', name: 'Bunyu, ID (BYQ)' },
        { code: 'BYR', name: 'Laeso Island, DK (BYR)' },
        { code: 'BYT', name: 'Bantry, IE (BYT)' },
        { code: 'BYU', name: 'Bayreuth, DE (BYU)' },
        { code: 'BYX', name: 'Baniyala, AU (BYX)' },
        { code: 'BZA', name: 'Bonanza, NI (BZA)' },
        { code: 'BZC', name: 'Buzios, BR (BZC)' },
        { code: 'BZD', name: 'Balranald, AU (BZD)' },
        { code: 'BZE', name: 'Belize City Goldson Intl Apt, BZ (BZE)' },
        { code: 'BZG', name: 'Bydgoszcz, PL (BZG)' },
        { code: 'BZI', name: 'Balikesir Airport, TR (BZI)' },
        { code: 'BZK', name: 'Briansk, RU (BZK)' },
        { code: 'BZL', name: 'Barisal, BD (BZL)' },
        { code: 'BZM', name: 'Bergen Op Zoom, NL (BZM)' },
        { code: 'BZN', name: 'Bozeman, US (BZN)' },
        { code: 'BZO', name: 'Bolzano/Bozen, IT (BZO)' },
        { code: 'BZP', name: 'Bizant, AU (BZP)' },
        { code: 'BZR', name: 'Beziers, FR (BZR)' },
        { code: 'BZZ', name: 'Brize Norton, GB (BZZ)' },
        { code: 'CAA', name: 'Catacamas, HN (CAA)' },
        { code: 'CAC', name: 'Cascavel, BR (CAC)' },
        { code: 'CAE', name: 'Columbia Metropolitan Apt, US (CAE)' },
        { code: 'CAF', name: 'Carauari, BR (CAF)' },
        { code: 'CAG', name: 'Cagliari, IT (CAG)' },
        { code: 'CAI', name: 'Cairo, EG (CAI)' },
        { code: 'CAJ', name: 'Canaima, VE (CAJ)' },
        { code: 'CAK', name: 'Akron/Canton Ohio Regional, US (CAK)' },
        { code: 'CAL', name: 'Campbeltown, GB (CAL)' },
        { code: 'CAM', name: 'Camiri, BO (CAM)' },
        { code: 'CAN', name: 'Guangzhou, CN (CAN)' },
        { code: 'CAP', name: 'Cap Haitien, HT (CAP)' },
        { code: 'CAQ', name: 'Caucasia, CO (CAQ)' },
        { code: 'CAT', name: 'Cat Island, BS (CAT)' },
        { code: 'CAU', name: 'Caruaru, BR (CAU)' },
        { code: 'CAW', name: 'Campos, BR (CAW)' },
        { code: 'CAX', name: 'Carlisle, GB (CAX)' },
        { code: 'CAZ', name: 'Cobar, AU (CAZ)' },
        { code: 'CBB', name: 'Cochabamba, BO (CBB)' },
        { code: 'CBC', name: 'Cherrabun, AU (CBC)' },
        { code: 'CBD', name: 'Car Nicobar, IN (CBD)' },
        { code: 'CBG', name: 'Cambridge, GB (CBG)' },
        { code: 'CBI', name: 'Cape Barren Island, AU (CBI)' },
        { code: 'CBJ', name: 'Cabo Rojo, DO (CBJ)' },
        { code: 'CBL', name: 'Ciudad Bolivar, VE (CBL)' },
        { code: 'CBN', name: 'Cirebon, ID (CBN)' },
        { code: 'CBO', name: 'Cotabato, PH (CBO)' },
        { code: 'CBP', name: 'Coimbra, PT (CBP)' },
        { code: 'CBR', name: 'Canberra, AU (CBR)' },
        { code: 'CBS', name: 'Cabimas, VE (CBS)' },
        { code: 'CBU', name: 'Cottbus, DE (CBU)' },
        { code: 'CBV', name: 'Coban, GT (CBV)' },
        { code: 'CBW', name: 'Campo Mourao, BR (CBW)' },
        { code: 'CBX', name: 'Condobolin, AU (CBX)' },
        { code: 'CBY', name: 'Canobie, AU (CBY)' },
        { code: 'CCC', name: 'Cayo Coco, CU (CCC)' },
        { code: 'CCE', name: 'St Martin Grand Case Apt, GP (CCE)' },
        { code: 'CCF', name: 'Carcassonne, FR (CCF)' },
        { code: 'CCH', name: 'Chile Chico, CL (CCH)' },
        { code: 'CCI', name: 'Concordia, BR (CCI)' },
        { code: 'CCJ', name: 'Kozhikode, IN (CCJ)' },
        { code: 'CCL', name: 'Chinchilla, AU (CCL)' },
        { code: 'CCM', name: 'Criciuma, BR (CCM)' },
        { code: 'CCO', name: 'Carimagua, CO (CCO)' },
        { code: 'CCP', name: 'Concepcion, CL (CCP)' },
        { code: 'CCQ', name: 'Cachoeira, BR (CCQ)' },
        { code: 'CCS', name: 'Caracas, VE (CCS)' },
        { code: 'CCT', name: 'Colonia Catriel, AR (CCT)' },
        { code: 'CCU', name: 'Kolkata, IN (CCU)' },
        { code: 'CCW', name: 'Cowell, AU (CCW)' },
        { code: 'CCX', name: 'Caceres, BR (CCX)' },
        { code: 'CCZ', name: 'Chub Cay, BS (CCZ)' },
        { code: 'CDA', name: 'Cooinda, AU (CDA)' },
        { code: 'CDB', name: 'Cold Bay, US (CDB)' },
        { code: 'CDC', name: 'Cedar City, US (CDC)' },
        { code: 'CDD', name: 'Cauquira, HN (CDD)' },
        { code: 'CDE', name: 'Caledonia, PA (CDE)' },
        { code: 'CDF', name: "Cortina D'Ampezzo, IT (CDF)" },
        { code: 'CDG', name: 'Paris Charles De Gaulle Apt, FR (CDG)' },
        { code: 'CDI', name: 'Cachoeiro De Itapemirim, BR (CDI)' },
        { code: 'CDJ', name: 'Conceicao Do Araguaia, BR (CDJ)' },
        { code: 'CDO', name: 'Cradock, ZA (CDO)' },
        { code: 'CDP', name: 'Cuddapah, IN (CDP)' },
        { code: 'CDQ', name: 'Croydon, AU (CDQ)' },
        { code: 'CDU', name: 'Camden, AU (CDU)' },
        { code: 'CDV', name: 'Cordova Mudhole Smith Airport, US (CDV)' },
        { code: 'CDY', name: 'Cagayan De Sulu, PH (CDY)' },
        { code: 'CDZ', name: 'Cadiz, ES (CDZ)' },
        { code: 'CEB', name: 'Cebu, PH (CEB)' },
        { code: 'CEC', name: 'Crescent City, US (CEC)' },
        { code: 'CED', name: 'Ceduna, AU (CED)' },
        { code: 'CEE', name: 'Cherepovets, RU (CEE)' },
        { code: 'CEG', name: 'Chester, GB (CEG)' },
        { code: 'CEJ', name: 'Chernigov, UA (CEJ)' },
        { code: 'CEK', name: 'Chelyabinsk, RU (CEK)' },
        { code: 'CEL', name: 'Cape Eleuthera, BS (CEL)' },
        { code: 'CEN', name: 'Ciudad Obregon, MX (CEN)' },
        { code: 'CEP', name: 'Concepcion, BO (CEP)' },
        { code: 'CEQ', name: 'Cannes Mandelieu, FR (CEQ)' },
        { code: 'CER', name: 'Cherbourg, FR (CER)' },
        { code: 'CES', name: 'Cessnock, AU (CES)' },
        { code: 'CET', name: 'Cholet, FR (CET)' },
        { code: 'CEZ', name: 'Cortez, US (CEZ)' },
        { code: 'CFB', name: 'Cabo Frio, BR (CFB)' },
        { code: 'CFC', name: 'Cacador, BR (CFC)' },
        { code: 'CFE', name: 'Clermont-Ferrand, FR (CFE)' },
        { code: 'CFG', name: 'Cienfuegos, CU (CFG)' },
        { code: 'CFH', name: 'Clifton Hills, AU (CFH)' },
        { code: 'CFI', name: 'Camfield, AU (CFI)' },
        { code: 'CFN', name: 'Donegal, IE (CFN)' },
        { code: 'CFO', name: 'Confreza, BR (CFO)' },
        { code: 'CFP', name: 'Carpentaria Downs, AU (CFP)' },
        { code: 'CFQ', name: 'Creston, CA (CFQ)' },
        { code: 'CFR', name: 'Caen, FR (CFR)' },
        { code: 'CFS', name: 'Coffs Harbour, AU (CFS)' },
        { code: 'CFU', name: 'Kerkyra, GR (CFU)' },
        { code: 'CGA', name: 'Craig, US (CGA)' },
        { code: 'CGB', name: 'Cuiaba, BR (CGB)' },
        { code: 'CGD', name: 'Changde, CN (CGD)' },
        { code: 'CGG', name: 'Casiguran, PH (CGG)' },
        { code: 'CGH', name: 'Sao Paulo Congonhas Apt, BR (CGH)' },
        { code: 'CGK', name: 'Jakarta Soekarno-Hatta Apt, ID (CGK)' },
        { code: 'CGM', name: 'Camiguin, PH (CGM)' },
        { code: 'CGN', name: 'Cologne/Bonn K.A.Apt, DE (CGN)' },
        { code: 'CGO', name: 'Zhengzhou, CN (CGO)' },
        { code: 'CGP', name: 'Chittagong, BD (CGP)' },
        { code: 'CGQ', name: 'Changchun, CN (CGQ)' },
        { code: 'CGR', name: 'Campo Grande, BR (CGR)' },
        { code: 'CGU', name: 'Ciudad Guayana, VE (CGU)' },
        { code: 'CGV', name: 'Caiguna, AU (CGV)' },
        { code: 'CGY', name: 'Cagayan De Oro, PH (CGY)' },
        { code: 'CHA', name: 'Chattanooga Lovell Field Apt, US (CHA)' },
        { code: 'CHC', name: 'Christchurch, NZ (CHC)' },
        { code: 'CHE', name: 'Caherciveen, IE (CHE)' },
        { code: 'CHF', name: 'Jinhae, KR (CHF)' },
        { code: 'CHG', name: 'Chaoyang, CN (CHG)' },
        { code: 'CHH', name: 'Chachapoyas, PE (CHH)' },
        { code: 'CHI', name: 'Chicago - All Airports, US (CHI)' },
        { code: 'CHM', name: 'Chimbote, PE (CHM)' },
        { code: 'CHN', name: 'Jeonju, KR (CHN)' },
        { code: 'CHO', name: 'Charlottesville, US (CHO)' },
        { code: 'CHQ', name: 'Chania, GR (CHQ)' },
        { code: 'CHR', name: 'Chateauroux, FR (CHR)' },
        { code: 'CHS', name: 'Charleston, US (CHS)' },
        { code: 'CHT', name: 'Chatham Island, NZ (CHT)' },
        { code: 'CHV', name: 'Chaves, PT (CHV)' },
        { code: 'CHW', name: 'Jiuquan, CN (CHW)' },
        { code: 'CHX', name: 'Changuinola, PA (CHX)' },
        { code: 'CIA', name: 'Rome Ciampino Apt, IT (CIA)' },
        { code: 'CIC', name: 'Chico, US (CIC)' },
        { code: 'CID', name: 'Cedar Rapids, US (CID)' },
        { code: 'CIE', name: 'Collie, AU (CIE)' },
        { code: 'CIF', name: 'Chifeng, CN (CIF)' },
        { code: 'CIH', name: 'Changzhi, CN (CIH)' },
        { code: 'CIJ', name: 'Cobija, BO (CIJ)' },
        { code: 'CIM', name: 'Cimitarra, CO (CIM)' },
        { code: 'CIO', name: 'Concepcion, PY (CIO)' },
        { code: 'CIQ', name: 'Chiquimula, GT (CIQ)' },
        { code: 'CIU', name: 'Sault Ste Marie Chippewa County Apt, US (CIU)' },
        { code: 'CIW', name: 'Canouan Island, VC (CIW)' },
        { code: 'CIX', name: 'Chiclayo, PE (CIX)' },
        { code: 'CIY', name: 'Comiso, IT (CIY)' },
        { code: 'CIZ', name: 'Coari, BR (CIZ)' },
        { code: 'CJA', name: 'Cajamarca, PE (CJA)' },
        { code: 'CJB', name: 'Coimbatore, IN (CJB)' },
        { code: 'CJC', name: 'Calama, CL (CJC)' },
        { code: 'CJD', name: 'Candilejas, CO (CJD)' },
        { code: 'CJH', name: 'Chilko Lake, CA (CJH)' },
        { code: 'CJI', name: 'Ciudad Real, ES (CJI)' },
        { code: 'CJJ', name: 'Cheongju, KR (CJJ)' },
        { code: 'CJS', name: 'Ciudad Juarez, MX (CJS)' },
        { code: 'CJU', name: 'Jeju International, KR (CJU)' },
        { code: 'CKB', name: 'Clarksburg, US (CKB)' },
        { code: 'CKC', name: 'Cherkassy, UA (CKC)' },
        { code: 'CKG', name: 'Chongqing, CN (CKG)' },
        { code: 'CKH', name: 'Chokurdah, RU (CKH)' },
        { code: 'CKI', name: 'Croker Island, AU (CKI)' },
        { code: 'CKL', name: 'Chkalovsky, RU (CKL)' },
        { code: 'CKO', name: 'Cornelio Procopio, BR (CKO)' },
        { code: 'CKS', name: 'Carajas, BR (CKS)' },
        { code: 'CKZ', name: 'Canakkale, TR (CKZ)' },
        { code: 'CLA', name: 'Comilla, BD (CLA)' },
        { code: 'CLB', name: 'Castlebar, IE (CLB)' },
        { code: 'CLE', name: 'Cleveland Hopkins International Apt, US (CLE)' },
        { code: 'CLH', name: 'Coolah, AU (CLH)' },
        { code: 'CLJ', name: 'Cluj, RO (CLJ)' },
        { code: 'CLL', name: 'College Station, US (CLL)' },
        { code: 'CLM', name: 'Port Angeles, US (CLM)' },
        { code: 'CLN', name: 'Carolina, BR (CLN)' },
        { code: 'CLO', name: 'Cali, CO (CLO)' },
        { code: 'CLQ', name: 'Colima, MX (CLQ)' },
        { code: 'CLT', name: 'Charlotte, US (CLT)' },
        { code: 'CLV', name: 'Caldas Novas, BR (CLV)' },
        { code: 'CLX', name: 'Clorinda, AR (CLX)' },
        { code: 'CLY', name: 'Calvi, FR (CLY)' },
        { code: 'CLZ', name: 'Calabozo, VE (CLZ)' },
        { code: 'CMA', name: 'Cunnamulla, AU (CMA)' },
        { code: 'CMC', name: 'Camocim, BR (CMC)' },
        { code: 'CMD', name: 'Cootamundra, AU (CMD)' },
        { code: 'CME', name: 'Ciudad Del Carmen, MX (CME)' },
        { code: 'CMF', name: 'Chambery, FR (CMF)' },
        { code: 'CMG', name: 'Corumba, BR (CMG)' },
        { code: 'CMH', name: 'Columbus Port Columbus Intl Apt, US (CMH)' },
        { code: 'CMI', name: 'Champaign, US (CMI)' },
        { code: 'CMJ', name: 'Chi Mei, TW (CMJ)' },
        { code: 'CML', name: 'Camooweal, AU (CML)' },
        { code: 'CMM', name: 'Carmelita, GT (CMM)' },
        { code: 'CMP', name: 'Santana Do Araguaia, BR (CMP)' },
        { code: 'CMQ', name: 'Clermont, AU (CMQ)' },
        { code: 'CMR', name: 'Colmar, FR (CMR)' },
        { code: 'CMT', name: 'Cameta, BR (CMT)' },
        { code: 'CMV', name: 'Coromandel, NZ (CMV)' },
        { code: 'CMW', name: 'Camaguey, CU (CMW)' },
        { code: 'CMX', name: 'Hancock, US (CMX)' },
        { code: 'CNB', name: 'Coonamble, AU (CNB)' },
        { code: 'CNC', name: 'Coconut Island, AU (CNC)' },
        { code: 'CND', name: 'Constanta, RO (CND)' },
        {
            code: 'CNF',
            name: 'Belo Horizonte Tancredo Neves Int Apt, BR (CNF)',
        },
        { code: 'CNG', name: 'Cognac, FR (CNG)' },
        { code: 'CNI', name: 'Changhai, CN (CNI)' },
        { code: 'CNJ', name: 'Cloncurry, AU (CNJ)' },
        { code: 'CNL', name: 'Sindal, DK (CNL)' },
        { code: 'CNM', name: 'Carlsbad, US (CNM)' },
        { code: 'CNN', name: 'Chulman, RU (CNN)' },
        { code: 'CNQ', name: 'Corrientes, AR (CNQ)' },
        { code: 'CNR', name: 'Chanaral, CL (CNR)' },
        { code: 'CNS', name: 'Cairns, AU (CNS)' },
        { code: 'CNT', name: 'Charata, AR (CNT)' },
        { code: 'CNV', name: 'Canavieiras, BR (CNV)' },
        { code: 'CNY', name: 'Moab, US (CNY)' },
        { code: 'COB', name: 'Coolibah, AU (COB)' },
        { code: 'COC', name: 'Concordia, AR (COC)' },
        { code: 'COD', name: 'Cody, US (COD)' },
        { code: 'COG', name: 'Condoto, CO (COG)' },
        { code: 'COH', name: 'Cooch Behar, IN (COH)' },
        { code: 'COJ', name: 'Coonabarabran, AU (COJ)' },
        { code: 'COK', name: 'Kochi, IN (COK)' },
        { code: 'COL', name: 'Coll, GB (COL)' },
        { code: 'COR', name: 'Cordoba, AR (COR)' },
        { code: 'COS', name: 'Colorado Springs Peterson Field, US (COS)' },
        { code: 'COU', name: 'Columbia, US (COU)' },
        { code: 'COV', name: 'Covilha, PT (COV)' },
        { code: 'COW', name: 'Coquimbo, CL (COW)' },
        { code: 'COX', name: 'Congo Town, BS (COX)' },
        { code: 'COY', name: 'Coolawanyah, AU (COY)' },
        { code: 'COZ', name: 'Constanza, DO (COZ)' },
        { code: 'CPB', name: 'Capurgana, CO (CPB)' },
        { code: 'CPC', name: 'San Martin De Los Andes, AR (CPC)' },
        { code: 'CPD', name: 'Coober Pedy, AU (CPD)' },
        { code: 'CPE', name: 'Campeche, MX (CPE)' },
        { code: 'CPF', name: 'Cepu, ID (CPF)' },
        { code: 'CPG', name: 'Carmen De Patagones, AR (CPG)' },
        { code: 'CPH', name: 'Copenhagen Apt, DK (CPH)' },
        { code: 'CPL', name: 'Chaparral, CO (CPL)' },
        { code: 'CPO', name: 'Copiapo, CL (CPO)' },
        { code: 'CPQ', name: 'Campinas, BR (CPQ)' },
        { code: 'CPR', name: 'Casper, US (CPR)' },
        { code: 'CPT', name: 'Cape Town, ZA (CPT)' },
        { code: 'CPU', name: 'Cururupu, BR (CPU)' },
        { code: 'CPV', name: 'Campina Grande, BR (CPV)' },
        { code: 'CPX', name: 'Culebra, PR (CPX)' },
        { code: 'CQA', name: 'Canarana, BR (CQA)' },
        { code: 'CQF', name: 'Calais Marck Apt, FR (CQF)' },
        { code: 'CQP', name: 'Cape Flattery, AU (CQP)' },
        { code: 'CQS', name: 'Costa Marques, BR (CQS)' },
        { code: 'CQT', name: 'Caquetania, CO (CQT)' },
        { code: 'CRA', name: 'Craiova, RO (CRA)' },
        { code: 'CRB', name: 'Collarenebri, AU (CRB)' },
        { code: 'CRC', name: 'Cartago, CO (CRC)' },
        { code: 'CRD', name: 'Comodoro Rivadavia, AR (CRD)' },
        { code: 'CRH', name: 'Cherribah, AU (CRH)' },
        { code: 'CRI', name: 'Crooked Island, BS (CRI)' },
        { code: 'CRJ', name: 'Coorabie, AU (CRJ)' },
        { code: 'CRK', name: 'Luzon Island Clark Field, PH (CRK)' },
        { code: 'CRL', name: 'Brussels South Charleroi Airport, BE (CRL)' },
        { code: 'CRM', name: 'Catarman, PH (CRM)' },
        { code: 'CRN', name: 'Cromarty, GB (CRN)' },
        { code: 'CRP', name: 'Corpus Christi International Apt, US (CRP)' },
        { code: 'CRQ', name: 'Caravelas, BR (CRQ)' },
        { code: 'CRR', name: 'Ceres, AR (CRR)' },
        { code: 'CRU', name: 'Carriacou, GD (CRU)' },
        { code: 'CRV', name: 'Crotone, IT (CRV)' },
        { code: 'CRW', name: 'Charleston, US (CRW)' },
        { code: 'CRY', name: 'Carlton Hill, AU (CRY)' },
        { code: 'CSA', name: 'Colonsay Island, GB (CSA)' },
        { code: 'CSB', name: 'Caransebes, RO (CSB)' },
        { code: 'CSC', name: 'Canas, CR (CSC)' },
        { code: 'CSD', name: 'Cresswell Downs, AU (CSD)' },
        { code: 'CSF', name: 'Creil, FR (CSF)' },
        { code: 'CSG', name: 'Columbus Metropolitan Apt, US (CSG)' },
        { code: 'CSH', name: 'Solovetsky, RU (CSH)' },
        { code: 'CSI', name: 'Casino, AU (CSI)' },
        { code: 'CSO', name: 'Cochstedt, DE (CSO)' },
        { code: 'CSR', name: 'Casuarito, CO (CSR)' },
        { code: 'CSS', name: 'Cassilandia, BR (CSS)' },
        { code: 'CSU', name: 'Santa Cruz Do Sul, BR (CSU)' },
        { code: 'CSW', name: 'Colorado Do Oeste, BR (CSW)' },
        { code: 'CSX', name: 'Changsha, CN (CSX)' },
        { code: 'CSY', name: 'Cheboksary, RU (CSY)' },
        { code: 'CSZ', name: 'Coronel Suarez, AR (CSZ)' },
        { code: 'CTA', name: 'Catania, IT (CTA)' },
        { code: 'CTC', name: 'Catamarca, AR (CTC)' },
        { code: 'CTD', name: 'Chitre, PA (CTD)' },
        { code: 'CTE', name: 'Carti, PA (CTE)' },
        { code: 'CTF', name: 'Coatepeque, GT (CTF)' },
        { code: 'CTG', name: 'Cartagena, CO (CTG)' },
        { code: 'CTL', name: 'Charleville, AU (CTL)' },
        { code: 'CTM', name: 'Chetumal, MX (CTM)' },
        { code: 'CTN', name: 'Cooktown, AU (CTN)' },
        { code: 'CTP', name: 'Carutapera, BR (CTP)' },
        { code: 'CTQ', name: 'Santa Vitoria, BR (CTQ)' },
        { code: 'CTR', name: 'Cattle Creek, AU (CTR)' },
        { code: 'CTS', name: 'Sapporo Chitose Apt, JP (CTS)' },
        { code: 'CTT', name: 'Le Castellet, FR (CTT)' },
        { code: 'CTU', name: 'Chengdu, CN (CTU)' },
        { code: 'CUC', name: 'Cucuta, CO (CUC)' },
        { code: 'CUD', name: 'Caloundra, AU (CUD)' },
        { code: 'CUE', name: 'Cuenca, EC (CUE)' },
        { code: 'CUF', name: 'Cuneo, IT (CUF)' },
        { code: 'CUG', name: 'Cudal, AU (CUG)' },
        { code: 'CUI', name: 'Currillo, CO (CUI)' },
        { code: 'CUJ', name: 'Culion, PH (CUJ)' },
        { code: 'CUK', name: 'Caye Caulker, BZ (CUK)' },
        { code: 'CUL', name: 'Culiacan, MX (CUL)' },
        { code: 'CUM', name: 'Cumana, VE (CUM)' },
        { code: 'CUN', name: 'Cancun, MX (CUN)' },
        { code: 'CUO', name: 'Caruru, CO (CUO)' },
        { code: 'CUP', name: 'Carupano, VE (CUP)' },
        { code: 'CUQ', name: 'Coen, AU (CUQ)' },
        { code: 'CUR', name: 'Curacao, CW (CUR)' },
        { code: 'CUT', name: 'Cutral, AR (CUT)' },
        { code: 'CUU', name: 'Chihuahua, MX (CUU)' },
        { code: 'CUV', name: 'Casigua, VE (CUV)' },
        { code: 'CUY', name: 'Cue, AU (CUY)' },
        { code: 'CUZ', name: 'Cuzco, PE (CUZ)' },
        { code: 'CVC', name: 'Cleve, AU (CVC)' },
        { code: 'CVE', name: 'Covenas, CO (CVE)' },
        { code: 'CVF', name: 'Courchevel, FR (CVF)' },
        {
            code: 'CVG',
            name: 'Cincinnati Northern Kentucky Intl Apt, US (CVG)',
        },
        { code: 'CVH', name: 'Caviahue, AR (CVH)' },
        { code: 'CVI', name: 'Caleta Olivia, AR (CVI)' },
        { code: 'CVJ', name: 'Cuernavaca, MX (CVJ)' },
        { code: 'CVM', name: 'Ciudad Victoria, MX (CVM)' },
        { code: 'CVQ', name: 'Carnarvon, AU (CVQ)' },
        { code: 'CVT', name: 'Coventry, GB (CVT)' },
        { code: 'CVU', name: 'Corvo Island, PT (CVU)' },
        { code: 'CWA', name: 'Wausau Central Wisconsin Apt, US (CWA)' },
        { code: 'CWB', name: 'Curitiba Afonso Pena, BR (CWB)' },
        { code: 'CWC', name: 'Chernovtsy, UA (CWC)' },
        { code: 'CWL', name: 'Cardiff, GB (CWL)' },
        { code: 'CWR', name: 'Cowarie, AU (CWR)' },
        { code: 'CWT', name: 'Cowra, AU (CWT)' },
        { code: 'CWW', name: 'Corowa, AU (CWW)' },
        { code: 'CXA', name: 'Caicara, VE (CXA)' },
        { code: 'CXB', name: "Cox'S Bazar, BD (CXB)" },
        { code: 'CXJ', name: 'Caxias Do Sul, BR (CXJ)' },
        { code: 'CXP', name: 'Cilacap, ID (CXP)' },
        { code: 'CXQ', name: 'Christmas Creek, AU (CXQ)' },
        { code: 'CXT', name: 'Charters Towers, AU (CXT)' },
        { code: 'CXY', name: 'Cat Cay, BS (CXY)' },
        { code: 'CYA', name: 'Les Cayes, HT (CYA)' },
        { code: 'CYB', name: 'Cayman Brac, KY (CYB)' },
        { code: 'CYC', name: 'Caye Chapel, BZ (CYC)' },
        { code: 'CYG', name: 'Corryong, AU (CYG)' },
        { code: 'CYI', name: 'Chiayi, TW (CYI)' },
        { code: 'CYL', name: 'Coyoles, HN (CYL)' },
        { code: 'CYO', name: 'Cayo Largo Del Sur, CU (CYO)' },
        { code: 'CYP', name: 'Calbayog, PH (CYP)' },
        { code: 'CYR', name: 'Colonia, UY (CYR)' },
        { code: 'CYS', name: 'Cheyenne Municipal Apt, US (CYS)' },
        { code: 'CYU', name: 'Cuyo, PH (CYU)' },
        { code: 'CYX', name: 'Cherskiy, RU (CYX)' },
        { code: 'CYZ', name: 'Cauayan, PH (CYZ)' },
        { code: 'CZB', name: 'Cruz Alta, BR (CZB)' },
        { code: 'CZE', name: 'Coro, VE (CZE)' },
        { code: 'CZH', name: 'Corozal, BZ (CZH)' },
        { code: 'CZJ', name: 'Corazon De Jesus, PA (CZJ)' },
        { code: 'CZM', name: 'Cozumel, MX (CZM)' },
        { code: 'CZS', name: 'Cruzeiro Do Sul, BR (CZS)' },
        { code: 'CZU', name: 'Corozal, CO (CZU)' },
        { code: 'CZW', name: 'Czestochowa, PL (CZW)' },
        { code: 'CZX', name: 'Changzhou, CN (CZX)' },
        { code: 'CZY', name: 'Cluny, AU (CZY)' },
        { code: 'DAB', name: 'Daytona Beach, US (DAB)' },
        { code: 'DAC', name: 'Dhaka, BD (DAC)' },
        { code: 'DAE', name: 'Daparizo, IN (DAE)' },
        { code: 'DAI', name: 'Darjeeling, IN (DAI)' },
        { code: 'DAJ', name: 'Dauan Island, AU (DAJ)' },
        { code: 'DAK', name: 'Dakhla Oasis, EG (DAK)' },
        { code: 'DAL', name: 'Dallas/Fort Worth Love Field, US (DAL)' },
        { code: 'DAS', name: 'Great Bear Lake, CA (DAS)' },
        { code: 'DAT', name: 'Datong, CN (DAT)' },
        { code: 'DAV', name: 'David, PA (DAV)' },
        { code: 'DAX', name: 'Daxian, CN (DAX)' },
        { code: 'DAY', name: 'Dayton Intl Apt, US (DAY)' },
        { code: 'DBB', name: 'Dabaa City, EG (DBB)' },
        { code: 'DBD', name: 'Dhanbad, IN (DBD)' },
        { code: 'DBO', name: 'Dubbo, AU (DBO)' },
        { code: 'DBQ', name: 'Dubuque, US (DBQ)' },
        { code: 'DBV', name: 'Dubrovnik, HR (DBV)' },
        { code: 'DBY', name: 'Dalby, AU (DBY)' },
        {
            code: 'DCA',
            name: 'Washington Ronald Reagan National Apt, US (DCA)',
        },
        { code: 'DCI', name: 'Decimomannu, IT (DCI)' },
        { code: 'DCM', name: 'Castres, FR (DCM)' },
        { code: 'DCT', name: 'Duncan Town, BS (DCT)' },
        { code: 'DDC', name: 'Dodge City, US (DDC)' },
        { code: 'DDG', name: 'Dandong, CN (DDG)' },
        { code: 'DDN', name: 'Delta Downs, AU (DDN)' },
        { code: 'DDP', name: 'Dorado, PR (DDP)' },
        { code: 'DEB', name: 'Debrecen, HU (DEB)' },
        { code: 'DED', name: 'Dehra Dun, IN (DED)' },
        { code: 'DEL', name: 'Delhi, IN (DEL)' },
        { code: 'DEN', name: 'Denver Intl Apt, US (DEN)' },
        { code: 'DEP', name: 'Deparizo, IN (DEP)' },
        { code: 'DFP', name: 'Drumduff, AU (DFP)' },
        { code: 'DFW', name: 'Dallas/Fort Worth Intl Apt, US (DFW)' },
        { code: 'DGA', name: 'Dangriga, BZ (DGA)' },
        { code: 'DGD', name: 'Dalgaranga, AU (DGD)' },
        { code: 'DGE', name: 'Mudgee, AU (DGE)' },
        { code: 'DGF', name: 'Douglas Lake, CA (DGF)' },
        { code: 'DGM', name: 'Dongguan, CN (DGM)' },
        { code: 'DGO', name: 'Durango, MX (DGO)' },
        { code: 'DGP', name: 'Daugavpils, LV (DGP)' },
        { code: 'DGR', name: 'Dargaville, NZ (DGR)' },
        { code: 'DGT', name: 'Dumaguete, PH (DGT)' },
        { code: 'DHD', name: 'Durham Downs, AU (DHD)' },
        { code: 'DHF', name: 'Abu Dhabi Al Dhafra Military Apt, AE (DHF)' },
        { code: 'DHM', name: 'Dharamshala, IN (DHM)' },
        { code: 'DHN', name: 'Dothan, US (DHN)' },
        { code: 'DHR', name: 'Den Helder, NL (DHR)' },
        { code: 'DIB', name: 'Dibrugarh, IN (DIB)' },
        { code: 'DIG', name: 'Diqing, CN (DIG)' },
        { code: 'DIJ', name: 'Dijon, FR (DIJ)' },
        { code: 'DIK', name: 'Dickinson, US (DIK)' },
        { code: 'DIQ', name: 'Divinopolis, BR (DIQ)' },
        { code: 'DIU', name: 'Diu, IN (DIU)' },
        { code: 'DIY', name: 'Diyarbakir, TR (DIY)' },
        { code: 'DJB', name: 'Jambi, ID (DJB)' },
        { code: 'DJJ', name: 'Jayapura, ID (DJJ)' },
        { code: 'DJU', name: 'Djupivogur, IS (DJU)' },
        { code: 'DKI', name: 'Dunk Island, AU (DKI)' },
        { code: 'DKS', name: 'Dikson, RU (DKS)' },
        { code: 'DKV', name: 'Docker River, AU (DKV)' },
        { code: 'DLC', name: 'Dalian, CN (DLC)' },
        { code: 'DLD', name: 'Geilo, NO (DLD)' },
        { code: 'DLE', name: 'Dole, FR (DLE)' },
        { code: 'DLG', name: 'Dillingham, US (DLG)' },
        { code: 'DLH', name: 'Duluth International Apt, US (DLH)' },
        { code: 'DLK', name: 'Dulkaninna, AU (DLK)' },
        { code: 'DLM', name: 'Dalaman, TR (DLM)' },
        { code: 'DLU', name: 'Dali City, CN (DLU)' },
        { code: 'DLV', name: 'Delissaville, AU (DLV)' },
        { code: 'DMD', name: 'Doomadgee Mission, AU (DMD)' },
        { code: 'DME', name: 'Moscow Domodedovo Apt, RU (DME)' },
        { code: 'DMM', name: 'Dammam, SA (DMM)' },
        { code: 'DMT', name: 'Diamantino, BR (DMT)' },
        { code: 'DMU', name: 'Dimapur, IN (DMU)' },
        { code: 'DNA', name: 'Okinawa Kadena Afb, JP (DNA)' },
        { code: 'DNB', name: 'Dunbar, AU (DNB)' },
        { code: 'DND', name: 'Dundee, GB (DND)' },
        { code: 'DNG', name: 'Doongan, AU (DNG)' },
        { code: 'DNH', name: 'Dunhuang, CN (DNH)' },
        { code: 'DNK', name: 'Dnepropetrovsk, UA (DNK)' },
        { code: 'DNM', name: 'Denham, AU (DNM)' },
        { code: 'DNO', name: 'Dianopolis, BR (DNO)' },
        { code: 'DNQ', name: 'Deniliquin, AU (DNQ)' },
        { code: 'DNR', name: 'Dinard, FR (DNR)' },
        { code: 'DNZ', name: 'Denizli, TR (DNZ)' },
        { code: 'DOB', name: 'Dobo, ID (DOB)' },
        { code: 'DOC', name: 'Dornoch, GB (DOC)' },
        { code: 'DOH', name: 'Doha, QA (DOH)' },
        { code: 'DOK', name: 'Donetsk, UA (DOK)' },
        { code: 'DOL', name: 'Deauville, FR (DOL)' },
        { code: 'DON', name: 'Dos Lagunas, GT (DON)' },
        { code: 'DOU', name: 'Dourados, BR (DOU)' },
        { code: 'DOX', name: 'Dongara, AU (DOX)' },
        { code: 'DOY', name: 'Dongying, CN (DOY)' },
        { code: 'DPE', name: 'Dieppe, FR (DPE)' },
        { code: 'DPL', name: 'Dipolog, PH (DPL)' },
        { code: 'DPO', name: 'Devonport, AU (DPO)' },
        { code: 'DPS', name: 'Denpasar Bali, ID (DPS)' },
        { code: 'DRB', name: 'Derby, AU (DRB)' },
        { code: 'DRD', name: 'Dorunda Station, AU (DRD)' },
        { code: 'DRH', name: 'Dabra, ID (DRH)' },
        { code: 'DRM', name: 'Drama, GR (DRM)' },
        { code: 'DRN', name: 'Dirranbandi, AU (DRN)' },
        { code: 'DRO', name: 'Durango La Plata Apt, US (DRO)' },
        { code: 'DRR', name: 'Durrie, AU (DRR)' },
        { code: 'DRS', name: 'Dresden, DE (DRS)' },
        { code: 'DRT', name: 'Del Rio International Apt, US (DRT)' },
        { code: 'DRW', name: 'Darwin Airport, AU (DRW)' },
        { code: 'DRY', name: 'Drysdale River, AU (DRY)' },
        { code: 'DSA', name: 'Doncaster, GB (DSA)' },
        { code: 'DSD', name: 'La Desirade, GP (DSD)' },
        { code: 'DSG', name: 'Dilasag, PH (DSG)' },
        { code: 'DSM', name: 'Des Moines, US (DSM)' },
        { code: 'DSN', name: 'Dongsheng, CN (DSN)' },
        { code: 'DTD', name: 'Datadawai, ID (DTD)' },
        { code: 'DTE', name: 'Daet, PH (DTE)' },
        { code: 'DTM', name: 'Dortmund, DE (DTM)' },
        { code: 'DTW', name: 'Detroit Wayne County, US (DTW)' },
        { code: 'DUB', name: 'Dublin, IE (DUB)' },
        { code: 'DUD', name: 'Dunedin, NZ (DUD)' },
        { code: 'DUJ', name: 'Dubois, US (DUJ)' },
        { code: 'DUK', name: 'Dukuduk, ZA (DUK)' },
        { code: 'DUM', name: 'Dumai, ID (DUM)' },
        { code: 'DUQ', name: 'Duncan, CA (DUQ)' },
        { code: 'DUR', name: 'Durban International Airport, ZA (DUR)' },
        { code: 'DUS', name: 'Dusseldorf International Airport, DE (DUS)' },
        { code: 'DUT', name: 'Dutch Harbor, US (DUT)' },
        { code: 'DVA', name: 'Deva, RO (DVA)' },
        { code: 'DVK', name: 'Diavik, CA (DVK)' },
        { code: 'DVL', name: 'Devils Lake, US (DVL)' },
        { code: 'DVO', name: 'Davao, PH (DVO)' },
        { code: 'DVP', name: 'Davenport Downs, AU (DVP)' },
        { code: 'DVR', name: 'Daly River, AU (DVR)' },
        { code: 'DWD', name: 'Dawadmi, SA (DWD)' },
        { code: 'DXA', name: 'Deux Alpes, FR (DXA)' },
        { code: 'DXB', name: 'Dubai, AE (DXB)' },
        { code: 'DXD', name: 'Dixie, AU (DXD)' },
        { code: 'DYA', name: 'Dysart, AU (DYA)' },
        { code: 'DYG', name: 'Dayong, CN (DYG)' },
        { code: 'DYM', name: 'Diamantina Lakes, AU (DYM)' },
        { code: 'DYR', name: 'Anadyr, RU (DYR)' },
        { code: 'DYW', name: 'Daly Waters, AU (DYW)' },
        { code: 'DZI', name: 'Codazzi, CO (DZI)' },
        { code: 'DZO', name: 'Durazno, UY (DZO)' },
        { code: 'DZU', name: 'Dazu, CN (DZU)' },
        { code: 'EAM', name: 'Nejran, SA (EAM)' },
        { code: 'EAR', name: 'Kearney, US (EAR)' },
        { code: 'EAS', name: 'San Sebastian, ES (EAS)' },
        { code: 'EAT', name: 'Wenatchee, US (EAT)' },
        { code: 'EAU', name: 'Eau Claire, US (EAU)' },
        { code: 'EBA', name: 'Elba Island, IT (EBA)' },
        { code: 'EBG', name: 'El Bagre, CO (EBG)' },
        { code: 'EBJ', name: 'Esbjerg Airport, DK (EBJ)' },
        { code: 'EBU', name: 'St Etienne, FR (EBU)' },
        { code: 'ECH', name: 'Echuca, AU (ECH)' },
        { code: 'ECO', name: 'El Encanto, CO (ECO)' },
        { code: 'ECP', name: 'Panama City Nw, US (ECP)' },
        { code: 'ECR', name: 'El Charco, CO (ECR)' },
        { code: 'EDD', name: 'Erldunda, AU (EDD)' },
        { code: 'EDI', name: 'Edinburgh, GB (EDI)' },
        { code: 'EDM', name: 'La Roche Sur Yon, FR (EDM)' },
        { code: 'EDO', name: 'Edremit/Korfez, TR (EDO)' },
        { code: 'EDQ', name: 'Erandique, HN (EDQ)' },
        { code: 'EDR', name: 'Edward River, AU (EDR)' },
        { code: 'EEK', name: 'Eek, US (EEK)' },
        { code: 'EFL', name: 'Kefallinia, GR (EFL)' },
        { code: 'EGC', name: 'Bergerac, FR (EGC)' },
        { code: 'EGE', name: 'Vail/Eagle County Apt, US (EGE)' },
        { code: 'EGO', name: 'Belgorod, RU (EGO)' },
        { code: 'EGS', name: 'Egilsstadir, IS (EGS)' },
        { code: 'EHL', name: 'El Bolson, AR (EHL)' },
        { code: 'EIB', name: 'Eisenach, DE (EIB)' },
        { code: 'EIE', name: 'Eniseysk, RU (EIE)' },
        { code: 'EIH', name: 'Einasleigh, AU (EIH)' },
        { code: 'EIN', name: 'Eindhoven Airport, NL (EIN)' },
        { code: 'EIS', name: 'Tortola/Beef Island B.V.I., VG (EIS)' },
        { code: 'EJA', name: 'Barrancabermeja, CO (EJA)' },
        { code: 'EJH', name: 'Wedjh, SA (EJH)' },
        { code: 'EKD', name: 'Elkedra, AU (EKD)' },
        { code: 'EKE', name: 'Ekereku, GY (EKE)' },
        { code: 'EKO', name: 'Elko, US (EKO)' },
        { code: 'EKT', name: 'Eskilstuna, SE (EKT)' },
        { code: 'ELB', name: 'El Banco, CO (ELB)' },
        { code: 'ELC', name: 'Elcho Island, AU (ELC)' },
        { code: 'ELE', name: 'El Real, PA (ELE)' },
        { code: 'ELH', name: 'North Eleuthera, BS (ELH)' },
        { code: 'ELJ', name: 'El Recreo, CO (ELJ)' },
        { code: 'ELL', name: 'Ellisras, ZA (ELL)' },
        { code: 'ELM', name: 'Elmira/Corning, US (ELM)' },
        { code: 'ELO', name: 'Eldorado, AR (ELO)' },
        { code: 'ELP', name: 'El Paso International Apt, US (ELP)' },
        { code: 'ELQ', name: 'Gassim, SA (ELQ)' },
        { code: 'ELR', name: 'Elelim, ID (ELR)' },
        { code: 'ELS', name: 'East London, ZA (ELS)' },
        { code: 'ELT', name: 'Tour Sinai City, EG (ELT)' },
        { code: 'ELX', name: 'El Tigre, VE (ELX)' },
        { code: 'EMA', name: 'Nottingham East Midlands Airport, GB (EMA)' },
        { code: 'EMD', name: 'Emerald, AU (EMD)' },
        { code: 'EME', name: 'Emden, DE (EME)' },
        { code: 'EMG', name: 'Empangeni, ZA (EMG)' },
        { code: 'EML', name: 'Emmen, CH (EML)' },
        { code: 'EMX', name: 'El Maiten, AR (EMX)' },
        { code: 'EMY', name: 'El Minya, EG (EMY)' },
        { code: 'ENA', name: 'Kenai, US (ENA)' },
        { code: 'ENB', name: 'Eneabba, AU (ENB)' },
        { code: 'ENE', name: 'Ende, ID (ENE)' },
        { code: 'ENF', name: 'Enontekio, FI (ENF)' },
        { code: 'ENH', name: 'Enshi, CN (ENH)' },
        { code: 'ENI', name: 'El Nido, PH (ENI)' },
        { code: 'ENJ', name: 'El Naranjo, GT (ENJ)' },
        { code: 'ENK', name: 'Enniskillen, GB (ENK)' },
        { code: 'ENO', name: 'Encarnacion, PY (ENO)' },
        { code: 'ENQ', name: 'Coronel Enrique Soto Cano, HN (ENQ)' },
        { code: 'ENS', name: 'Enschede, NL (ENS)' },
        { code: 'ENV', name: 'Wendover, US (ENV)' },
        { code: 'ENY', name: "Yan'An, CN (ENY)" },
        { code: 'EOH', name: 'Medellin Enrique Olaya Herrerra Apt, CO (EOH)' },
        { code: 'EOI', name: 'Eday, GB (EOI)' },
        { code: 'EOR', name: 'El Dorado, VE (EOR)' },
        { code: 'EOZ', name: 'Elorza, VE (EOZ)' },
        { code: 'EPA', name: 'El Palomar, AR (EPA)' },
        { code: 'EPL', name: 'Epinal, FR (EPL)' },
        { code: 'EPR', name: 'Esperance, AU (EPR)' },
        { code: 'EPS', name: 'El Portillo / Samana, DO (EPS)' },
        { code: 'EQS', name: 'Esquel, AR (EQS)' },
        { code: 'ERB', name: 'Ernabella, AU (ERB)' },
        { code: 'ERC', name: 'Erzincan, TR (ERC)' },
        { code: 'ERD', name: 'Berdyansk, UA (ERD)' },
        { code: 'ERF', name: 'Erfurt, DE (ERF)' },
        { code: 'ERI', name: 'Erie, US (ERI)' },
        { code: 'ERM', name: 'Erechim, BR (ERM)' },
        { code: 'ERN', name: 'Eirunepe, BR (ERN)' },
        { code: 'ERZ', name: 'Erzurum, TR (ERZ)' },
        { code: 'ESB', name: 'Ankara Esenboga Apt, TR (ESB)' },
        { code: 'ESC', name: 'Escanaba, US (ESC)' },
        { code: 'ESE', name: 'Ensenada, MX (ESE)' },
        { code: 'ESG', name: 'Mariscal Estigarribia, PY (ESG)' },
        { code: 'ESH', name: 'Shoreham By Sea, GB (ESH)' },
        { code: 'ESI', name: 'Espinosa, BR (ESI)' },
        { code: 'ESL', name: 'Elista, RU (ESL)' },
        { code: 'ESM', name: 'Esmeraldas, EC (ESM)' },
        { code: 'ESR', name: 'El Salvador, CL (ESR)' },
        { code: 'ESS', name: 'Essen, DE (ESS)' },
        { code: 'ETD', name: 'Etadunna, AU (ETD)' },
        { code: 'ETZ', name: 'Metz/Nancy, FR (ETZ)' },
        { code: 'EUC', name: 'Eucla, AU (EUC)' },
        { code: 'EUG', name: 'Eugene, US (EUG)' },
        { code: 'EUM', name: 'Neumunster, DE (EUM)' },
        { code: 'EUO', name: 'Paratebueno, CO (EUO)' },
        { code: 'EUQ', name: 'Antique, PH (EUQ)' },
        { code: 'EVD', name: 'Eva Downs, AU (EVD)' },
        { code: 'EVE', name: 'Harstad-Narvik, NO (EVE)' },
        { code: 'EVG', name: 'Sveg, SE (EVG)' },
        { code: 'EVH', name: 'Evans Head, AU (EVH)' },
        { code: 'EVV', name: 'Evansville, US (EVV)' },
        { code: 'EVX', name: 'Evreux, FR (EVX)' },
        { code: 'EWB', name: 'New Bedford, US (EWB)' },
        { code: 'EWE', name: 'Ewer, ID (EWE)' },
        { code: 'EWI', name: 'Enarotali, ID (EWI)' },
        { code: 'EWN', name: 'New Bern, US (EWN)' },
        { code: 'EWR', name: 'Newark Liberty International Apt, US (EWR)' },
        { code: 'EWY', name: 'Newbury, GB (EWY)' },
        { code: 'EXM', name: 'Exmouth Gulf, AU (EXM)' },
        { code: 'EXT', name: 'Exeter, GB (EXT)' },
        { code: 'EYP', name: 'El Yopal, CO (EYP)' },
        { code: 'EYW', name: 'Key West International Apt, US (EYW)' },
        { code: 'EZE', name: 'Buenos Aires Ministro Pistarini, AR (EZE)' },
        { code: 'EZS', name: 'Elazig, TR (EZS)' },
        { code: 'FAB', name: 'Farnborough, GB (FAB)' },
        { code: 'FAG', name: 'Fagurholsmyri, IS (FAG)' },
        { code: 'FAI', name: 'Fairbanks International Apt, US (FAI)' },
        { code: 'FAJ', name: 'Fajardo, PR (FAJ)' },
        { code: 'FAN', name: 'Farsund, NO (FAN)' },
        { code: 'FAO', name: 'Faro, PT (FAO)' },
        { code: 'FAR', name: 'Fargo, US (FAR)' },
        { code: 'FAS', name: 'Faskrudsfjordur, IS (FAS)' },
        {
            code: 'FAT',
            name: 'Fresno Yosemite International Airport, US (FAT)',
        },
        { code: 'FAY', name: 'Fayetteville Municipal Apt, US (FAY)' },
        { code: 'FBE', name: 'Francisco Beltrao, BR (FBE)' },
        { code: 'FCA', name: 'Kalispell, US (FCA)' },
        { code: 'FCB', name: 'Ficksburg, ZA (FCB)' },
        { code: 'FCN', name: 'Cuxhaven, DE (FCN)' },
        { code: 'FCO', name: 'Rome Fiumicino Apt, IT (FCO)' },
        { code: 'FDE', name: 'Forde, NO (FDE)' },
        { code: 'FDH', name: 'Friedrichshafen, DE (FDH)' },
        { code: 'FEA', name: 'Fetlar, GB (FEA)' },
        { code: 'FEC', name: 'Feira De Santana, BR (FEC)' },
        { code: 'FEJ', name: 'Feijo, BR (FEJ)' },
        { code: 'FEL', name: 'Furstenfeldbruck, DE (FEL)' },
        { code: 'FEN', name: 'Fernando De Noronha, BR (FEN)' },
        { code: 'FES', name: 'San Fernando, ES (FES)' },
        { code: 'FFD', name: 'Fairford, GB (FFD)' },
        { code: 'FFU', name: 'Futaleufu, CL (FFU)' },
        { code: 'FGL', name: 'Fox Glacier, NZ (FGL)' },
        { code: 'FGR', name: 'Fuengirola, ES (FGR)' },
        { code: 'FIE', name: 'Fair Isle, GB (FIE)' },
        { code: 'FIK', name: 'Finke, AU (FIK)' },
        { code: 'FIZ', name: 'Fitzroy Crossing, AU (FIZ)' },
        { code: 'FJR', name: 'Al-Fujairah, AE (FJR)' },
        { code: 'FKB', name: 'Karlsruhe/Baden Baden, DE (FKB)' },
        { code: 'FKH', name: 'Fakenham, GB (FKH)' },
        { code: 'FKJ', name: 'Fukui, JP (FKJ)' },
        { code: 'FKQ', name: 'Fak Fak, ID (FKQ)' },
        { code: 'FKS', name: 'Fukushima, JP (FKS)' },
        { code: 'FLA', name: 'Florencia, CO (FLA)' },
        { code: 'FLB', name: 'Floriano, BR (FLB)' },
        { code: 'FLC', name: 'Falls Creek, AU (FLC)' },
        { code: 'FLF', name: 'Flensburg, DE (FLF)' },
        { code: 'FLG', name: 'Grand Canyon Pulliam Field Airport, US (FLG)' },
        { code: 'FLH', name: 'Flotta, GB (FLH)' },
        { code: 'FLI', name: 'Flateyri, IS (FLI)' },
        { code: 'FLL', name: 'Fort Lauderdale, US (FLL)' },
        { code: 'FLM', name: 'Filadelfia, PY (FLM)' },
        { code: 'FLN', name: 'Florianopolis, BR (FLN)' },
        { code: 'FLO', name: 'Florence, US (FLO)' },
        { code: 'FLR', name: 'Florence, IT (FLR)' },
        { code: 'FLS', name: 'Flinders Island, AU (FLS)' },
        { code: 'FLW', name: 'Flores Island, PT (FLW)' },
        { code: 'FLY', name: 'Finley, AU (FLY)' },
        { code: 'FMA', name: 'Formosa, AR (FMA)' },
        { code: 'FMG', name: 'Flamingo, CR (FMG)' },
        { code: 'FMM', name: 'Memmingen, DE (FMM)' },
        { code: 'FMN', name: 'Farmington, US (FMN)' },
        { code: 'FMO', name: 'Munster, DE (FMO)' },
        { code: 'FNB', name: 'Neubrandenburg, DE (FNB)' },
        { code: 'FNC', name: 'Funchal, PT (FNC)' },
        { code: 'FNI', name: 'Nimes, FR (FNI)' },
        { code: 'FNL', name: 'Fort Collins/Loveland Municipal Apt, US (FNL)' },
        { code: 'FNT', name: 'Flint, US (FNT)' },
        { code: 'FOA', name: 'Foula, GB (FOA)' },
        { code: 'FOC', name: 'Fuzhou, CN (FOC)' },
        { code: 'FOD', name: 'Fort Dodge, US (FOD)' },
        { code: 'FOG', name: 'Foggia, IT (FOG)' },
        { code: 'FON', name: 'Fortuna, CR (FON)' },
        { code: 'FOO', name: 'Numfoor, ID (FOO)' },
        { code: 'FOR', name: 'Fortaleza, BR (FOR)' },
        { code: 'FOS', name: 'Forrest, AU (FOS)' },
        { code: 'FOT', name: 'Forster, AU (FOT)' },
        { code: 'FPO', name: 'Freeport, BS (FPO)' },
        { code: 'FRA', name: 'Frankfurt International Apt, DE (FRA)' },
        { code: 'FRB', name: 'Forbes, AU (FRB)' },
        { code: 'FRC', name: 'Franca, BR (FRC)' },
        { code: 'FRF', name: 'Frankfurt Rhein Main Afb, DE (FRF)' },
        { code: 'FRJ', name: 'Frejus, FR (FRJ)' },
        { code: 'FRL', name: 'Forli, IT (FRL)' },
        { code: 'FRO', name: 'Floro, NO (FRO)' },
        { code: 'FRS', name: 'Flores, GT (FRS)' },
        { code: 'FRT', name: 'Frutillar, CL (FRT)' },
        { code: 'FRW', name: 'Francistown, BW (FRW)' },
        { code: 'FRZ', name: 'Fritzlar, DE (FRZ)' },
        { code: 'FSC', name: 'Figari, FR (FSC)' },
        { code: 'FSD', name: 'Sioux Falls, US (FSD)' },
        { code: 'FSL', name: 'Fossil Downs, AU (FSL)' },
        { code: 'FSM', name: 'Fort Smith, US (FSM)' },
        { code: 'FSS', name: 'Forres, GB (FSS)' },
        { code: 'FTE', name: 'El Calafate, AR (FTE)' },
        { code: 'FUD', name: 'Sui Fen He, CN (FUD)' },
        { code: 'FUE', name: 'Fuerteventura, ES (FUE)' },
        { code: 'FUG', name: 'Fuyang, CN (FUG)' },
        { code: 'FUJ', name: 'Fukue, JP (FUJ)' },
        { code: 'FUK', name: 'Fukuoka, JP (FUK)' },
        { code: 'FUO', name: 'Fuoshan, CN (FUO)' },
        { code: 'FVL', name: 'Flora Valley, AU (FVL)' },
        { code: 'FWA', name: 'Fort Wayne Baer Field, US (FWA)' },
        { code: 'FYN', name: 'Fuyun, CN (FYN)' },
        { code: 'FYU', name: 'Fort Yukon, US (FYU)' },
        { code: 'FZO', name: 'Filton, GB (FZO)' },
        { code: 'GAA', name: 'Guamal, CO (GAA)' },
        { code: 'GAC', name: 'Gracias, HN (GAC)' },
        { code: 'GAH', name: 'Gayndah, AU (GAH)' },
        { code: 'GAJ', name: 'Yamagata, JP (GAJ)' },
        { code: 'GAL', name: 'Galena, US (GAL)' },
        { code: 'GAM', name: 'Gambell, US (GAM)' },
        { code: 'GAO', name: 'Guantanamo Los Canos Apt, CU (GAO)' },
        { code: 'GAT', name: 'Gap, FR (GAT)' },
        { code: 'GAU', name: 'Guwahati, IN (GAU)' },
        { code: 'GAV', name: 'Gag Island, ID (GAV)' },
        { code: 'GAY', name: 'Gaya, IN (GAY)' },
        { code: 'GBE', name: 'Gaborone, BW (GBE)' },
        { code: 'GBI', name: 'Grand Bahama, BS (GBI)' },
        { code: 'GBJ', name: 'Marie Galante, GP (GBJ)' },
        { code: 'GBL', name: 'Goulburn Island, AU (GBL)' },
        { code: 'GBN', name: 'San Giovanni Rotondo, IT (GBN)' },
        { code: 'GBP', name: 'Gamboola, AU (GBP)' },
        { code: 'GBS', name: 'Port Fitzroy, NZ (GBS)' },
        { code: 'GBV', name: 'Gibb River, AU (GBV)' },
        { code: 'GBZ', name: 'Great Barrier Island, NZ (GBZ)' },
        { code: 'GCA', name: 'Guacamaya, CO (GCA)' },
        { code: 'GCC', name: 'Gillette, US (GCC)' },
        { code: 'GCI', name: 'Guernsey, GB (GCI)' },
        { code: 'GCJ', name: 'Johannesburg Grand Central Apt, ZA (GCJ)' },
        { code: 'GCK', name: 'Garden City, US (GCK)' },
        { code: 'GCM', name: 'Grand Cayman Island, KY (GCM)' },
        { code: 'GCN', name: 'Grand Canyon National Park Apt, US (GCN)' },
        { code: 'GCV', name: 'Gravatai, BR (GCV)' },
        { code: 'GDD', name: 'Gordon Downs, AU (GDD)' },
        { code: 'GDG', name: 'Magdagachi, RU (GDG)' },
        { code: 'GDL', name: 'Guadalajara, MX (GDL)' },
        { code: 'GDN', name: 'Gdansk, PL (GDN)' },
        { code: 'GDO', name: 'Guasdualito, VE (GDO)' },
        { code: 'GDP', name: 'Guadalupe, BR (GDP)' },
        { code: 'GDT', name: 'Grand Turk, TC (GDT)' },
        { code: 'GDX', name: 'Magadan, RU (GDX)' },
        { code: 'GDZ', name: 'Gelendzik, RU (GDZ)' },
        { code: 'GEB', name: 'Gebe, ID (GEB)' },
        { code: 'GEE', name: 'George Town, AU (GEE)' },
        { code: 'GEG', name: 'Spokane International Apt, US (GEG)' },
        { code: 'GEL', name: 'Santo Angelo, BR (GEL)' },
        { code: 'GEN', name: 'Puente Genil, ES (GEN)' },
        { code: 'GEO', name: 'Georgetown, GY (GEO)' },
        { code: 'GER', name: 'Nueva Gerona, CU (GER)' },
        { code: 'GES', name: 'General Santos, PH (GES)' },
        { code: 'GET', name: 'Geraldton, AU (GET)' },
        { code: 'GEV', name: 'Gallivare, SE (GEV)' },
        { code: 'GEX', name: 'Geelong, AU (GEX)' },
        { code: 'GFE', name: 'Grenfell, AU (GFE)' },
        { code: 'GFF', name: 'Griffith, AU (GFF)' },
        { code: 'GFK', name: 'Grand Forks, US (GFK)' },
        { code: 'GFN', name: 'Grafton, AU (GFN)' },
        { code: 'GFO', name: 'Bartica, GY (GFO)' },
        { code: 'GFR', name: 'Granville, FR (GFR)' },
        { code: 'GGD', name: 'Gregory Downs, AU (GGD)' },
        { code: 'GGG', name: 'Longview, US (GGG)' },
        { code: 'GGL', name: 'Gilgal, CO (GGL)' },
        { code: 'GGS', name: 'Gobernador Gregores, AR (GGS)' },
        { code: 'GGT', name: 'George Town, BS (GGT)' },
        { code: 'GHB', name: 'Governors Harbour, BS (GHB)' },
        { code: 'GHC', name: 'Great Harbour Cay, BS (GHC)' },
        { code: 'GHE', name: 'Garachine, PA (GHE)' },
        { code: 'GHF', name: 'Giebelstadt, DE (GHF)' },
        { code: 'GHN', name: 'Guanghan, CN (GHN)' },
        { code: 'GHU', name: 'Gualeguaychu, AR (GHU)' },
        { code: 'GIC', name: 'Boigu Island, AU (GIC)' },
        { code: 'GIG', name: 'Rio De Janeiro International Apt, BR (GIG)' },
        { code: 'GIR', name: 'Girardot, CO (GIR)' },
        { code: 'GIS', name: 'Gisborne, NZ (GIS)' },
        { code: 'GIY', name: 'Giyani, ZA (GIY)' },
        { code: 'GIZ', name: 'Jazan, SA (GIZ)' },
        { code: 'GJA', name: 'Guanaja, HN (GJA)' },
        { code: 'GJM', name: 'Guajara-Mirim, BR (GJM)' },
        { code: 'GJR', name: 'Gjogur, IS (GJR)' },
        { code: 'GJT', name: 'Grand Junction, US (GJT)' },
        { code: 'GKE', name: 'Geilenkirchen, DE (GKE)' },
        { code: 'GKL', name: 'Great Keppel Island, AU (GKL)' },
        { code: 'GLA', name: 'Glasgow International Airport, GB (GLA)' },
        { code: 'GLF', name: 'Golfito, CR (GLF)' },
        { code: 'GLG', name: 'Glengyle, AU (GLG)' },
        { code: 'GLH', name: 'Greenville, US (GLH)' },
        { code: 'GLI', name: 'Glen Innes, AU (GLI)' },
        { code: 'GLL', name: 'Gol, NO (GLL)' },
        { code: 'GLM', name: 'Glenormiston, AU (GLM)' },
        { code: 'GLO', name: 'Gloucester, GB (GLO)' },
        { code: 'GLT', name: 'Gladstone, AU (GLT)' },
        { code: 'GLX', name: 'Galela, ID (GLX)' },
        { code: 'GLY', name: 'Goldsworthy, AU (GLY)' },
        { code: 'GLZ', name: 'Breda, NL (GLZ)' },
        { code: 'GMC', name: 'Guerima, CO (GMC)' },
        { code: 'GME', name: 'Gomel, BY (GME)' },
        { code: 'GMN', name: 'Greymouth, NZ (GMN)' },
        { code: 'GMP', name: 'Seoul Gimpo International Airport, KR (GMP)' },
        { code: 'GMS', name: 'Guimaraes, BR (GMS)' },
        { code: 'GMY', name: 'Rheindahlen, DE (GMY)' },
        { code: 'GMZ', name: 'San Sebastian De La Gomera, ES (GMZ)' },
        { code: 'GNA', name: 'Grodno, BY (GNA)' },
        { code: 'GNB', name: 'Lyon Grenoble Saint Geoirs Apt, FR (GNB)' },
        { code: 'GND', name: 'Grenada, GD (GND)' },
        { code: 'GNE', name: 'Ghent, BE (GNE)' },
        { code: 'GNI', name: 'Green Island, TW (GNI)' },
        { code: 'GNM', name: 'Guanambi, BR (GNM)' },
        { code: 'GNR', name: 'General Roca, AR (GNR)' },
        { code: 'GNS', name: 'Gunungsitoli, ID (GNS)' },
        { code: 'GNV', name: 'Gainesville, US (GNV)' },
        { code: 'GNZ', name: 'Ghanzi, BW (GNZ)' },
        { code: 'GOA', name: 'Genoa, IT (GOA)' },
        { code: 'GOI', name: 'Goa, IN (GOI)' },
        { code: 'GOJ', name: 'Nizhniy Novgorod, RU (GOJ)' },
        { code: 'GOO', name: 'Goondiwindi, AU (GOO)' },
        { code: 'GOP', name: 'Gorakhpur, IN (GOP)' },
        { code: 'GOQ', name: 'Golmud, CN (GOQ)' },
        { code: 'GOS', name: 'Gosford, AU (GOS)' },
        { code: 'GOT', name: 'Gothenburg Landvetter.Apt, SE (GOT)' },
        { code: 'GOV', name: 'Gove, AU (GOV)' },
        { code: 'GPA', name: 'Patras, GR (GPA)' },
        { code: 'GPB', name: 'Guarapuava, BR (GPB)' },
        { code: 'GPI', name: 'Guapi, CO (GPI)' },
        { code: 'GPL', name: 'Guapiles, CR (GPL)' },
        { code: 'GPN', name: 'Garden Point, AU (GPN)' },
        { code: 'GPO', name: 'General Pico, AR (GPO)' },
        { code: 'GPS', name: 'Galapagos Islands, EC (GPS)' },
        { code: 'GPT', name: 'Gulfport/Biloxi, US (GPT)' },
        { code: 'GQJ', name: 'Machrihanish, GB (GQJ)' },
        { code: 'GRA', name: 'Gamarra, CO (GRA)' },
        { code: 'GRB', name: 'Green Bay, US (GRB)' },
        { code: 'GRI', name: 'Grand Island, US (GRI)' },
        { code: 'GRJ', name: 'George, ZA (GRJ)' },
        { code: 'GRK', name: 'Killeen Gray Aaf, US (GRK)' },
        { code: 'GRO', name: 'Gerona, ES (GRO)' },
        { code: 'GRP', name: 'Gurupi, BR (GRP)' },
        { code: 'GRQ', name: 'Groningen, NL (GRQ)' },
        { code: 'GRR', name: 'Grand Rapids, US (GRR)' },
        { code: 'GRS', name: 'Grosseto, IT (GRS)' },
        { code: 'GRU', name: 'Sao Paulo Guarulhos Intl Apt, BR (GRU)' },
        { code: 'GRV', name: 'Groznyj, RU (GRV)' },
        { code: 'GRW', name: 'Graciosa Island, PT (GRW)' },
        { code: 'GRX', name: 'Granada, ES (GRX)' },
        { code: 'GRY', name: 'Grimsey, IS (GRY)' },
        { code: 'GRZ', name: 'Graz, AT (GRZ)' },
        { code: 'GSA', name: 'Long Pasia, MY (GSA)' },
        { code: 'GSC', name: 'Gascoyne Junction, AU (GSC)' },
        { code: 'GSE', name: 'Gothenburg Saeve Apt, SE (GSE)' },
        { code: 'GSL', name: 'Taltheilei Narrows, CA (GSL)' },
        { code: 'GSN', name: 'Mount Gunson, AU (GSN)' },
        { code: 'GSO', name: 'Greensboro/High Point, US (GSO)' },
        { code: 'GSP', name: 'Greenville/Spartanburg Apt, US (GSP)' },
        { code: 'GSQ', name: 'Shark Elowainat, EG (GSQ)' },
        { code: 'GSS', name: 'Sabi Sabi, ZA (GSS)' },
        { code: 'GST', name: 'Gustavus Apt, US (GST)' },
        { code: 'GSY', name: 'Grimsby, GB (GSY)' },
        { code: 'GTB', name: 'Genting, MY (GTB)' },
        { code: 'GTC', name: 'Green Turtle, BS (GTC)' },
        { code: 'GTE', name: 'Groote Eylandt, AU (GTE)' },
        { code: 'GTF', name: 'Great Falls International Apt, US (GTF)' },
        { code: 'GTI', name: 'Guettin, DE (GTI)' },
        { code: 'GTK', name: 'Sungei Tekai, MY (GTK)' },
        { code: 'GTN', name: 'Mount Cook Glentanner Apt, NZ (GTN)' },
        { code: 'GTO', name: 'Gorontalo, ID (GTO)' },
        {
            code: 'GTR',
            name: 'Columbus Golden Triangle Regional Apt, US (GTR)',
        },
        { code: 'GTS', name: 'Granites, AU (GTS)' },
        { code: 'GTT', name: 'Georgetown, AU (GTT)' },
        { code: 'GTW', name: 'Zlin, CZ (GTW)' },
        { code: 'GUA', name: 'Guatemala City, GT (GUA)' },
        { code: 'GUC', name: 'Gunnison, US (GUC)' },
        { code: 'GUH', name: 'Gunnedah, AU (GUH)' },
        { code: 'GUI', name: 'Guiria, VE (GUI)' },
        { code: 'GUJ', name: 'Guaratingueta, BR (GUJ)' },
        { code: 'GUL', name: 'Goulburn, AU (GUL)' },
        { code: 'GUO', name: 'Gualaco, HN (GUO)' },
        { code: 'GUQ', name: 'Guanare, VE (GUQ)' },
        { code: 'GUT', name: 'Gutersloh, DE (GUT)' },
        { code: 'GUU', name: 'Grundarfjordur, IS (GUU)' },
        { code: 'GUX', name: 'Guna, IN (GUX)' },
        { code: 'GUZ', name: 'Guarapari, BR (GUZ)' },
        { code: 'GVA', name: 'Geneva, CH (GVA)' },
        { code: 'GVP', name: 'Greenvale, AU (GVP)' },
        { code: 'GVR', name: 'Governador Valadares, BR (GVR)' },
        { code: 'GVX', name: 'Gavle Sandviken Airport, SE (GVX)' },
        { code: 'GWL', name: 'Gwalior, IN (GWL)' },
        { code: 'GWT', name: 'Westerland, DE (GWT)' },
        { code: 'GWY', name: 'Galway, IE (GWY)' },
        { code: 'GXH', name: 'Mildenhall Naf, GB (GXH)' },
        { code: 'GXQ', name: 'Coyhaique, CL (GXQ)' },
        { code: 'GYA', name: 'Guayaramerin, BO (GYA)' },
        { code: 'GYD', name: 'Baku Heydar Aliyev International Apt, AZ (GYD)' },
        { code: 'GYE', name: 'Guayaquil, EC (GYE)' },
        { code: 'GYL', name: 'Argyle, AU (GYL)' },
        { code: 'GYM', name: 'Guaymas, MX (GYM)' },
        { code: 'GYN', name: 'Goiania, BR (GYN)' },
        { code: 'GYP', name: 'Gympie, AU (GYP)' },
        { code: 'GYS', name: 'Guang Yuan, CN (GYS)' },
        { code: 'GZT', name: 'Gaziantep, TR (GZT)' },
        { code: 'HAA', name: 'Hasvik, NO (HAA)' },
        { code: 'HAC', name: 'Hachijo Jima, JP (HAC)' },
        { code: 'HAD', name: 'Halmstad, SE (HAD)' },
        { code: 'HAG', name: 'The Hague, NL (HAG)' },
        { code: 'HAJ', name: 'Hanover, DE (HAJ)' },
        { code: 'HAK', name: 'Haikou, CN (HAK)' },
        { code: 'HAM', name: 'Hamburg Fuhlsbuettel Airport, DE (HAM)' },
        { code: 'HAS', name: 'Hail, SA (HAS)' },
        { code: 'HAT', name: 'Heathlands, AU (HAT)' },
        { code: 'HAU', name: 'Haugesund, NO (HAU)' },
        { code: 'HAV', name: 'Havana, CU (HAV)' },
        { code: 'HAW', name: 'Haverfordwest, GB (HAW)' },
        { code: 'HBA', name: 'Hobart, AU (HBA)' },
        { code: 'HBE', name: 'Alexandria Borg El Arab Airport, EG (HBE)' },
        { code: 'HBI', name: 'Harbour Island, BS (HBI)' },
        { code: 'HBL', name: 'Babelegi, ZA (HBL)' },
        { code: 'HBT', name: 'Hafr Albatin, SA (HBT)' },
        { code: 'HBX', name: 'Hubli, IN (HBX)' },
        { code: 'HCN', name: 'Hengchun, TW (HCN)' },
        { code: 'HCQ', name: 'Halls Creek, AU (HCQ)' },
        { code: 'HDB', name: 'Heidelberg, DE (HDB)' },
        { code: 'HDF', name: 'Heringsdorf, DE (HDF)' },
        {
            code: 'HDN',
            name: 'Steamboat Springs Hayden Yampa Valley, US (HDN)',
        },
        { code: 'HDS', name: 'Hoedspruit, ZA (HDS)' },
        { code: 'HEI', name: 'Heide-Buesum, DE (HEI)' },
        { code: 'HEK', name: 'Heihe, CN (HEK)' },
        { code: 'HEL', name: 'Helsinki, FI (HEL)' },
        { code: 'HEM', name: 'Helsinki-Malmi, FI (HEM)' },
        { code: 'HEN', name: 'Hendon, GB (HEN)' },
        { code: 'HER', name: 'Heraklion, GR (HER)' },
        { code: 'HET', name: 'Hohhot, CN (HET)' },
        { code: 'HEV', name: 'Huelva, ES (HEV)' },
        { code: 'HEW', name: 'Athens Hellinikon Airport, GR (HEW)' },
        { code: 'HEX', name: 'Santo Domingo Herrera Apt, DO (HEX)' },
        { code: 'HFE', name: 'Hefei, CN (HFE)' },
        { code: 'HFN', name: 'Hornafjordur, IS (HFN)' },
        { code: 'HFS', name: 'Hagfors, SE (HFS)' },
        { code: 'HFT', name: 'Hammerfest, NO (HFT)' },
        { code: 'HGD', name: 'Hughenden, AU (HGD)' },
        { code: 'HGH', name: 'Hangzhou, CN (HGH)' },
        { code: 'HGL', name: 'Helgoland, DE (HGL)' },
        { code: 'HGR', name: 'Hagerstown, US (HGR)' },
        { code: 'HHE', name: 'Hachinohe, JP (HHE)' },
        { code: 'HHN', name: 'Frankfurt Hahn Airport, DE (HHN)' },
        { code: 'HIB', name: 'Hibbing/Chisholm, US (HIB)' },
        { code: 'HID', name: 'Horn Island, AU (HID)' },
        { code: 'HIG', name: 'Highbury, AU (HIG)' },
        { code: 'HIH', name: 'Hook Island, AU (HIH)' },
        { code: 'HIJ', name: 'Hiroshima International Apt, JP (HIJ)' },
        { code: 'HIN', name: 'Jinju, KR (HIN)' },
        { code: 'HIP', name: 'Headingly, AU (HIP)' },
        { code: 'HIW', name: 'Hiroshima West Apt, JP (HIW)' },
        { code: 'HJJ', name: 'Zhi Jiang, CN (HJJ)' },
        { code: 'HJR', name: 'Khajuraho, IN (HJR)' },
        { code: 'HKD', name: 'Hakodate, JP (HKD)' },
        { code: 'HKG', name: 'Hong Kong International Apt, HK (HKG)' },
        { code: 'HKK', name: 'Hokitika, NZ (HKK)' },
        { code: 'HLA', name: 'Lanseria, ZA (HLA)' },
        { code: 'HLD', name: 'Hailar, CN (HLD)' },
        { code: 'HLF', name: 'Hultsfred, SE (HLF)' },
        { code: 'HLH', name: 'Ulanhot, CN (HLH)' },
        { code: 'HLL', name: 'Hillside, AU (HLL)' },
        { code: 'HLN', name: 'Helena, US (HLN)' },
        { code: 'HLP', name: 'Jakarta Halim Perdana Kusuma Apt, ID (HLP)' },
        { code: 'HLS', name: 'St Helens, AU (HLS)' },
        { code: 'HLT', name: 'Hamilton, AU (HLT)' },
        { code: 'HLV', name: 'Helenvale, AU (HLV)' },
        { code: 'HLW', name: 'Hluhluwe, ZA (HLW)' },
        { code: 'HLY', name: 'Holyhead, GB (HLY)' },
        { code: 'HLZ', name: 'Hamilton, NZ (HLZ)' },
        { code: 'HMA', name: 'Khanty-Mansiysk, RU (HMA)' },
        { code: 'HMG', name: 'Hermannsburg, AU (HMG)' },
        { code: 'HMI', name: 'Hami, CN (HMI)' },
        { code: 'HMJ', name: 'Khmelnitskiy, UA (HMJ)' },
        { code: 'HMO', name: 'Hermosillo, MX (HMO)' },
        { code: 'HMR', name: 'Hamar, NO (HMR)' },
        { code: 'HMV', name: 'Hemavan, SE (HMV)' },
        { code: 'HNA', name: 'Hanamaki, JP (HNA)' },
        { code: 'HND', name: 'Tokyo Haneda Apt, JP (HND)' },
        { code: 'HNH', name: 'Hoonah, US (HNH)' },
        { code: 'HNK', name: 'Hinchinbrook Island, AU (HNK)' },
        { code: 'HNL', name: 'Honolulu International Apt, US (HNL)' },
        { code: 'HNS', name: 'Haines, US (HNS)' },
        { code: 'HNY', name: 'Hengyang, CN (HNY)' },
        { code: 'HOB', name: 'Hobbs Lea County Apt, US (HOB)' },
        { code: 'HOF', name: 'Alahsa, SA (HOF)' },
        { code: 'HOG', name: 'Holguin, CU (HOG)' },
        { code: 'HOH', name: 'Hohenems, AT (HOH)' },
        { code: 'HOK', name: 'Hooker Creek, AU (HOK)' },
        { code: 'HOM', name: 'Homer, US (HOM)' },
        { code: 'HOQ', name: 'Hof, DE (HOQ)' },
        { code: 'HOR', name: 'Horta, PT (HOR)' },
        { code: 'HOS', name: 'Chos Malal, AR (HOS)' },
        { code: 'HOU', name: 'Houston Hobby Apt, US (HOU)' },
        { code: 'HOV', name: 'Orsta-Volda, NO (HOV)' },
        { code: 'HOW', name: 'Fort Kobbe, PA (HOW)' },
        { code: 'HOY', name: 'Hoy, GB (HOY)' },
        { code: 'HPE', name: 'Hope Vale, AU (HPE)' },
        { code: 'HPN', name: 'Westchester County, US (HPN)' },
        { code: 'HRB', name: 'Harbin, CN (HRB)' },
        { code: 'HRG', name: 'Hurghada, EG (HRG)' },
        { code: 'HRK', name: 'Kharkov, UA (HRK)' },
        { code: 'HRL', name: 'Harlingen, US (HRL)' },
        { code: 'HRN', name: 'Heron Island, AU (HRN)' },
        { code: 'HRR', name: 'Herrera, CO (HRR)' },
        { code: 'HRS', name: 'Harrismith, ZA (HRS)' },
        { code: 'HRT', name: 'Harrogate, GB (HRT)' },
        { code: 'HRY', name: 'Henbury, AU (HRY)' },
        { code: 'HRZ', name: 'Horizontina, BR (HRZ)' },
        { code: 'HSC', name: 'Shaoguan, CN (HSC)' },
        { code: 'HSG', name: 'Saga, JP (HSG)' },
        { code: 'HSK', name: 'Huesca, ES (HSK)' },
        { code: 'HSM', name: 'Horsham, AU (HSM)' },
        { code: 'HSN', name: 'Zhoushan, CN (HSN)' },
        { code: 'HSS', name: 'Hisar, IN (HSS)' },
        { code: 'HSV', name: 'Huntsville International Airport, US (HSV)' },
        { code: 'HSZ', name: 'Hsinchu, TW (HSZ)' },
        { code: 'HTA', name: 'Chita, RU (HTA)' },
        { code: 'HTB', name: 'Terre-De-Bas, GP (HTB)' },
        { code: 'HTF', name: 'Hatfield, GB (HTF)' },
        { code: 'HTG', name: 'Hatanga, RU (HTG)' },
        { code: 'HTI', name: 'Hamilton Island, AU (HTI)' },
        { code: 'HTN', name: 'Hotan, CN (HTN)' },
        { code: 'HTR', name: 'Hateruma, JP (HTR)' },
        { code: 'HTS', name: 'Huntington, US (HTS)' },
        { code: 'HTU', name: 'Hopetoun, AU (HTU)' },
        { code: 'HTZ', name: 'Hato Corozal, CO (HTZ)' },
        { code: 'HUB', name: 'Humbert River, AU (HUB)' },
        { code: 'HUC', name: 'Humacao Apt, PR (HUC)' },
        { code: 'HUG', name: 'Huehuetenango, GT (HUG)' },
        { code: 'HUK', name: 'Hukuntsi, BW (HUK)' },
        { code: 'HUN', name: 'Hualien, TW (HUN)' },
        { code: 'HUU', name: 'Huanuco, PE (HUU)' },
        { code: 'HUV', name: 'Hudiksvall, SE (HUV)' },
        { code: 'HUX', name: 'Huatulco, MX (HUX)' },
        { code: 'HUY', name: 'Humberside, GB (HUY)' },
        { code: 'HUZ', name: 'Huizhou, CN (HUZ)' },
        { code: 'HVB', name: 'Hervey Bay, AU (HVB)' },
        { code: 'HVG', name: 'Honningsvag, NO (HVG)' },
        { code: 'HVK', name: 'Holmavik, IS (HVK)' },
        { code: 'HVM', name: 'Hvammstangi, IS (HVM)' },
        { code: 'HVN', name: 'New Haven, US (HVN)' },
        { code: 'HWK', name: 'Hawker, AU (HWK)' },
        { code: 'HXX', name: 'Hay, AU (HXX)' },
        { code: 'HYA', name: 'Hyannis, US (HYA)' },
        { code: 'HYC', name: 'High Wycombe, GB (HYC)' },
        { code: 'HYD', name: 'Hyderabad, IN (HYD)' },
        { code: 'HYN', name: 'Huangyan, CN (HYN)' },
        { code: 'HYS', name: 'Hays, US (HYS)' },
        { code: 'HYV', name: 'Hyvinkaa, FI (HYV)' },
        { code: 'HZB', name: 'Hazebrouck, FR (HZB)' },
        { code: 'HZG', name: 'Hanzhong, CN (HZG)' },
        { code: 'HZH', name: 'Liping City, CN (HZH)' },
        { code: 'HZK', name: 'Husavik, IS (HZK)' },
        { code: 'HZV', name: 'Hazyview, ZA (HZV)' },
        { code: 'IAA', name: 'Igarka, RU (IAA)' },
        { code: 'IAD', name: 'Washington Dulles International Apt, US (IAD)' },
        { code: 'IAG', name: 'Niagara Falls, US (IAG)' },
        {
            code: 'IAH',
            name: 'Houston George Bush Intercontinental Ap, US (IAH)',
        },
        { code: 'IAN', name: 'Kiana, US (IAN)' },
        { code: 'IAR', name: 'Yaroslavl, RU (IAR)' },
        { code: 'IAS', name: 'Iasi, RO (IAS)' },
        { code: 'IBE', name: 'Ibague, CO (IBE)' },
        { code: 'IBP', name: 'Iberia, PE (IBP)' },
        { code: 'IBZ', name: 'Ibiza, ES (IBZ)' },
        { code: 'ICA', name: 'Icabaru, VE (ICA)' },
        { code: 'ICN', name: 'Seoul Incheon International Airport, KR (ICN)' },
        { code: 'ICO', name: 'Sicogon Island, PH (ICO)' },
        { code: 'ICR', name: 'Nicaro, CU (ICR)' },
        { code: 'ICT', name: 'Wichita Mid-Continent Apt, US (ICT)' },
        { code: 'IDA', name: 'Idaho Falls, US (IDA)' },
        { code: 'IDB', name: 'Idre, SE (IDB)' },
        { code: 'IDK', name: 'Indulkana, AU (IDK)' },
        { code: 'IDO', name: 'Santa Isabel Do Morro, BR (IDO)' },
        { code: 'IDR', name: 'Indore, IN (IDR)' },
        { code: 'IDY', name: "Ile D'Yeu, FR (IDY)" },
        { code: 'IEG', name: 'Zielona Gora, PL (IEG)' },
        { code: 'IEJ', name: 'Iejima, JP (IEJ)' },
        { code: 'IES', name: 'Riesa, DE (IES)' },
        { code: 'IEV', name: 'Kiev Zhulyany Apt, UA (IEV)' },
        { code: 'IFF', name: 'Iffley, AU (IFF)' },
        { code: 'IFJ', name: 'Isafjordur, IS (IFJ)' },
        { code: 'IFL', name: 'Innisfail, AU (IFL)' },
        { code: 'IFO', name: 'Ivano-Frankovsk, UA (IFO)' },
        { code: 'IFP', name: 'Bullhead City, US (IFP)' },
        { code: 'IGA', name: 'Inagua, BS (IGA)' },
        { code: 'IGB', name: 'Ingeniero Jacobacci, AR (IGB)' },
        { code: 'IGH', name: 'Ingham, AU (IGH)' },
        { code: 'IGL', name: 'Izmir Cigli Military, TR (IGL)' },
        { code: 'IGN', name: 'Iligan, PH (IGN)' },
        { code: 'IGO', name: 'Chigorodo, CO (IGO)' },
        { code: 'IGR', name: 'Iguazu, AR (IGR)' },
        { code: 'IGS', name: 'Ingolstadt-Manching, DE (IGS)' },
        { code: 'IGU', name: 'Iguassu Falls, BR (IGU)' },
        { code: 'IHA', name: 'Niihama, JP (IHA)' },
        { code: 'IIA', name: 'Inishmaan, IE (IIA)' },
        { code: 'IIN', name: 'Nishino-Omote, JP (IIN)' },
        { code: 'IJK', name: 'Izhevsk, RU (IJK)' },
        { code: 'IJU', name: 'Ijui, BR (IJU)' },
        { code: 'IKI', name: 'Iki, JP (IKI)' },
        { code: 'IKP', name: 'Inkerman, AU (IKP)' },
        { code: 'IKS', name: 'Tiksi, RU (IKS)' },
        { code: 'IKT', name: 'Irkutsk, RU (IKT)' },
        { code: 'ILA', name: 'Illaga, ID (ILA)' },
        { code: 'ILB', name: 'Ilha Solteira, BR (ILB)' },
        { code: 'ILF', name: 'Ilford, CA (ILF)' },
        { code: 'ILG', name: 'Wilmington Greater Wilmington Apt, US (ILG)' },
        { code: 'ILH', name: 'Illisheim, DE (ILH)' },
        { code: 'ILI', name: 'Iliamna, US (ILI)' },
        { code: 'ILM', name: 'Wilmington, US (ILM)' },
        { code: 'ILO', name: 'Iloilo, PH (ILO)' },
        { code: 'ILQ', name: 'Ilo, PE (ILQ)' },
        { code: 'ILY', name: 'Islay, GB (ILY)' },
        { code: 'ILZ', name: 'Zilina, SK (ILZ)' },
        { code: 'IMB', name: 'Imbaimadai, GY (IMB)' },
        { code: 'IMF', name: 'Imphal, IN (IMF)' },
        { code: 'IMP', name: 'Imperatriz, BR (IMP)' },
        { code: 'IMT', name: 'Iron Mountain, US (IMT)' },
        { code: 'INA', name: 'Inta, RU (INA)' },
        { code: 'INB', name: 'Independence, BZ (INB)' },
        { code: 'INC', name: 'Yinchuan, CN (INC)' },
        { code: 'IND', name: 'Indianapolis, US (IND)' },
        { code: 'ING', name: 'Lago Argentino, AR (ING)' },
        { code: 'INJ', name: 'Injune, AU (INJ)' },
        { code: 'INL', name: 'International Falls, US (INL)' },
        { code: 'INM', name: 'Innamincka, AU (INM)' },
        { code: 'INN', name: 'Innsbruck, AT (INN)' },
        { code: 'INQ', name: 'Inisheer, IE (INQ)' },
        { code: 'INV', name: 'Inverness, GB (INV)' },
        { code: 'INX', name: 'Inanwatan, ID (INX)' },
        { code: 'INY', name: 'Inyati, ZA (INY)' },
        { code: 'IOA', name: 'Ioannina, GR (IOA)' },
        { code: 'IOM', name: 'Isle Of Man, GB (IOM)' },
        { code: 'IOR', name: 'Inishmore, IE (IOR)' },
        { code: 'IOS', name: 'Ilheus, BR (IOS)' },
        { code: 'IPC', name: 'Easter Island, CL (IPC)' },
        { code: 'IPE', name: 'Ipil, PH (IPE)' },
        { code: 'IPG', name: 'Ipiranga, BR (IPG)' },
        { code: 'IPH', name: 'Ipoh, MY (IPH)' },
        { code: 'IPI', name: 'Ipiales, CO (IPI)' },
        { code: 'IPL', name: 'Imperial County Apt, US (IPL)' },
        { code: 'IPN', name: 'Ipatinga, BR (IPN)' },
        { code: 'IPT', name: 'Williamsport, US (IPT)' },
        { code: 'IPU', name: 'Ipiau, BR (IPU)' },
        { code: 'IPW', name: 'Ipswich, GB (IPW)' },
        { code: 'IQM', name: 'Qiemo, CN (IQM)' },
        { code: 'IQN', name: 'Qingyang, CN (IQN)' },
        { code: 'IQQ', name: 'Iquique, CL (IQQ)' },
        { code: 'IQT', name: 'Iquitos, PE (IQT)' },
        { code: 'IRD', name: 'Ishurdi, BD (IRD)' },
        { code: 'IRE', name: 'Irece, BR (IRE)' },
        { code: 'IRG', name: 'Lockhart River, AU (IRG)' },
        { code: 'IRJ', name: 'La Rioja, AR (IRJ)' },
        { code: 'IRN', name: 'Iriona, HN (IRN)' },
        { code: 'ISA', name: 'Mount Isa, AU (ISA)' },
        { code: 'ISC', name: "Isles Of Scilly St Mary'S Apt, GB (ISC)" },
        { code: 'ISD', name: 'Iscuande, CO (ISD)' },
        { code: 'ISE', name: 'Isparta, TR (ISE)' },
        { code: 'ISG', name: 'Ishigaki, JP (ISG)' },
        { code: 'ISH', name: 'Ischia, IT (ISH)' },
        { code: 'ISI', name: 'Isisford, AU (ISI)' },
        { code: 'ISJ', name: 'Isla Mujeres, MX (ISJ)' },
        { code: 'ISK', name: 'Nasik, IN (ISK)' },
        { code: 'ISN', name: 'Williston, US (ISN)' },
        { code: 'ISP', name: 'Long Island Macarthur, US (ISP)' },
        { code: 'IST', name: 'Istanbul Ataturk Airport, TR (IST)' },
        { code: 'ITA', name: 'Itacoatiara, BR (ITA)' },
        { code: 'ITB', name: 'Itaituba, BR (ITB)' },
        { code: 'ITE', name: 'Itubera, BR (ITE)' },
        { code: 'ITH', name: 'Ithaca, US (ITH)' },
        { code: 'ITI', name: 'Itambacuri, BR (ITI)' },
        { code: 'ITM', name: 'Osaka Itami Airport, JP (ITM)' },
        { code: 'ITN', name: 'Itabuna, BR (ITN)' },
        { code: 'ITO', name: 'Hilo, US (ITO)' },
        { code: 'ITP', name: 'Itaperuna, BR (ITP)' },
        { code: 'ITQ', name: 'Itaqui, BR (ITQ)' },
        { code: 'ITR', name: 'Itumbiara, BR (ITR)' },
        { code: 'IUL', name: 'Ilu, ID (IUL)' },
        { code: 'IUM', name: 'Summit Lake, CA (IUM)' },
        { code: 'IVC', name: 'Invercargill, NZ (IVC)' },
        { code: 'IVL', name: 'Ivalo, FI (IVL)' },
        { code: 'IVO', name: 'Chivolo, CO (IVO)' },
        { code: 'IVR', name: 'Inverell, AU (IVR)' },
        { code: 'IVW', name: 'Inverway, AU (IVW)' },
        { code: 'IWA', name: 'Ivanovo, RU (IWA)' },
        { code: 'IWJ', name: 'Iwami, JP (IWJ)' },
        { code: 'IWO', name: 'Iwo Jima, JP (IWO)' },
        { code: 'IXA', name: 'Agartala, IN (IXA)' },
        { code: 'IXB', name: 'Bagdogra, IN (IXB)' },
        { code: 'IXC', name: 'Chandigarh, IN (IXC)' },
        { code: 'IXD', name: 'Allahabad, IN (IXD)' },
        { code: 'IXE', name: 'Mangalore, IN (IXE)' },
        { code: 'IXG', name: 'Belgaum, IN (IXG)' },
        { code: 'IXH', name: 'Kailashahar, IN (IXH)' },
        { code: 'IXI', name: 'Lilabari, IN (IXI)' },
        { code: 'IXJ', name: 'Jammu, IN (IXJ)' },
        { code: 'IXK', name: 'Keshod, IN (IXK)' },
        { code: 'IXL', name: 'Leh, IN (IXL)' },
        { code: 'IXM', name: 'Madurai, IN (IXM)' },
        { code: 'IXN', name: 'Khowai, IN (IXN)' },
        { code: 'IXP', name: 'Pathankot, IN (IXP)' },
        { code: 'IXQ', name: 'Kamalpur, IN (IXQ)' },
        { code: 'IXR', name: 'Ranchi, IN (IXR)' },
        { code: 'IXS', name: 'Silchar, IN (IXS)' },
        { code: 'IXT', name: 'Pasighat, IN (IXT)' },
        { code: 'IXU', name: 'Aurangabad, IN (IXU)' },
        { code: 'IXV', name: 'Along, IN (IXV)' },
        { code: 'IXW', name: 'Jamshedpur, IN (IXW)' },
        { code: 'IXY', name: 'Kandla, IN (IXY)' },
        { code: 'IXZ', name: 'Port Blair, IN (IXZ)' },
        { code: 'IYK', name: 'Inyokern, US (IYK)' },
        { code: 'IZO', name: 'Izumo, JP (IZO)' },
        { code: 'JAB', name: 'Jabiru, AU (JAB)' },
        { code: 'JAC', name: 'Jackson, US (JAC)' },
        { code: 'JAD', name: 'Jandakot, AU (JAD)' },
        { code: 'JAH', name: 'Aubagne, FR (JAH)' },
        { code: 'JAI', name: 'Jaipur, IN (JAI)' },
        { code: 'JAK', name: 'Jacmel, HT (JAK)' },
        { code: 'JAL', name: 'Jalapa, MX (JAL)' },
        { code: 'JAN', name: 'Jackson-Evers International Airport, US (JAN)' },
        { code: 'JAP', name: 'Punta Renes, CR (JAP)' },
        { code: 'JAU', name: 'Jauja, PE (JAU)' },
        { code: 'JAX', name: 'Jacksonville International Apt, US (JAX)' },
        { code: 'JCB', name: 'Joacaba, BR (JCB)' },
        { code: 'JCK', name: 'Julia Creek, AU (JCK)' },
        { code: 'JCM', name: 'Jacobina, BR (JCM)' },
        { code: 'JCN', name: 'Incheon, KR (JCN)' },
        { code: 'JCR', name: 'Jacareacanga, BR (JCR)' },
        { code: 'JCU', name: 'Ceuta, ES (JCU)' },
        { code: 'JDF', name: 'Juiz De Fora, BR (JDF)' },
        { code: 'JDH', name: 'Jodhpur, IN (JDH)' },
        { code: 'JDO', name: 'Juazeiro Do Norte, BR (JDO)' },
        { code: 'JDZ', name: 'Jingdezhen, CN (JDZ)' },
        { code: 'JED', name: 'Jeddah, SA (JED)' },
        { code: 'JEE', name: 'Jeremie, HT (JEE)' },
        { code: 'JEQ', name: 'Jequie, BR (JEQ)' },
        { code: 'JER', name: 'Jersey, GB (JER)' },
        {
            code: 'JFK',
            name: 'New York J F Kennedy International Apt, US (JFK)',
        },
        { code: 'JFM', name: 'Fremantle, AU (JFM)' },
        { code: 'JGA', name: 'Jamnagar, IN (JGA)' },
        { code: 'JGB', name: 'Jagdalpur, IN (JGB)' },
        { code: 'JGE', name: 'Geoje, KR (JGE)' },
        { code: 'JGN', name: 'Jiayuguan, CN (JGN)' },
        { code: 'JGS', name: 'Ji An, CN (JGS)' },
        { code: 'JHB', name: 'Johor Bahru, MY (JHB)' },
        { code: 'JHG', name: 'Jinghong, CN (JHG)' },
        { code: 'JHW', name: 'Jamestown, US (JHW)' },
        { code: 'JIA', name: 'Juina, BR (JIA)' },
        { code: 'JIK', name: 'Ikaria Island, GR (JIK)' },
        { code: 'JIL', name: 'Jilin, CN (JIL)' },
        { code: 'JIP', name: 'Jipijapa, EC (JIP)' },
        { code: 'JIU', name: 'Jiujiang, CN (JIU)' },
        { code: 'JJI', name: 'Juanjui, PE (JJI)' },
        { code: 'JJN', name: 'Jinjiang, CN (JJN)' },
        { code: 'JKG', name: 'Jonkoping, SE (JKG)' },
        { code: 'JKH', name: 'Chios, GR (JKH)' },
        { code: 'JLD', name: 'Landskrona, SE (JLD)' },
        { code: 'JLN', name: 'Joplin, US (JLN)' },
        { code: 'JLO', name: 'Jesolo, IT (JLO)' },
        { code: 'JLP', name: 'Juan Les Pins, FR (JLP)' },
        { code: 'JLR', name: 'Jabalpur, IN (JLR)' },
        { code: 'JLS', name: 'Jales, BR (JLS)' },
        { code: 'JMK', name: 'Mikonos, GR (JMK)' },
        { code: 'JMS', name: 'Jamestown, US (JMS)' },
        { code: 'JMU', name: 'Jiamusi, CN (JMU)' },
        { code: 'JNA', name: 'Januaria, BR (JNA)' },
        { code: 'JNB', name: 'Johannesburg International, ZA (JNB)' },
        { code: 'JNG', name: 'Jining, CN (JNG)' },
        { code: 'JNI', name: 'Junin, AR (JNI)' },
        { code: 'JNU', name: 'Juneau, US (JNU)' },
        { code: 'JNX', name: 'Naxos Is, GR (JNX)' },
        { code: 'JNZ', name: 'Jinzhou, CN (JNZ)' },
        { code: 'JOE', name: 'Joensuu, FI (JOE)' },
        { code: 'JOG', name: 'Yogyakarta, ID (JOG)' },
        { code: 'JOH', name: 'Port St Johns, ZA (JOH)' },
        { code: 'JOI', name: 'Joinville, BR (JOI)' },
        { code: 'JOK', name: 'Joshkar-Ola, RU (JOK)' },
        { code: 'JOL', name: 'Jolo, PH (JOL)' },
        { code: 'JON', name: 'Johnston Island, UM (JON)' },
        { code: 'JPA', name: 'Joao Pessoa, BR (JPA)' },
        { code: 'JPR', name: 'Ji-Parana, BR (JPR)' },
        { code: 'JQE', name: 'Jaque, PA (JQE)' },
        { code: 'JRH', name: 'Jorhat, IN (JRH)' },
        { code: 'JRN', name: 'Juruena, BR (JRN)' },
        { code: 'JSA', name: 'Jaisalmer, IN (JSA)' },
        { code: 'JSH', name: 'Sitia, GR (JSH)' },
        { code: 'JSI', name: 'Skiathos, GR (JSI)' },
        { code: 'JSM', name: 'Jose De San Martin, AR (JSM)' },
        { code: 'JSO', name: 'Sodertalje, SE (JSO)' },
        { code: 'JSR', name: 'Jessore, BD (JSR)' },
        { code: 'JSS', name: 'Spetsai, GR (JSS)' },
        { code: 'JST', name: 'Johnstown, US (JST)' },
        { code: 'JSY', name: 'Syros Island, GR (JSY)' },
        { code: 'JTI', name: 'Jatai, BR (JTI)' },
        { code: 'JTR', name: 'Thira, GR (JTR)' },
        { code: 'JTY', name: 'Astypalaia Island, GR (JTY)' },
        { code: 'JUA', name: 'Juara, BR (JUA)' },
        { code: 'JUI', name: 'Juist, DE (JUI)' },
        { code: 'JUJ', name: 'Jujuy, AR (JUJ)' },
        { code: 'JUL', name: 'Juliaca, PE (JUL)' },
        { code: 'JUN', name: 'Jundah, AU (JUN)' },
        { code: 'JUO', name: 'Jurado, CO (JUO)' },
        { code: 'JUR', name: 'Jurien Bay, AU (JUR)' },
        { code: 'JUT', name: 'Juticalpa, HN (JUT)' },
        { code: 'JUZ', name: 'Juzhou, CN (JUZ)' },
        { code: 'JWA', name: 'Jwaneng, BW (JWA)' },
        { code: 'JYV', name: 'Jyvaskyla, FI (JYV)' },
        { code: 'JZH', name: 'Song Pan, CN (JZH)' },
        { code: 'KAG', name: 'Gangneung, KR (KAG)' },
        { code: 'KAI', name: 'Kaieteur, GY (KAI)' },
        { code: 'KAJ', name: 'Kajaani, FI (KAJ)' },
        { code: 'KAO', name: 'Kuusamo, FI (KAO)' },
        { code: 'KAR', name: 'Kamarang, GY (KAR)' },
        { code: 'KAT', name: 'Kaitaia, NZ (KAT)' },
        { code: 'KAU', name: 'Kauhava, FI (KAU)' },
        { code: 'KAV', name: 'Kavanayen, VE (KAV)' },
        { code: 'KAX', name: 'Kalbarri, AU (KAX)' },
        { code: 'KAZ', name: 'Kau, ID (KAZ)' },
        { code: 'KBB', name: 'Kirkimbie, AU (KBB)' },
        { code: 'KBD', name: 'Kimberley Downs, AU (KBD)' },
        { code: 'KBF', name: 'Karubaga, ID (KBF)' },
        { code: 'KBJ', name: 'Kings Canyon, AU (KBJ)' },
        { code: 'KBP', name: 'Kiev Borispol Apt, UA (KBP)' },
        { code: 'KBR', name: 'Kota Bharu, MY (KBR)' },
        { code: 'KBU', name: 'Kotabaru, ID (KBU)' },
        { code: 'KBX', name: 'Kambuaya, ID (KBX)' },
        { code: 'KBY', name: 'Streaky Bay, AU (KBY)' },
        { code: 'KBZ', name: 'Kaikoura, NZ (KBZ)' },
        { code: 'KCA', name: 'Kuqa, CN (KCA)' },
        { code: 'KCD', name: 'Kamur, ID (KCD)' },
        { code: 'KCE', name: 'Collinsville, AU (KCE)' },
        { code: 'KCH', name: 'Kuching, MY (KCH)' },
        { code: 'KCI', name: 'Kono, ID (KCI)' },
        { code: 'KCM', name: 'Kahramanmaras, TR (KCM)' },
        { code: 'KCO', name: 'Kocaeli, TR (KCO)' },
        { code: 'KCP', name: 'Kamenets Podolskiy, UA (KCP)' },
        { code: 'KCS', name: 'Kings Creek Station, AU (KCS)' },
        { code: 'KCZ', name: 'Kochi, JP (KCZ)' },
        { code: 'KDB', name: 'Kambalda, AU (KDB)' },
        { code: 'KDI', name: 'Kendari, ID (KDI)' },
        { code: 'KDS', name: 'Kamaran Downs, AU (KDS)' },
        { code: 'KEA', name: 'Keisah, ID (KEA)' },
        { code: 'KEB', name: 'Nanwalek, US (KEB)' },
        { code: 'KEF', name: 'Reykjavik Keflavik International Apt, IS (KEF)' },
        { code: 'KEI', name: 'Kepi, ID (KEI)' },
        { code: 'KEJ', name: 'Kemerovo, RU (KEJ)' },
        { code: 'KEL', name: 'Kiel, DE (KEL)' },
        { code: 'KEM', name: 'Kemi/Tornio, FI (KEM)' },
        { code: 'KEQ', name: 'Kebar, ID (KEQ)' },
        { code: 'KES', name: 'Kelsey, CA (KES)' },
        { code: 'KEV', name: 'Kuorevesi, FI (KEV)' },
        { code: 'KEW', name: 'Keewaywin, CA (KEW)' },
        { code: 'KFF', name: 'Farranfore, IE (KFF)' },
        { code: 'KFG', name: 'Kalkurung, AU (KFG)' },
        { code: 'KFS', name: 'Kastamonu, TR (KFS)' },
        { code: 'KGC', name: 'Kingscote, AU (KGC)' },
        { code: 'KGD', name: 'Kaliningrad, RU (KGD)' },
        { code: 'KGI', name: 'Kalgoorlie, AU (KGI)' },
        { code: 'KGO', name: 'Kirovograd, UA (KGO)' },
        { code: 'KGP', name: 'Kogalym, RU (KGP)' },
        { code: 'KGR', name: 'Kulgera, AU (KGR)' },
        { code: 'KGS', name: 'Kos, GR (KGS)' },
        { code: 'KGU', name: 'Keningau, MY (KGU)' },
        { code: 'KGY', name: 'Kingaroy, AU (KGY)' },
        { code: 'KHC', name: 'Kerch, UA (KHC)' },
        { code: 'KHE', name: 'Kherson, UA (KHE)' },
        { code: 'KHG', name: 'Kashi, CN (KHG)' },
        { code: 'KHH', name: 'Kaohsiung, TW (KHH)' },
        { code: 'KHJ', name: 'Kauhajoki, FI (KHJ)' },
        { code: 'KHL', name: 'Khulna, BD (KHL)' },
        { code: 'KHN', name: 'Nanchang, CN (KHN)' },
        { code: 'KHO', name: 'Khoka Moya, ZA (KHO)' },
        { code: 'KHS', name: 'Khasab, OM (KHS)' },
        { code: 'KHU', name: 'Kremenchug, UA (KHU)' },
        { code: 'KHV', name: 'Khabarovsk, RU (KHV)' },
        { code: 'KHW', name: 'Khwai River Lodge, BW (KHW)' },
        { code: 'KID', name: 'Kristianstad, SE (KID)' },
        { code: 'KIF', name: 'Kingfisher Lake, CA (KIF)' },
        { code: 'KIG', name: 'Koinghaas, ZA (KIG)' },
        { code: 'KIJ', name: 'Niigata, JP (KIJ)' },
        { code: 'KIM', name: 'Kimberley, ZA (KIM)' },
        { code: 'KIN', name: 'Kingston Manley Intl, JM (KIN)' },
        { code: 'KIR', name: 'Kerry County, IE (KIR)' },
        { code: 'KIT', name: 'Kithira, GR (KIT)' },
        { code: 'KIX', name: 'Osaka Kansai International Airport, JP (KIX)' },
        { code: 'KJA', name: 'Krasnoyarsk, RU (KJA)' },
        { code: 'KJB', name: 'Kimsquit, CA (KJB)' },
        { code: 'KJK', name: 'Kortrijk, BE (KJK)' },
        { code: 'KJP', name: 'Kerama, JP (KJP)' },
        { code: 'KKE', name: 'Kerikeri, NZ (KKE)' },
        { code: 'KKG', name: 'Konawaruk, GY (KKG)' },
        { code: 'KKJ', name: 'Kita Kyushu, JP (KKJ)' },
        { code: 'KKN', name: 'Kirkenes, NO (KKN)' },
        { code: 'KKO', name: 'Kaikohe, NZ (KKO)' },
        { code: 'KKP', name: 'Koolburra, AU (KKP)' },
        { code: 'KKX', name: 'Kikaiga Shima, JP (KKX)' },
        { code: 'KKY', name: 'Kilkenny, IE (KKY)' },
        { code: 'KLD', name: 'Kalinin, RU (KLD)' },
        { code: 'KLF', name: 'Kaluga, RU (KLF)' },
        { code: 'KLG', name: 'Kalskag, US (KLG)' },
        { code: 'KLH', name: 'Kolhapur, IN (KLH)' },
        { code: 'KLO', name: 'Kalibo, PH (KLO)' },
        { code: 'KLQ', name: 'Keluang, ID (KLQ)' },
        { code: 'KLR', name: 'Kalmar, SE (KLR)' },
        { code: 'KLU', name: 'Klagenfurt, AT (KLU)' },
        { code: 'KLV', name: 'Karlovy Vary, CZ (KLV)' },
        { code: 'KLX', name: 'Kalamata, GR (KLX)' },
        { code: 'KLZ', name: 'Kleinzee, ZA (KLZ)' },
        { code: 'KMC', name: 'King Khalid Military City, SA (KMC)' },
        { code: 'KMG', name: 'Kunming, CN (KMG)' },
        { code: 'KMH', name: 'Kuruman, ZA (KMH)' },
        { code: 'KMI', name: 'Miyazaki, JP (KMI)' },
        { code: 'KMJ', name: 'Kumamoto, JP (KMJ)' },
        { code: 'KML', name: 'Kamileroi, AU (KML)' },
        { code: 'KMM', name: 'Kimam, ID (KMM)' },
        { code: 'KMQ', name: 'Komatsu, JP (KMQ)' },
        { code: 'KMW', name: 'Kostroma, RU (KMW)' },
        { code: 'KMX', name: 'Khamis Mushait, SA (KMX)' },
        { code: 'KNA', name: 'Vina Del Mar, CL (KNA)' },
        { code: 'KNC', name: "Ji'An, CN (KNC)" },
        { code: 'KNF', name: 'Kings Lynn, GB (KNF)' },
        { code: 'KNG', name: 'Kaimana, ID (KNG)' },
        { code: 'KNH', name: 'Kinmen, TW (KNH)' },
        { code: 'KNI', name: 'Katanning, AU (KNI)' },
        { code: 'KNO', name: 'Knokke, BE (KNO)' },
        { code: 'KNS', name: 'King Island, AU (KNS)' },
        { code: 'KNU', name: 'Kanpur, IN (KNU)' },
        { code: 'KNV', name: 'Knights Inlet, CA (KNV)' },
        { code: 'KNX', name: 'Kununurra, AU (KNX)' },
        { code: 'KNY', name: 'Kinoosao, CA (KNY)' },
        { code: 'KOA', name: 'Kona, US (KOA)' },
        { code: 'KOD', name: 'Kotabangun, ID (KOD)' },
        { code: 'KOE', name: 'Kupang, ID (KOE)' },
        { code: 'KOF', name: 'Komatipoort, ZA (KOF)' },
        { code: 'KOH', name: 'Koolatah, AU (KOH)' },
        { code: 'KOI', name: 'Kirkwall, GB (KOI)' },
        { code: 'KOJ', name: 'Kagoshima, JP (KOJ)' },
        { code: 'KOK', name: 'Kokkola/Pietarsaari, FI (KOK)' },
        { code: 'KOQ', name: 'Koethen, DE (KOQ)' },
        { code: 'KOW', name: 'Ganzhou, CN (KOW)' },
        { code: 'KOX', name: 'Kokonao, ID (KOX)' },
        { code: 'KPG', name: 'Kurupung, GY (KPG)' },
        { code: 'KPI', name: 'Kapit, MY (KPI)' },
        { code: 'KPO', name: 'Pohang, KR (KPO)' },
        { code: 'KPP', name: 'Kalpowar, AU (KPP)' },
        { code: 'KPS', name: 'Kempsey, AU (KPS)' },
        { code: 'KQB', name: 'Koonibba, AU (KQB)' },
        { code: 'KRA', name: 'Kerang, AU (KRA)' },
        { code: 'KRB', name: 'Karumba, AU (KRB)' },
        { code: 'KRC', name: 'Kerinci, ID (KRC)' },
        { code: 'KRD', name: 'Kurundi, AU (KRD)' },
        { code: 'KRF', name: 'Kramfors, SE (KRF)' },
        { code: 'KRG', name: 'Karasabai, GY (KRG)' },
        { code: 'KRK', name: 'Krakow, PL (KRK)' },
        { code: 'KRL', name: 'Korla, CN (KRL)' },
        { code: 'KRM', name: 'Karanambo, GY (KRM)' },
        { code: 'KRN', name: 'Kiruna, SE (KRN)' },
        { code: 'KRO', name: 'Kurgan, RU (KRO)' },
        { code: 'KRP', name: 'Karup, DK (KRP)' },
        { code: 'KRQ', name: 'Kramatorsk, UA (KRQ)' },
        { code: 'KRR', name: 'Krasnodar, RU (KRR)' },
        { code: 'KRS', name: 'Kristiansand Kjevik Airport, NO (KRS)' },
        { code: 'KRY', name: 'Karamay, CN (KRY)' },
        { code: 'KSC', name: 'Kosice, SK (KSC)' },
        { code: 'KSD', name: 'Karlstad, SE (KSD)' },
        { code: 'KSF', name: 'Kassel, DE (KSF)' },
        { code: 'KSJ', name: 'Kasos Island, GR (KSJ)' },
        { code: 'KSK', name: 'Karlskoga, SE (KSK)' },
        { code: 'KSM', name: 'St Marys, US (KSM)' },
        { code: 'KSO', name: 'Kastoria, GR (KSO)' },
        { code: 'KSU', name: 'Kristiansund, NO (KSU)' },
        { code: 'KSV', name: 'Springvale, AU (KSV)' },
        { code: 'KSY', name: 'Kars, TR (KSY)' },
        { code: 'KSZ', name: 'Kotlas, RU (KSZ)' },
        { code: 'KTA', name: 'Karratha, AU (KTA)' },
        { code: 'KTD', name: 'Kitadaito, JP (KTD)' },
        { code: 'KTE', name: 'Kerteh, MY (KTE)' },
        { code: 'KTF', name: 'Takaka, NZ (KTF)' },
        { code: 'KTG', name: 'Ketapang, ID (KTG)' },
        { code: 'KTN', name: 'Ketchikan International Apt, US (KTN)' },
        { code: 'KTO', name: 'Kato, GY (KTO)' },
        { code: 'KTP', name: 'Kingston Tinson Apt, JM (KTP)' },
        { code: 'KTQ', name: 'Kitee, FI (KTQ)' },
        { code: 'KTR', name: 'Katherine, AU (KTR)' },
        { code: 'KTT', name: 'Kittila, FI (KTT)' },
        { code: 'KTU', name: 'Kota, IN (KTU)' },
        { code: 'KTV', name: 'Kamarata, VE (KTV)' },
        { code: 'KTW', name: 'Katowice, PL (KTW)' },
        { code: 'KTZ', name: 'Kwun Tong, HK (KTZ)' },
        { code: 'KUA', name: 'Kuantan, MY (KUA)' },
        { code: 'KUD', name: 'Kudat, MY (KUD)' },
        { code: 'KUF', name: 'Samara, RU (KUF)' },
        { code: 'KUG', name: 'Kubin Island, AU (KUG)' },
        { code: 'KUH', name: 'Kushiro, JP (KUH)' },
        { code: 'KUI', name: 'Kawau Island, NZ (KUI)' },
        { code: 'KUJ', name: 'Kushimoto, JP (KUJ)' },
        { code: 'KUL', name: 'Kuala Lumpur International Airport, MY (KUL)' },
        { code: 'KUM', name: 'Yakushima, JP (KUM)' },
        { code: 'KUN', name: 'Kaunas, LT (KUN)' },
        { code: 'KUO', name: 'Kuopio, FI (KUO)' },
        { code: 'KUU', name: 'Kulu, IN (KUU)' },
        { code: 'KUV', name: 'Gunsan, KR (KUV)' },
        { code: 'KUZ', name: 'Gunsan, KR (KUZ)' },
        { code: 'KVA', name: 'Kavala, GR (KVA)' },
        { code: 'KVB', name: 'Skovde, SE (KVB)' },
        { code: 'KVC', name: 'King Cove, US (KVC)' },
        { code: 'KVD', name: 'Gyandzha, AZ (KVD)' },
        { code: 'KVK', name: 'Kirovsk, RU (KVK)' },
        { code: 'KVL', name: 'Kivalina, US (KVL)' },
        { code: 'KVR', name: 'Kavalerovo, RU (KVR)' },
        { code: 'KVX', name: 'Kirov, RU (KVX)' },
        { code: 'KWB', name: 'Karimunjawa, ID (KWB)' },
        { code: 'KWE', name: 'Guiyang, CN (KWE)' },
        { code: 'KWG', name: 'Krivoy Rog, UA (KWG)' },
        { code: 'KWJ', name: 'Gwangju, KR (KWJ)' },
        { code: 'KWL', name: 'Guilin, CN (KWL)' },
        { code: 'KWM', name: 'Kowanyama, AU (KWM)' },
        { code: 'KWU', name: 'Mansion House, NZ (KWU)' },
        { code: 'KXE', name: 'Klerksdorp, ZA (KXE)' },
        { code: 'KXK', name: 'Komsomolsk Na Amure, RU (KXK)' },
        { code: 'KYA', name: 'Konya, TR (KYA)' },
        { code: 'KYB', name: 'Yangoonabie, AU (KYB)' },
        { code: 'KYD', name: 'Orchid Island, TW (KYD)' },
        { code: 'KYF', name: 'Yeelirrie, AU (KYF)' },
        { code: 'KYI', name: 'Yalata Mission, AU (KYI)' },
        { code: 'KYN', name: 'Milton Keynes, GB (KYN)' },
        { code: 'KYZ', name: 'Kyzyl, RU (KYZ)' },
        { code: 'KZG', name: 'Kitzingen, DE (KZG)' },
        { code: 'KZI', name: 'Kozani, GR (KZI)' },
        { code: 'KZN', name: 'Kazan, RU (KZN)' },
        { code: 'KZS', name: 'Kastelorizo, GR (KZS)' },
        { code: 'LAC', name: 'Pulau Layang-Layang, MY (LAC)' },
        { code: 'LAG', name: 'La Guaira, VE (LAG)' },
        { code: 'LAH', name: 'Labuha, ID (LAH)' },
        { code: 'LAI', name: 'Lannion, FR (LAI)' },
        { code: 'LAJ', name: 'Lages, BR (LAJ)' },
        { code: 'LAK', name: 'Aklavik, CA (LAK)' },
        { code: 'LAN', name: 'Lansing, US (LAN)' },
        { code: 'LAO', name: 'Laoag, PH (LAO)' },
        { code: 'LAP', name: 'La Paz, MX (LAP)' },
        { code: 'LAR', name: 'Laramie, US (LAR)' },
        { code: 'LAS', name: 'Las Vegas Mccarran International Apt, US (LAS)' },
        { code: 'LAT', name: 'La Uribe, CO (LAT)' },
        { code: 'LAW', name: 'Lawton, US (LAW)' },
        { code: 'LAX', name: 'Los Angeles International Apt, US (LAX)' },
        { code: 'LAY', name: 'Ladysmith, ZA (LAY)' },
        { code: 'LAZ', name: 'Bom Jesus Da Lapa, BR (LAZ)' },
        { code: 'LBA', name: 'Leeds Bradford, GB (LBA)' },
        {
            code: 'LBB',
            name: 'Lubbock Preston Smith International Apt, US (LBB)',
        },
        { code: 'LBC', name: 'Hamburg Luebeck Airport, DE (LBC)' },
        { code: 'LBE', name: 'Latrobe, US (LBE)' },
        { code: 'LBF', name: 'North Platte, US (LBF)' },
        { code: 'LBG', name: 'Paris Le Bourget Apt, FR (LBG)' },
        { code: 'LBI', name: 'Albi, FR (LBI)' },
        { code: 'LBJ', name: 'Labuan Bajo, ID (LBJ)' },
        { code: 'LBL', name: 'Liberal, US (LBL)' },
        { code: 'LBP', name: 'Long Banga, MY (LBP)' },
        { code: 'LBR', name: 'Labrea, BR (LBR)' },
        { code: 'LBU', name: 'Labuan, MY (LBU)' },
        { code: 'LBW', name: 'Long Bawan, ID (LBW)' },
        { code: 'LBX', name: 'Lubang, PH (LBX)' },
        { code: 'LBY', name: 'La Baule, FR (LBY)' },
        { code: 'LCB', name: 'Pontes-E-Lacerda, BR (LCB)' },
        { code: 'LCC', name: 'Lecce, IT (LCC)' },
        { code: 'LCD', name: 'Louis Trichardt, ZA (LCD)' },
        { code: 'LCE', name: 'La Ceiba, HN (LCE)' },
        { code: 'LCF', name: 'Rio Dulce, GT (LCF)' },
        { code: 'LCG', name: 'La Coruna, ES (LCG)' },
        { code: 'LCH', name: 'Lake Charles, US (LCH)' },
        { code: 'LCJ', name: 'Lodz, PL (LCJ)' },
        { code: 'LCK', name: 'Columbus Rickenbacker Apt, US (LCK)' },
        { code: 'LCL', name: 'La Coloma, CU (LCL)' },
        { code: 'LCM', name: 'La Cumbre, AR (LCM)' },
        { code: 'LCN', name: 'Balcanoona, AU (LCN)' },
        { code: 'LCP', name: 'Loncopue, AR (LCP)' },
        { code: 'LCR', name: 'La Chorrera, CO (LCR)' },
        { code: 'LCS', name: 'Las Canas, CR (LCS)' },
        { code: 'LCV', name: 'Lucca, IT (LCV)' },
        { code: 'LCX', name: 'Longyan, CN (LCX)' },
        { code: 'LCY', name: 'London City Apt, GB (LCY)' },
        { code: 'LDA', name: 'Malda, IN (LDA)' },
        { code: 'LDB', name: 'Londrina, BR (LDB)' },
        { code: 'LDE', name: 'Lourdes/Tarbes, FR (LDE)' },
        { code: 'LDG', name: 'Leshukonskoye, RU (LDG)' },
        { code: 'LDH', name: 'Lord Howe Island, AU (LDH)' },
        { code: 'LDK', name: 'Lidkoping, SE (LDK)' },
        { code: 'LDU', name: 'Lahad Datu, MY (LDU)' },
        { code: 'LDV', name: 'Landivisiau, FR (LDV)' },
        { code: 'LDW', name: 'Landsdowne, AU (LDW)' },
        { code: 'LDY', name: 'Londonderry, GB (LDY)' },
        { code: 'LDZ', name: 'Londolozi, ZA (LDZ)' },
        { code: 'LEA', name: 'Learmonth, AU (LEA)' },
        { code: 'LEB', name: 'Lebanon, US (LEB)' },
        { code: 'LEC', name: 'Lencois, BR (LEC)' },
        { code: 'LED', name: 'St Petersburg Pulkovo Apt, RU (LED)' },
        { code: 'LEH', name: 'Le Havre, FR (LEH)' },
        { code: 'LEI', name: 'Almeria, ES (LEI)' },
        { code: 'LEJ', name: 'Leipzig/Halle, DE (LEJ)' },
        { code: 'LEL', name: 'Lake Evella, AU (LEL)' },
        { code: 'LEN', name: 'Leon, ES (LEN)' },
        { code: 'LEP', name: 'Leopoldina, BR (LEP)' },
        { code: 'LEQ', name: 'Lands End, GB (LEQ)' },
        { code: 'LER', name: 'Leinster, AU (LER)' },
        { code: 'LET', name: 'Leticia, CO (LET)' },
        { code: 'LEU', name: 'Seo De Urgel, ES (LEU)' },
        { code: 'LEX', name: 'Lexington Blue Grass Apt, US (LEX)' },
        { code: 'LEY', name: 'Lelystad, NL (LEY)' },
        { code: 'LEZ', name: 'La Esperanza, HN (LEZ)' },
        { code: 'LFP', name: 'Lakefield, AU (LFP)' },
        { code: 'LFR', name: 'La Fria, VE (LFR)' },
        { code: 'LFT', name: 'Lafayette Regional Apt, US (LFT)' },
        { code: 'LGA', name: 'New York La Guardia Apt, US (LGA)' },
        { code: 'LGB', name: 'Long Beach Apt, US (LGB)' },
        { code: 'LGE', name: 'Lake Gregory, AU (LGE)' },
        { code: 'LGG', name: 'Liege Bierset Apt, BE (LGG)' },
        { code: 'LGH', name: 'Leigh Creek, AU (LGH)' },
        { code: 'LGI', name: 'Deadmans Cay, BS (LGI)' },
        { code: 'LGK', name: 'Langkawi, MY (LGK)' },
        { code: 'LGL', name: 'Long Lellang, MY (LGL)' },
        { code: 'LGO', name: 'Langeoog, DE (LGO)' },
        { code: 'LGP', name: 'Legaspi, PH (LGP)' },
        { code: 'LGQ', name: 'Lago Agrio, EC (LGQ)' },
        { code: 'LGR', name: 'Cochrane, CL (LGR)' },
        { code: 'LGS', name: 'Malargue, AR (LGS)' },
        { code: 'LGT', name: 'Las Gaviotas, CO (LGT)' },
        { code: 'LGW', name: 'London Gatwick Apt, GB (LGW)' },
        { code: 'LGY', name: 'Lagunillas, VE (LGY)' },
        { code: 'LGZ', name: 'Leguizamo, CO (LGZ)' },
        { code: 'LHA', name: 'Lahr, DE (LHA)' },
        { code: 'LHG', name: 'Lightning Ridge, AU (LHG)' },
        { code: 'LHI', name: 'Lereh, ID (LHI)' },
        { code: 'LHK', name: 'Guanghua, CN (LHK)' },
        { code: 'LHN', name: 'Lishan, TW (LHN)' },
        { code: 'LHR', name: 'London Heathrow Apt, GB (LHR)' },
        { code: 'LHS', name: 'Las Heras, AR (LHS)' },
        { code: 'LHW', name: 'Lanzhou Apt, CN (LHW)' },
        { code: 'LIA', name: 'Liangping, CN (LIA)' },
        { code: 'LIB', name: 'Limbunya, AU (LIB)' },
        { code: 'LID', name: 'Leiden, NL (LID)' },
        { code: 'LIG', name: 'Limoges, FR (LIG)' },
        { code: 'LIH', name: 'Kauai Island Lihue Municipal Apt, US (LIH)' },
        { code: 'LII', name: 'Mulia, ID (LII)' },
        { code: 'LIL', name: 'Lille Lesquin Airport, FR (LIL)' },
        { code: 'LIM', name: 'Lima, PE (LIM)' },
        { code: 'LIN', name: 'Milan Linate Apt, IT (LIN)' },
        { code: 'LIO', name: 'Limon, CR (LIO)' },
        { code: 'LIP', name: 'Lins, BR (LIP)' },
        { code: 'LIR', name: 'Liberia, CR (LIR)' },
        { code: 'LIS', name: 'Lisbon, PT (LIS)' },
        { code: 'LIT', name: 'Little Rock, US (LIT)' },
        { code: 'LIU', name: 'Linosa, IT (LIU)' },
        { code: 'LJG', name: 'Lijiang City, CN (LJG)' },
        { code: 'LJU', name: 'Ljubljana, SI (LJU)' },
        { code: 'LKA', name: 'Larantuka, ID (LKA)' },
        { code: 'LKD', name: 'Lakeland Downs, AU (LKD)' },
        { code: 'LKH', name: 'Long Akah, MY (LKH)' },
        { code: 'LKL', name: 'Lakselv, NO (LKL)' },
        { code: 'LKN', name: 'Leknes, NO (LKN)' },
        { code: 'LKO', name: 'Lucknow, IN (LKO)' },
        { code: 'LKZ', name: 'Brandon, GB (LKZ)' },
        { code: 'LLA', name: 'Lulea, SE (LLA)' },
        { code: 'LLE', name: 'Malelane, ZA (LLE)' },
        { code: 'LLF', name: 'Ling Ling, CN (LLF)' },
        { code: 'LLG', name: 'Chillagoe, AU (LLG)' },
        { code: 'LLH', name: 'Las Limas, HN (LLH)' },
        { code: 'LLL', name: 'Lissadell, AU (LLL)' },
        { code: 'LLM', name: 'Long Lama, MY (LLM)' },
        { code: 'LLN', name: 'Kelila, ID (LLN)' },
        { code: 'LLP', name: 'Linda Downs, AU (LLP)' },
        { code: 'LLS', name: 'Las Lomitas, AR (LLS)' },
        { code: 'LMC', name: 'Lamacarena, CO (LMC)' },
        { code: 'LMD', name: 'Los Menucos, AR (LMD)' },
        { code: 'LME', name: 'Le Mans Arnage Airport, FR (LME)' },
        { code: 'LMH', name: 'Limon, HN (LMH)' },
        { code: 'LMM', name: 'Los Mochis, MX (LMM)' },
        { code: 'LMN', name: 'Limbang, MY (LMN)' },
        { code: 'LMO', name: 'Lossiemouth, GB (LMO)' },
        { code: 'LMP', name: 'Lampedusa, IT (LMP)' },
        { code: 'LMR', name: 'Lime Acres, ZA (LMR)' },
        { code: 'LMT', name: 'Klamath Falls, US (LMT)' },
        { code: 'LMX', name: 'Lopez De Micay, CO (LMX)' },
        { code: 'LNH', name: 'Lake Nash, AU (LNH)' },
        { code: 'LNJ', name: 'Lincang, CN (LNJ)' },
        { code: 'LNK', name: 'Lincoln, US (LNK)' },
        { code: 'LNO', name: 'Leonora, AU (LNO)' },
        { code: 'LNS', name: 'Lancaster, US (LNS)' },
        { code: 'LNX', name: 'Smolensk, RU (LNX)' },
        { code: 'LNY', name: 'Lanai City, US (LNY)' },
        { code: 'LNZ', name: 'Linz, AT (LNZ)' },
        { code: 'LOA', name: 'Lorraine, AU (LOA)' },
        { code: 'LOB', name: 'Los Andes, CL (LOB)' },
        { code: 'LOC', name: 'Lock, AU (LOC)' },
        { code: 'LOH', name: 'Loja, EC (LOH)' },
        { code: 'LOI', name: 'Lontras, BR (LOI)' },
        { code: 'LON', name: 'London Airports (All), GB (LON)' },
        { code: 'LOQ', name: 'Lobatse, BW (LOQ)' },
        { code: 'LOV', name: 'Monclova, MX (LOV)' },
        { code: 'LOX', name: 'Los Tablones, GT (LOX)' },
        { code: 'LPA', name: 'Las Palmas, ES (LPA)' },
        { code: 'LPB', name: 'La Paz, BO (LPB)' },
        { code: 'LPD', name: 'La Pedrera, CO (LPD)' },
        { code: 'LPE', name: 'La Primavera, CO (LPE)' },
        { code: 'LPG', name: 'La Plata, AR (LPG)' },
        { code: 'LPH', name: 'Lochgilphead, GB (LPH)' },
        { code: 'LPI', name: 'Linkoping, SE (LPI)' },
        { code: 'LPJ', name: 'Pijiguaos, VE (LPJ)' },
        { code: 'LPK', name: 'Lipetsk, RU (LPK)' },
        { code: 'LPL', name: 'Liverpool, GB (LPL)' },
        { code: 'LPP', name: 'Lappeenranta, FI (LPP)' },
        { code: 'LPU', name: 'Long Apung, ID (LPU)' },
        { code: 'LPX', name: 'Liepaya, LV (LPX)' },
        { code: 'LPY', name: 'Le Puy, FR (LPY)' },
        { code: 'LQM', name: 'Puerto Leguizamo, CO (LQM)' },
        { code: 'LRA', name: 'Larissa, GR (LRA)' },
        { code: 'LRC', name: 'Laarbruch, DE (LRC)' },
        { code: 'LRD', name: 'Laredo, US (LRD)' },
        { code: 'LRE', name: 'Longreach, AU (LRE)' },
        { code: 'LRH', name: 'La Rochelle, FR (LRH)' },
        { code: 'LRI', name: 'Lorica, CO (LRI)' },
        { code: 'LRM', name: 'La Romana, DO (LRM)' },
        { code: 'LRQ', name: 'Laurie River, CA (LRQ)' },
        { code: 'LRS', name: 'Leros, GR (LRS)' },
        { code: 'LRT', name: 'Lorient, FR (LRT)' },
        { code: 'LRV', name: 'Los Roques, VE (LRV)' },
        { code: 'LSC', name: 'La Serena, CL (LSC)' },
        { code: 'LSE', name: 'La Crosse, US (LSE)' },
        { code: 'LSI', name: 'Shetland Islands Sumburgh Apt, GB (LSI)' },
        { code: 'LSL', name: 'Los Chiles, CR (LSL)' },
        { code: 'LSM', name: 'Long Semado, MY (LSM)' },
        { code: 'LSO', name: 'Les Sables, FR (LSO)' },
        { code: 'LSP', name: 'Las Piedras, VE (LSP)' },
        { code: 'LSS', name: 'Terre-De-Haut, GP (LSS)' },
        { code: 'LST', name: 'Launceston, AU (LST)' },
        { code: 'LSU', name: 'Long Sukang, MY (LSU)' },
        { code: 'LSW', name: 'Lhok Seumawe, ID (LSW)' },
        { code: 'LSX', name: 'Lhok Sukon, ID (LSX)' },
        { code: 'LSY', name: 'Lismore, AU (LSY)' },
        { code: 'LSZ', name: 'Mali Losinj, HR (LSZ)' },
        { code: 'LTA', name: 'Tzaneen, ZA (LTA)' },
        { code: 'LTB', name: 'Latrobe, AU (LTB)' },
        { code: 'LTM', name: 'Lethem, GY (LTM)' },
        { code: 'LTN', name: 'London Luton Apt, GB (LTN)' },
        { code: 'LTO', name: 'Loreto, MX (LTO)' },
        { code: 'LTP', name: 'Lyndhurst, AU (LTP)' },
        { code: 'LTQ', name: 'Le Touquet, FR (LTQ)' },
        { code: 'LTR', name: 'Letterkenny, IE (LTR)' },
        { code: 'LTT', name: 'St Tropez, FR (LTT)' },
        { code: 'LTV', name: 'Lotusvale, AU (LTV)' },
        { code: 'LTX', name: 'Latacunga, EC (LTX)' },
        { code: 'LUB', name: 'Lumid Pau, GY (LUB)' },
        { code: 'LUE', name: 'Lucenec, SK (LUE)' },
        { code: 'LUG', name: 'Lugano, CH (LUG)' },
        { code: 'LUH', name: 'Ludhiana, IN (LUH)' },
        { code: 'LUI', name: 'La Union, HN (LUI)' },
        { code: 'LUJ', name: 'Lusikisiki, ZA (LUJ)' },
        { code: 'LUK', name: 'Cincinnati Municipal Apt, US (LUK)' },
        { code: 'LUM', name: 'Luxi, CN (LUM)' },
        { code: 'LUQ', name: 'San Luis, AR (LUQ)' },
        { code: 'LUT', name: 'Laura Station, AU (LUT)' },
        { code: 'LUU', name: 'Laura, AU (LUU)' },
        { code: 'LUV', name: 'Langgur, ID (LUV)' },
        { code: 'LUW', name: 'Luwuk, ID (LUW)' },
        { code: 'LUX', name: 'Luxembourg, LU (LUX)' },
        { code: 'LUZ', name: 'Lushan, CN (LUZ)' },
        { code: 'LVA', name: 'Laval, FR (LVA)' },
        { code: 'LVB', name: 'Livramento, BR (LVB)' },
        { code: 'LVO', name: 'Laverton, AU (LVO)' },
        { code: 'LWA', name: 'Lwbak, PH (LWA)' },
        { code: 'LWB', name: 'Lewisburg, US (LWB)' },
        { code: 'LWE', name: 'Lewoleba, ID (LWE)' },
        { code: 'LWH', name: 'Lawn Hill, AU (LWH)' },
        {
            code: 'LWK',
            name: 'Shetland Islands Lerwick/Tingwall Apt, GB (LWK)',
        },
        { code: 'LWO', name: 'Lviv, UA (LWO)' },
        { code: 'LWR', name: 'Leeuwarden, NL (LWR)' },
        { code: 'LWS', name: 'Lewiston, US (LWS)' },
        { code: 'LWY', name: 'Lawas, MY (LWY)' },
        { code: 'LXA', name: 'Lhasa, CN (LXA)' },
        { code: 'LXI', name: 'Linxi, CN (LXI)' },
        { code: 'LXR', name: 'Luxor, EG (LXR)' },
        { code: 'LXS', name: 'Limnos, GR (LXS)' },
        { code: 'LYA', name: 'Luoyang, CN (LYA)' },
        { code: 'LYB', name: 'Little Cayman, KY (LYB)' },
        { code: 'LYC', name: 'Lycksele, SE (LYC)' },
        { code: 'LYE', name: 'Lyneham, GB (LYE)' },
        { code: 'LYG', name: 'Lianyungang, CN (LYG)' },
        { code: 'LYH', name: 'Lynchburg, US (LYH)' },
        { code: 'LYI', name: 'Linyi, CN (LYI)' },
        { code: 'LYK', name: 'Lunyuk, ID (LYK)' },
        { code: 'LYN', name: 'Lyon Bron Apt, FR (LYN)' },
        { code: 'LYR', name: 'Longyearbyen, NO (LYR)' },
        { code: 'LYS', name: 'Lyon Saint Exupery Apt, FR (LYS)' },
        { code: 'LYT', name: 'Lady Elliot Island, AU (LYT)' },
        { code: 'LYX', name: 'Lydd, GB (LYX)' },
        { code: 'LZC', name: 'Lazaro Cardenas, MX (LZC)' },
        { code: 'LZD', name: 'Lanzhou Lanzhoudong-Lanzhou East Apt, CN (LZD)' },
        { code: 'LZH', name: 'Liuzhou, CN (LZH)' },
        { code: 'LZN', name: 'Nangan, TW (LZN)' },
        { code: 'LZO', name: 'Luzhou, CN (LZO)' },
        { code: 'LZR', name: 'Lizard Island, AU (LZR)' },
        { code: 'LZY', name: 'Lin Zhi, CN (LZY)' },
        { code: 'MAA', name: 'Chennai, IN (MAA)' },
        { code: 'MAB', name: 'Maraba, BR (MAB)' },
        { code: 'MAD', name: 'Madrid Barajas Apt, ES (MAD)' },
        { code: 'MAF', name: 'Midland Odessa Regional Apt, US (MAF)' },
        { code: 'MAH', name: 'Menorca, ES (MAH)' },
        { code: 'MAL', name: 'Mangole, ID (MAL)' },
        { code: 'MAM', name: 'Matamoros, MX (MAM)' },
        { code: 'MAN', name: 'Manchester International Apt, GB (MAN)' },
        { code: 'MAO', name: 'Manaus, BR (MAO)' },
        { code: 'MAR', name: 'Maracaibo, VE (MAR)' },
        { code: 'MAY', name: 'Mangrove Cay, BS (MAY)' },
        { code: 'MAZ', name: 'Mayaguez, PR (MAZ)' },
        { code: 'MBB', name: 'Marble Bar, AU (MBB)' },
        { code: 'MBD', name: 'Mmabatho, ZA (MBD)' },
        { code: 'MBE', name: 'Monbetsu, JP (MBE)' },
        { code: 'MBF', name: 'Mount Buffalo, AU (MBF)' },
        { code: 'MBH', name: 'Maryborough, AU (MBH)' },
        { code: 'MBJ', name: 'Montego Bay, JM (MBJ)' },
        { code: 'MBK', name: 'Matupa, BR (MBK)' },
        { code: 'MBL', name: 'Manistee, US (MBL)' },
        { code: 'MBM', name: 'Mkambati, ZA (MBM)' },
        { code: 'MBN', name: 'Mt.Barnett, AU (MBN)' },
        { code: 'MBO', name: 'Mamburao, PH (MBO)' },
        { code: 'MBP', name: 'Moyobamba, PE (MBP)' },
        { code: 'MBS', name: 'Saginaw, US (MBS)' },
        { code: 'MBT', name: 'Masbate, PH (MBT)' },
        { code: 'MBW', name: 'Moorabbin, AU (MBW)' },
        { code: 'MBX', name: 'Maribor, SI (MBX)' },
        { code: 'MBZ', name: 'Maues, BR (MBZ)' },
        { code: 'MCG', name: 'Mcgrath, US (MCG)' },
        { code: 'MCH', name: 'Machala, EC (MCH)' },
        { code: 'MCI', name: 'Kansas City International Apt, US (MCI)' },
        { code: 'MCJ', name: 'Maicao, CO (MCJ)' },
        { code: 'MCO', name: 'Orlando International Apt, US (MCO)' },
        { code: 'MCP', name: 'Macapa, BR (MCP)' },
        { code: 'MCQ', name: 'Miskolc, HU (MCQ)' },
        { code: 'MCR', name: 'Melchor Mencos, GT (MCR)' },
        { code: 'MCS', name: 'Monte Caseros, AR (MCS)' },
        { code: 'MCT', name: 'Muscat, OM (MCT)' },
        { code: 'MCU', name: 'Montlucon, FR (MCU)' },
        { code: 'MCV', name: 'Mcarthur River, AU (MCV)' },
        { code: 'MCW', name: 'Mason City, US (MCW)' },
        { code: 'MCX', name: 'Makhachkala, RU (MCX)' },
        { code: 'MCY', name: 'Sunshine Coast, AU (MCY)' },
        { code: 'MCZ', name: 'Maceio, BR (MCZ)' },
        { code: 'MDB', name: 'Melinda, BZ (MDB)' },
        { code: 'MDC', name: 'Manado, ID (MDC)' },
        { code: 'MDE', name: 'Medellin Jose Maria Cordova Intl, CO (MDE)' },
        { code: 'MDG', name: 'Mudanjiang, CN (MDG)' },
        { code: 'MDP', name: 'Mindiptana, ID (MDP)' },
        { code: 'MDQ', name: 'Mar Del Plata, AR (MDQ)' },
        { code: 'MDS', name: 'Middle Caicos, TC (MDS)' },
        { code: 'MDT', name: 'Harrisburg International Apt, US (MDT)' },
        { code: 'MDW', name: 'Chicago Midway Apt, US (MDW)' },
        { code: 'MDX', name: 'Mercedes, AR (MDX)' },
        { code: 'MDY', name: 'Midway Island, UM (MDY)' },
        { code: 'MDZ', name: 'Mendoza, AR (MDZ)' },
        { code: 'MEA', name: 'Macae, BR (MEA)' },
        { code: 'MEB', name: 'Melbourne Essendon Apt, AU (MEB)' },
        { code: 'MEC', name: 'Manta, EC (MEC)' },
        { code: 'MED', name: 'Madinah, SA (MED)' },
        { code: 'MEH', name: 'Mehamn, NO (MEH)' },
        { code: 'MEI', name: 'Meridian, US (MEI)' },
        { code: 'MEL', name: 'Melbourne Airport, AU (MEL)' },
        { code: 'MEM', name: 'Memphis International Apt, US (MEM)' },
        { code: 'MEN', name: 'Mende, FR (MEN)' },
        { code: 'MEP', name: 'Mersing, MY (MEP)' },
        { code: 'MEQ', name: 'Meulaboh, ID (MEQ)' },
        { code: 'MES', name: 'Medan, ID (MES)' },
        { code: 'MET', name: 'Moreton, AU (MET)' },
        { code: 'MEU', name: 'Monte Dourado, BR (MEU)' },
        { code: 'MEX', name: 'Mexico City/Ciudad De Mexico, MX (MEX)' },
        { code: 'MEZ', name: 'Messina, ZA (MEZ)' },
        { code: 'MFB', name: 'Monfort, CO (MFB)' },
        { code: 'MFE', name: 'Mcallen, US (MFE)' },
        { code: 'MFK', name: 'Matsu, TW (MFK)' },
        { code: 'MFL', name: 'Mount Fullstop, AU (MFL)' },
        { code: 'MFN', name: 'Milford Sound, NZ (MFN)' },
        { code: 'MFP', name: 'Manners Creek, AU (MFP)' },
        { code: 'MFR', name: 'Medford, US (MFR)' },
        { code: 'MFS', name: 'Miraflores, CO (MFS)' },
        { code: 'MFT', name: 'Machu Picchu, PE (MFT)' },
        { code: 'MFX', name: 'Meribel, FR (MFX)' },
        { code: 'MGA', name: 'Managua, NI (MGA)' },
        { code: 'MGB', name: 'Mount Gambier, AU (MGB)' },
        { code: 'MGD', name: 'Magdalena, BO (MGD)' },
        { code: 'MGF', name: 'Maringa, BR (MGF)' },
        { code: 'MGH', name: 'Margate, ZA (MGH)' },
        { code: 'MGL', name: 'Dusseldorf Moenchengladbach, DE (MGL)' },
        { code: 'MGM', name: 'Montgomery Dannelly Field, US (MGM)' },
        { code: 'MGN', name: 'Magangue, CO (MGN)' },
        { code: 'MGT', name: 'Milingimbi, AU (MGT)' },
        { code: 'MGV', name: 'Margaret River Station, AU (MGV)' },
        { code: 'MGW', name: 'Morgantown, US (MGW)' },
        { code: 'MHA', name: 'Mahdia, GY (MHA)' },
        { code: 'MHC', name: 'Macmahon Camp Four, AU (MHC)' },
        { code: 'MHF', name: 'Morichal, CO (MHF)' },
        { code: 'MHG', name: 'Mannheim, DE (MHG)' },
        { code: 'MHH', name: 'Marsh Harbour, BS (MHH)' },
        { code: 'MHK', name: 'Manhattan, US (MHK)' },
        { code: 'MHO', name: 'Mount House, AU (MHO)' },
        { code: 'MHP', name: 'Minsk International Apt 1, BY (MHP)' },
        { code: 'MHQ', name: 'Mariehamn, FI (MHQ)' },
        { code: 'MHT', name: 'Manchester, US (MHT)' },
        { code: 'MHU', name: 'Mount Hotham, AU (MHU)' },
        { code: 'MHW', name: 'Monteagudo, BO (MHW)' },
        { code: 'MHZ', name: 'Mildenhall Apt, GB (MHZ)' },
        { code: 'MIA', name: 'Miami International, US (MIA)' },
        { code: 'MID', name: 'Merida, MX (MID)' },
        { code: 'MIG', name: 'Mian Yang, CN (MIG)' },
        { code: 'MIH', name: 'Mitchell Plateau, AU (MIH)' },
        { code: 'MII', name: 'Marilia, BR (MII)' },
        { code: 'MIK', name: 'Mikkeli, FI (MIK)' },
        { code: 'MIM', name: 'Merimbula, AU (MIM)' },
        { code: 'MIN', name: 'Minnipa, AU (MIN)' },
        { code: 'MIX', name: 'Miriti, CO (MIX)' },
        { code: 'MIY', name: 'Mittiebah, AU (MIY)' },
        { code: 'MIZ', name: 'Mainoru, AU (MIZ)' },
        { code: 'MJF', name: 'Mosjoen, NO (MJF)' },
        { code: 'MJG', name: 'Mayajigua, CU (MJG)' },
        { code: 'MJH', name: 'Majma, SA (MJH)' },
        { code: 'MJK', name: 'Monkey Mia, AU (MJK)' },
        { code: 'MJP', name: 'Manjimup, AU (MJP)' },
        { code: 'MJR', name: 'Miramar, AR (MJR)' },
        { code: 'MJT', name: 'Mytilene, GR (MJT)' },
        { code: 'MJU', name: 'Mamuju, ID (MJU)' },
        { code: 'MJV', name: 'Murcia, ES (MJV)' },
        { code: 'MJY', name: 'Mangunjaya, ID (MJY)' },
        { code: 'MJZ', name: 'Mirnyj, RU (MJZ)' },
        { code: 'MKA', name: 'Marianske Lazne, CZ (MKA)' },
        { code: 'MKE', name: 'Milwaukee General Mitchell Intl Apt, US (MKE)' },
        { code: 'MKG', name: 'Muskegon, US (MKG)' },
        { code: 'MKK', name: 'Hoolehua, US (MKK)' },
        { code: 'MKM', name: 'Mukah, MY (MKM)' },
        { code: 'MKQ', name: 'Merauke, ID (MKQ)' },
        { code: 'MKR', name: 'Meekatharra, AU (MKR)' },
        { code: 'MKV', name: 'Mt Cavenagh, AU (MKV)' },
        { code: 'MKW', name: 'Manokwari, ID (MKW)' },
        { code: 'MKY', name: 'Mackay, AU (MKY)' },
        { code: 'MKZ', name: 'Malacca, MY (MKZ)' },
        { code: 'MLB', name: 'Melbourne, US (MLB)' },
        { code: 'MLG', name: 'Malang, ID (MLG)' },
        { code: 'MLH', name: 'Mulhouse, FR (MLH)' },
        { code: 'MLI', name: 'Moline, US (MLI)' },
        { code: 'MLM', name: 'Morelia, MX (MLM)' },
        { code: 'MLN', name: 'Melilla, ES (MLN)' },
        { code: 'MLO', name: 'Milos, GR (MLO)' },
        { code: 'MLP', name: 'Malabang, PH (MLP)' },
        { code: 'MLR', name: 'Millicent, AU (MLR)' },
        { code: 'MLU', name: 'Monroe, US (MLU)' },
        { code: 'MLV', name: 'Merluna, AU (MLV)' },
        { code: 'MLX', name: 'Malatya, TR (MLX)' },
        { code: 'MLZ', name: 'Melo, UY (MLZ)' },
        { code: 'MMB', name: 'Memambetsu, JP (MMB)' },
        { code: 'MMD', name: 'Minami Daito, JP (MMD)' },
        { code: 'MME', name: 'Durham Tees Valley, GB (MME)' },
        { code: 'MMG', name: 'Mount Magnet, AU (MMG)' },
        { code: 'MMH', name: 'Mammoth Lakes, US (MMH)' },
        { code: 'MMJ', name: 'Matsumoto, JP (MMJ)' },
        { code: 'MMK', name: 'Murmansk, RU (MMK)' },
        { code: 'MMM', name: 'Middlemount, AU (MMM)' },
        { code: 'MMP', name: 'Mompos, CO (MMP)' },
        { code: 'MMX', name: 'Malmo Sturup Apt, SE (MMX)' },
        { code: 'MMY', name: 'Miyako Jima, JP (MMY)' },
        { code: 'MNA', name: 'Melangguane, ID (MNA)' },
        { code: 'MND', name: 'Medina, CO (MND)' },
        { code: 'MNE', name: 'Mungeranie, AU (MNE)' },
        { code: 'MNG', name: 'Maningrida, AU (MNG)' },
        {
            code: 'MNL',
            name: 'Manila Ninoy Aquino International Apt, PH (MNL)',
        },
        { code: 'MNQ', name: 'Monto, AU (MNQ)' },
        { code: 'MNV', name: 'Mountain Valley, AU (MNV)' },
        { code: 'MNW', name: 'Macdonald Downs, AU (MNW)' },
        { code: 'MNX', name: 'Manicore, BR (MNX)' },
        { code: 'MOA', name: 'Moa, CU (MOA)' },
        { code: 'MOB', name: 'Mobile Municipal Apt, US (MOB)' },
        { code: 'MOC', name: 'Montes Claros, BR (MOC)' },
        { code: 'MOD', name: 'Modesto, US (MOD)' },
        { code: 'MOF', name: 'Maumere, ID (MOF)' },
        { code: 'MOH', name: 'Mohanbari, IN (MOH)' },
        { code: 'MOL', name: 'Molde, NO (MOL)' },
        { code: 'MON', name: 'Mount Cook Apt, NZ (MON)' },
        { code: 'MOO', name: 'Moomba, AU (MOO)' },
        { code: 'MOT', name: 'Minot International Apt, US (MOT)' },
        { code: 'MOU', name: 'Mountain Village, US (MOU)' },
        { code: 'MOV', name: 'Moranbah, AU (MOV)' },
        { code: 'MPC', name: 'Muko-Muko, ID (MPC)' },
        { code: 'MPH', name: 'Caticlan, PH (MPH)' },
        { code: 'MPI', name: 'Mamitupo, PA (MPI)' },
        { code: 'MPK', name: 'Mokpo, KR (MPK)' },
        { code: 'MPL', name: 'Montpellier Mediterranee Apt, FR (MPL)' },
        { code: 'MPP', name: 'Mulatupo, PA (MPP)' },
        { code: 'MPW', name: 'Mariupol, UA (MPW)' },
        { code: 'MQA', name: 'Mandora, AU (MQA)' },
        { code: 'MQD', name: 'Maquinchao, AR (MQD)' },
        { code: 'MQE', name: 'Marqua, AU (MQE)' },
        { code: 'MQF', name: 'Magnitogorsk, RU (MQF)' },
        { code: 'MQH', name: 'Minacu, BR (MQH)' },
        { code: 'MQJ', name: 'Balikesir Merkez Airport, TR (MQJ)' },
        { code: 'MQK', name: 'San Matias, BO (MQK)' },
        { code: 'MQL', name: 'Mildura, AU (MQL)' },
        { code: 'MQM', name: 'Mardin, TR (MQM)' },
        { code: 'MQN', name: 'Mo I Rana, NO (MQN)' },
        { code: 'MQP', name: 'Nelspruit, ZA (MQP)' },
        { code: 'MQR', name: 'Mosquera, CO (MQR)' },
        { code: 'MQS', name: 'Mustique, VC (MQS)' },
        { code: 'MQU', name: 'Mariquita, CO (MQU)' },
        { code: 'MQZ', name: 'Margaret River, AU (MQZ)' },
        { code: 'MRD', name: 'Merida, VE (MRD)' },
        { code: 'MRG', name: 'Mareeba, AU (MRG)' },
        { code: 'MRI', name: 'Anchorage Merrill Field, US (MRI)' },
        { code: 'MRJ', name: 'Marcala, HN (MRJ)' },
        { code: 'MRL', name: "Miner'S Lake, AU (MRL)" },
        { code: 'MRO', name: 'Masterton, NZ (MRO)' },
        { code: 'MRP', name: 'Marla, AU (MRP)' },
        { code: 'MRQ', name: 'Marinduque, PH (MRQ)' },
        { code: 'MRR', name: 'Macara, EC (MRR)' },
        { code: 'MRS', name: 'Marseille Provence Apt, FR (MRS)' },
        { code: 'MRT', name: 'Moroak, AU (MRT)' },
        { code: 'MRV', name: 'Mineralnye Vody, RU (MRV)' },
        { code: 'MRW', name: 'Maribo, DK (MRW)' },
        { code: 'MRY', name: 'Monterey Peninsula Apt, US (MRY)' },
        { code: 'MRZ', name: 'Moree, AU (MRZ)' },
        { code: 'MSA', name: 'Muskrat Dam, CA (MSA)' },
        { code: 'MSE', name: 'Manston, GB (MSE)' },
        { code: 'MSF', name: 'Mount Swan, AU (MSF)' },
        { code: 'MSH', name: 'Masirah, OM (MSH)' },
        { code: 'MSI', name: 'Masalembo, ID (MSI)' },
        { code: 'MSJ', name: 'Misawa, JP (MSJ)' },
        { code: 'MSK', name: 'Mastic Point, BS (MSK)' },
        { code: 'MSL', name: 'Muscle Shoals, US (MSL)' },
        { code: 'MSN', name: 'Madison, US (MSN)' },
        { code: 'MSO', name: 'Missoula, US (MSO)' },
        { code: 'MSP', name: 'Minneapolis International Apt, US (MSP)' },
        { code: 'MSQ', name: 'Minsk International Apt 2, BY (MSQ)' },
        { code: 'MSR', name: 'Mus, TR (MSR)' },
        { code: 'MSS', name: 'Massena, US (MSS)' },
        { code: 'MST', name: 'Maastricht/Aachen De Apt, NL (MST)' },
        { code: 'MSY', name: 'New Orleans Louis Armstrong Intl Apt, US (MSY)' },
        { code: 'MTA', name: 'Matamata, NZ (MTA)' },
        { code: 'MTB', name: 'Monte Libano, CO (MTB)' },
        { code: 'MTD', name: 'Mt Sanford, AU (MTD)' },
        { code: 'MTE', name: 'Monte Alegre, BR (MTE)' },
        { code: 'MTG', name: 'Mato Grosso, BR (MTG)' },
        { code: 'MTJ', name: 'Montrose, US (MTJ)' },
        { code: 'MTL', name: 'Maitland, AU (MTL)' },
        { code: 'MTM', name: 'Metlakatla, US (MTM)' },
        { code: 'MTQ', name: 'Mitchell, AU (MTQ)' },
        { code: 'MTR', name: 'Monteria, CO (MTR)' },
        { code: 'MTT', name: 'Minatitlan, MX (MTT)' },
        {
            code: 'MTY',
            name: 'Monterrey General Mariano Escobedo Apt, MX (MTY)',
        },
        { code: 'MUB', name: 'Maun, BW (MUB)' },
        { code: 'MUC', name: 'Munich International Airport, DE (MUC)' },
        { code: 'MUF', name: 'Muting, ID (MUF)' },
        { code: 'MUH', name: 'Mersa Matruh, EG (MUH)' },
        { code: 'MUN', name: 'Maturin, VE (MUN)' },
        { code: 'MUP', name: 'Mulga Park, AU (MUP)' },
        { code: 'MUQ', name: 'Muccan, AU (MUQ)' },
        { code: 'MUR', name: 'Marudi, MY (MUR)' },
        { code: 'MUS', name: 'Marcus Island, JP (MUS)' },
        { code: 'MVA', name: 'Myvatn, IS (MVA)' },
        { code: 'MVD', name: 'Montevideo, UY (MVD)' },
        { code: 'MVF', name: 'Mossoro, BR (MVF)' },
        { code: 'MVH', name: 'Macksville, AU (MVH)' },
        { code: 'MVJ', name: 'Mandeville, JM (MVJ)' },
        { code: 'MVK', name: 'Mulka, AU (MVK)' },
        { code: 'MVP', name: 'Mitu, CO (MVP)' },
        { code: 'MVQ', name: 'Mogilev, BY (MVQ)' },
        { code: 'MVS', name: 'Mucuri, BR (MVS)' },
        { code: 'MVU', name: 'Musgrave, AU (MVU)' },
        { code: 'MVV', name: 'Megeve, FR (MVV)' },
        { code: 'MVY', name: "Martha'S Vineyard, US (MVY)" },
        { code: 'MWA', name: 'Marion, US (MWA)' },
        { code: 'MWB', name: 'Morawa, AU (MWB)' },
        { code: 'MWH', name: 'Moses Lake Grant County Apt, US (MWH)' },
        { code: 'MWJ', name: 'Matthews Ridge, GY (MWJ)' },
        { code: 'MWK', name: 'Matak, ID (MWK)' },
        { code: 'MWR', name: 'Motswari, ZA (MWR)' },
        { code: 'MWT', name: 'Moolawatana, AU (MWT)' },
        { code: 'MWW', name: 'Mouscron, BE (MWW)' },
        { code: 'MWY', name: 'Miranda Downs, AU (MWY)' },
        { code: 'MXB', name: 'Masamba, ID (MXB)' },
        { code: 'MXD', name: 'Marion Downs, AU (MXD)' },
        { code: 'MXI', name: 'Mati, PH (MXI)' },
        { code: 'MXL', name: 'Mexicali, MX (MXL)' },
        { code: 'MXN', name: 'Morlaix, FR (MXN)' },
        { code: 'MXP', name: 'Milan Malpensa Apt, IT (MXP)' },
        { code: 'MXQ', name: 'Mitchell River, AU (MXQ)' },
        { code: 'MXR', name: 'Mirgorod, UA (MXR)' },
        { code: 'MXU', name: 'Mullewa, AU (MXU)' },
        { code: 'MXX', name: 'Mora, SE (MXX)' },
        { code: 'MXZ', name: 'Meixian, CN (MXZ)' },
        { code: 'MYA', name: 'Moruya, AU (MYA)' },
        { code: 'MYC', name: 'Maracay, VE (MYC)' },
        { code: 'MYE', name: 'Miyake Jima, JP (MYE)' },
        { code: 'MYG', name: 'Mayaguana, BS (MYG)' },
        { code: 'MYI', name: 'Murray Island, AU (MYI)' },
        { code: 'MYJ', name: 'Matsuyama, JP (MYJ)' },
        { code: 'MYM', name: 'Monkey Mountain, GY (MYM)' },
        { code: 'MYO', name: 'Myroodah, AU (MYO)' },
        { code: 'MYQ', name: 'Mysore, IN (MYQ)' },
        { code: 'MYR', name: 'Myrtle Beach Afb, US (MYR)' },
        { code: 'MYY', name: 'Miri, MY (MYY)' },
        { code: 'MZA', name: 'Muzaffarnagar, IN (MZA)' },
        { code: 'MZD', name: 'Mendez, EC (MZD)' },
        { code: 'MZE', name: 'Manatee, BZ (MZE)' },
        { code: 'MZF', name: 'Mzamba, ZA (MZF)' },
        { code: 'MZG', name: 'Makung, TW (MZG)' },
        { code: 'MZH', name: 'Merzifon, TR (MZH)' },
        { code: 'MZL', name: 'Manizales, CO (MZL)' },
        { code: 'MZM', name: 'Metz, FR (MZM)' },
        { code: 'MZO', name: 'Manzanillo, CU (MZO)' },
        { code: 'MZP', name: 'Motueka, NZ (MZP)' },
        { code: 'MZQ', name: 'Mkuze, ZA (MZQ)' },
        { code: 'MZS', name: 'Mostyn, MY (MZS)' },
        { code: 'MZT', name: 'Mazatlan, MX (MZT)' },
        { code: 'MZU', name: 'Muzaffarpur, IN (MZU)' },
        { code: 'MZV', name: 'Mulu, MY (MZV)' },
        { code: 'MZY', name: 'Mossel Bay, ZA (MZY)' },
        { code: 'NAA', name: 'Narrabri, AU (NAA)' },
        { code: 'NAC', name: 'Naracoorte, AU (NAC)' },
        { code: 'NAD', name: 'Macanal, CO (NAD)' },
        { code: 'NAF', name: 'Banaina, ID (NAF)' },
        { code: 'NAG', name: 'Nagpur, IN (NAG)' },
        { code: 'NAH', name: 'Naha, ID (NAH)' },
        { code: 'NAI', name: 'Annai, GY (NAI)' },
        { code: 'NAJ', name: 'Nakhichevan, AZ (NAJ)' },
        { code: 'NAL', name: 'Nalchik, RU (NAL)' },
        { code: 'NAM', name: 'Namlea, ID (NAM)' },
        { code: 'NAO', name: 'Nanchong, CN (NAO)' },
        { code: 'NAP', name: 'Naples Capodichino Apt, IT (NAP)' },
        { code: 'NAR', name: 'Nare, CO (NAR)' },
        { code: 'NAS', name: 'Nassau International Apt, BS (NAS)' },
        { code: 'NAT', name: 'Natal, BR (NAT)' },
        { code: 'NAV', name: 'Nevsehir, TR (NAV)' },
        { code: 'NAY', name: 'Beijing Nanyuan Apt, CN (NAY)' },
        { code: 'NBB', name: 'Barrancominas, CO (NBB)' },
        { code: 'NBC', name: 'Naberevnye Chelny, RU (NBC)' },
        { code: 'NBH', name: 'Nambucca Heads, AU (NBH)' },
        { code: 'NBL', name: 'San Blas, PA (NBL)' },
        { code: 'NBR', name: 'Nambour, AU (NBR)' },
        { code: 'NBV', name: 'Cana Brava, BR (NBV)' },
        { code: 'NBW', name: 'Guantanamo Nas, CU (NBW)' },
        { code: 'NBX', name: 'Nabire, ID (NBX)' },
        { code: 'NCA', name: 'North Caicos, TC (NCA)' },
        { code: 'NCE', name: 'Nice, FR (NCE)' },
        { code: 'NCI', name: 'Necocli, CO (NCI)' },
        { code: 'NCL', name: 'Newcastle, GB (NCL)' },
        { code: 'NCP', name: 'Luzon Island Cubi Point Nas, PH (NCP)' },
        { code: 'NCR', name: 'San Carlos, NI (NCR)' },
        { code: 'NCS', name: 'Newcastle, ZA (NCS)' },
        { code: 'NCT', name: 'Nicoya, CR (NCT)' },
        { code: 'NCY', name: 'Annecy, FR (NCY)' },
        { code: 'NDA', name: 'Bandanaira, ID (NDA)' },
        { code: 'NDC', name: 'Nanded, IN (NDC)' },
        { code: 'NDG', name: 'Qiqihar, CN (NDG)' },
        { code: 'NDO', name: 'La Palma Del Condada, ES (NDO)' },
        { code: 'NDS', name: 'Sandstone, AU (NDS)' },
        { code: 'NDY', name: 'Sanday, GB (NDY)' },
        { code: 'NDZ', name: 'Nordholz-Spieka, DE (NDZ)' },
        { code: 'NEC', name: 'Necochea, AR (NEC)' },
        { code: 'NEF', name: 'Neftekamsk, RU (NEF)' },
        { code: 'NEG', name: 'Negril, JM (NEG)' },
        { code: 'NER', name: 'Neryungri, RU (NER)' },
        { code: 'NEV', name: 'Nevis, KN (NEV)' },
        { code: 'NFG', name: 'Nefteyugansk, RU (NFG)' },
        { code: 'NGA', name: 'Young, AU (NGA)' },
        { code: 'NGB', name: 'Ningbo, CN (NGB)' },
        { code: 'NGD', name: 'Anegada, VG (NGD)' },
        { code: 'NGL', name: 'Ngala, ZA (NGL)' },
        { code: 'NGN', name: 'Nargana, PA (NGN)' },
        {
            code: 'NGO',
            name: 'Nagoya Chubu Centrair International Apt, JP (NGO)',
        },
        { code: 'NGS', name: 'Nagasaki, JP (NGS)' },
        { code: 'NHD', name: 'Minhad Ab, AE (NHD)' },
        { code: 'NHT', name: 'Northolt, GB (NHT)' },
        { code: 'NIF', name: 'Nifty, AU (NIF)' },
        { code: 'NIT', name: 'Niort, FR (NIT)' },
        { code: 'NJA', name: 'Atsugi, JP (NJA)' },
        { code: 'NJC', name: 'Nizhnevartovsk, RU (NJC)' },
        { code: 'NKB', name: 'Noonkanbah, AU (NKB)' },
        { code: 'NKD', name: 'Sinak, ID (NKD)' },
        { code: 'NKG', name: 'Nanjing, CN (NKG)' },
        { code: 'NKM', name: 'Nagoya Komaki Airport, JP (NKM)' },
        { code: 'NLD', name: 'Nuevo Laredo, MX (NLD)' },
        { code: 'NLF', name: 'Darnley Island, AU (NLF)' },
        { code: 'NLL', name: 'Nullagine, AU (NLL)' },
        { code: 'NLP', name: 'Nelspruit, ZA (NLP)' },
        { code: 'NLS', name: 'Nicholson, AU (NLS)' },
        { code: 'NLV', name: 'Nikolaev, UA (NLV)' },
        { code: 'NMB', name: 'Daman, IN (NMB)' },
        { code: 'NMC', name: "Norman'S Cay, BS (NMC)" },
        { code: 'NMG', name: 'San Miguel, PA (NMG)' },
        { code: 'NMP', name: 'New Moon, AU (NMP)' },
        { code: 'NMR', name: 'Nappa Merrie, AU (NMR)' },
        { code: 'NNG', name: 'Nanning, CN (NNG)' },
        { code: 'NNM', name: 'Naryan-Mar, RU (NNM)' },
        { code: 'NNR', name: 'Spiddal, IE (NNR)' },
        { code: 'NNU', name: 'Nanuque, BR (NNU)' },
        { code: 'NNX', name: 'Nunukan, ID (NNX)' },
        { code: 'NNY', name: 'Nanyang, CN (NNY)' },
        { code: 'NOA', name: 'Nowra, AU (NOA)' },
        { code: 'NOB', name: 'Nosara Beach, CR (NOB)' },
        { code: 'NOC', name: 'Knock, IE (NOC)' },
        { code: 'NOD', name: 'Norden, DE (NOD)' },
        { code: 'NOE', name: 'Norddeich, DE (NOE)' },
        { code: 'NOG', name: 'Nogales, MX (NOG)' },
        { code: 'NOI', name: 'Novorossijsk, RU (NOI)' },
        { code: 'NOJ', name: 'Nojabrxsk, RU (NOJ)' },
        { code: 'NOK', name: 'Nova Xavantina, BR (NOK)' },
        { code: 'NOR', name: 'Nordfjordur, IS (NOR)' },
        { code: 'NOZ', name: 'Novokuznetsk, RU (NOZ)' },
        { code: 'NPE', name: 'Napier-Hastings, NZ (NPE)' },
        { code: 'NPL', name: 'New Plymouth, NZ (NPL)' },
        { code: 'NPO', name: 'Nangapinoh, ID (NPO)' },
        { code: 'NPP', name: 'Napperby, AU (NPP)' },
        { code: 'NPU', name: 'San Pedro Uraba, CO (NPU)' },
        { code: 'NQL', name: 'Niquelandia, BR (NQL)' },
        { code: 'NQN', name: 'Neuquen, AR (NQN)' },
        { code: 'NQT', name: 'Nottingham Airport, GB (NQT)' },
        { code: 'NQU', name: 'Nuqui, CO (NQU)' },
        { code: 'NQY', name: 'Newquay, GB (NQY)' },
        { code: 'NRA', name: 'Narrandera, AU (NRA)' },
        { code: 'NRD', name: 'Norderney, DE (NRD)' },
        { code: 'NRE', name: 'Namrole, ID (NRE)' },
        { code: 'NRG', name: 'Narrogin, AU (NRG)' },
        { code: 'NRK', name: 'Norrkoping, SE (NRK)' },
        { code: 'NRL', name: 'North Ronaldsay, GB (NRL)' },
        { code: 'NRN', name: 'Dusseldorf Niederrhein Airport, DE (NRN)' },
        { code: 'NRR', name: 'Roosevelt Roads, PR (NRR)' },
        { code: 'NRT', name: 'Tokyo Narita Apt, JP (NRT)' },
        { code: 'NRY', name: 'Newry, AU (NRY)' },
        { code: 'NSA', name: 'Noosa, AU (NSA)' },
        { code: 'NSK', name: 'Norilsk, RU (NSK)' },
        { code: 'NSM', name: 'Norseman, AU (NSM)' },
        { code: 'NSN', name: 'Nelson, NZ (NSN)' },
        { code: 'NSO', name: 'Scone, AU (NSO)' },
        { code: 'NSP', name: 'Sangley Point, PH (NSP)' },
        { code: 'NSV', name: 'Noosaville, AU (NSV)' },
        { code: 'NSY', name: 'Sigonella, IT (NSY)' },
        { code: 'NTB', name: 'Notodden, NO (NTB)' },
        { code: 'NTE', name: 'Nantes Atlantique Airport, FR (NTE)' },
        { code: 'NTG', name: 'Nantong, CN (NTG)' },
        { code: 'NTI', name: 'Bintuni, ID (NTI)' },
        { code: 'NTL', name: 'Newcastle Williamtown Apt, AU (NTL)' },
        { code: 'NTM', name: 'Miracema Do Norte, BR (NTM)' },
        { code: 'NTN', name: 'Normanton, AU (NTN)' },
        { code: 'NTQ', name: 'Wajima, JP (NTQ)' },
        { code: 'NTX', name: 'Natuna, ID (NTX)' },
        { code: 'NTY', name: 'Sun City, ZA (NTY)' },
        { code: 'NUB', name: 'Numbulwar, AU (NUB)' },
        { code: 'NUE', name: 'Nuremberg Apt, DE (NUE)' },
        { code: 'NUH', name: 'Nunchia, CO (NUH)' },
        { code: 'NUL', name: 'Nulato, US (NUL)' },
        { code: 'NUR', name: 'Nullarbor, AU (NUR)' },
        { code: 'NUX', name: 'Novy Urengoy, RU (NUX)' },
        { code: 'NVA', name: 'Neiva, CO (NVA)' },
        { code: 'NVG', name: 'Nueva Guinea, NI (NVG)' },
        { code: 'NVK', name: 'Narvik, NO (NVK)' },
        { code: 'NVP', name: 'Novo Aripuana, BR (NVP)' },
        { code: 'NVR', name: 'Novgorod, RU (NVR)' },
        { code: 'NVS', name: 'Nevers, FR (NVS)' },
        { code: 'NVT', name: 'Navegantes, BR (NVT)' },
        { code: 'NVY', name: 'Neyveli, IN (NVY)' },
        { code: 'NWI', name: 'Norwich, GB (NWI)' },
        { code: 'NWP', name: 'Argentia, CA (NWP)' },
        { code: 'NWU', name: 'Bermuda Nas, BM (NWU)' },
        { code: 'NYC', name: 'New York All Airports, US (NYC)' },
        { code: 'NYM', name: 'Nadym, RU (NYM)' },
        { code: 'NYN', name: 'Nyngan, AU (NYN)' },
        { code: 'NYO', name: 'Stockholm Skavsta Airport, SE (NYO)' },
        { code: 'NZH', name: 'Manzhouli, CN (NZH)' },
        { code: 'OAG', name: 'Orange, AU (OAG)' },
        { code: 'OAJ', name: 'Jacksonville, US (OAJ)' },
        { code: 'OAK', name: 'Oakland International Apt, US (OAK)' },
        { code: 'OAL', name: 'Cacoal, BR (OAL)' },
        { code: 'OAM', name: 'Oamaru, NZ (OAM)' },
        { code: 'OAN', name: 'Olanchito, HN (OAN)' },
        { code: 'OAX', name: 'Oaxaca, MX (OAX)' },
        { code: 'OBA', name: 'Oban, AU (OBA)' },
        { code: 'OBD', name: 'Obano, ID (OBD)' },
        { code: 'OBF', name: 'Oberpfaffenhofen, DE (OBF)' },
        { code: 'OBI', name: 'Obidos, BR (OBI)' },
        { code: 'OBL', name: 'Zoersel, BE (OBL)' },
        { code: 'OBN', name: 'Oban Connel Apt, GB (OBN)' },
        { code: 'OBO', name: 'Obihiro, JP (OBO)' },
        { code: 'OBS', name: 'Aubenas, FR (OBS)' },
        { code: 'OCC', name: 'Coca, EC (OCC)' },
        { code: 'OCJ', name: 'Ocho Rios, JM (OCJ)' },
        { code: 'OCV', name: 'Ocana, CO (OCV)' },
        { code: 'ODB', name: 'Cordoba, ES (ODB)' },
        { code: 'ODD', name: 'Oodnadatta, AU (ODD)' },
        { code: 'ODE', name: 'Odense Airport, DK (ODE)' },
        { code: 'ODH', name: 'Odiham, GB (ODH)' },
        { code: 'ODL', name: 'Cordillo Downs, AU (ODL)' },
        { code: 'ODN', name: 'Long Seridan, MY (ODN)' },
        { code: 'ODR', name: 'Ord River, AU (ODR)' },
        { code: 'ODS', name: 'Odessa, UA (ODS)' },
        { code: 'OEL', name: 'Orel, RU (OEL)' },
        { code: 'OER', name: 'Ornskoldsvik, SE (OER)' },
        { code: 'OES', name: 'San Antonio Oeste, AR (OES)' },
        { code: 'OFJ', name: 'Olafsfjordur, IS (OFJ)' },
        { code: 'OGG', name: 'Kahului, US (OGG)' },
        { code: 'OGL', name: 'Ogle, GY (OGL)' },
        { code: 'OGN', name: 'Yonaguni Jima, JP (OGN)' },
        { code: 'OGZ', name: 'Vladikavkaz, RU (OGZ)' },
        { code: 'OHA', name: 'Ohakea, NZ (OHA)' },
        { code: 'OHO', name: 'Okhotsk, RU (OHO)' },
        { code: 'OHR', name: 'Wyk Auf Fohr, DE (OHR)' },
        { code: 'OIA', name: 'Ourilandia, BR (OIA)' },
        { code: 'OIM', name: 'Oshima, JP (OIM)' },
        { code: 'OIR', name: 'Okushiri, JP (OIR)' },
        { code: 'OIT', name: 'Oita, JP (OIT)' },
        { code: 'OKA', name: 'Okinawa Naha Apt., JP (OKA)' },
        { code: 'OKB', name: 'Orchid Beach, AU (OKB)' },
        { code: 'OKC', name: 'Oklahoma City Will Rogers Apt, US (OKC)' },
        { code: 'OKD', name: 'Sapporo Okadama Apt, JP (OKD)' },
        { code: 'OKE', name: 'Okino Erabu, JP (OKE)' },
        { code: 'OKH', name: 'Oakham, GB (OKH)' },
        { code: 'OKI', name: 'Oki Island, JP (OKI)' },
        { code: 'OKJ', name: 'Okayama, JP (OKJ)' },
        { code: 'OKL', name: 'Oksibil, ID (OKL)' },
        { code: 'OKO', name: 'Tokyo Yokota Afb, JP (OKO)' },
        { code: 'OKQ', name: 'Okaba, ID (OKQ)' },
        { code: 'OKR', name: 'Yorke Island, AU (OKR)' },
        { code: 'OKT', name: 'Oktiabrskij, RU (OKT)' },
        { code: 'OKY', name: 'Oakey, AU (OKY)' },
        { code: 'OLA', name: 'Orland, NO (OLA)' },
        { code: 'OLB', name: 'Olbia, IT (OLB)' },
        { code: 'OLI', name: 'Olafsvik, IS (OLI)' },
        { code: 'OLK', name: 'Fuerte Olimpo, PY (OLK)' },
        { code: 'OLN', name: 'Colonia Sarmiento, AR (OLN)' },
        { code: 'OLO', name: 'Olomouc, CZ (OLO)' },
        { code: 'OLP', name: 'Olympic Dam, AU (OLP)' },
        { code: 'OMA', name: 'Omaha Eppley Airfield, US (OMA)' },
        { code: 'OMC', name: 'Ormoc, PH (OMC)' },
        { code: 'OME', name: 'Nome, US (OME)' },
        { code: 'OMJ', name: 'Omura, JP (OMJ)' },
        { code: 'OMM', name: 'Marmul, OM (OMM)' },
        { code: 'OMN', name: 'Osmanabad, IN (OMN)' },
        { code: 'OMO', name: 'Mostar, BA (OMO)' },
        { code: 'OMR', name: 'Oradea, RO (OMR)' },
        { code: 'OMS', name: 'Omsk, RU (OMS)' },
        { code: 'ONG', name: 'Mornington Island, AU (ONG)' },
        { code: 'ONI', name: 'Moanamani, ID (ONI)' },
        { code: 'ONJ', name: 'Odate Noshiro, JP (ONJ)' },
        { code: 'ONQ', name: 'Zonguldak, TR (ONQ)' },
        { code: 'ONR', name: 'Monkira, AU (ONR)' },
        { code: 'ONS', name: 'Onslow, AU (ONS)' },
        { code: 'ONT', name: 'Ontario International Apt, US (ONT)' },
        { code: 'ONX', name: 'Colon, PA (ONX)' },
        { code: 'OOK', name: 'Toksook Bay, US (OOK)' },
        { code: 'OOL', name: 'Gold Coast, AU (OOL)' },
        { code: 'OOM', name: 'Cooma, AU (OOM)' },
        { code: 'OOR', name: 'Mooraberree, AU (OOR)' },
        { code: 'OPA', name: 'Kopasker, IS (OPA)' },
        { code: 'OPI', name: 'Oenpelli, AU (OPI)' },
        { code: 'OPO', name: 'Porto, PT (OPO)' },
        { code: 'OPS', name: 'Sinop, BR (OPS)' },
        { code: 'ORA', name: 'Oran, AR (ORA)' },
        { code: 'ORB', name: 'Orebro, SE (ORB)' },
        { code: 'ORC', name: 'Orocue, CO (ORC)' },
        { code: 'ORD', name: "Chicago O'Hare International Apt, US (ORD)" },
        { code: 'ORE', name: 'Orleans, FR (ORE)' },
        { code: 'ORF', name: 'Norfolk International Apt, US (ORF)' },
        { code: 'ORH', name: 'Worcester, US (ORH)' },
        { code: 'ORI', name: 'Port Lions, US (ORI)' },
        { code: 'ORJ', name: 'Orinduik, GY (ORJ)' },
        { code: 'ORK', name: 'Cork, IE (ORK)' },
        { code: 'ORM', name: 'Northampton, GB (ORM)' },
        { code: 'ORO', name: 'Yoro, HN (ORO)' },
        { code: 'ORP', name: 'Orapa, BW (ORP)' },
        { code: 'ORR', name: 'Yorketown, AU (ORR)' },
        { code: 'ORS', name: 'Orpheus Island Resort, AU (ORS)' },
        { code: 'ORU', name: 'Oruro, BO (ORU)' },
        { code: 'ORX', name: 'Oriximina, BR (ORX)' },
        { code: 'ORY', name: 'Paris Orly Apt, FR (ORY)' },
        { code: 'ORZ', name: 'Orange Walk, BZ (ORZ)' },
        { code: 'OSD', name: 'Ostersund, SE (OSD)' },
        { code: 'OSI', name: 'Osijek, HR (OSI)' },
        { code: 'OSK', name: 'Oskarshamn, SE (OSK)' },
        { code: 'OSL', name: 'Oslo Airport, NO (OSL)' },
        { code: 'OSN', name: 'Osan, KR (OSN)' },
        { code: 'OSP', name: 'Slupsk, PL (OSP)' },
        { code: 'OSR', name: 'Ostrava, CZ (OSR)' },
        { code: 'OST', name: 'Ostend, BE (OST)' },
        { code: 'OSW', name: 'Orsk, RU (OSW)' },
        { code: 'OSY', name: 'Namsos, NO (OSY)' },
        { code: 'OSZ', name: 'Koszalin, PL (OSZ)' },
        { code: 'OTD', name: 'Contadora, PA (OTD)' },
        { code: 'OTH', name: 'North Bend, US (OTH)' },
        { code: 'OTI', name: 'Morotai Island, ID (OTI)' },
        { code: 'OTP', name: 'Bucharest Otopeni Apt, RO (OTP)' },
        { code: 'OTR', name: 'Coto 47, CR (OTR)' },
        { code: 'OTU', name: 'Otu, CO (OTU)' },
        { code: 'OTZ', name: 'Kotzebue, US (OTZ)' },
        { code: 'OUH', name: 'Oudtshoorn, ZA (OUH)' },
        { code: 'OUK', name: 'Outer Skerries, GB (OUK)' },
        { code: 'OUL', name: 'Oulu, FI (OUL)' },
        { code: 'OUS', name: 'Ourinhos, BR (OUS)' },
        { code: 'OVB', name: 'Novosibirsk, RU (OVB)' },
        { code: 'OVD', name: 'Asturias, ES (OVD)' },
        { code: 'OVG', name: 'Overberg, ZA (OVG)' },
        { code: 'OVL', name: 'Ovalle, CL (OVL)' },
        { code: 'OVR', name: 'Olavarria, AR (OVR)' },
        { code: 'OWB', name: 'Owensboro, US (OWB)' },
        { code: 'OXF', name: 'Oxford, GB (OXF)' },
        { code: 'OXO', name: 'Orientos, AU (OXO)' },
        { code: 'OXR', name: 'Oxnard/Ventura, US (OXR)' },
        { code: 'OXY', name: 'Morney, AU (OXY)' },
        { code: 'OYA', name: 'Goya, AR (OYA)' },
        { code: 'OYK', name: 'Oiapoque, BR (OYK)' },
        { code: 'OYN', name: 'Ouyen, AU (OYN)' },
        { code: 'OYO', name: 'Tres Arroyos, AR (OYO)' },
        { code: 'OZC', name: 'Ozamis, PH (OZC)' },
        { code: 'OZH', name: 'Zaporozhye, UA (OZH)' },
        { code: 'OZI', name: 'Bobadilla, ES (OZI)' },
        { code: 'OZP', name: 'Moron, ES (OZP)' },
        { code: 'OZU', name: 'Montilla, ES (OZU)' },
        { code: 'PAB', name: 'Bilaspur, IN (PAB)' },
        { code: 'PAC', name: 'Panama City Paitilla Apt, PA (PAC)' },
        { code: 'PAD', name: 'Paderborn, DE (PAD)' },
        { code: 'PAE', name: 'Everett, US (PAE)' },
        { code: 'PAG', name: 'Pagadian, PH (PAG)' },
        { code: 'PAH', name: 'Paducah, US (PAH)' },
        { code: 'PAL', name: 'Palanquero, CO (PAL)' },
        { code: 'PAP', name: 'Port Au Prince, HT (PAP)' },
        { code: 'PAS', name: 'Paros, GR (PAS)' },
        { code: 'PAT', name: 'Patna, IN (PAT)' },
        { code: 'PAV', name: 'Paulo Afonso, BR (PAV)' },
        { code: 'PAX', name: 'Port De Paix, HT (PAX)' },
        { code: 'PAY', name: 'Pamol, MY (PAY)' },
        { code: 'PAZ', name: 'Poza Rica, MX (PAZ)' },
        { code: 'PBB', name: 'Paranaiba, BR (PBB)' },
        { code: 'PBC', name: 'Puebla, MX (PBC)' },
        { code: 'PBD', name: 'Porbandar, IN (PBD)' },
        { code: 'PBE', name: 'Puerto Berrio, CO (PBE)' },
        { code: 'PBG', name: 'Plattsburgh Afb, US (PBG)' },
        { code: 'PBI', name: 'West Palm Beach, US (PBI)' },
        { code: 'PBL', name: 'Puerto Cabello, VE (PBL)' },
        { code: 'PBO', name: 'Paraburdoo, AU (PBO)' },
        { code: 'PBP', name: 'Punta Islita, CR (PBP)' },
        { code: 'PBQ', name: 'Pimenta Bueno, BR (PBQ)' },
        { code: 'PBR', name: 'Puerto Barrios, GT (PBR)' },
        { code: 'PBT', name: 'Puerto Leda, PY (PBT)' },
        { code: 'PBV', name: 'Portos Dos Gauchos, BR (PBV)' },
        { code: 'PBW', name: 'Palibelo, ID (PBW)' },
        { code: 'PBX', name: 'Porto Alegre Do Norte, BR (PBX)' },
        { code: 'PBY', name: 'Peppers Palm Bay, AU (PBY)' },
        { code: 'PBZ', name: 'Plettenberg Bay, ZA (PBZ)' },
        { code: 'PCB', name: 'Pondok Cabe, ID (PCB)' },
        { code: 'PCC', name: 'Puerto Rico, CO (PCC)' },
        { code: 'PCG', name: 'Paso Caballos, GT (PCG)' },
        { code: 'PCH', name: 'Palacios, HN (PCH)' },
        { code: 'PCJ', name: 'Puerto La Victoria, PY (PCJ)' },
        { code: 'PCL', name: 'Pucallpa, PE (PCL)' },
        { code: 'PCN', name: 'Picton, NZ (PCN)' },
        { code: 'PCR', name: 'Puerto Carreno, CO (PCR)' },
        { code: 'PCS', name: 'Picos, BR (PCS)' },
        { code: 'PCV', name: 'Punta Chivato, MX (PCV)' },
        { code: 'PDA', name: 'Puerto Inirida, CO (PDA)' },
        { code: 'PDE', name: 'Pandie Pandie, AU (PDE)' },
        { code: 'PDF', name: 'Prado, BR (PDF)' },
        { code: 'PDG', name: 'Padang, ID (PDG)' },
        { code: 'PDL', name: 'Ponta Delgada, PT (PDL)' },
        { code: 'PDN', name: 'Parndana, AU (PDN)' },
        { code: 'PDO', name: 'Pendopo, ID (PDO)' },
        { code: 'PDP', name: 'Punta Del Este, UY (PDP)' },
        { code: 'PDR', name: 'Presidente Dutra, BR (PDR)' },
        { code: 'PDT', name: 'Pendleton, US (PDT)' },
        { code: 'PDU', name: 'Paysandu, UY (PDU)' },
        { code: 'PDX', name: 'Portland, US (PDX)' },
        { code: 'PDZ', name: 'Pedernales, VE (PDZ)' },
        { code: 'PEA', name: 'Penneshaw, AU (PEA)' },
        { code: 'PED', name: 'Pardubice, CZ (PED)' },
        { code: 'PEE', name: 'Perm, RU (PEE)' },
        { code: 'PEF', name: 'Peenemuende, DE (PEF)' },
        { code: 'PEG', name: 'Perugia, IT (PEG)' },
        { code: 'PEH', name: 'Pehuajo, AR (PEH)' },
        { code: 'PEI', name: 'Pereira, CO (PEI)' },
        { code: 'PEJ', name: 'Peschiei, IT (PEJ)' },
        { code: 'PEK', name: 'Beijing Capital Apt, CN (PEK)' },
        { code: 'PEM', name: 'Puerto Maldonado, PE (PEM)' },
        { code: 'PEN', name: 'Penang, MY (PEN)' },
        { code: 'PEP', name: 'Peppimenarti, AU (PEP)' },
        { code: 'PER', name: 'Perth, AU (PER)' },
        { code: 'PES', name: 'Petrozavodsk, RU (PES)' },
        { code: 'PET', name: 'Pelotas, BR (PET)' },
        { code: 'PEU', name: 'Puerto Lempira, HN (PEU)' },
        { code: 'PEV', name: 'Pecs, HU (PEV)' },
        { code: 'PEX', name: 'Pechora, RU (PEX)' },
        { code: 'PEY', name: 'Penong, AU (PEY)' },
        { code: 'PEZ', name: 'Penza, RU (PEZ)' },
        { code: 'PFB', name: 'Passo Fundo, BR (PFB)' },
        { code: 'PFJ', name: 'Patreksfjordur, IS (PFJ)' },
        { code: 'PGA', name: 'Page, US (PGA)' },
        { code: 'PGD', name: 'Punta Gorda, US (PGD)' },
        { code: 'PGF', name: 'Perpignan, FR (PGF)' },
        { code: 'PGG', name: 'Progresso, BR (PGG)' },
        { code: 'PGH', name: 'Pantnagar, IN (PGH)' },
        { code: 'PGK', name: 'Pangkalpinang, ID (PGK)' },
        { code: 'PGM', name: 'Port Graham, US (PGM)' },
        { code: 'PGV', name: 'Greenville, US (PGV)' },
        { code: 'PGX', name: 'Perigueux, FR (PGX)' },
        { code: 'PGZ', name: 'Ponta Grossa, BR (PGZ)' },
        { code: 'PHB', name: 'Parnaiba, BR (PHB)' },
        { code: 'PHE', name: 'Port Hedland, AU (PHE)' },
        { code: 'PHF', name: 'Newport News, US (PHF)' },
        { code: 'PHI', name: 'Pinheiro, BR (PHI)' },
        { code: 'PHJ', name: 'Port Hunter, AU (PHJ)' },
        { code: 'PHL', name: 'Philadelphia International Apt, US (PHL)' },
        { code: 'PHM', name: 'Boeblingen, DE (PHM)' },
        { code: 'PHO', name: 'Point Hope, US (PHO)' },
        { code: 'PHW', name: 'Phalaborwa, ZA (PHW)' },
        { code: 'PHX', name: 'Phoenix Sky Harbor Intl Apt., US (PHX)' },
        { code: 'PIA', name: 'Peoria, US (PIA)' },
        {
            code: 'PIB',
            name: 'Laurel Hattiesburg-Laurel Regional Apt, US (PIB)',
        },
        { code: 'PIC', name: 'Pine Cay, TC (PIC)' },
        { code: 'PID', name: 'Nassau Paradise Island, BS (PID)' },
        {
            code: 'PIE',
            name: 'Tampa St Petersbrg-Clearwater Intl Apt, US (PIE)',
        },
        { code: 'PIF', name: 'Pingtung, TW (PIF)' },
        { code: 'PIG', name: 'Pitinga, BR (PIG)' },
        { code: 'PIH', name: 'Pocatello, US (PIH)' },
        { code: 'PIK', name: 'Glasgow Prestwick Apt, GB (PIK)' },
        { code: 'PIL', name: 'Pilar, PY (PIL)' },
        { code: 'PIN', name: 'Parintins, BR (PIN)' },
        { code: 'PIO', name: 'Pisco, PE (PIO)' },
        { code: 'PIQ', name: 'Pipillipai, GY (PIQ)' },
        { code: 'PIR', name: 'Pierre, US (PIR)' },
        { code: 'PIS', name: 'Poitiers Biard Airport, FR (PIS)' },
        { code: 'PIT', name: 'Pittsburgh International Apt, US (PIT)' },
        { code: 'PIU', name: 'Piura, PE (PIU)' },
        { code: 'PIV', name: 'Pirapora, BR (PIV)' },
        { code: 'PIW', name: 'Pikwitonei, CA (PIW)' },
        { code: 'PIX', name: 'Pico Island, PT (PIX)' },
        { code: 'PJA', name: 'Pajala, SE (PJA)' },
        { code: 'PJC', name: 'Pedro Juan Caballero, PY (PJC)' },
        { code: 'PJM', name: 'Puerto Jimenez, CR (PJM)' },
        { code: 'PKB', name: 'Parkersburg/Marietta, US (PKB)' },
        { code: 'PKC', name: 'Petropavlovsk-Kamchats, RU (PKC)' },
        { code: 'PKE', name: 'Parkes, AU (PKE)' },
        { code: 'PKG', name: 'Pangkor, MY (PKG)' },
        { code: 'PKJ', name: 'Playa Grande, GT (PKJ)' },
        { code: 'PKL', name: 'Pakatoa Island, NZ (PKL)' },
        { code: 'PKM', name: 'Port Kaituma, GY (PKM)' },
        { code: 'PKN', name: 'Pangkalanbun, ID (PKN)' },
        { code: 'PKT', name: 'Port Keats, AU (PKT)' },
        { code: 'PKU', name: 'Pekanbaru, ID (PKU)' },
        { code: 'PKV', name: 'Pskov, RU (PKV)' },
        { code: 'PKW', name: 'Selebi-Phikwe, BW (PKW)' },
        { code: 'PKY', name: 'Palangkaraya, ID (PKY)' },
        { code: 'PLA', name: 'Planadas, CO (PLA)' },
        { code: 'PLC', name: 'Planeta Rica, CO (PLC)' },
        { code: 'PLD', name: 'Playa Samara, CR (PLD)' },
        { code: 'PLG', name: 'La Plagne, FR (PLG)' },
        { code: 'PLH', name: 'Plymouth, GB (PLH)' },
        { code: 'PLI', name: 'Palm Island, VC (PLI)' },
        { code: 'PLJ', name: 'Placencia, BZ (PLJ)' },
        { code: 'PLL', name: 'Ponta Pelada, BR (PLL)' },
        { code: 'PLM', name: 'Palembang, ID (PLM)' },
        { code: 'PLN', name: 'Pellston, US (PLN)' },
        { code: 'PLO', name: 'Port Lincoln, AU (PLO)' },
        { code: 'PLP', name: 'La Palma, PA (PLP)' },
        { code: 'PLQ', name: 'Klaipeda/Palanga, LT (PLQ)' },
        { code: 'PLS', name: 'Providenciales, TC (PLS)' },
        { code: 'PLT', name: 'Plato, CO (PLT)' },
        { code: 'PLU', name: 'Belo Horizonte Pampulha Apt, BR (PLU)' },
        { code: 'PLV', name: 'Poltava, UA (PLV)' },
        { code: 'PLW', name: 'Palu, ID (PLW)' },
        { code: 'PLZ', name: 'Port Elizabeth, ZA (PLZ)' },
        { code: 'PMC', name: 'Puerto Montt, CL (PMC)' },
        { code: 'PME', name: 'Portsmouth, GB (PME)' },
        { code: 'PMF', name: 'Milan Parma Apt, IT (PMF)' },
        { code: 'PMG', name: 'Ponta Pora, BR (PMG)' },
        { code: 'PMI', name: 'Palma Mallorca, ES (PMI)' },
        { code: 'PMK', name: 'Palm Island, AU (PMK)' },
        { code: 'PMO', name: 'Palermo, IT (PMO)' },
        { code: 'PMQ', name: 'Perito Moreno, AR (PMQ)' },
        { code: 'PMR', name: 'Palmerston North, NZ (PMR)' },
        { code: 'PMT', name: 'Paramakatoi, GY (PMT)' },
        { code: 'PMV', name: 'Porlamar, VE (PMV)' },
        { code: 'PMW', name: 'Palmas, BR (PMW)' },
        { code: 'PMY', name: 'Puerto Madryn, AR (PMY)' },
        { code: 'PMZ', name: 'Palmar, CR (PMZ)' },
        { code: 'PNA', name: 'Pamplona, ES (PNA)' },
        { code: 'PNB', name: 'Porto Nacional, BR (PNB)' },
        { code: 'PND', name: 'Punta Gorda, BZ (PND)' },
        { code: 'PNG', name: 'Paranagua, BR (PNG)' },
        { code: 'PNJ', name: 'Peng Lai, CN (PNJ)' },
        { code: 'PNK', name: 'Pontianak, ID (PNK)' },
        { code: 'PNL', name: 'Pantelleria, IT (PNL)' },
        { code: 'PNQ', name: 'Pune, IN (PNQ)' },
        { code: 'PNS', name: 'Pensacola Municipal Apt, US (PNS)' },
        { code: 'PNT', name: 'Puerto Natales, CL (PNT)' },
        { code: 'PNV', name: 'Panevezys, LT (PNV)' },
        { code: 'PNY', name: 'Pondicherry, IN (PNY)' },
        { code: 'PNZ', name: 'Petrolina, BR (PNZ)' },
        { code: 'POA', name: 'Porto Alegre, BR (POA)' },
        { code: 'POI', name: 'Potosi, BO (POI)' },
        { code: 'POJ', name: 'Patos De Minas, BR (POJ)' },
        { code: 'PON', name: 'Poptun, GT (PON)' },
        { code: 'POO', name: 'Pocos De Caldas, BR (POO)' },
        { code: 'POP', name: 'Puerto Plata, DO (POP)' },
        { code: 'POR', name: 'Pori, FI (POR)' },
        { code: 'POS', name: 'Port Of Spain, TT (POS)' },
        { code: 'POT', name: 'Port Antonio, JM (POT)' },
        { code: 'POV', name: 'Presov, SK (POV)' },
        { code: 'POW', name: 'Portoroz, SI (POW)' },
        { code: 'POX', name: 'Paris Cergy Pontoise Airport, FR (POX)' },
        { code: 'POZ', name: 'Poznan, PL (POZ)' },
        { code: 'PPB', name: 'President Prudente, BR (PPB)' },
        { code: 'PPD', name: 'Humacao Palmas Del Mar Hotel Apt, PR (PPD)' },
        { code: 'PPE', name: 'Puerto Penasco, MX (PPE)' },
        { code: 'PPH', name: 'Peraitepuy, VE (PPH)' },
        { code: 'PPI', name: 'Port Pirie, AU (PPI)' },
        { code: 'PPJ', name: 'Pulau Panjang, ID (PPJ)' },
        { code: 'PPN', name: 'Popayan, CO (PPN)' },
        { code: 'PPO', name: 'Powell Point, BS (PPO)' },
        { code: 'PPP', name: 'Proserpine, AU (PPP)' },
        { code: 'PPQ', name: 'Paraparaumu, NZ (PPQ)' },
        { code: 'PPR', name: 'Pasir Pangarayan, ID (PPR)' },
        { code: 'PPS', name: 'Puerto Princesa, PH (PPS)' },
        { code: 'PPW', name: 'Papa Westray, GB (PPW)' },
        { code: 'PPY', name: 'Pouso Alegre, BR (PPY)' },
        { code: 'PPZ', name: 'Puerto Paez, VE (PPZ)' },
        { code: 'PQI', name: 'Presque Isle, US (PQI)' },
        { code: 'PQQ', name: 'Port Macquarie, AU (PQQ)' },
        { code: 'PRA', name: 'Parana, AR (PRA)' },
        { code: 'PRC', name: 'Prescott, US (PRC)' },
        { code: 'PRD', name: 'Pardoo, AU (PRD)' },
        { code: 'PRE', name: 'Pore, CO (PRE)' },
        { code: 'PRG', name: 'Prague, CZ (PRG)' },
        { code: 'PRJ', name: 'Capri, IT (PRJ)' },
        { code: 'PRK', name: 'Prieska, ZA (PRK)' },
        { code: 'PRM', name: 'Portimao, PT (PRM)' },
        { code: 'PRP', name: 'Propriano, FR (PRP)' },
        { code: 'PRQ', name: 'President Roque Saenz Pena, AR (PRQ)' },
        { code: 'PRR', name: 'Paruima, GY (PRR)' },
        { code: 'PRV', name: 'Prerov, CZ (PRV)' },
        { code: 'PRY', name: 'Pretoria Wonderboom Apt, ZA (PRY)' },
        { code: 'PSA', name: 'Pisa, IT (PSA)' },
        { code: 'PSC', name: 'Pasco, US (PSC)' },
        { code: 'PSD', name: 'Port Said, EG (PSD)' },
        { code: 'PSE', name: 'Ponce, PR (PSE)' },
        { code: 'PSG', name: 'Petersburg, US (PSG)' },
        { code: 'PSH', name: 'St Peter-Ording, DE (PSH)' },
        { code: 'PSJ', name: 'Poso, ID (PSJ)' },
        { code: 'PSL', name: 'Perth, GB (PSL)' },
        {
            code: 'PSM',
            name: 'Portsmouth Pease International Airport, US (PSM)',
        },
        { code: 'PSO', name: 'Pasto, CO (PSO)' },
        { code: 'PSP', name: 'Palm Springs Municipal Apt, US (PSP)' },
        { code: 'PSR', name: 'Pescara, IT (PSR)' },
        { code: 'PSS', name: 'Posadas, AR (PSS)' },
        { code: 'PST', name: 'Preston, CU (PST)' },
        { code: 'PSU', name: 'Putussibau, ID (PSU)' },
        { code: 'PSV', name: 'Papa Stour, GB (PSV)' },
        { code: 'PSW', name: 'Passos, BR (PSW)' },
        { code: 'PSZ', name: 'Puerto Suarez, BO (PSZ)' },
        { code: 'PTE', name: 'Port Stephens, AU (PTE)' },
        { code: 'PTG', name: 'Polokwane, ZA (PTG)' },
        { code: 'PTI', name: 'Port Douglas, AU (PTI)' },
        { code: 'PTJ', name: 'Portland, AU (PTJ)' },
        { code: 'PTM', name: 'Palmarito, VE (PTM)' },
        { code: 'PTO', name: 'Pato Branco, BR (PTO)' },
        { code: 'PTP', name: 'Pointe-A-Pitre, GP (PTP)' },
        { code: 'PTQ', name: 'Porto De Moz, BR (PTQ)' },
        { code: 'PTX', name: 'Pitalito, CO (PTX)' },
        { code: 'PTY', name: 'Panama City Tocumen Internationl, PA (PTY)' },
        { code: 'PTZ', name: 'Pastaza, EC (PTZ)' },
        { code: 'PUB', name: 'Pueblo, US (PUB)' },
        { code: 'PUD', name: 'Puerto Deseado, AR (PUD)' },
        { code: 'PUE', name: 'Puerto Obaldia, PA (PUE)' },
        { code: 'PUF', name: 'Pau, FR (PUF)' },
        { code: 'PUG', name: 'Port Augusta, AU (PUG)' },
        { code: 'PUJ', name: 'Punta Cana, DO (PUJ)' },
        { code: 'PUM', name: 'Pomala, ID (PUM)' },
        { code: 'PUQ', name: 'Punta Arenas, CL (PUQ)' },
        { code: 'PUS', name: 'Busan, KR (PUS)' },
        { code: 'PUT', name: 'Puttaparthi, IN (PUT)' },
        { code: 'PUU', name: 'Puerto Asis, CO (PUU)' },
        { code: 'PUW', name: 'Pullman, US (PUW)' },
        { code: 'PUX', name: 'Puerto Varas, CL (PUX)' },
        { code: 'PUY', name: 'Pula, HR (PUY)' },
        { code: 'PUZ', name: 'Puerto Cabezas, NI (PUZ)' },
        { code: 'PVA', name: 'Providencia, CO (PVA)' },
        { code: 'PVC', name: 'Provincetown, US (PVC)' },
        { code: 'PVD', name: 'Providence, US (PVD)' },
        { code: 'PVE', name: 'El Porvenir, PA (PVE)' },
        { code: 'PVG', name: 'Shanghai Pu Dong Apt, CN (PVG)' },
        { code: 'PVH', name: 'Porto Velho, BR (PVH)' },
        { code: 'PVI', name: 'Paranavai, BR (PVI)' },
        { code: 'PVK', name: 'Preveza/Lefkas, GR (PVK)' },
        { code: 'PVO', name: 'Portoviejo, EC (PVO)' },
        { code: 'PVR', name: 'Puerto Vallarta, MX (PVR)' },
        { code: 'PVS', name: 'Provideniya, RU (PVS)' },
        { code: 'PVU', name: 'Provo, US (PVU)' },
        { code: 'PVX', name: 'Provedenia, RU (PVX)' },
        { code: 'PWE', name: 'Pevek, RU (PWE)' },
        { code: 'PWL', name: 'Purwokerto, ID (PWL)' },
        { code: 'PWM', name: 'Portland, US (PWM)' },
        { code: 'PWN', name: 'Pitts Town, BS (PWN)' },
        { code: 'PXM', name: 'Puerto Escondido, MX (PXM)' },
        { code: 'PXO', name: 'Porto Santo, PT (PXO)' },
        { code: 'PXS', name: 'Puerto De Santa Maria, ES (PXS)' },
        { code: 'PYA', name: 'Puerto Boyaca, CO (PYA)' },
        { code: 'PYB', name: 'Jeypore, IN (PYB)' },
        { code: 'PYC', name: 'Playon Chico, PA (PYC)' },
        { code: 'PYH', name: 'Puerto Ayacucho, VE (PYH)' },
        { code: 'PYJ', name: 'Polyarnyj, RU (PYJ)' },
        { code: 'PYN', name: 'Payan, CO (PYN)' },
        { code: 'PYO', name: 'Putumayo, EC (PYO)' },
        { code: 'PYR', name: 'Pyrgos, GR (PYR)' },
        { code: 'PYV', name: 'Yaviza, PA (PYV)' },
        { code: 'PZA', name: 'Paz De Ariporo, CO (PZA)' },
        { code: 'PZB', name: 'Pietermaritzburg, ZA (PZB)' },
        { code: 'PZE', name: 'Penzance, GB (PZE)' },
        { code: 'PZI', name: 'Pan Zhi Hua, CN (PZI)' },
        { code: 'PZL', name: 'Phinda Game Reserve, ZA (PZL)' },
        { code: 'PZO', name: 'Puerto Ordaz, VE (PZO)' },
        { code: 'PZY', name: 'Piestany, SK (PZY)' },
        { code: 'QBC', name: 'Bella Coola, CA (QBC)' },
        { code: 'QDF', name: 'Dallas - All Airports, US (QDF)' },
        { code: 'QHU', name: 'Husum, DE (QHU)' },
        { code: 'QLX', name: 'Lauterach, AT (QLX)' },
        { code: 'QMM', name: 'Marina Di Massa, IT (QMM)' },
        { code: 'QPG', name: 'Singapore Paya Lebar Airport, SG (QPG)' },
        { code: 'QRA', name: 'Johannesburg Randgermiston, ZA (QRA)' },
        { code: 'QRO', name: 'Queretaro, MX (QRO)' },
        { code: 'RAD', name: 'Tortola Road Town Apt, VG (RAD)' },
        { code: 'RAE', name: 'Arar, SA (RAE)' },
        { code: 'RAF', name: 'Ras An Naqb, EG (RAF)' },
        { code: 'RAG', name: 'Raglan, NZ (RAG)' },
        { code: 'RAH', name: 'Rafha, SA (RAH)' },
        { code: 'RAJ', name: 'Rajkot, IN (RAJ)' },
        { code: 'RAM', name: 'Ramingining, AU (RAM)' },
        { code: 'RAN', name: 'Ravenna, IT (RAN)' },
        { code: 'RAO', name: 'Ribeirao Preto, BR (RAO)' },
        { code: 'RAP', name: 'Rapid City Regional Apt, US (RAP)' },
        { code: 'RAQ', name: 'Raha, ID (RAQ)' },
        { code: 'RAT', name: 'Raduzhnyi, RU (RAT)' },
        { code: 'RAU', name: 'Rangpur, BD (RAU)' },
        { code: 'RAV', name: 'Cravo Norte, CO (RAV)' },
        { code: 'RAY', name: 'Rothesay, GB (RAY)' },
        { code: 'RBB', name: 'Borba, BR (RBB)' },
        { code: 'RBC', name: 'Robinvale, AU (RBC)' },
        { code: 'RBJ', name: 'Rebun, JP (RBJ)' },
        { code: 'RBM', name: 'Straubing, DE (RBM)' },
        { code: 'RBO', name: 'Robore, BO (RBO)' },
        { code: 'RBQ', name: 'Rurrenabaque, BO (RBQ)' },
        { code: 'RBR', name: 'Rio Branco, BR (RBR)' },
        { code: 'RBS', name: 'Orbost, AU (RBS)' },
        { code: 'RBU', name: 'Roebourne, AU (RBU)' },
        { code: 'RCB', name: 'Richards Bay, ZA (RCB)' },
        { code: 'RCH', name: 'Riohacha, CO (RCH)' },
        { code: 'RCM', name: 'Richmond, AU (RCM)' },
        { code: 'RCN', name: 'American River, AU (RCN)' },
        { code: 'RCO', name: 'Rochefort, FR (RCO)' },
        { code: 'RCQ', name: 'Reconquista, AR (RCQ)' },
        { code: 'RCS', name: 'Rochester, GB (RCS)' },
        { code: 'RCU', name: 'Rio Cuarto, AR (RCU)' },
        { code: 'RCY', name: 'Rum Cay, BS (RCY)' },
        { code: 'RDA', name: 'Rockhampton Downs, AU (RDA)' },
        { code: 'RDC', name: 'Redencao, BR (RDC)' },
        { code: 'RDD', name: 'Redding, US (RDD)' },
        { code: 'RDE', name: 'Merdey, ID (RDE)' },
        { code: 'RDM', name: 'Redmond, US (RDM)' },
        { code: 'RDN', name: 'Redang, MY (RDN)' },
        { code: 'RDS', name: 'Rincon De Los Sauces, AR (RDS)' },
        { code: 'RDU', name: 'Raleigh/Durham, US (RDU)' },
        { code: 'RDZ', name: 'Rodez, FR (RDZ)' },
        { code: 'REB', name: 'Rechlin, DE (REB)' },
        { code: 'REC', name: 'Recife, BR (REC)' },
        { code: 'REG', name: 'Reggio Calabria, IT (REG)' },
        { code: 'REL', name: 'Trelew, AR (REL)' },
        { code: 'REN', name: 'Orenburg, RU (REN)' },
        { code: 'RER', name: 'Retalhuleu, GT (RER)' },
        { code: 'RES', name: 'Resistencia, AR (RES)' },
        { code: 'RET', name: 'Rost, NO (RET)' },
        { code: 'REU', name: 'Reus, ES (REU)' },
        { code: 'REW', name: 'Rewa, IN (REW)' },
        { code: 'REX', name: 'Reynosa, MX (REX)' },
        { code: 'REY', name: 'Reyes, BO (REY)' },
        { code: 'REZ', name: 'Resende, BR (REZ)' },
        { code: 'RFD', name: 'Chicago Rockford Airport, US (RFD)' },
        { code: 'RFN', name: 'Raufarhofn, IS (RFN)' },
        { code: 'RFR', name: 'Rio Frio, CR (RFR)' },
        { code: 'RFS', name: 'Rosita, NI (RFS)' },
        { code: 'RGA', name: 'Rio Grande, AR (RGA)' },
        { code: 'RGH', name: 'Balurghat, IN (RGH)' },
        { code: 'RGL', name: 'Rio Gallegos, AR (RGL)' },
        { code: 'RGS', name: 'Burgos, ES (RGS)' },
        { code: 'RGT', name: 'Rengat, ID (RGT)' },
        { code: 'RHA', name: 'Reykholar, IS (RHA)' },
        { code: 'RHD', name: 'Rio Hondo, AR (RHD)' },
        { code: 'RHE', name: 'Reims, FR (RHE)' },
        { code: 'RHI', name: 'Rhinelander, US (RHI)' },
        { code: 'RHL', name: 'Roy Hill, AU (RHL)' },
        { code: 'RHO', name: 'Rhodes, GR (RHO)' },
        { code: 'RIA', name: 'Santa Maria, BR (RIA)' },
        { code: 'RIB', name: 'Riberalta, BO (RIB)' },
        { code: 'RIC', name: 'Richmond, US (RIC)' },
        { code: 'RIG', name: 'Rio Grande, BR (RIG)' },
        { code: 'RIJ', name: 'Rioja, PE (RIJ)' },
        { code: 'RIK', name: 'Carrillo, CR (RIK)' },
        { code: 'RIM', name: 'Rodriguez De Mendoza, PE (RIM)' },
        { code: 'RIS', name: 'Rishiri, JP (RIS)' },
        { code: 'RIT', name: 'Rio Tigre, PA (RIT)' },
        { code: 'RIW', name: 'Riverton, US (RIW)' },
        { code: 'RIX', name: 'Riga, LV (RIX)' },
        { code: 'RIZ', name: 'Rio Alzucar, PA (RIZ)' },
        { code: 'RJA', name: 'Rajahmundry, IN (RJA)' },
        { code: 'RJH', name: 'Rajshahi, BD (RJH)' },
        { code: 'RJI', name: 'Rajouri, IN (RJI)' },
        { code: 'RJK', name: 'Rijeka, HR (RJK)' },
        { code: 'RJL', name: 'Logrono, ES (RJL)' },
        { code: 'RKD', name: 'Rockland, US (RKD)' },
        { code: 'RKE', name: 'Copenhagen Roskilde Apt, DK (RKE)' },
        { code: 'RKI', name: 'Rokot, ID (RKI)' },
        { code: 'RKO', name: 'Sipora, ID (RKO)' },
        { code: 'RKS', name: 'Rock Springs, US (RKS)' },
        { code: 'RKT', name: 'Ras Al Khaimah, AE (RKT)' },
        { code: 'RKV', name: 'Reykjavik Apt, IS (RKV)' },
        { code: 'RKY', name: 'Rokeby, AU (RKY)' },
        { code: 'RLG', name: 'Rostock-Laage, DE (RLG)' },
        { code: 'RLO', name: 'Merlo, AR (RLO)' },
        { code: 'RLP', name: 'Rosella Plains, AU (RLP)' },
        { code: 'RMA', name: 'Roma, AU (RMA)' },
        { code: 'RMB', name: 'Buraimi, OM (RMB)' },
        { code: 'RMD', name: 'Ramagundam, IN (RMD)' },
        { code: 'RMF', name: 'Marsa Alam, EG (RMF)' },
        { code: 'RMI', name: 'Rimini, IT (RMI)' },
        { code: 'RMK', name: 'Renmark, AU (RMK)' },
        { code: 'RMQ', name: 'Taichung, TW (RMQ)' },
        { code: 'RMS', name: 'Ramstein, DE (RMS)' },
        { code: 'RNB', name: 'Ronneby, SE (RNB)' },
        { code: 'RNE', name: 'Roanne, FR (RNE)' },
        { code: 'RNI', name: 'Corn Island, NI (RNI)' },
        { code: 'RNJ', name: 'Yoronjima, JP (RNJ)' },
        { code: 'RNN', name: 'Bornholm, DK (RNN)' },
        { code: 'RNO', name: 'Reno, US (RNO)' },
        { code: 'RNS', name: 'Rennes St Jacques Airport, FR (RNS)' },
        { code: 'RNU', name: 'Ranau, MY (RNU)' },
        { code: 'ROA', name: 'Roanoke, US (ROA)' },
        { code: 'ROC', name: 'Rochester, US (ROC)' },
        { code: 'ROD', name: 'Robertson, ZA (ROD)' },
        { code: 'ROH', name: 'Robinhood, AU (ROH)' },
        { code: 'ROK', name: 'Rockhampton, AU (ROK)' },
        { code: 'RON', name: 'Rondon, CO (RON)' },
        { code: 'ROO', name: 'Rondonopolis, BR (ROO)' },
        { code: 'ROS', name: 'Rosario, AR (ROS)' },
        { code: 'ROT', name: 'Rotorua, NZ (ROT)' },
        { code: 'ROV', name: 'Rostov, RU (ROV)' },
        { code: 'ROW', name: 'Roswell, US (ROW)' },
        { code: 'ROY', name: 'Rio Mayo, AR (ROY)' },
        { code: 'RPB', name: 'Roper Bar, AU (RPB)' },
        { code: 'RPM', name: 'Ngukurr, AU (RPM)' },
        { code: 'RPR', name: 'Raipur, IN (RPR)' },
        { code: 'RPV', name: 'Roper Valley, AU (RPV)' },
        { code: 'RRA', name: 'Ronda, ES (RRA)' },
        { code: 'RRE', name: 'Marree, AU (RRE)' },
        { code: 'RRK', name: 'Rourkela, IN (RRK)' },
        { code: 'RRN', name: 'Serra Norte, BR (RRN)' },
        { code: 'RRO', name: 'Sorrento, IT (RRO)' },
        { code: 'RRS', name: 'Roros, NO (RRS)' },
        { code: 'RRV', name: 'Robinson River, AU (RRV)' },
        { code: 'RSA', name: 'Santa Rosa, AR (RSA)' },
        { code: 'RSB', name: 'Roseberth, AU (RSB)' },
        { code: 'RSD', name: 'Rock Sound, BS (RSD)' },
        { code: 'RSE', name: 'Sydney Au-Rose Bay Apt, AU (RSE)' },
        { code: 'RSG', name: 'Serra Pelada, BR (RSG)' },
        { code: 'RSH', name: 'Russian Mission, US (RSH)' },
        { code: 'RSI', name: 'Rio Sidra, PA (RSI)' },
        { code: 'RSK', name: 'Ransiki, ID (RSK)' },
        { code: 'RST', name: 'Rochester Municipal Apt, US (RST)' },
        { code: 'RSU', name: 'Yeosu, KR (RSU)' },
        {
            code: 'RSW',
            name: 'Fort Myers Sw Florida International Apt, US (RSW)',
        },
        { code: 'RTB', name: 'Roatan, HN (RTB)' },
        { code: 'RTC', name: 'Ratnagiri, IN (RTC)' },
        { code: 'RTG', name: 'Ruteng, ID (RTG)' },
        { code: 'RTI', name: 'Roti, ID (RTI)' },
        { code: 'RTM', name: 'Rotterdam Apt, NL (RTM)' },
        { code: 'RTP', name: 'Rutland Plains, AU (RTP)' },
        { code: 'RTS', name: 'Rottnest Island, AU (RTS)' },
        { code: 'RTW', name: 'Saratov, RU (RTW)' },
        { code: 'RTY', name: 'Merty, AU (RTY)' },
        { code: 'RUF', name: 'Yuruf, ID (RUF)' },
        { code: 'RUG', name: 'Rugao, CN (RUG)' },
        { code: 'RUH', name: 'Riyadh, SA (RUH)' },
        { code: 'RUP', name: 'Rupsi, IN (RUP)' },
        { code: 'RUT', name: 'Rutland, US (RUT)' },
        { code: 'RUV', name: 'Rubelsanto, GT (RUV)' },
        { code: 'RUY', name: 'Copan, HN (RUY)' },
        { code: 'RVD', name: 'Rio Verde, BR (RVD)' },
        { code: 'RVE', name: 'Saravena, CO (RVE)' },
        { code: 'RVH', name: 'St Petersburg Rzhevka, RU (RVH)' },
        { code: 'RVK', name: 'Roervik, NO (RVK)' },
        { code: 'RVN', name: 'Rovaniemi, FI (RVN)' },
        { code: 'RVO', name: 'Reivilo, ZA (RVO)' },
        { code: 'RVT', name: 'Ravensthorpe, AU (RVT)' },
        { code: 'RVY', name: 'Rivera, UY (RVY)' },
        { code: 'RWN', name: 'Rovno, UA (RWN)' },
        { code: 'RWS', name: 'Sumare, BR (RWS)' },
        { code: 'RXS', name: 'Roxas, PH (RXS)' },
        { code: 'RYB', name: 'Rybinsk, RU (RYB)' },
        { code: 'RYN', name: 'Royan, FR (RYN)' },
        { code: 'RYO', name: 'Rio Turbio, AR (RYO)' },
        { code: 'RZA', name: 'Santa Cruz, AR (RZA)' },
        { code: 'RZE', name: 'Rzeszow, PL (RZE)' },
        { code: 'RZN', name: 'Ryazan, RU (RZN)' },
        { code: 'RZP', name: 'Taytay Sandoval, PH (RZP)' },
        { code: 'SAE', name: 'Sangir, ID (SAE)' },
        { code: 'SAF', name: 'Santa Fe, US (SAF)' },
        { code: 'SAJ', name: 'Sirajganj, BD (SAJ)' },
        { code: 'SAK', name: 'Saudarkrokur, IS (SAK)' },
        { code: 'SAN', name: 'San Diego International, US (SAN)' },
        { code: 'SAP', name: 'San Pedro Sula, HN (SAP)' },
        { code: 'SAQ', name: 'San Andros, BS (SAQ)' },
        { code: 'SAT', name: 'San Antonio International Apt, US (SAT)' },
        { code: 'SAU', name: 'Sawu, ID (SAU)' },
        {
            code: 'SAV',
            name: 'Savannah/Hilton Head International Apt, US (SAV)',
        },
        { code: 'SAW', name: 'Istanbul Sabiha Gokcen Apt, TR (SAW)' },
        { code: 'SAX', name: 'Sambu, PA (SAX)' },
        { code: 'SAY', name: 'Siena, IT (SAY)' },
        { code: 'SBA', name: 'Santa Barbara Municipal Apt, US (SBA)' },
        { code: 'SBB', name: 'Santa Barbara Barinas, VE (SBB)' },
        { code: 'SBG', name: 'Sabang, ID (SBG)' },
        { code: 'SBH', name: 'St Barthelemy, GP (SBH)' },
        { code: 'SBJ', name: 'Sao Mateus, BR (SBJ)' },
        { code: 'SBK', name: 'St Brieuc, FR (SBK)' },
        { code: 'SBL', name: 'Santa Ana, BO (SBL)' },
        { code: 'SBN', name: 'South Bend, US (SBN)' },
        { code: 'SBP', name: 'San Luis Obispo County Apt, US (SBP)' },
        { code: 'SBR', name: 'Saibai Island, AU (SBR)' },
        { code: 'SBU', name: 'Springbok, ZA (SBU)' },
        { code: 'SBW', name: 'Sibu, MY (SBW)' },
        { code: 'SBY', name: 'Salisbury-Ocean City, US (SBY)' },
        { code: 'SBZ', name: 'Sibiu, RO (SBZ)' },
        { code: 'SCA', name: 'Santa Catalina, CO (SCA)' },
        { code: 'SCC', name: 'Prudhoe Bay/Deadhorse, US (SCC)' },
        { code: 'SCD', name: 'Sulaco, HN (SCD)' },
        { code: 'SCG', name: 'Spring Creek, AU (SCG)' },
        { code: 'SCI', name: 'San Cristobal, VE (SCI)' },
        { code: 'SCK', name: 'Sacramento Stockton Airport, US (SCK)' },
        { code: 'SCL', name: 'Santiago Arturo Merino Benitez, CL (SCL)' },
        { code: 'SCM', name: 'Scammon Bay, US (SCM)' },
        { code: 'SCN', name: 'Saarbrucken Ensheim Airport, DE (SCN)' },
        { code: 'SCP', name: 'St Crepin, FR (SCP)' },
        { code: 'SCQ', name: 'Santiago De Compostela, ES (SCQ)' },
        { code: 'SCS', name: 'Shetland Islands Scatsta Apt, GB (SCS)' },
        { code: 'SCU', name: 'Santiago, CU (SCU)' },
        { code: 'SCV', name: 'Suceava, RO (SCV)' },
        { code: 'SCW', name: 'Syktyvkar, RU (SCW)' },
        { code: 'SCX', name: 'Salina Cruz, MX (SCX)' },
        { code: 'SCY', name: 'San Cristobal, EC (SCY)' },
        { code: 'SDB', name: 'Saldanha Bay, ZA (SDB)' },
        { code: 'SDC', name: 'Sandcreek, GY (SDC)' },
        { code: 'SDE', name: 'Santiago Del Estero, AR (SDE)' },
        { code: 'SDF', name: 'Louisville International, US (SDF)' },
        { code: 'SDH', name: 'Santa Rosa De Copan, HN (SDH)' },
        { code: 'SDJ', name: 'Sendai, JP (SDJ)' },
        { code: 'SDK', name: 'Sandakan, MY (SDK)' },
        { code: 'SDL', name: 'Sundsvall, SE (SDL)' },
        { code: 'SDN', name: 'Sandane, NO (SDN)' },
        { code: 'SDO', name: 'Ryotsu Sado Island, JP (SDO)' },
        { code: 'SDP', name: 'Sand Point, US (SDP)' },
        { code: 'SDQ', name: 'Santo Domingo, DO (SDQ)' },
        { code: 'SDR', name: 'Santander, ES (SDR)' },
        { code: 'SDS', name: 'Sado Shima, JP (SDS)' },
        { code: 'SDU', name: 'Rio De Janeiro Santos Dumont Apt, BR (SDU)' },
        { code: 'SDW', name: 'Sandwip, BD (SDW)' },
        { code: 'SDY', name: 'Sidney, US (SDY)' },
        { code: 'SEA', name: 'Seattle/Tacoma International Apt, US (SEA)' },
        { code: 'SEC', name: 'Serre Chevalier, FR (SEC)' },
        { code: 'SEH', name: 'Senggeh, ID (SEH)' },
        { code: 'SEI', name: 'Senhor Do Bonfim, BR (SEI)' },
        { code: 'SEJ', name: 'Seydisfjordur, IS (SEJ)' },
        { code: 'SEN', name: 'Southend, GB (SEN)' },
        { code: 'SEQ', name: 'Sungai Pakning, ID (SEQ)' },
        { code: 'SET', name: 'San Esteban, HN (SET)' },
        { code: 'SEV', name: 'Severodoneck, UA (SEV)' },
        { code: 'SEW', name: 'Siwa, EG (SEW)' },
        { code: 'SEX', name: 'Sembach, DE (SEX)' },
        {
            code: 'SFB',
            name: 'Orlando Sanford International Airport, US (SFB)',
        },
        { code: 'SFC', name: 'St Francois, GP (SFC)' },
        { code: 'SFD', name: 'San Fernando De Apure, VE (SFD)' },
        { code: 'SFE', name: 'San Fernando, PH (SFE)' },
        { code: 'SFG', name: 'St Martin Esperance Apt, GP (SFG)' },
        { code: 'SFH', name: 'San Felipe, MX (SFH)' },
        { code: 'SFK', name: 'Soure, BR (SFK)' },
        { code: 'SFN', name: 'Santa Fe, AR (SFN)' },
        { code: 'SFO', name: 'San Francisco International Apt, US (SFO)' },
        { code: 'SFP', name: 'Surfers Paradise, AU (SFP)' },
        { code: 'SFQ', name: 'Sanliurfa, TR (SFQ)' },
        { code: 'SFS', name: 'Subic Bay, PH (SFS)' },
        { code: 'SFT', name: 'Skelleftea, SE (SFT)' },
        { code: 'SFV', name: 'Santa Fe Do Sul, BR (SFV)' },
        { code: 'SFW', name: 'Santa Fe, PA (SFW)' },
        { code: 'SFX', name: 'San Felix, VE (SFX)' },
        { code: 'SGC', name: 'Surgut, RU (SGC)' },
        { code: 'SGD', name: 'Sonderborg Airport, DK (SGD)' },
        { code: 'SGE', name: 'Siegen, DE (SGE)' },
        { code: 'SGF', name: 'Springfield, US (SGF)' },
        { code: 'SGG', name: 'Simanggang, MY (SGG)' },
        { code: 'SGL', name: 'Manila Sangley Nas, PH (SGL)' },
        { code: 'SGM', name: 'San Ignacio, MX (SGM)' },
        { code: 'SGO', name: 'St George, AU (SGO)' },
        { code: 'SGP', name: 'Shay Gap, AU (SGP)' },
        { code: 'SGQ', name: 'Sanggata, ID (SGQ)' },
        { code: 'SGS', name: 'Sanga Sanga, PH (SGS)' },
        { code: 'SGU', name: 'St George, US (SGU)' },
        { code: 'SGV', name: 'Sierra Grande, AR (SGV)' },
        { code: 'SGY', name: 'Skagway, US (SGY)' },
        { code: 'SHA', name: 'Shanghai Hongqiao Apt, CN (SHA)' },
        { code: 'SHB', name: 'Nakashibetsu, JP (SHB)' },
        { code: 'SHD', name: 'Staunton, US (SHD)' },
        { code: 'SHE', name: 'Shenyang, CN (SHE)' },
        { code: 'SHF', name: 'Shanhaiguan, CN (SHF)' },
        { code: 'SHH', name: 'Shishmaref, US (SHH)' },
        { code: 'SHI', name: 'Shimojishima, JP (SHI)' },
        { code: 'SHJ', name: 'Sharjah, AE (SHJ)' },
        { code: 'SHL', name: 'Shillong, IN (SHL)' },
        { code: 'SHM', name: 'Nanki Shirahama, JP (SHM)' },
        { code: 'SHO', name: 'Sokcho, KR (SHO)' },
        { code: 'SHP', name: 'Qinhuangdao, CN (SHP)' },
        { code: 'SHQ', name: 'Southport, AU (SHQ)' },
        { code: 'SHR', name: 'Sheridan, US (SHR)' },
        { code: 'SHS', name: 'Shashi, CN (SHS)' },
        { code: 'SHT', name: 'Shepparton, AU (SHT)' },
        { code: 'SHU', name: 'Smith Point, AU (SHU)' },
        { code: 'SHV', name: 'Shreveport Regional Apt, US (SHV)' },
        { code: 'SHW', name: 'Sharurah, SA (SHW)' },
        { code: 'SIA', name: 'Xi An Xiguan Military Airport, CN (SIA)' },
        { code: 'SIC', name: 'Sinop, TR (SIC)' },
        { code: 'SIE', name: 'Sines, PT (SIE)' },
        { code: 'SIG', name: 'San Juan Isla Grande Apt, PR (SIG)' },
        { code: 'SIJ', name: 'Siglufjordur, IS (SIJ)' },
        { code: 'SIN', name: 'Singapore Changi Apt, SG (SIN)' },
        { code: 'SIO', name: 'Smithton, AU (SIO)' },
        { code: 'SIP', name: 'Simferopol, UA (SIP)' },
        { code: 'SIQ', name: 'Singkep, ID (SIQ)' },
        { code: 'SIR', name: 'Sion, CH (SIR)' },
        { code: 'SIS', name: 'Sishen, ZA (SIS)' },
        { code: 'SIT', name: 'Sitka, US (SIT)' },
        { code: 'SIU', name: 'Siuna, NI (SIU)' },
        { code: 'SIW', name: 'Sibisa, ID (SIW)' },
        { code: 'SIX', name: 'Singleton, AU (SIX)' },
        { code: 'SJA', name: 'San Juan, PE (SJA)' },
        { code: 'SJB', name: 'San Joaquin, BO (SJB)' },
        { code: 'SJC', name: 'San Jose International Apt, US (SJC)' },
        { code: 'SJD', name: 'San Jose Cabo, MX (SJD)' },
        { code: 'SJE', name: 'San Jose Del Guaviare, CO (SJE)' },
        { code: 'SJF', name: 'St John Island, VI (SJF)' },
        { code: 'SJG', name: 'San Pedro Jagua, CO (SJG)' },
        { code: 'SJH', name: 'San Juan Del Cesar, CO (SJH)' },
        { code: 'SJI', name: 'San Jose, PH (SJI)' },
        { code: 'SJJ', name: 'Sarajevo, BA (SJJ)' },
        { code: 'SJK', name: 'Sao Jose Dos Campos, BR (SJK)' },
        { code: 'SJL', name: 'Sao Gabriel, BR (SJL)' },
        { code: 'SJM', name: 'San Juan De La Maguana, DO (SJM)' },
        { code: 'SJO', name: 'San Jose Juan Santamaria Apt, CR (SJO)' },
        { code: 'SJP', name: 'Sao Jose Do Rio Preto, BR (SJP)' },
        { code: 'SJR', name: 'San Juan De Uraba, CO (SJR)' },
        { code: 'SJS', name: 'San Jose, BO (SJS)' },
        { code: 'SJT', name: 'San Angelo Mathis Field, US (SJT)' },
        { code: 'SJU', name: 'San Juan Luis Munoz Marin Intl Apt, PR (SJU)' },
        { code: 'SJV', name: 'San Javier, BO (SJV)' },
        { code: 'SJW', name: 'Shijiazhuang, CN (SJW)' },
        { code: 'SJX', name: 'Sartaneja, BZ (SJX)' },
        { code: 'SJY', name: 'Seinajoki, FI (SJY)' },
        { code: 'SJZ', name: 'Sao Jorge Island, PT (SJZ)' },
        { code: 'SKB', name: 'St Kitts, KN (SKB)' },
        { code: 'SKE', name: 'Skien, NO (SKE)' },
        { code: 'SKG', name: 'Thessaloniki, GR (SKG)' },
        { code: 'SKL', name: 'Isle Of Skye, GB (SKL)' },
        { code: 'SKM', name: 'Skeldon, GY (SKM)' },
        { code: 'SKN', name: 'Stokmarknes, NO (SKN)' },
        { code: 'SKS', name: 'Vojens Airport, DK (SKS)' },
        { code: 'SKU', name: 'Skiros, GR (SKU)' },
        { code: 'SKV', name: 'Santa Katarina, EG (SKV)' },
        { code: 'SKX', name: 'Saransk, RU (SKX)' },
        { code: 'SLA', name: 'Salta, AR (SLA)' },
        { code: 'SLC', name: 'Salt Lake City, US (SLC)' },
        { code: 'SLD', name: 'Sliac, SK (SLD)' },
        { code: 'SLF', name: 'Sulayel, SA (SLF)' },
        { code: 'SLK', name: 'Saranac Lake, US (SLK)' },
        { code: 'SLL', name: 'Salalah, OM (SLL)' },
        { code: 'SLM', name: 'Salamanca, ES (SLM)' },
        { code: 'SLN', name: 'Salina, US (SLN)' },
        { code: 'SLP', name: 'San Luis Potosi, MX (SLP)' },
        { code: 'SLU', name: 'St Lucia Vigie Apt, LC (SLU)' },
        { code: 'SLV', name: 'Simla, IN (SLV)' },
        { code: 'SLW', name: 'Saltillo, MX (SLW)' },
        { code: 'SLX', name: 'Salt Cay, TC (SLX)' },
        { code: 'SLY', name: 'Salekhard, RU (SLY)' },
        { code: 'SLZ', name: 'Sao Luiz, BR (SLZ)' },
        { code: 'SMA', name: 'Santa Maria, PT (SMA)' },
        { code: 'SMB', name: 'Cerro Sombrero, CL (SMB)' },
        { code: 'SMC', name: 'Santa Maria, CO (SMC)' },
        { code: 'SMF', name: 'Sacramento International Apt, US (SMF)' },
        { code: 'SMG', name: 'Santa Maria, PE (SMG)' },
        { code: 'SMI', name: 'Samos, GR (SMI)' },
        { code: 'SMK', name: 'St Michael, US (SMK)' },
        { code: 'SML', name: 'Stella Maris, BS (SML)' },
        { code: 'SMM', name: 'Semporna, MY (SMM)' },
        { code: 'SMQ', name: 'Sampit, ID (SMQ)' },
        { code: 'SMR', name: 'Santa Marta, CO (SMR)' },
        { code: 'SMT', name: 'Sun Moon Lake, TW (SMT)' },
        { code: 'SMV', name: 'St Moritz, CH (SMV)' },
        { code: 'SMX', name: 'Santa Maria, US (SMX)' },
        { code: 'SNA', name: 'Santa Ana John Wayne Apt, US (SNA)' },
        { code: 'SNB', name: 'Snake Bay, AU (SNB)' },
        { code: 'SNC', name: 'Salinas, EC (SNC)' },
        { code: 'SNF', name: 'San Felipe, VE (SNF)' },
        { code: 'SNG', name: 'San Ignacio De Velasco, BO (SNG)' },
        { code: 'SNH', name: 'Stanthorpe, AU (SNH)' },
        { code: 'SNJ', name: 'San Julian, CU (SNJ)' },
        { code: 'SNM', name: 'San Ignacio De Moxos, BO (SNM)' },
        { code: 'SNN', name: 'Shannon, IE (SNN)' },
        { code: 'SNP', name: 'St Paul Island, US (SNP)' },
        { code: 'SNR', name: 'St Nazaire, FR (SNR)' },
        { code: 'SNT', name: 'Sabana De Torres, CO (SNT)' },
        { code: 'SNU', name: 'Santa Clara, CU (SNU)' },
        { code: 'SNV', name: 'Santa Elena, VE (SNV)' },
        { code: 'SNX', name: 'Sabana De La Mar, DO (SNX)' },
        { code: 'SNZ', name: 'Santa Cruz, BR (SNZ)' },
        { code: 'SOB', name: 'Balaton, HU (SOB)' },
        { code: 'SOC', name: 'Solo City, ID (SOC)' },
        { code: 'SOD', name: 'Sorocaba, BR (SOD)' },
        { code: 'SOG', name: 'Sogndal, NO (SOG)' },
        { code: 'SOH', name: 'Solita, CO (SOH)' },
        { code: 'SOJ', name: 'Sorkjosen, NO (SOJ)' },
        { code: 'SOM', name: 'San Tome, VE (SOM)' },
        { code: 'SOO', name: 'Soderhamn, SE (SOO)' },
        { code: 'SOQ', name: 'Sorong, ID (SOQ)' },
        { code: 'SOT', name: 'Sodankyla, FI (SOT)' },
        { code: 'SOU', name: 'Southampton, GB (SOU)' },
        { code: 'SOV', name: 'Seldovia, US (SOV)' },
        { code: 'SOW', name: 'Show Low, US (SOW)' },
        { code: 'SOX', name: 'Sogamoso, CO (SOX)' },
        { code: 'SOY', name: 'Stronsay, GB (SOY)' },
        { code: 'SOZ', name: 'Solenzara, FR (SOZ)' },
        { code: 'SPB', name: 'St Thomas Seaplane Base, VI (SPB)' },
        { code: 'SPC', name: 'Santa Cruz De La Palma, ES (SPC)' },
        { code: 'SPD', name: 'Saidpur, BD (SPD)' },
        { code: 'SPE', name: 'Sepulot, MY (SPE)' },
        { code: 'SPI', name: 'Springfield, US (SPI)' },
        { code: 'SPJ', name: 'Sparta, GR (SPJ)' },
        { code: 'SPM', name: 'Spangdahlem, DE (SPM)' },
        { code: 'SPO', name: 'San Pablo, ES (SPO)' },
        { code: 'SPR', name: 'San Pedro, BZ (SPR)' },
        { code: 'SPS', name: 'Wichita Falls Sheppard Afb, US (SPS)' },
        { code: 'SPT', name: 'Sipitang, MY (SPT)' },
        { code: 'SPU', name: 'Split, HR (SPU)' },
        { code: 'SQB', name: 'Santa Ana, CO (SQB)' },
        { code: 'SQC', name: 'Southern Cross, AU (SQC)' },
        { code: 'SQE', name: 'San Luis De Palenque, CO (SQE)' },
        { code: 'SQF', name: 'Solano, CO (SQF)' },
        { code: 'SQG', name: 'Sintang, ID (SQG)' },
        { code: 'SQK', name: 'Sidi Barani, EG (SQK)' },
        { code: 'SQM', name: 'Sao Miguel Do Araguaia, BR (SQM)' },
        { code: 'SQN', name: 'Sanana, ID (SQN)' },
        { code: 'SQO', name: 'Storuman, SE (SQO)' },
        { code: 'SQP', name: 'Starcke, AU (SQP)' },
        { code: 'SQQ', name: 'Siauliai, LT (SQQ)' },
        { code: 'SQR', name: 'Soroako, ID (SQR)' },
        { code: 'SQS', name: 'San Ignacia, BZ (SQS)' },
        { code: 'SQU', name: 'Saposoa, PE (SQU)' },
        { code: 'SQW', name: 'Skive, DK (SQW)' },
        { code: 'SQX', name: 'Sao Miguel Do Oeste, BR (SQX)' },
        { code: 'SQY', name: 'Sao Lourenco Do Sul, BR (SQY)' },
        { code: 'SQZ', name: 'Scampton, GB (SQZ)' },
        { code: 'SRA', name: 'Santa Rosa, BR (SRA)' },
        { code: 'SRB', name: 'Santa Rosa, BO (SRB)' },
        { code: 'SRD', name: 'San Ramon, BO (SRD)' },
        { code: 'SRE', name: 'Sucre, BO (SRE)' },
        { code: 'SRG', name: 'Semarang, ID (SRG)' },
        { code: 'SRI', name: 'Samarinda, ID (SRI)' },
        { code: 'SRJ', name: 'San Borja, BO (SRJ)' },
        { code: 'SRM', name: 'Sandringham, AU (SRM)' },
        { code: 'SRN', name: 'Strahan, AU (SRN)' },
        { code: 'SRO', name: 'Santana Ramos, CO (SRO)' },
        { code: 'SRP', name: 'Stord, NO (SRP)' },
        { code: 'SRQ', name: 'Sarasota/Bradenton, US (SRQ)' },
        { code: 'SRR', name: 'Stradbroke Island, AU (SRR)' },
        { code: 'SRS', name: 'San Marcos, CO (SRS)' },
        { code: 'SRZ', name: 'Santa Cruz El Trompillo Apt, BO (SRZ)' },
        { code: 'SSA', name: 'Salvador, BR (SSA)' },
        { code: 'SSD', name: 'San Felipe, CO (SSD)' },
        { code: 'SSE', name: 'Sholapur, IN (SSE)' },
        { code: 'SSH', name: 'Sharm El Sheikh, EG (SSH)' },
        { code: 'SSJ', name: 'Sandnessjoen, NO (SSJ)' },
        { code: 'SSK', name: 'Sturt Creek, AU (SSK)' },
        { code: 'SSL', name: 'Santa Rosalia, CO (SSL)' },
        { code: 'SSN', name: 'Seoul Air Base, KR (SSN)' },
        { code: 'SSO', name: 'Sao Lourenco, BR (SSO)' },
        { code: 'SSP', name: 'Silver Plains, AU (SSP)' },
        { code: 'SSQ', name: 'La Sarre, CA (SSQ)' },
        { code: 'SST', name: 'Santa Teresita, AR (SST)' },
        { code: 'SSV', name: 'Siasi, PH (SSV)' },
        { code: 'SSX', name: 'Samsun Airport, TR (SSX)' },
        { code: 'SSZ', name: 'Santos, BR (SSZ)' },
        { code: 'STA', name: 'Stauning, DK (STA)' },
        { code: 'STB', name: 'Santa Barbara Zulia, VE (STB)' },
        { code: 'STC', name: 'St Cloud Municipal Apt, US (STC)' },
        { code: 'STD', name: 'Santo Domingo, VE (STD)' },
        { code: 'STF', name: 'Stephen Island, AU (STF)' },
        { code: 'STH', name: 'Strathmore, AU (STH)' },
        { code: 'STI', name: 'Santiago, DO (STI)' },
        { code: 'STL', name: 'St Louis Lambert Intl Apt, US (STL)' },
        { code: 'STM', name: 'Santarem, BR (STM)' },
        { code: 'STN', name: 'London Stansted Apt, GB (STN)' },
        { code: 'STR', name: 'Stuttgart Echterdingen Apt, DE (STR)' },
        { code: 'STS', name: 'Santa Rosa, US (STS)' },
        { code: 'STT', name: 'St Thomas Cyril E King Apt, VI (STT)' },
        { code: 'STU', name: 'Santa Cruz, BZ (STU)' },
        { code: 'STV', name: 'Surat, IN (STV)' },
        { code: 'STW', name: 'Stavropol, RU (STW)' },
        { code: 'STX', name: 'St Croix Henry E. Rohlsen Apt, VI (STX)' },
        { code: 'STY', name: 'Salto, UY (STY)' },
        { code: 'STZ', name: 'Santa Terezinha, BR (STZ)' },
        { code: 'SUB', name: 'Surabaya, ID (SUB)' },
        { code: 'SUF', name: 'Lamezia Terme, IT (SUF)' },
        { code: 'SUG', name: 'Surigao, PH (SUG)' },
        { code: 'SUH', name: 'Sur, OM (SUH)' },
        { code: 'SUJ', name: 'Satu Mare, RO (SUJ)' },
        { code: 'SUK', name: 'Samcheok, KR (SUK)' },
        { code: 'SUN', name: 'Sun Valley Friedman Memorial Apt, US (SUN)' },
        { code: 'SUP', name: 'Sumenep, ID (SUP)' },
        { code: 'SUQ', name: 'Sucua, EC (SUQ)' },
        { code: 'SUR', name: 'Summer Beaver, CA (SUR)' },
        { code: 'SUX', name: 'Sioux City, US (SUX)' },
        { code: 'SUY', name: 'Sudureyri, IS (SUY)' },
        { code: 'SVA', name: 'Savoonga, US (SVA)' },
        { code: 'SVD', name: 'St Vincent, VC (SVD)' },
        { code: 'SVG', name: 'Stavanger, NO (SVG)' },
        { code: 'SVI', name: 'San Vicente Del Caguan, CO (SVI)' },
        { code: 'SVJ', name: 'Svolvaer, NO (SVJ)' },
        { code: 'SVK', name: 'Silver Creek, BZ (SVK)' },
        { code: 'SVL', name: 'Savonlinna, FI (SVL)' },
        { code: 'SVM', name: 'St Pauls Mission, AU (SVM)' },
        { code: 'SVO', name: 'Moscow Sheremetyevo Apt, RU (SVO)' },
        { code: 'SVQ', name: 'Seville, ES (SVQ)' },
        { code: 'SVT', name: 'Savuti, BW (SVT)' },
        { code: 'SVV', name: 'San Salvador De Paul, VE (SVV)' },
        { code: 'SVX', name: 'Ekaterinburg, RU (SVX)' },
        { code: 'SVZ', name: 'San Antonio, VE (SVZ)' },
        { code: 'SWA', name: 'Shantou, CN (SWA)' },
        { code: 'SWB', name: 'Shaw River, AU (SWB)' },
        { code: 'SWC', name: 'Stawell, AU (SWC)' },
        { code: 'SWF', name: 'Newburgh, US (SWF)' },
        { code: 'SWH', name: 'Swan Hill, AU (SWH)' },
        { code: 'SWK', name: 'Milan Segrate Apt, IT (SWK)' },
        { code: 'SWL', name: 'Spanish Wells, BS (SWL)' },
        { code: 'SWM', name: 'Suia-Missu, BR (SWM)' },
        { code: 'SWQ', name: 'Sumbawa, ID (SWQ)' },
        { code: 'SWS', name: 'Swansea, GB (SWS)' },
        { code: 'SWT', name: 'Strzhewoi, RU (SWT)' },
        { code: 'SWU', name: 'Suwon, KR (SWU)' },
        { code: 'SWX', name: 'Shakawe, BW (SWX)' },
        { code: 'SWY', name: 'Sitiawan, MY (SWY)' },
        { code: 'SWZ', name: 'Sydney West Airport, AU (SWZ)' },
        { code: 'SXB', name: 'Strasbourg Entzheim Airport, FR (SXB)' },
        { code: 'SXD', name: 'Sophia Antipolis, FR (SXD)' },
        { code: 'SXE', name: 'Sale, AU (SXE)' },
        { code: 'SXF', name: 'Berlin Schonefeld Apt, DE (SXF)' },
        { code: 'SXJ', name: 'Shanshan, CN (SXJ)' },
        { code: 'SXK', name: 'Saumlaki, ID (SXK)' },
        { code: 'SXL', name: 'Sligo, IE (SXL)' },
        { code: 'SXM', name: 'St Maarten, SX (SXM)' },
        { code: 'SXN', name: 'Suapan, BW (SXN)' },
        { code: 'SXO', name: 'Sao Felix Do Araguaia, BR (SXO)' },
        { code: 'SXR', name: 'Srinagar, IN (SXR)' },
        { code: 'SXS', name: 'Sahabat 16, MY (SXS)' },
        { code: 'SXT', name: 'Taman Negara, MY (SXT)' },
        { code: 'SXV', name: 'Salem, IN (SXV)' },
        { code: 'SXX', name: 'Sao Felix Do Xingu, BR (SXX)' },
        { code: 'SXZ', name: 'Siirt, TR (SXZ)' },
        { code: 'SYC', name: 'Shiringayoc, PE (SYC)' },
        { code: 'SYD', name: 'Sydney Kingsford Smith Apt, AU (SYD)' },
        { code: 'SYF', name: 'Silva Bay, CA (SYF)' },
        { code: 'SYG', name: 'Svalbard, NO (SYG)' },
        { code: 'SYK', name: 'Stykkisholmur, IS (SYK)' },
        { code: 'SYM', name: 'Simao, CN (SYM)' },
        { code: 'SYO', name: 'Shonai, JP (SYO)' },
        { code: 'SYP', name: 'Santiago, PA (SYP)' },
        { code: 'SYQ', name: 'San Jose Tobias Bolanos Apt, CR (SYQ)' },
        { code: 'SYR', name: 'Syracuse, US (SYR)' },
        { code: 'SYS', name: 'Suncheon, KR (SYS)' },
        { code: 'SYT', name: 'St Yan, FR (SYT)' },
        { code: 'SYU', name: 'Warraber Island, AU (SYU)' },
        { code: 'SYX', name: 'Sanya, CN (SYX)' },
        { code: 'SYY', name: 'Stornoway, GB (SYY)' },
        {
            code: 'SZB',
            name: 'Kuala Lumpur Sultan Abdul Aziz Shah Apt, MY (SZB)',
        },
        { code: 'SZC', name: 'Santa Cruz, CR (SZC)' },
        { code: 'SZD', name: 'Sheffield, GB (SZD)' },
        { code: 'SZF', name: 'Samsun Carsamba Airport, TR (SZF)' },
        { code: 'SZG', name: 'Salzburg, AT (SZG)' },
        { code: 'SZH', name: 'Senipah, ID (SZH)' },
        { code: 'SZJ', name: 'Siguanea, CU (SZJ)' },
        { code: 'SZK', name: 'Skukuza, ZA (SZK)' },
        { code: 'SZO', name: 'Shanzhou, CN (SZO)' },
        { code: 'SZQ', name: 'Saenz Pena, AR (SZQ)' },
        { code: 'SZS', name: 'Stewart Island, NZ (SZS)' },
        { code: 'SZT', name: 'San Cristobal De Las Casas, MX (SZT)' },
        { code: 'SZV', name: 'Suzhou, CN (SZV)' },
        { code: 'SZW', name: 'Schwerin, DE (SZW)' },
        { code: 'SZX', name: 'Shenzhen, CN (SZX)' },
        { code: 'SZY', name: 'Szymany, PL (SZY)' },
        { code: 'SZZ', name: 'Szczecin, PL (SZZ)' },
        { code: 'TAB', name: 'Tobago, TT (TAB)' },
        { code: 'TAC', name: 'Tacloban, PH (TAC)' },
        { code: 'TAE', name: 'Daegu, KR (TAE)' },
        { code: 'TAG', name: 'Tagbilaran, PH (TAG)' },
        { code: 'TAK', name: 'Takamatsu, JP (TAK)' },
        { code: 'TAL', name: 'Tanana, US (TAL)' },
        { code: 'TAM', name: 'Tampico, MX (TAM)' },
        { code: 'TAO', name: 'Qingdao, CN (TAO)' },
        { code: 'TAP', name: 'Tapachula, MX (TAP)' },
        { code: 'TAQ', name: 'Tarcoola, AU (TAQ)' },
        { code: 'TAR', name: 'Taranto, IT (TAR)' },
        { code: 'TAT', name: 'Poprad/Tatry, SK (TAT)' },
        { code: 'TAU', name: 'Tauramena, CO (TAU)' },
        { code: 'TAW', name: 'Tacuarembo, UY (TAW)' },
        { code: 'TAX', name: 'Taliabu, ID (TAX)' },
        { code: 'TBD', name: 'Timbiqui, CO (TBD)' },
        { code: 'TBH', name: 'Tablas, PH (TBH)' },
        { code: 'TBI', name: 'The Bight, BS (TBI)' },
        { code: 'TBK', name: 'Timber Creek, AU (TBK)' },
        { code: 'TBL', name: 'Tableland, AU (TBL)' },
        { code: 'TBM', name: 'Tumbang Samba, ID (TBM)' },
        { code: 'TBN', name: 'Fort Leonard Wood, US (TBN)' },
        { code: 'TBP', name: 'Tumbes, PE (TBP)' },
        { code: 'TBT', name: 'Tabatinga, BR (TBT)' },
        { code: 'TBW', name: 'Tambov, RU (TBW)' },
        { code: 'TBY', name: 'Tsabong, BW (TBY)' },
        { code: 'TCA', name: 'Tennant Creek, AU (TCA)' },
        { code: 'TCB', name: 'Treasure Cay, BS (TCB)' },
        { code: 'TCD', name: 'Tarapaca, CO (TCD)' },
        { code: 'TCE', name: 'Tulcea, RO (TCE)' },
        { code: 'TCF', name: 'Tocoa, HN (TCF)' },
        { code: 'TCG', name: 'Tacheng, CN (TCG)' },
        { code: 'TCO', name: 'Tumaco, CO (TCO)' },
        { code: 'TCP', name: 'Taba, EG (TCP)' },
        { code: 'TCQ', name: 'Tacna, PE (TCQ)' },
        { code: 'TCR', name: 'Tuticorin, IN (TCR)' },
        { code: 'TCU', name: 'Thaba Nchu, ZA (TCU)' },
        { code: 'TCW', name: 'Tocumwal, AU (TCW)' },
        { code: 'TDA', name: 'Trinidad, CO (TDA)' },
        { code: 'TDD', name: 'Trinidad, BO (TDD)' },
        { code: 'TDG', name: 'Tandag, PH (TDG)' },
        { code: 'TDL', name: 'Tandil, AR (TDL)' },
        { code: 'TDN', name: 'Theda Station, AU (TDN)' },
        { code: 'TDR', name: 'Theodore, AU (TDR)' },
        { code: 'TDT', name: 'Tanda Tula, ZA (TDT)' },
        { code: 'TEA', name: 'Tela, HN (TEA)' },
        { code: 'TEC', name: 'Telemaco Borba, BR (TEC)' },
        { code: 'TED', name: 'Thisted, DK (TED)' },
        { code: 'TEF', name: 'Telfer, AU (TEF)' },
        { code: 'TEI', name: 'Tezu, IN (TEI)' },
        { code: 'TEL', name: 'Telupid, MY (TEL)' },
        { code: 'TEM', name: 'Temora, AU (TEM)' },
        { code: 'TEN', name: 'Tongren, CN (TEN)' },
        { code: 'TEQ', name: 'Tekirdag, TR (TEQ)' },
        { code: 'TER', name: 'Terceira, PT (TER)' },
        { code: 'TEU', name: 'Te Anau, NZ (TEU)' },
        { code: 'TEX', name: 'Telluride, US (TEX)' },
        { code: 'TEY', name: 'Thingeyri, IS (TEY)' },
        { code: 'TEZ', name: 'Tezpur, IN (TEZ)' },
        { code: 'TFF', name: 'Tefe, BR (TFF)' },
        { code: 'TFL', name: 'Teofilo Otoni, BR (TFL)' },
        { code: 'TFN', name: 'Tenerife Norte Apt, ES (TFN)' },
        { code: 'TFS', name: 'Tenerife Sur Reina Sofia Apt, ES (TFS)' },
        { code: 'TGB', name: 'Tagbita, PH (TGB)' },
        { code: 'TGF', name: 'Tignes, FR (TGF)' },
        { code: 'TGG', name: 'Kuala Terengganu, MY (TGG)' },
        { code: 'TGI', name: 'Tingo Maria, PE (TGI)' },
        { code: 'TGM', name: 'Tirgu Mures, RO (TGM)' },
        { code: 'TGN', name: 'Traralgon, AU (TGN)' },
        { code: 'TGO', name: 'Tongliao, CN (TGO)' },
        { code: 'TGQ', name: 'Tangara Da Serra, BR (TGQ)' },
        { code: 'TGU', name: 'Tegucigalpa, HN (TGU)' },
        { code: 'TGZ', name: 'Tuxtla Gutierrez, MX (TGZ)' },
        { code: 'THE', name: 'Teresina, BR (THE)' },
        { code: 'THF', name: 'Berlin Tempelhof Apt, DE (THF)' },
        { code: 'THG', name: 'Thangool, AU (THG)' },
        { code: 'THH', name: 'Taharoa, NZ (THH)' },
        { code: 'THN', name: 'Trollhattan, SE (THN)' },
        { code: 'THO', name: 'Thorshofn, IS (THO)' },
        { code: 'THY', name: 'Thohoyandou, ZA (THY)' },
        { code: 'TIA', name: 'Tirana, AL (TIA)' },
        { code: 'TIB', name: 'Tibu, CO (TIB)' },
        { code: 'TIF', name: 'Taif, SA (TIF)' },
        { code: 'TIJ', name: 'Tijuana, MX (TIJ)' },
        { code: 'TIL', name: 'Inverlake, CA (TIL)' },
        { code: 'TIM', name: 'Tembagapura, ID (TIM)' },
        { code: 'TIR', name: 'Tirupati, IN (TIR)' },
        { code: 'TIU', name: 'Timaru, NZ (TIU)' },
        { code: 'TJA', name: 'Tarija, BO (TJA)' },
        { code: 'TJB', name: 'Tanjung Balai, ID (TJB)' },
        { code: 'TJC', name: 'Ticantiki, PA (TJC)' },
        { code: 'TJG', name: 'Tanjung, ID (TJG)' },
        { code: 'TJH', name: 'Toyooka, JP (TJH)' },
        { code: 'TJI', name: 'Trujillo, HN (TJI)' },
        { code: 'TJK', name: 'Tokat, TR (TJK)' },
        { code: 'TJM', name: 'Tyumen, RU (TJM)' },
        { code: 'TJQ', name: 'Tanjung Pandan, ID (TJQ)' },
        { code: 'TJS', name: 'Tanjung Selor, ID (TJS)' },
        { code: 'TJV', name: 'Thanjavur, IN (TJV)' },
        { code: 'TKG', name: 'Bandar Lampung, ID (TKG)' },
        { code: 'TKM', name: 'Tikal, GT (TKM)' },
        { code: 'TKN', name: 'Tokunoshima, JP (TKN)' },
        { code: 'TKR', name: 'Thakurgaon, BD (TKR)' },
        { code: 'TKS', name: 'Tokushima, JP (TKS)' },
        { code: 'TKU', name: 'Turku, FI (TKU)' },
        { code: 'TKY', name: 'Turkey Creek, AU (TKY)' },
        { code: 'TKZ', name: 'Tokoroa, NZ (TKZ)' },
        { code: 'TLC', name: 'Mexico City Toluca Airport, MX (TLC)' },
        { code: 'TLD', name: 'Tuli Block, BW (TLD)' },
        { code: 'TLH', name: 'Tallahassee, US (TLH)' },
        { code: 'TLI', name: 'Tolitoli, ID (TLI)' },
        { code: 'TLK', name: 'Talknafjordur, IS (TLK)' },
        { code: 'TLN', name: 'Toulon, FR (TLN)' },
        { code: 'TLS', name: 'Toulouse Blagnac Apt, FR (TLS)' },
        { code: 'TLT', name: 'Tuluksak, US (TLT)' },
        { code: 'TLU', name: 'Tolu, CO (TLU)' },
        { code: 'TLX', name: 'Talca, CL (TLX)' },
        { code: 'TLY', name: 'Plastun, RU (TLY)' },
        { code: 'TLZ', name: 'Catalao, BR (TLZ)' },
        { code: 'TMC', name: 'Tambolaka, ID (TMC)' },
        { code: 'TME', name: 'Tame, CO (TME)' },
        { code: 'TMG', name: 'Tomanggong, MY (TMG)' },
        { code: 'TMH', name: 'Tanahmerah, ID (TMH)' },
        { code: 'TMO', name: 'Tumeremo, VE (TMO)' },
        { code: 'TMP', name: 'Tampere, FI (TMP)' },
        { code: 'TMT', name: 'Trombetas, BR (TMT)' },
        { code: 'TMU', name: 'Tambor, CR (TMU)' },
        { code: 'TMW', name: 'Tamworth, AU (TMW)' },
        { code: 'TMY', name: 'Tiom, ID (TMY)' },
        { code: 'TMZ', name: 'Thames, NZ (TMZ)' },
        { code: 'TNA', name: 'Jinan, CN (TNA)' },
        { code: 'TNB', name: 'Tanah Grogot, ID (TNB)' },
        { code: 'TND', name: 'Trinidad, CU (TND)' },
        { code: 'TNE', name: 'Tanegashima, JP (TNE)' },
        { code: 'TNF', name: 'Toussus-Le-Noble, FR (TNF)' },
        { code: 'TNH', name: 'Tonghua, CN (TNH)' },
        { code: 'TNI', name: 'Satna, IN (TNI)' },
        { code: 'TNJ', name: 'Tanjung Pinang, ID (TNJ)' },
        { code: 'TNL', name: 'Ternopol, UA (TNL)' },
        { code: 'TNN', name: 'Tainan, TW (TNN)' },
        { code: 'TNO', name: 'Tamarindo, CR (TNO)' },
        { code: 'TNS', name: 'Tungsten, CA (TNS)' },
        { code: 'TOD', name: 'Tioman, MY (TOD)' },
        { code: 'TOF', name: 'Tomsk, RU (TOF)' },
        { code: 'TOJ', name: 'Madrid Torrejon Afb, ES (TOJ)' },
        { code: 'TOL', name: 'Toledo Express Apt, US (TOL)' },
        { code: 'TOO', name: 'San Vito, CR (TOO)' },
        { code: 'TOQ', name: 'Tocopilla, CL (TOQ)' },
        { code: 'TOS', name: 'Tromso, NO (TOS)' },
        { code: 'TOW', name: 'Toledo, BR (TOW)' },
        { code: 'TOX', name: 'Tobolsk, RU (TOX)' },
        { code: 'TOY', name: 'Toyama, JP (TOY)' },
        { code: 'TPA', name: 'Tampa International Apt, US (TPA)' },
        { code: 'TPC', name: 'Tarapoa, EC (TPC)' },
        { code: 'TPE', name: 'Taipei Chiang Kai Shek Intl Apt, TW (TPE)' },
        { code: 'TPG', name: 'Taiping, MY (TPG)' },
        { code: 'TPK', name: 'Tapaktuan, ID (TPK)' },
        { code: 'TPN', name: 'Tiputini, EC (TPN)' },
        { code: 'TPP', name: 'Tarapoto, PE (TPP)' },
        { code: 'TPQ', name: 'Tepic, MX (TPQ)' },
        { code: 'TPR', name: 'Tom Price, AU (TPR)' },
        { code: 'TPS', name: 'Trapani, IT (TPS)' },
        { code: 'TQR', name: 'San Domino Island (Tremiti Islands), IT (TQR)' },
        { code: 'TQS', name: 'Tres Esquinas, CO (TQS)' },
        { code: 'TRA', name: 'Taramajima, JP (TRA)' },
        { code: 'TRB', name: 'Turbo, CO (TRB)' },
        { code: 'TRC', name: 'Torreon, MX (TRC)' },
        { code: 'TRD', name: 'Trondheim Vaernes Airport, NO (TRD)' },
        { code: 'TRE', name: 'Tiree, GB (TRE)' },
        { code: 'TRF', name: 'Oslo Torp Airport, NO (TRF)' },
        { code: 'TRG', name: 'Tauranga, NZ (TRG)' },
        { code: 'TRI', name: 'Tri-Cities Regional, US (TRI)' },
        { code: 'TRK', name: 'Tarakan, ID (TRK)' },
        { code: 'TRN', name: 'Turin Citta Di Torino Airport, IT (TRN)' },
        { code: 'TRO', name: 'Taree, AU (TRO)' },
        { code: 'TRQ', name: 'Tarauaca, BR (TRQ)' },
        { code: 'TRS', name: 'Trieste, IT (TRS)' },
        { code: 'TRU', name: 'Trujillo, PE (TRU)' },
        { code: 'TRV', name: 'Thiruvananthapuram, IN (TRV)' },
        { code: 'TRZ', name: 'Tiruchirapally, IN (TRZ)' },
        { code: 'TSA', name: 'Taipei Sung Shan Apt, TW (TSA)' },
        { code: 'TSC', name: 'Taisha, EC (TSC)' },
        { code: 'TSD', name: 'Tshipise, ZA (TSD)' },
        { code: 'TSF', name: 'Venice Treviso Apt, IT (TSF)' },
        { code: 'TSJ', name: 'Tsushima, JP (TSJ)' },
        { code: 'TSN', name: 'Tianjin, CN (TSN)' },
        { code: 'TSO', name: 'Isles Of Scilly Tresco Apt, GB (TSO)' },
        { code: 'TSQ', name: 'Torres, BR (TSQ)' },
        { code: 'TSR', name: 'Timisoara, RO (TSR)' },
        { code: 'TSV', name: 'Townsville, AU (TSV)' },
        { code: 'TSX', name: 'Tanjung Santan, ID (TSX)' },
        { code: 'TSY', name: 'Tasikmalaya, ID (TSY)' },
        { code: 'TTB', name: 'Tortoli, IT (TTB)' },
        { code: 'TTC', name: 'Taltal, CL (TTC)' },
        { code: 'TTE', name: 'Ternate, ID (TTE)' },
        { code: 'TTG', name: 'Tartagal, AR (TTG)' },
        { code: 'TTH', name: 'Thumrait, OM (TTH)' },
        { code: 'TTJ', name: 'Tottori, JP (TTJ)' },
        { code: 'TTM', name: 'Tablon De Tamara, CO (TTM)' },
        { code: 'TTN', name: 'Philadelphia Trenton-Mercer Apt, US (TTN)' },
        { code: 'TTQ', name: 'Tortuquero, CR (TTQ)' },
        { code: 'TTR', name: 'Tana Toraja, ID (TTR)' },
        { code: 'TTT', name: 'Taitung, TW (TTT)' },
        { code: 'TUA', name: 'Tulcan, EC (TUA)' },
        { code: 'TUC', name: 'Tucuman, AR (TUC)' },
        { code: 'TUE', name: 'Tupile, PA (TUE)' },
        { code: 'TUF', name: 'Tours St Symphorien Airport, FR (TUF)' },
        { code: 'TUG', name: 'Tuguegarao, PH (TUG)' },
        { code: 'TUI', name: 'Turaif, SA (TUI)' },
        { code: 'TUL', name: 'Tulsa International Apt, US (TUL)' },
        { code: 'TUM', name: 'Tumut, AU (TUM)' },
        { code: 'TUO', name: 'Taupo, NZ (TUO)' },
        { code: 'TUP', name: 'Tupelo, US (TUP)' },
        { code: 'TUR', name: 'Tucurui, BR (TUR)' },
        { code: 'TUS', name: 'Tucson International Apt, US (TUS)' },
        { code: 'TUU', name: 'Tabuk, SA (TUU)' },
        { code: 'TUV', name: 'Tucupita, VE (TUV)' },
        { code: 'TUW', name: 'Tubala, PA (TUW)' },
        { code: 'TUX', name: 'Tumbler Ridge, CA (TUX)' },
        { code: 'TUZ', name: 'Tucuma, BR (TUZ)' },
        { code: 'TVC', name: 'Traverse City, US (TVC)' },
        { code: 'TVF', name: 'Thief River Falls, US (TVF)' },
        { code: 'TWB', name: 'Toowoomba, AU (TWB)' },
        { code: 'TWF', name: 'Twin Falls, US (TWF)' },
        { code: 'TWN', name: 'Tewantin, AU (TWN)' },
        { code: 'TWP', name: 'Torwood, AU (TWP)' },
        { code: 'TWT', name: 'Tawitawi, PH (TWT)' },
        { code: 'TWU', name: 'Tawau, MY (TWU)' },
        { code: 'TWZ', name: 'Mount Cook Pukaki/Twizel Apt, NZ (TWZ)' },
        { code: 'TXF', name: 'Teixeira De Freitas, BR (TXF)' },
        { code: 'TXG', name: 'Taichung, TW (TXG)' },
        { code: 'TXK', name: 'Texarkana, US (TXK)' },
        { code: 'TXL', name: 'Berlin Tegel Apt, DE (TXL)' },
        { code: 'TXM', name: 'Teminabuan, ID (TXM)' },
        { code: 'TXN', name: 'Tunxi, CN (TXN)' },
        { code: 'TXR', name: 'Tanbar, AU (TXR)' },
        { code: 'TYA', name: 'Tula, RU (TYA)' },
        { code: 'TYB', name: 'Tibooburra, AU (TYB)' },
        { code: 'TYD', name: 'Tynda, RU (TYD)' },
        { code: 'TYF', name: 'Torsby, SE (TYF)' },
        { code: 'TYG', name: 'Thylungra, AU (TYG)' },
        { code: 'TYL', name: 'Talara, PE (TYL)' },
        { code: 'TYM', name: 'Staniel Cay, BS (TYM)' },
        { code: 'TYN', name: 'Taiyuan, CN (TYN)' },
        { code: 'TYP', name: 'Tobermorey, AU (TYP)' },
        { code: 'TYR', name: 'Tyler, US (TYR)' },
        { code: 'TYS', name: 'Knoxville, US (TYS)' },
        { code: 'TYT', name: 'Treinta Y Tres, UY (TYT)' },
        { code: 'TZA', name: 'Belize City Municipal Apt, BZ (TZA)' },
        { code: 'TZL', name: 'Tuzla, BA (TZL)' },
        { code: 'TZN', name: 'South Andros, BS (TZN)' },
        { code: 'TZX', name: 'Trabzon, TR (TZX)' },
        { code: 'UAB', name: 'Adana, TR (UAB)' },
        { code: 'UAQ', name: 'San Juan, AR (UAQ)' },
        { code: 'UAX', name: 'Uaxactun, GT (UAX)' },
        { code: 'UBA', name: 'Uberaba, BR (UBA)' },
        { code: 'UBB', name: 'Mabuiag Island, AU (UBB)' },
        { code: 'UBJ', name: 'Ube, JP (UBJ)' },
        { code: 'UBR', name: 'Ubrub, ID (UBR)' },
        { code: 'UBT', name: 'Ubatuba, BR (UBT)' },
        { code: 'UBU', name: 'Kalumburu, AU (UBU)' },
        { code: 'UCK', name: 'Lutsk, UA (UCK)' },
        { code: 'UCT', name: 'Ukhta, RU (UCT)' },
        { code: 'UCZ', name: 'Uchiza, PE (UCZ)' },
        { code: 'UDA', name: 'Undarra, AU (UDA)' },
        { code: 'UDE', name: 'Uden, NL (UDE)' },
        { code: 'UDI', name: 'Uberlandia, BR (UDI)' },
        { code: 'UDJ', name: 'Uzhgorod, UA (UDJ)' },
        { code: 'UDN', name: 'Udine, IT (UDN)' },
        { code: 'UDR', name: 'Udaipur, IN (UDR)' },
        { code: 'UEE', name: 'Queenstown, AU (UEE)' },
        { code: 'UEO', name: 'Kume Jima, JP (UEO)' },
        { code: 'UER', name: 'Puertollano, ES (UER)' },
        { code: 'UFA', name: 'Ufa, RU (UFA)' },
        { code: 'UGU', name: 'Zugapa, ID (UGU)' },
        { code: 'UHE', name: 'Uherske Hradiste, CZ (UHE)' },
        { code: 'UHF', name: 'Upper Heyford, GB (UHF)' },
        { code: 'UIB', name: 'Quibdo, CO (UIB)' },
        { code: 'UII', name: 'Utila, HN (UII)' },
        { code: 'UIK', name: 'Ust-Ilimsk, RU (UIK)' },
        { code: 'UIN', name: 'Quincy, US (UIN)' },
        { code: 'UIO', name: 'Quito, EC (UIO)' },
        { code: 'UIP', name: 'Quimper, FR (UIP)' },
        { code: 'UIR', name: 'Quirindi, AU (UIR)' },
        { code: 'UKB', name: 'Osaka Kobe Airport, JP (UKB)' },
        { code: 'UKS', name: 'Sevastopol, UA (UKS)' },
        { code: 'UKX', name: 'Ust-Kut, RU (UKX)' },
        { code: 'UKY', name: 'Kyoto, JP (UKY)' },
        { code: 'ULA', name: 'San Julian, AR (ULA)' },
        { code: 'ULC', name: 'Santiago Los Cerrillos Apt, CL (ULC)' },
        { code: 'ULD', name: 'Ulundi, ZA (ULD)' },
        { code: 'ULL', name: 'Mull, GB (ULL)' },
        { code: 'ULP', name: 'Quilpie, AU (ULP)' },
        { code: 'ULQ', name: 'Tulua, CO (ULQ)' },
        { code: 'ULS', name: 'Mulatos, CO (ULS)' },
        { code: 'ULX', name: 'Ulusaba, ZA (ULX)' },
        { code: 'ULY', name: 'Ulyanovsk, RU (ULY)' },
        { code: 'UMA', name: 'Punta De Maisi, CU (UMA)' },
        { code: 'UME', name: 'Umea, SE (UME)' },
        { code: 'UMI', name: 'Quincemil, PE (UMI)' },
        { code: 'UMR', name: 'Woomera, AU (UMR)' },
        { code: 'UMU', name: 'Umuarama, BR (UMU)' },
        { code: 'UMY', name: 'Sumy, UA (UMY)' },
        { code: 'UNA', name: 'Una, BR (UNA)' },
        { code: 'UNC', name: 'Unguia, CO (UNC)' },
        { code: 'UNI', name: 'Union Island, VC (UNI)' },
        { code: 'UNK', name: 'Unalakleet, US (UNK)' },
        { code: 'UNT', name: 'Unst, GB (UNT)' },
        { code: 'UOL', name: 'Buol, ID (UOL)' },
        { code: 'UPA', name: 'Punta Alegre, CU (UPA)' },
        { code: 'UPB', name: 'Havana, CU (UPB)' },
        { code: 'UPC', name: 'Puerto De La Cruz, ES (UPC)' },
        { code: 'UPF', name: 'Pforzheim, DE (UPF)' },
        { code: 'UPG', name: 'Ujung Pandang, ID (UPG)' },
        { code: 'UPL', name: 'Upala, CR (UPL)' },
        { code: 'UPN', name: 'Uruapan, MX (UPN)' },
        { code: 'UPV', name: 'Upavon, GB (UPV)' },
        { code: 'URB', name: 'Urubupunga, BR (URB)' },
        { code: 'URC', name: 'Urumqi, CN (URC)' },
        { code: 'URD', name: 'Burg Feuerstein, DE (URD)' },
        { code: 'URG', name: 'Uruguaiana, BR (URG)' },
        { code: 'URI', name: 'Uribe, CO (URI)' },
        { code: 'URJ', name: 'Uraj, RU (URJ)' },
        { code: 'URM', name: 'Uriman, VE (URM)' },
        { code: 'URO', name: 'Rouen, FR (URO)' },
        { code: 'URR', name: 'Urrao, CO (URR)' },
        { code: 'URS', name: 'Kursk, RU (URS)' },
        { code: 'URY', name: 'Gurayat, SA (URY)' },
        { code: 'USH', name: 'Ushuaia, AR (USH)' },
        { code: 'USI', name: 'Mabaruma, GY (USI)' },
        { code: 'USK', name: 'Usinsk, RU (USK)' },
        { code: 'USL', name: 'Useless Loop, AU (USL)' },
        { code: 'USN', name: 'Ulsan, KR (USN)' },
        { code: 'USQ', name: 'Usak, TR (USQ)' },
        { code: 'USS', name: 'Sancti Spiritus, CU (USS)' },
        { code: 'USU', name: 'Busuanga, PH (USU)' },
        { code: 'UTB', name: 'Muttaburra, AU (UTB)' },
        { code: 'UTC', name: 'Utrecht Soesterberg Apt, NL (UTC)' },
        { code: 'UTD', name: 'Nutwood Downs, AU (UTD)' },
        { code: 'UTE', name: 'Butterworth, ZA (UTE)' },
        { code: 'UTI', name: 'Kouvola, FI (UTI)' },
        { code: 'UTL', name: 'Torremolinos, ES (UTL)' },
        { code: 'UTN', name: 'Upington, ZA (UTN)' },
        { code: 'UTT', name: 'Umtata, ZA (UTT)' },
        { code: 'UTU', name: 'Ustupo, PA (UTU)' },
        { code: 'UTW', name: 'Queenstown, ZA (UTW)' },
        { code: 'UUA', name: 'Bugulma, RU (UUA)' },
        { code: 'UUD', name: 'Ulan-Ude, RU (UUD)' },
        { code: 'UUS', name: 'Yuzhno-Sakhalinsk, RU (UUS)' },
        { code: 'UVF', name: 'St Lucia Hewanorra Apt, LC (UVF)' },
        { code: 'UVL', name: 'Kharga, EG (UVL)' },
        { code: 'UYN', name: 'Yulin, CN (UYN)' },
        { code: 'UZH', name: 'Unayzah, SA (UZH)' },
        { code: 'UZU', name: 'Curuzu Cuatia, AR (UZU)' },
        { code: 'VAA', name: 'Vaasa, FI (VAA)' },
        { code: 'VAB', name: 'Yavarate, CO (VAB)' },
        { code: 'VAC', name: 'Varrelbusch, DE (VAC)' },
        { code: 'VAF', name: 'Valence, FR (VAF)' },
        { code: 'VAG', name: 'Varginha, BR (VAG)' },
        { code: 'VAH', name: 'Vallegrande, BO (VAH)' },
        { code: 'VAK', name: 'Chevak, US (VAK)' },
        { code: 'VAL', name: 'Valenca, BR (VAL)' },
        { code: 'VAN', name: 'Van, TR (VAN)' },
        { code: 'VAP', name: 'Valparaiso, CL (VAP)' },
        { code: 'VAS', name: 'Sivas, TR (VAS)' },
        { code: 'VAW', name: 'Vardoe, NO (VAW)' },
        { code: 'VAZ', name: "Val D'Isere, FR (VAZ)" },
        { code: 'VBS', name: 'Verona Brescia Airport, IT (VBS)' },
        { code: 'VBY', name: 'Visby, SE (VBY)' },
        { code: 'VCD', name: 'Victoria River Downs, AU (VCD)' },
        { code: 'VCE', name: 'Venice Marco Polo Apt, IT (VCE)' },
        { code: 'VCF', name: 'Valcheta, AR (VCF)' },
        { code: 'VCH', name: 'Vichadero, UY (VCH)' },
        { code: 'VCP', name: 'Sao Paulo Viracopos Apt, BR (VCP)' },
        { code: 'VCR', name: 'Carora, VE (VCR)' },
        { code: 'VCT', name: 'Victoria, US (VCT)' },
        { code: 'VDB', name: 'Fagernes, NO (VDB)' },
        { code: 'VDC', name: 'Vitoria Da Conquista, BR (VDC)' },
        { code: 'VDE', name: 'Valverde, ES (VDE)' },
        { code: 'VDM', name: 'Viedma, AR (VDM)' },
        { code: 'VDP', name: 'Valle De Pascua, VE (VDP)' },
        { code: 'VDR', name: 'Villa Dolores, AR (VDR)' },
        { code: 'VDS', name: 'Vadso, NO (VDS)' },
        { code: 'VDZ', name: 'Valdez, US (VDZ)' },
        { code: 'VEG', name: 'Maikwak, GY (VEG)' },
        { code: 'VEJ', name: 'Vejle Airport, DK (VEJ)' },
        { code: 'VEL', name: 'Vernal, US (VEL)' },
        { code: 'VER', name: 'Veracruz, MX (VER)' },
        { code: 'VEY', name: 'Vestmannaeyjar, IS (VEY)' },
        { code: 'VGA', name: 'Vijayawada, IN (VGA)' },
        { code: 'VGD', name: 'Vologda, RU (VGD)' },
        { code: 'VGO', name: 'Vigo, ES (VGO)' },
        { code: 'VGS', name: 'General Villegas, AR (VGS)' },
        { code: 'VGT', name: 'Las Vegas North Air Terminal, US (VGT)' },
        { code: 'VGZ', name: 'Mocoa, CO (VGZ)' },
        { code: 'VHM', name: 'Vilhelmina, SE (VHM)' },
        { code: 'VHY', name: 'Vichy, FR (VHY)' },
        { code: 'VIA', name: 'Videira, BR (VIA)' },
        { code: 'VIC', name: 'Vicenza, IT (VIC)' },
        { code: 'VIE', name: 'Vienna, AT (VIE)' },
        { code: 'VIF', name: 'Vieste, IT (VIF)' },
        { code: 'VIG', name: 'El Vigia, VE (VIG)' },
        { code: 'VIJ', name: 'Virgin Gorda, VG (VIJ)' },
        { code: 'VIN', name: 'Vinnica, UA (VIN)' },
        { code: 'VIR', name: 'Durban Virginia Airport, ZA (VIR)' },
        { code: 'VIT', name: 'Vitoria, ES (VIT)' },
        { code: 'VIX', name: 'Vitoria, BR (VIX)' },
        { code: 'VIY', name: 'Paris Villa Coublay, FR (VIY)' },
        { code: 'VKO', name: 'Moscow Vnukovo International Apt, RU (VKO)' },
        { code: 'VKT', name: 'Vorkuta, RU (VKT)' },
        { code: 'VLC', name: 'Valencia, ES (VLC)' },
        { code: 'VLD', name: 'Valdosta Regional Apt, US (VLD)' },
        { code: 'VLG', name: 'Villa Gesell, AR (VLG)' },
        { code: 'VLK', name: 'Volgodonsk, RU (VLK)' },
        { code: 'VLL', name: 'Valladolid, ES (VLL)' },
        { code: 'VLM', name: 'Villamontes, BO (VLM)' },
        { code: 'VLN', name: 'Valencia, VE (VLN)' },
        { code: 'VLP', name: 'Vila Rica, BR (VLP)' },
        { code: 'VLR', name: 'Vallenar, CL (VLR)' },
        { code: 'VLU', name: 'Velikiye Luki, RU (VLU)' },
        { code: 'VLV', name: 'Valera, VE (VLV)' },
        { code: 'VME', name: 'Villa Mercedes, AR (VME)' },
        { code: 'VMI', name: 'Vallemi, PY (VMI)' },
        { code: 'VNE', name: 'Vannes, FR (VNE)' },
        { code: 'VNO', name: 'Vilnius, LT (VNO)' },
        { code: 'VNR', name: 'Vanrook, AU (VNR)' },
        { code: 'VNS', name: 'Varanasi, IN (VNS)' },
        { code: 'VOG', name: 'Volgograd, RU (VOG)' },
        { code: 'VOL', name: 'Volos, GR (VOL)' },
        { code: 'VOT', name: 'Votuporanga, BR (VOT)' },
        { code: 'VOZ', name: 'Voronezh, RU (VOZ)' },
        { code: 'VPN', name: 'Vopnafjordur, IS (VPN)' },
        { code: 'VPS', name: 'Valparaiso Fort Walton Beach Apt, US (VPS)' },
        { code: 'VQS', name: 'Vieques, PR (VQS)' },
        { code: 'VRA', name: 'Varadero Juan Gualberto Gomez Apt, CU (VRA)' },
        { code: 'VRB', name: 'Vero Beach, US (VRB)' },
        { code: 'VRC', name: 'Virac, PH (VRC)' },
        { code: 'VRE', name: 'Vredendal, ZA (VRE)' },
        { code: 'VRK', name: 'Varkaus, FI (VRK)' },
        { code: 'VRL', name: 'Vila Real, PT (VRL)' },
        { code: 'VRN', name: 'Verona Airport, IT (VRN)' },
        { code: 'VRO', name: 'Varadero Kawama Apt, CU (VRO)' },
        { code: 'VRU', name: 'Vryburg, ZA (VRU)' },
        { code: 'VRY', name: 'Vaeroy, NO (VRY)' },
        { code: 'VSA', name: 'Villahermosa, MX (VSA)' },
        { code: 'VSE', name: 'Viseu, PT (VSE)' },
        { code: 'VSG', name: 'Lugansk, UA (VSG)' },
        { code: 'VST', name: 'Stockholm Vasteras Apt, SE (VST)' },
        { code: 'VTA', name: 'Victoria, HN (VTA)' },
        { code: 'VTB', name: 'Vitebsk, BY (VTB)' },
        { code: 'VTL', name: 'Vittel, FR (VTL)' },
        { code: 'VTU', name: 'Las Tunas, CU (VTU)' },
        { code: 'VTZ', name: 'Vishakhapatnam, IN (VTZ)' },
        { code: 'VUP', name: 'Valledupar, CO (VUP)' },
        { code: 'VUS', name: 'Velikij Ustyug, RU (VUS)' },
        { code: 'VVC', name: 'Villavicencio, CO (VVC)' },
        {
            code: 'VVI',
            name: 'Santa Cruz Viru Viru International Apt, BO (VVI)',
        },
        { code: 'VVK', name: 'Vastervik, SE (VVK)' },
        { code: 'VVO', name: 'Vladivostok, RU (VVO)' },
        { code: 'VXO', name: 'Vaxjo, SE (VXO)' },
        { code: 'VYD', name: 'Vryheid, ZA (VYD)' },
        { code: 'WAE', name: 'Wadi Ad Dawasir, SA (WAE)' },
        { code: 'WAG', name: 'Wanganui, NZ (WAG)' },
        { code: 'WAN', name: 'Waverney, AU (WAN)' },
        { code: 'WAP', name: 'Alto Palena, CL (WAP)' },
        { code: 'WAR', name: 'Waris, ID (WAR)' },
        { code: 'WAS', name: 'Washington - All Airports, US (WAS)' },
        { code: 'WAT', name: 'Waterford, IE (WAT)' },
        { code: 'WAU', name: 'Wauchope, AU (WAU)' },
        { code: 'WAV', name: 'Wave Hill, AU (WAV)' },
        { code: 'WAW', name: 'Warsaw, PL (WAW)' },
        { code: 'WAZ', name: 'Warwick, AU (WAZ)' },
        { code: 'WBA', name: 'Wahai, ID (WBA)' },
        { code: 'WBG', name: 'Schleswig-Jaqel, DE (WBG)' },
        { code: 'WCA', name: 'Castro, CL (WCA)' },
        { code: 'WCH', name: 'Chaiten, CL (WCH)' },
        { code: 'WDI', name: 'Wondai, AU (WDI)' },
        { code: 'WEF', name: 'Weifang, CN (WEF)' },
        { code: 'WEH', name: 'Weihai, CN (WEH)' },
        { code: 'WEI', name: 'Weipa, AU (WEI)' },
        { code: 'WEL', name: 'Welkom, ZA (WEL)' },
        { code: 'WEM', name: 'West Malling, GB (WEM)' },
        { code: 'WET', name: 'Wagethe, ID (WET)' },
        { code: 'WEW', name: 'Wee Waa, AU (WEW)' },
        { code: 'WEX', name: 'Wexford, IE (WEX)' },
        { code: 'WFD', name: 'Woodford, GB (WFD)' },
        { code: 'WGA', name: 'Wagga Wagga, AU (WGA)' },
        { code: 'WGC', name: 'Warangal, IN (WGC)' },
        { code: 'WGE', name: 'Walgett, AU (WGE)' },
        { code: 'WGN', name: 'Waitangi, NZ (WGN)' },
        { code: 'WGP', name: 'Waingapu, ID (WGP)' },
        { code: 'WGT', name: 'Wangaratta, AU (WGT)' },
        { code: 'WHK', name: 'Whakatane, NZ (WHK)' },
        { code: 'WHL', name: 'Welshpool, AU (WHL)' },
        { code: 'WHO', name: 'Franz Josef, NZ (WHO)' },
        { code: 'WHO', name: 'Houston - All Airports, US (WHO)' },
        { code: 'WHS', name: 'Whalsay, GB (WHS)' },
        { code: 'WHU', name: 'Wuhu, CN (WHU)' },
        { code: 'WIC', name: 'Wick, GB (WIC)' },
        { code: 'WID', name: 'Wildenrath, DE (WID)' },
        { code: 'WIE', name: 'Wiesbaden Air Base, DE (WIE)' },
        { code: 'WIK', name: 'Surfdale, NZ (WIK)' },
        { code: 'WIN', name: 'Winton, AU (WIN)' },
        { code: 'WIO', name: 'Wilcannia, AU (WIO)' },
        { code: 'WIR', name: 'Wairoa, NZ (WIR)' },
        { code: 'WIT', name: 'Wittenoom Gorge, AU (WIT)' },
        { code: 'WJU', name: 'Won-Ju, KR (WJU)' },
        { code: 'WKA', name: 'Wanaka, NZ (WKA)' },
        { code: 'WKB', name: 'Warracknabeal, AU (WKB)' },
        { code: 'WKF', name: 'Waterkloof, ZA (WKF)' },
        { code: 'WKJ', name: 'Wakkanai, JP (WKJ)' },
        { code: 'WKR', name: 'Walker Cay, BS (WKR)' },
        { code: 'WLA', name: 'Wallal, AU (WLA)' },
        { code: 'WLC', name: 'Walcha, AU (WLC)' },
        { code: 'WLG', name: 'Wellington, NZ (WLG)' },
        { code: 'WLK', name: 'Selawik, US (WLK)' },
        { code: 'WLL', name: 'Wollogorang, AU (WLL)' },
        { code: 'WLO', name: 'Waterloo, AU (WLO)' },
        { code: 'WMB', name: 'Warrnambool, AU (WMB)' },
        { code: 'WME', name: 'Mount Keith, AU (WME)' },
        { code: 'WMX', name: 'Wamena, ID (WMX)' },
        { code: 'WND', name: 'Windarra, AU (WND)' },
        { code: 'WNH', name: 'Wenshan, CN (WNH)' },
        { code: 'WNN', name: 'Wunnummin Lake, CA (WNN)' },
        { code: 'WNP', name: 'Naga, PH (WNP)' },
        { code: 'WNR', name: 'Windorah, AU (WNR)' },
        { code: 'WNZ', name: 'Wenzhou, CN (WNZ)' },
        { code: 'WOE', name: 'Woensdrecht, NL (WOE)' },
        { code: 'WOG', name: 'Woodgreen, AU (WOG)' },
        { code: 'WOK', name: 'Wonken, VE (WOK)' },
        { code: 'WOL', name: 'Wollongong, AU (WOL)' },
        { code: 'WON', name: 'Wondoola, AU (WON)' },
        { code: 'WOT', name: 'Wonan, TW (WOT)' },
        { code: 'WPA', name: 'Puerto Aisen, CL (WPA)' },
        { code: 'WPC', name: 'Pincher Creek, CA (WPC)' },
        { code: 'WPK', name: 'Wrotham Park, AU (WPK)' },
        { code: 'WPL', name: 'Powell Lake, CA (WPL)' },
        { code: 'WPR', name: 'Porvenir, CL (WPR)' },
        { code: 'WPU', name: 'Puerto Williams, CL (WPU)' },
        { code: 'WRE', name: 'Whangarei, NZ (WRE)' },
        { code: 'WRG', name: 'Wrangell, US (WRG)' },
        { code: 'WRL', name: 'Worland, US (WRL)' },
        { code: 'WRO', name: 'Wroclaw, PL (WRO)' },
        { code: 'WRW', name: 'Warrawagine, AU (WRW)' },
        { code: 'WRY', name: 'Westray, GB (WRY)' },
        { code: 'WSP', name: 'Waspam, NI (WSP)' },
        { code: 'WSR', name: 'Wasior, ID (WSR)' },
        { code: 'WST', name: 'Westerly, US (WST)' },
        { code: 'WSY', name: 'Airlie Beach, AU (WSY)' },
        { code: 'WSZ', name: 'Westport, NZ (WSZ)' },
        { code: 'WTD', name: 'West End, BS (WTD)' },
        { code: 'WTK', name: 'Noatak, US (WTK)' },
        { code: 'WTN', name: 'Waddington, GB (WTN)' },
        { code: 'WTZ', name: 'Whitianga, NZ (WTZ)' },
        { code: 'WUA', name: 'Wu Hai, CN (WUA)' },
        { code: 'WUD', name: 'Wudinna, AU (WUD)' },
        { code: 'WUH', name: 'Wuhan, CN (WUH)' },
        { code: 'WUN', name: 'Wiluna, AU (WUN)' },
        { code: 'WUS', name: 'Wuyishan, CN (WUS)' },
        { code: 'WUX', name: 'Wuxi, CN (WUX)' },
        { code: 'WUZ', name: 'Wuzhou, CN (WUZ)' },
        { code: 'WVN', name: 'Wilhelmshaven, DE (WVN)' },
        { code: 'WWG', name: 'Al Koude, OM (WWG)' },
        { code: 'WWI', name: 'Woodie Woodie, AU (WWI)' },
        { code: 'WWY', name: 'West Wyalong, AU (WWY)' },
        { code: 'WXF', name: 'Braintree, GB (WXF)' },
        { code: 'WXN', name: 'Wanxian, CN (WXN)' },
        { code: 'WYA', name: 'Whyalla, AU (WYA)' },
        { code: 'WYN', name: 'Wyndham, AU (WYN)' },
        { code: 'WYS', name: 'West Yellowstone, US (WYS)' },
        { code: 'XAP', name: 'Chapeco, BR (XAP)' },
        { code: 'XBB', name: 'Blubber Bay, CA (XBB)' },
        { code: 'XBE', name: 'Bearskin Lake, CA (XBE)' },
        { code: 'XBR', name: 'Brockville, CA (XBR)' },
        { code: 'XBW', name: 'Killineq, CA (XBW)' },
        { code: 'XCL', name: 'Cluff Lake, CA (XCL)' },
        { code: 'XCM', name: 'Chatham, CA (XCM)' },
        { code: 'XCN', name: 'Coron, PH (XCN)' },
        { code: 'XCO', name: 'Colac, AU (XCO)' },
        { code: 'XCR', name: 'Chalons Sur Marne, FR (XCR)' },
        { code: 'XCY', name: 'Chateau Thierry, FR (XCY)' },
        { code: 'XEN', name: 'Xingcheng, CN (XEN)' },
        { code: 'XFN', name: 'Xiangfan, CN (XFN)' },
        { code: 'XFW', name: 'Finkenwerder, DE (XFW)' },
        { code: 'XGL', name: 'Granville Lake, CA (XGL)' },
        { code: 'XGR', name: 'Kangiqsualujjuaq, CA (XGR)' },
        { code: 'XIC', name: 'Xichang, CN (XIC)' },
        { code: 'XIG', name: 'Xinguara, BR (XIG)' },
        { code: 'XIL', name: 'Xilinhot, CN (XIL)' },
        { code: 'XIN', name: 'Xingning, CN (XIN)' },
        { code: 'XIY', name: 'Xi An Xianyang Apt, CN (XIY)' },
        { code: 'XKO', name: 'Kemano, CA (XKO)' },
        { code: 'XKS', name: 'Kasabonika, CA (XKS)' },
        { code: 'XLB', name: 'Lac Brochet, CA (XLB)' },
        { code: 'XLF', name: 'Leaf Bay, CA (XLF)' },
        { code: 'XLW', name: 'Lemwerder, DE (XLW)' },
        { code: 'XMA', name: 'Maramag, PH (XMA)' },
        { code: 'XMC', name: 'Mallacoota, AU (XMC)' },
        { code: 'XML', name: 'Minlaton, AU (XML)' },
        { code: 'XMN', name: 'Xiamen, CN (XMN)' },
        { code: 'XMP', name: 'Macmillan Pass, CA (XMP)' },
        { code: 'XMS', name: 'Macas, EC (XMS)' },
        { code: 'XMY', name: 'Yam Island, AU (XMY)' },
        {
            code: 'XNA',
            name: 'Fayetteville Northwest Arkansas Reg Apt, US (XNA)',
        },
        { code: 'XNN', name: 'Xining, CN (XNN)' },
        { code: 'XNT', name: 'Xingtai, CN (XNT)' },
        { code: 'XOZ', name: 'Doha Free Zone, QA (XOZ)' },
        { code: 'XPK', name: 'Pukatawagan, CA (XPK)' },
        { code: 'XPL', name: 'Comayagua, HN (XPL)' },
        { code: 'XPP', name: 'Poplar River, CA (XPP)' },
        { code: 'XQP', name: 'Quepos, CR (XQP)' },
        { code: 'XQU', name: 'Qualicum, CA (XQU)' },
        { code: 'XRH', name: 'Richmond, AU (XRH)' },
        { code: 'XRR', name: 'Ross River, CA (XRR)' },
        { code: 'XRY', name: 'Jerez De La Frontera, ES (XRY)' },
        { code: 'XSC', name: 'South Caicos, TC (XSC)' },
        { code: 'XSI', name: 'South Indian Lake, CA (XSI)' },
        { code: 'XSO', name: 'Siocon, PH (XSO)' },
        { code: 'XSP', name: 'Singapore Seletar Apt, SG (XSP)' },
        { code: 'XTG', name: 'Thargomindah, AU (XTG)' },
        { code: 'XTL', name: 'Tadoule Lake, CA (XTL)' },
        { code: 'XTO', name: 'Taroom, AU (XTO)' },
        { code: 'XTR', name: 'Tara, AU (XTR)' },
        { code: 'XUZ', name: 'Xuzhou, CN (XUZ)' },
        { code: 'XXF', name: 'Scenic Flight, NZ (XXF)' },
        { code: 'XXS', name: 'Skiplane Scenic, NZ (XXS)' },
        { code: 'XYT', name: 'Toulouse Montaudran Apt, FR (XYT)' },
        { code: 'YAA', name: 'Anahim Lake, CA (YAA)' },
        { code: 'YAB', name: 'Arctic Bay, CA (YAB)' },
        { code: 'YAC', name: 'Cat Lake, CA (YAC)' },
        { code: 'YAD', name: 'Moose Lake, CA (YAD)' },
        { code: 'YAE', name: 'Alta Lake, CA (YAE)' },
        { code: 'YAF', name: 'Asbestos Hill, CA (YAF)' },
        { code: 'YAG', name: 'Fort Frances, CA (YAG)' },
        { code: 'YAH', name: 'Lagrande 4, CA (YAH)' },
        { code: 'YAI', name: 'Chillan, CL (YAI)' },
        { code: 'YAJ', name: 'Lyall Harbour, CA (YAJ)' },
        { code: 'YAK', name: 'Yakutat, US (YAK)' },
        { code: 'YAL', name: 'Alert Bay, CA (YAL)' },
        { code: 'YAM', name: 'Sault Ste Marie, CA (YAM)' },
        { code: 'YAQ', name: 'Maple Bay, CA (YAQ)' },
        { code: 'YAR', name: 'Lagrande, CA (YAR)' },
        { code: 'YAT', name: 'Attawapiskat, CA (YAT)' },
        { code: 'YAU', name: 'Kattiniq/Donaldson Lake, CA (YAU)' },
        { code: 'YAV', name: "Miner'S Bay, CA (YAV)" },
        { code: 'YAW', name: 'Halifax Shearwater Apt, CA (YAW)' },
        { code: 'YAX', name: 'Angling Lake, CA (YAX)' },
        { code: 'YAY', name: 'St Anthony, CA (YAY)' },
        { code: 'YAZ', name: 'Tofino, CA (YAZ)' },
        { code: 'YBA', name: 'Banff, CA (YBA)' },
        { code: 'YBB', name: 'Pelly Bay, CA (YBB)' },
        { code: 'YBC', name: 'Baie Comeau, CA (YBC)' },
        { code: 'YBD', name: 'New Westminster, CA (YBD)' },
        { code: 'YBE', name: 'Uranium City, CA (YBE)' },
        { code: 'YBF', name: 'Bamfield, CA (YBF)' },
        { code: 'YBG', name: 'Bagotville, CA (YBG)' },
        { code: 'YBH', name: 'Bull Harbour, CA (YBH)' },
        { code: 'YBI', name: 'Black Tickle, CA (YBI)' },
        { code: 'YBJ', name: 'Baie Johan Beetz, CA (YBJ)' },
        { code: 'YBK', name: 'Baker Lake, CA (YBK)' },
        { code: 'YBL', name: 'Campbell River Municipal Apt, CA (YBL)' },
        { code: 'YBM', name: 'Bronson Creek, CA (YBM)' },
        { code: 'YBN', name: 'Borden, CA (YBN)' },
        { code: 'YBO', name: 'Bobquinn Lake, CA (YBO)' },
        { code: 'YBP', name: 'Yibin, CN (YBP)' },
        { code: 'YBQ', name: 'Telegraph Harbour, CA (YBQ)' },
        { code: 'YBR', name: 'Brandon, CA (YBR)' },
        { code: 'YBS', name: 'Opapamiska Lake, CA (YBS)' },
        { code: 'YBT', name: 'Brochet, CA (YBT)' },
        { code: 'YBV', name: 'Berens River, CA (YBV)' },
        { code: 'YBW', name: 'Bedwell Harbor, CA (YBW)' },
        { code: 'YBX', name: 'Blanc Sablon, CA (YBX)' },
        { code: 'YBY', name: 'Bonnyville, CA (YBY)' },
        { code: 'YCA', name: 'Courtenay, CA (YCA)' },
        { code: 'YCB', name: 'Cambridge Bay, CA (YCB)' },
        { code: 'YCC', name: 'Cornwall, CA (YCC)' },
        { code: 'YCD', name: 'Nanaimo Cassidy Apt, CA (YCD)' },
        { code: 'YCE', name: 'Centralia, CA (YCE)' },
        { code: 'YCF', name: 'Cortes Bay, CA (YCF)' },
        { code: 'YCG', name: 'Castlegar, CA (YCG)' },
        { code: 'YCH', name: 'Miramichi, CA (YCH)' },
        { code: 'YCI', name: 'Caribou Island, CA (YCI)' },
        { code: 'YCJ', name: 'Cape St James, CA (YCJ)' },
        { code: 'YCK', name: 'Colville Lake, CA (YCK)' },
        { code: 'YCL', name: 'Charlo, CA (YCL)' },
        { code: 'YCM', name: 'St Catharines, CA (YCM)' },
        { code: 'YCN', name: 'Cochrane, CA (YCN)' },
        { code: 'YCO', name: 'Kugluktuk Coppermine, CA (YCO)' },
        { code: 'YCP', name: 'Co-Op Point, CA (YCP)' },
        { code: 'YCQ', name: 'Chetwynd, CA (YCQ)' },
        { code: 'YCR', name: 'Cross Lake, CA (YCR)' },
        { code: 'YCS', name: 'Chesterfield Inlet, CA (YCS)' },
        { code: 'YCT', name: 'Coronation, CA (YCT)' },
        { code: 'YCU', name: 'Yun Cheng, CN (YCU)' },
        { code: 'YCV', name: 'Cartierville, CA (YCV)' },
        { code: 'YCW', name: 'Chilliwack, CA (YCW)' },
        { code: 'YCX', name: 'Gagetown, CA (YCX)' },
        { code: 'YCY', name: 'Clyde River, CA (YCY)' },
        { code: 'YCZ', name: 'Fairmount Springs, CA (YCZ)' },
        { code: 'YDA', name: 'Dawson City, CA (YDA)' },
        { code: 'YDB', name: 'Burwash Landings, CA (YDB)' },
        { code: 'YDC', name: 'Drayton Valley, CA (YDC)' },
        { code: 'YDE', name: 'Paradise River, CA (YDE)' },
        { code: 'YDF', name: 'Deer Lake, CA (YDF)' },
        { code: 'YDG', name: 'Digby, CA (YDG)' },
        { code: 'YDH', name: 'Daniels Harbour, CA (YDH)' },
        { code: 'YDI', name: 'Davis Inlet, CA (YDI)' },
        { code: 'YDJ', name: 'Hatchett Lake, CA (YDJ)' },
        { code: 'YDK', name: 'Main Duck Island, CA (YDK)' },
        { code: 'YDL', name: 'Dease Lake, CA (YDL)' },
        { code: 'YDN', name: 'Dauphin, CA (YDN)' },
        { code: 'YDO', name: 'Dolbeau, CA (YDO)' },
        { code: 'YDP', name: 'Nain, CA (YDP)' },
        { code: 'YDQ', name: 'Dawson Creek, CA (YDQ)' },
        { code: 'YDR', name: 'Broadview, CA (YDR)' },
        { code: 'YDS', name: 'Desolation Sound, CA (YDS)' },
        { code: 'YDT', name: 'Vancouver Boundary Bay Apt, CA (YDT)' },
        { code: 'YDU', name: 'Kasba Lake, CA (YDU)' },
        { code: 'YDV', name: 'Bloodvein, CA (YDV)' },
        { code: 'YDW', name: 'Obre Lake, CA (YDW)' },
        { code: 'YDX', name: 'Doc Creek, CA (YDX)' },
        { code: 'YEB', name: 'Bar River, CA (YEB)' },
        { code: 'YEC', name: 'Yecheon, KR (YEC)' },
        { code: 'YED', name: 'Edmonton Namao Field, CA (YED)' },
        { code: 'YEG', name: 'Edmonton International Apt, CA (YEG)' },
        { code: 'YEI', name: 'Bursa, TR (YEI)' },
        { code: 'YEK', name: 'Arviat, CA (YEK)' },
        { code: 'YEL', name: 'Elliot Lake, CA (YEL)' },
        { code: 'YEM', name: 'Manitowaning, CA (YEM)' },
        { code: 'YEN', name: 'Estevan, CA (YEN)' },
        { code: 'YEO', name: 'Yeovilton, GB (YEO)' },
        { code: 'YEP', name: 'Estevan Point, CA (YEP)' },
        { code: 'YER', name: 'Fort Severn, CA (YER)' },
        { code: 'YET', name: 'Edson, CA (YET)' },
        { code: 'YEU', name: 'Eureka, CA (YEU)' },
        { code: 'YEV', name: 'Inuvik, CA (YEV)' },
        { code: 'YEY', name: 'Amos, CA (YEY)' },
        { code: 'YFA', name: 'Fort Albany, CA (YFA)' },
        { code: 'YFB', name: 'Iqaluit, CA (YFB)' },
        { code: 'YFC', name: 'Fredericton, CA (YFC)' },
        { code: 'YFE', name: 'Forestville, CA (YFE)' },
        { code: 'YFG', name: 'Fontanges, CA (YFG)' },
        { code: 'YFH', name: 'Fort Hope, CA (YFH)' },
        { code: 'YFJ', name: 'Snare Lake, CA (YFJ)' },
        { code: 'YFL', name: 'Fort Reliance, CA (YFL)' },
        { code: 'YFO', name: 'Flin Flon, CA (YFO)' },
        { code: 'YFR', name: 'Fort Resolution, CA (YFR)' },
        { code: 'YFS', name: 'Fort Simpson, CA (YFS)' },
        { code: 'YFX', name: 'Fox Harbour, CA (YFX)' },
        { code: 'YGA', name: 'Gagnon, CA (YGA)' },
        { code: 'YGB', name: 'Gillies Bay, CA (YGB)' },
        { code: 'YGC', name: 'Grande Cache, CA (YGC)' },
        { code: 'YGE', name: 'Gorge Harbor, CA (YGE)' },
        { code: 'YGG', name: 'Ganges Harbor, CA (YGG)' },
        { code: 'YGH', name: 'Fort Good Hope, CA (YGH)' },
        { code: 'YGJ', name: 'Yonago, JP (YGJ)' },
        { code: 'YGK', name: 'Kingston Norman Rogers Apt, CA (YGK)' },
        { code: 'YGL', name: 'La Grande, CA (YGL)' },
        { code: 'YGM', name: 'Gimli, CA (YGM)' },
        { code: 'YGN', name: 'Greenway Sound, CA (YGN)' },
        { code: 'YGO', name: 'Gods Narrows, CA (YGO)' },
        { code: 'YGP', name: 'Gaspe, CA (YGP)' },
        { code: 'YGQ', name: 'Geraldton, CA (YGQ)' },
        { code: 'YGR', name: 'Iles De La Madeleine, CA (YGR)' },
        { code: 'YGS', name: 'Germansen, CA (YGS)' },
        { code: 'YGT', name: 'Igloolik, CA (YGT)' },
        { code: 'YGV', name: 'Havre St Pierre, CA (YGV)' },
        { code: 'YGW', name: 'Kuujjuarapik, CA (YGW)' },
        { code: 'YGX', name: 'Gillam, CA (YGX)' },
        { code: 'YGY', name: 'Deception, CA (YGY)' },
        { code: 'YGZ', name: 'Grise Fiord, CA (YGZ)' },
        { code: 'YHA', name: 'Port Hope Simpson, CA (YHA)' },
        { code: 'YHB', name: 'Hudson Bay, CA (YHB)' },
        { code: 'YHC', name: 'Hakai Pass, CA (YHC)' },
        { code: 'YHD', name: 'Dryden, CA (YHD)' },
        { code: 'YHE', name: 'Hope, CA (YHE)' },
        { code: 'YHF', name: 'Hearst, CA (YHF)' },
        { code: 'YHG', name: 'Charlottetown, CA (YHG)' },
        { code: 'YHI', name: 'Holman Island, CA (YHI)' },
        { code: 'YHK', name: 'Gjoa Haven, CA (YHK)' },
        { code: 'YHM', name: 'Toronto John C Munro Hamilton, CA (YHM)' },
        { code: 'YHN', name: 'Hornepayne, CA (YHN)' },
        { code: 'YHO', name: 'Hopedale, CA (YHO)' },
        { code: 'YHP', name: 'Poplar Hill, CA (YHP)' },
        { code: 'YHR', name: 'Chevery, CA (YHR)' },
        { code: 'YHS', name: 'Sechelt, CA (YHS)' },
        { code: 'YHT', name: 'Haines Junction, CA (YHT)' },
        { code: 'YHU', name: 'Montreal St Hubert Apt, CA (YHU)' },
        { code: 'YHY', name: 'Hay River, CA (YHY)' },
        { code: 'YHZ', name: 'Halifax International Apt, CA (YHZ)' },
        { code: 'YIB', name: 'Atikokan, CA (YIB)' },
        { code: 'YIF', name: 'Pakuashipi, CA (YIF)' },
        { code: 'YIG', name: 'Big Bay Marina, CA (YIG)' },
        { code: 'YIH', name: 'Yichang, CN (YIH)' },
        { code: 'YIK', name: 'Ivujivik, CA (YIK)' },
        { code: 'YIN', name: 'Yining, CN (YIN)' },
        { code: 'YIO', name: 'Pond Inlet, CA (YIO)' },
        { code: 'YIV', name: 'Island Lake/Garden Hill, CA (YIV)' },
        { code: 'YIW', name: 'Yiwu, CN (YIW)' },
        { code: 'YJA', name: 'Jasper Hinton Airport, CA (YJA)' },
        { code: 'YJF', name: 'Fort Liard, CA (YJF)' },
        { code: 'YJN', name: 'St Jean, CA (YJN)' },
        { code: 'YJO', name: 'Johnny Mountain, CA (YJO)' },
        { code: 'YJP', name: 'Jasper-Hinton, CA (YJP)' },
        { code: 'YJT', name: 'Stephenville, CA (YJT)' },
        { code: 'YKA', name: 'Kamloops, CA (YKA)' },
        { code: 'YKC', name: 'Collins Bay, CA (YKC)' },
        { code: 'YKD', name: 'Kincardine, CA (YKD)' },
        { code: 'YKE', name: 'Knee Lake, CA (YKE)' },
        { code: 'YKF', name: 'Kitchener/Waterloo, CA (YKF)' },
        { code: 'YKG', name: 'Kangirsuk, CA (YKG)' },
        { code: 'YKI', name: 'Kennosao Lake, CA (YKI)' },
        { code: 'YKJ', name: 'Key Lake, CA (YKJ)' },
        { code: 'YKK', name: 'Kitkatla, CA (YKK)' },
        { code: 'YKL', name: 'Schefferville, CA (YKL)' },
        { code: 'YKM', name: 'Yakima Air Terminal, US (YKM)' },
        { code: 'YKQ', name: 'Waskaganish, CA (YKQ)' },
        { code: 'YKS', name: 'Yakutsk, RU (YKS)' },
        { code: 'YKT', name: 'Klemtu, CA (YKT)' },
        { code: 'YKU', name: 'Chisasibi, CA (YKU)' },
        { code: 'YKX', name: 'Kirkland Lake, CA (YKX)' },
        { code: 'YKY', name: 'Kindersley, CA (YKY)' },
        { code: 'YKZ', name: 'Toronto Buttonville Apt, CA (YKZ)' },
        { code: 'YLA', name: 'Langara, CA (YLA)' },
        { code: 'YLB', name: 'Lac Biche, CA (YLB)' },
        { code: 'YLC', name: 'Kimmirut/Lake Harbour, CA (YLC)' },
        { code: 'YLD', name: 'Chapleau, CA (YLD)' },
        { code: 'YLE', name: 'Wha Ti Lac La Martre, CA (YLE)' },
        { code: 'YLF', name: 'Laforges, CA (YLF)' },
        { code: 'YLG', name: 'Yalgoo, AU (YLG)' },
        { code: 'YLH', name: 'Lansdowne House, CA (YLH)' },
        { code: 'YLI', name: 'Ylivieska, FI (YLI)' },
        { code: 'YLJ', name: 'Meadow Lake, CA (YLJ)' },
        { code: 'YLL', name: 'Lloydminster, CA (YLL)' },
        { code: 'YLM', name: 'Clinton Creek, CA (YLM)' },
        { code: 'YLN', name: 'Yilan, CN (YLN)' },
        { code: 'YLO', name: 'Shilo, CA (YLO)' },
        { code: 'YLP', name: 'Mingan, CA (YLP)' },
        { code: 'YLQ', name: 'La Tuque, CA (YLQ)' },
        { code: 'YLR', name: 'Leaf Rapids, CA (YLR)' },
        { code: 'YLS', name: 'Lebel-Sur-Quevillon, CA (YLS)' },
        { code: 'YLT', name: 'Alert, CA (YLT)' },
        { code: 'YLW', name: 'Kelowna, CA (YLW)' },
        { code: 'YLX', name: 'Long Point, CA (YLX)' },
        { code: 'YLY', name: 'Langley, CA (YLY)' },
        { code: 'YMA', name: 'Mayo, CA (YMA)' },
        { code: 'YMB', name: 'Merritt, CA (YMB)' },
        { code: 'YMC', name: 'Maricourt Airstrip, CA (YMC)' },
        { code: 'YMD', name: 'Mould Bay, CA (YMD)' },
        { code: 'YME', name: 'Matane, CA (YME)' },
        { code: 'YMF', name: 'Montagne Harbor, CA (YMF)' },
        { code: 'YMG', name: 'Manitouwadge, CA (YMG)' },
        { code: 'YMH', name: "Mary'S Harbour, CA (YMH)" },
        { code: 'YMI', name: 'Minaki, CA (YMI)' },
        { code: 'YMJ', name: 'Moose Jaw, CA (YMJ)' },
        { code: 'YML', name: 'Murray Bay, CA (YML)' },
        { code: 'YMM', name: 'Fort Mcmurray, CA (YMM)' },
        { code: 'YMN', name: 'Makkovik, CA (YMN)' },
        { code: 'YMO', name: 'Moosonee, CA (YMO)' },
        { code: 'YMP', name: 'Port Mcneil, CA (YMP)' },
        { code: 'YMR', name: 'Merry Island, CA (YMR)' },
        { code: 'YMS', name: 'Yurimaguas, PE (YMS)' },
        { code: 'YMT', name: 'Chibougamau, CA (YMT)' },
        { code: 'YMW', name: 'Maniwaki, CA (YMW)' },
        { code: 'YMX', name: 'Montreal Mirabel Intl Apt, CA (YMX)' },
        { code: 'YNA', name: 'Natashquan, CA (YNA)' },
        { code: 'YNB', name: 'Yanbu, SA (YNB)' },
        { code: 'YNC', name: 'Wemindji, CA (YNC)' },
        { code: 'YND', name: 'Ottawa Gatineau Airport, CA (YND)' },
        { code: 'YNE', name: 'Norway House, CA (YNE)' },
        { code: 'YNF', name: 'Corner Brook, CA (YNF)' },
        { code: 'YNG', name: 'Youngstown, US (YNG)' },
        { code: 'YNH', name: 'Hudson Hope, CA (YNH)' },
        { code: 'YNI', name: 'Nitchequon, CA (YNI)' },
        { code: 'YNJ', name: 'Yanji, CN (YNJ)' },
        { code: 'YNK', name: 'Nootka Sound, CA (YNK)' },
        { code: 'YNL', name: 'Points North Landing, CA (YNL)' },
        { code: 'YNM', name: 'Matagami, CA (YNM)' },
        { code: 'YNN', name: 'Yandicoogina, AU (YNN)' },
        { code: 'YNO', name: 'North Spirit Lake, CA (YNO)' },
        { code: 'YNP', name: 'Natuashish, CA (YNP)' },
        { code: 'YNR', name: 'Arnes, CA (YNR)' },
        { code: 'YNS', name: 'Nemiscau, CA (YNS)' },
        { code: 'YNT', name: 'Yantai, CN (YNT)' },
        { code: 'YNY', name: 'Yangyang, KR (YNY)' },
        { code: 'YNZ', name: 'Yancheng, CN (YNZ)' },
        { code: 'YOA', name: 'Ekati, CA (YOA)' },
        { code: 'YOC', name: 'Old Crow, CA (YOC)' },
        { code: 'YOD', name: 'Cold Lake, CA (YOD)' },
        { code: 'YOE', name: 'Falher, CA (YOE)' },
        { code: 'YOG', name: 'Ogoki, CA (YOG)' },
        { code: 'YOH', name: 'Oxford House, CA (YOH)' },
        { code: 'YOJ', name: 'High Level, CA (YOJ)' },
        { code: 'YOK', name: 'Yokohama, JP (YOK)' },
        { code: 'YOO', name: 'Oshawa, CA (YOO)' },
        { code: 'YOP', name: 'Rainbow Lake, CA (YOP)' },
        { code: 'YOS', name: 'Owen Sound, CA (YOS)' },
        { code: 'YOW', name: 'Ottawa Mcdonald Cartier Intl Apt, CA (YOW)' },
        { code: 'YOY', name: 'Valcartier, CA (YOY)' },
        { code: 'YPA', name: 'Prince Albert, CA (YPA)' },
        { code: 'YPB', name: 'Port Alberni, CA (YPB)' },
        { code: 'YPC', name: 'Paulatuk, CA (YPC)' },
        { code: 'YPD', name: 'Parry Sound, CA (YPD)' },
        { code: 'YPE', name: 'Peace River, CA (YPE)' },
        { code: 'YPF', name: 'Esquimalt, CA (YPF)' },
        { code: 'YPG', name: 'Portage La Prairie, CA (YPG)' },
        { code: 'YPH', name: 'Inukjuak, CA (YPH)' },
        { code: 'YPI', name: 'Port Simpson, CA (YPI)' },
        { code: 'YPJ', name: 'Aupaluk, CA (YPJ)' },
        { code: 'YPL', name: 'Pickle Lake, CA (YPL)' },
        { code: 'YPM', name: 'Pikangikum, CA (YPM)' },
        { code: 'YPN', name: 'Port Menier, CA (YPN)' },
        { code: 'YPO', name: 'Peawanuck, CA (YPO)' },
        { code: 'YPP', name: 'Pine Point, CA (YPP)' },
        { code: 'YPQ', name: 'Peterborough, CA (YPQ)' },
        { code: 'YPR', name: 'Prince Rupert Digby Island Apt, CA (YPR)' },
        { code: 'YPS', name: 'Port Hawkesbury, CA (YPS)' },
        { code: 'YPT', name: 'Pender Harbor, CA (YPT)' },
        { code: 'YPW', name: 'Powell River, CA (YPW)' },
        { code: 'YPX', name: 'Puvirnituq, CA (YPX)' },
        { code: 'YPY', name: 'Fort Chipewyan, CA (YPY)' },
        { code: 'YPZ', name: 'Burns Lake, CA (YPZ)' },
        { code: 'YQA', name: 'Muskoka, CA (YQA)' },
        { code: 'YQB', name: 'Quebec International Airport, CA (YQB)' },
        { code: 'YQC', name: 'Quaqtaq, CA (YQC)' },
        { code: 'YQD', name: 'The Pas, CA (YQD)' },
        { code: 'YQE', name: 'Kimberley, CA (YQE)' },
        { code: 'YQF', name: 'Red Deer, CA (YQF)' },
        { code: 'YQG', name: 'Windsor, CA (YQG)' },
        { code: 'YQH', name: 'Watson Lake, CA (YQH)' },
        { code: 'YQI', name: 'Yarmouth, CA (YQI)' },
        { code: 'YQK', name: 'Kenora, CA (YQK)' },
        { code: 'YQL', name: 'Lethbridge, CA (YQL)' },
        {
            code: 'YQM',
            name: 'Greater Moncton International Airport, CA (YQM)',
        },
        { code: 'YQN', name: 'Nakina, CA (YQN)' },
        { code: 'YQQ', name: 'Comox, CA (YQQ)' },
        { code: 'YQR', name: 'Regina, CA (YQR)' },
        { code: 'YQS', name: 'St Thomas, CA (YQS)' },
        { code: 'YQT', name: 'Thunder Bay, CA (YQT)' },
        { code: 'YQU', name: 'Grande Prairie, CA (YQU)' },
        { code: 'YQV', name: 'Yorkton, CA (YQV)' },
        { code: 'YQW', name: 'North Battleford, CA (YQW)' },
        { code: 'YQX', name: 'Gander, CA (YQX)' },
        { code: 'YQY', name: 'Sydney, CA (YQY)' },
        { code: 'YQZ', name: 'Quesnel, CA (YQZ)' },
        { code: 'YRA', name: 'Rae Lakes, CA (YRA)' },
        { code: 'YRB', name: 'Resolute, CA (YRB)' },
        { code: 'YRD', name: 'Dean River, CA (YRD)' },
        { code: 'YRE', name: 'Resolution Island, CA (YRE)' },
        { code: 'YRF', name: 'Cartwright, CA (YRF)' },
        { code: 'YRG', name: 'Rigolet, CA (YRG)' },
        { code: 'YRI', name: 'Riviere Du Loup, CA (YRI)' },
        { code: 'YRJ', name: 'Roberval, CA (YRJ)' },
        { code: 'YRL', name: 'Red Lake, CA (YRL)' },
        { code: 'YRM', name: 'Rocky Mountain House, CA (YRM)' },
        { code: 'YRN', name: "River'S Inlet, CA (YRN)" },
        { code: 'YRO', name: 'Ottawa Rockcliffe Stolport, CA (YRO)' },
        { code: 'YRQ', name: 'Trois-Rivieres, CA (YRQ)' },
        { code: 'YRR', name: 'Stuart Island, CA (YRR)' },
        { code: 'YRS', name: 'Red Sucker Lake, CA (YRS)' },
        { code: 'YRT', name: 'Rankin Inlet, CA (YRT)' },
        { code: 'YRV', name: 'Revelstoke, CA (YRV)' },
        { code: 'YSA', name: 'Sable Island, CA (YSA)' },
        { code: 'YSB', name: 'Sudbury Airport, CA (YSB)' },
        { code: 'YSC', name: 'Sherbrooke, CA (YSC)' },
        { code: 'YSD', name: 'Suffield, CA (YSD)' },
        { code: 'YSE', name: 'Squamish, CA (YSE)' },
        { code: 'YSF', name: 'Stony Rapids, CA (YSF)' },
        { code: 'YSG', name: 'Lutselke Snowdrift, CA (YSG)' },
        { code: 'YSH', name: 'Smith Falls, CA (YSH)' },
        { code: 'YSI', name: 'San Souci, CA (YSI)' },
        { code: 'YSJ', name: 'St John, CA (YSJ)' },
        { code: 'YSK', name: 'Sanikiluaq, CA (YSK)' },
        { code: 'YSL', name: 'St Leonard, CA (YSL)' },
        { code: 'YSM', name: 'Fort Smith, CA (YSM)' },
        { code: 'YSN', name: 'Salmon Arm, CA (YSN)' },
        { code: 'YSO', name: 'Postville, CA (YSO)' },
        { code: 'YSP', name: 'Marathon, CA (YSP)' },
        { code: 'YSQ', name: 'Spring Island, CA (YSQ)' },
        { code: 'YSR', name: 'Nanisivik, CA (YSR)' },
        { code: 'YSS', name: 'Slate Island, CA (YSS)' },
        { code: 'YST', name: 'Ste Therese Point, CA (YST)' },
        { code: 'YSU', name: 'Summerside, CA (YSU)' },
        { code: 'YSV', name: 'Saglek, CA (YSV)' },
        { code: 'YSX', name: 'Shearwater, CA (YSX)' },
        { code: 'YSY', name: 'Sachs Harbour, CA (YSY)' },
        { code: 'YSZ', name: 'Squirrel Cove, CA (YSZ)' },
        { code: 'YTA', name: 'Pembroke, CA (YTA)' },
        { code: 'YTB', name: 'Hartley Bay, CA (YTB)' },
        { code: 'YTC', name: 'Sturdee, CA (YTC)' },
        { code: 'YTD', name: 'Thicket Portage, CA (YTD)' },
        { code: 'YTE', name: 'Cape Dorset, CA (YTE)' },
        { code: 'YTF', name: 'Alma, CA (YTF)' },
        { code: 'YTG', name: 'Sullivan Bay, CA (YTG)' },
        { code: 'YTH', name: 'Thompson, CA (YTH)' },
        { code: 'YTI', name: 'Triple Island, CA (YTI)' },
        { code: 'YTJ', name: 'Terrace Bay, CA (YTJ)' },
        { code: 'YTK', name: 'Tulugak, CA (YTK)' },
        { code: 'YTL', name: 'Big Trout Lake, CA (YTL)' },
        { code: 'YTM', name: 'Mont Tremblant, CA (YTM)' },
        { code: 'YTN', name: 'Riviere-Au-Tonnerre, CA (YTN)' },
        { code: 'YTO', name: 'Toronto - All Airports, CA (YTO)' },
        { code: 'YTQ', name: 'Tasiujuaq, CA (YTQ)' },
        { code: 'YTR', name: 'Trenton, CA (YTR)' },
        { code: 'YTS', name: 'Timmins, CA (YTS)' },
        { code: 'YTT', name: 'Tisdale, CA (YTT)' },
        { code: 'YTU', name: 'Tasu, CA (YTU)' },
        { code: 'YTX', name: 'Telegraph Creek, CA (YTX)' },
        { code: 'YTZ', name: 'Toronto City Centre Apt, CA (YTZ)' },
        { code: 'YUA', name: 'Yuanmou, CN (YUA)' },
        { code: 'YUB', name: 'Tuktoyaktuk, CA (YUB)' },
        { code: 'YUD', name: 'Umiujaq, CA (YUD)' },
        { code: 'YUE', name: 'Yuendumu, AU (YUE)' },
        {
            code: 'YUL',
            name: 'Montreal Pierre Elliott Trudeau Int Apt, CA (YUL)',
        },
        { code: 'YUT', name: 'Repulse Bay, CA (YUT)' },
        { code: 'YUX', name: 'Hall Beach, CA (YUX)' },
        { code: 'YUY', name: 'Rouyn, CA (YUY)' },
        { code: 'YVB', name: 'Bonaventure, CA (YVB)' },
        { code: 'YVC', name: 'La Ronge, CA (YVC)' },
        { code: 'YVE', name: 'Vernon, CA (YVE)' },
        { code: 'YVG', name: 'Vermillion, CA (YVG)' },
        { code: 'YVM', name: 'Qikiqtarjuaq, CA (YVM)' },
        { code: 'YVO', name: "Val D'Or, CA (YVO)" },
        { code: 'YVP', name: 'Kuujjuaq, CA (YVP)' },
        { code: 'YVQ', name: 'Norman Wells, CA (YVQ)' },
        { code: 'YVR', name: 'Vancouver International Apt, CA (YVR)' },
        { code: 'YVT', name: 'Buffalo Narrows, CA (YVT)' },
        { code: 'YVV', name: 'Wiarton, CA (YVV)' },
        { code: 'YVZ', name: 'Deer Lake, CA (YVZ)' },
        { code: 'YWA', name: 'Petawawa, CA (YWA)' },
        { code: 'YWB', name: 'Kangiqsujuaq, CA (YWB)' },
        { code: 'YWG', name: 'Winnipeg International Apt, CA (YWG)' },
        { code: 'YWH', name: 'Victoria Inner Harbour Apt, CA (YWH)' },
        { code: 'YWJ', name: 'Deline, CA (YWJ)' },
        { code: 'YWK', name: 'Wabush, CA (YWK)' },
        { code: 'YWL', name: 'Williams Lake, CA (YWL)' },
        { code: 'YWM', name: 'Williams Harbour, CA (YWM)' },
        { code: 'YWN', name: 'Winisk, CA (YWN)' },
        { code: 'YWO', name: 'Lupin, CA (YWO)' },
        { code: 'YWP', name: 'Webequie, CA (YWP)' },
        { code: 'YWQ', name: 'Chute Des Passes, CA (YWQ)' },
        { code: 'YWR', name: 'White River, CA (YWR)' },
        { code: 'YWS', name: 'Whistler, CA (YWS)' },
        { code: 'YWY', name: 'Wrigley, CA (YWY)' },
        { code: 'YXC', name: 'Cranbrook, CA (YXC)' },
        { code: 'YXD', name: 'Edmonton Municipal Apt, CA (YXD)' },
        { code: 'YXE', name: 'Saskatoon, CA (YXE)' },
        { code: 'YXF', name: 'Snake River, CA (YXF)' },
        { code: 'YXH', name: 'Medicine Hat, CA (YXH)' },
        { code: 'YXI', name: 'Killaloe, CA (YXI)' },
        { code: 'YXJ', name: 'Fort St John, CA (YXJ)' },
        { code: 'YXK', name: 'Rimouski, CA (YXK)' },
        { code: 'YXL', name: 'Sioux Lookout, CA (YXL)' },
        { code: 'YXN', name: 'Whale Cove, CA (YXN)' },
        { code: 'YXP', name: 'Pangnirtung, CA (YXP)' },
        { code: 'YXQ', name: 'Beaver Creek, CA (YXQ)' },
        { code: 'YXR', name: 'Earlton, CA (YXR)' },
        { code: 'YXS', name: 'Prince George, CA (YXS)' },
        { code: 'YXT', name: 'Terrace, CA (YXT)' },
        { code: 'YXU', name: 'London Municipal Apt, CA (YXU)' },
        { code: 'YXX', name: 'Abbotsford, CA (YXX)' },
        { code: 'YXY', name: 'Whitehorse, CA (YXY)' },
        { code: 'YXZ', name: 'Wawa, CA (YXZ)' },
        { code: 'YYA', name: 'Big Bay Yacht Club, CA (YYA)' },
        { code: 'YYB', name: 'North Bay, CA (YYB)' },
        { code: 'YYC', name: 'Calgary, CA (YYC)' },
        { code: 'YYD', name: 'Smithers, CA (YYD)' },
        { code: 'YYE', name: 'Fort Nelson, CA (YYE)' },
        { code: 'YYF', name: 'Penticton, CA (YYF)' },
        { code: 'YYG', name: 'Charlottetown, CA (YYG)' },
        { code: 'YYH', name: 'Taloyoak, CA (YYH)' },
        { code: 'YYI', name: 'Rivers, CA (YYI)' },
        { code: 'YYJ', name: 'Victoria International Apt, CA (YYJ)' },
        { code: 'YYL', name: 'Lynn Lake, CA (YYL)' },
        { code: 'YYM', name: 'Cowley, CA (YYM)' },
        { code: 'YYN', name: 'Swift Current, CA (YYN)' },
        { code: 'YYQ', name: 'Churchill, CA (YYQ)' },
        { code: 'YYR', name: 'Goose Bay, CA (YYR)' },
        { code: 'YYT', name: 'St Johns, CA (YYT)' },
        { code: 'YYU', name: 'Kapuskasing, CA (YYU)' },
        { code: 'YYW', name: 'Armstrong, CA (YYW)' },
        { code: 'YYY', name: 'Mont Joli, CA (YYY)' },
        { code: 'YYZ', name: 'Toronto Lester B Pearson Intl Apt, CA (YYZ)' },
        { code: 'YZA', name: 'Ashcroft, CA (YZA)' },
        { code: 'YZC', name: 'Beatton River, CA (YZC)' },
        { code: 'YZE', name: 'Gore Bay, CA (YZE)' },
        { code: 'YZF', name: 'Yellowknife, CA (YZF)' },
        { code: 'YZG', name: 'Salluit, CA (YZG)' },
        { code: 'YZH', name: 'Slave Lake, CA (YZH)' },
        { code: 'YZM', name: 'Buchans, CA (YZM)' },
        { code: 'YZP', name: 'Sandspit, CA (YZP)' },
        { code: 'YZR', name: 'Sarnia, CA (YZR)' },
        { code: 'YZS', name: 'Coral Harbour, CA (YZS)' },
        { code: 'YZT', name: 'Port Hardy, CA (YZT)' },
        { code: 'YZU', name: 'Whitecourt, CA (YZU)' },
        { code: 'YZV', name: 'Sept-Iles, CA (YZV)' },
        { code: 'YZW', name: 'Teslin, CA (YZW)' },
        { code: 'YZX', name: 'Greenwood, CA (YZX)' },
        { code: 'YZZ', name: 'Trail, CA (YZZ)' },
        { code: 'ZAA', name: 'Alice Arm, CA (ZAA)' },
        { code: 'ZAC', name: 'York Landing, CA (ZAC)' },
        { code: 'ZAD', name: 'Zadar, HR (ZAD)' },
        { code: 'ZAG', name: 'Zagreb, HR (ZAG)' },
        { code: 'ZAL', name: 'Valdivia, CL (ZAL)' },
        { code: 'ZAM', name: 'Zamboanga, PH (ZAM)' },
        { code: 'ZAO', name: 'Cahors, FR (ZAO)' },
        { code: 'ZAT', name: 'Zhaotong, CN (ZAT)' },
        { code: 'ZAZ', name: 'Zaragoza, ES (ZAZ)' },
        { code: 'ZBE', name: 'Zabreh, CZ (ZBE)' },
        { code: 'ZBF', name: 'Bathurst, CA (ZBF)' },
        { code: 'ZBL', name: 'Biloela, AU (ZBL)' },
        { code: 'ZBM', name: 'Bromont, CA (ZBM)' },
        { code: 'ZBO', name: 'Bowen, AU (ZBO)' },
        { code: 'ZCL', name: 'Zacatecas, MX (ZCL)' },
        { code: 'ZCO', name: 'Temuco, CL (ZCO)' },
        { code: 'ZEC', name: 'Secunda, ZA (ZEC)' },
        { code: 'ZEG', name: 'Senggo, ID (ZEG)' },
        { code: 'ZEL', name: 'Bella Bella, CA (ZEL)' },
        { code: 'ZEM', name: 'East Main, CA (ZEM)' },
        { code: 'ZER', name: 'Zero, IN (ZER)' },
        { code: 'ZFA', name: 'Faro, CA (ZFA)' },
        { code: 'ZFB', name: 'Old Fort Bay, CA (ZFB)' },
        { code: 'ZFD', name: 'Fond Du Lac, CA (ZFD)' },
        { code: 'ZFL', name: 'South Trout, CA (ZFL)' },
        { code: 'ZFM', name: 'Fort Mcpherson, CA (ZFM)' },
        { code: 'ZFN', name: 'Tulita Fort Norman, CA (ZFN)' },
        { code: 'ZFW', name: 'Fairview, CA (ZFW)' },
        { code: 'ZGC', name: 'Lanzhou Zhongchuan-Lanzhou West Apt, CN (ZGC)' },
        { code: 'ZGF', name: 'Grand Forks, CA (ZGF)' },
        { code: 'ZGI', name: 'Gods River, CA (ZGI)' },
        { code: 'ZGL', name: 'South Galway, AU (ZGL)' },
        { code: 'ZGR', name: 'Little Grand Rapids, CA (ZGR)' },
        { code: 'ZGS', name: 'Gethsemani, CA (ZGS)' },
        { code: 'ZHA', name: 'Zhanjiang, CN (ZHA)' },
        { code: 'ZHM', name: 'Shamshernagar, BD (ZHM)' },
        { code: 'ZHP', name: 'High Prairie, CA (ZHP)' },
        { code: 'ZIC', name: 'Victoria, CL (ZIC)' },
        { code: 'ZIH', name: 'Ixtapa/Zihuatanejo, MX (ZIH)' },
        { code: 'ZIW', name: 'Kehl, DE (ZIW)' },
        { code: 'ZJG', name: 'Jenpeg, CA (ZJG)' },
        { code: 'ZJN', name: 'Swan River, CA (ZJN)' },
        { code: 'ZKE', name: 'Kaschechewan, CA (ZKE)' },
        { code: 'ZKG', name: 'Kegaska, CA (ZKG)' },
        { code: 'ZKL', name: 'Steenkool, ID (ZKL)' },
        { code: 'ZLA', name: 'Los Angeles - All Airports, US (ZLA)' },
        { code: 'ZLO', name: 'Manzanillo, MX (ZLO)' },
        { code: 'ZLT', name: 'La Tabatiere, CA (ZLT)' },
        { code: 'ZMD', name: 'Sena Madureira, BR (ZMD)' },
        { code: 'ZMH', name: 'One Hundred Eight Mile Ranch, CA (ZMH)' },
        { code: 'ZMM', name: 'Zamora, MX (ZMM)' },
        { code: 'ZMT', name: 'Masset, CA (ZMT)' },
        { code: 'ZNE', name: 'Newman, AU (ZNE)' },
        { code: 'ZNG', name: 'Negginan, CA (ZNG)' },
        { code: 'ZNU', name: 'Namu, CA (ZNU)' },
        { code: 'ZOF', name: 'Ocean Falls, CA (ZOF)' },
        { code: 'ZOS', name: 'Osorno, CL (ZOS)' },
        { code: 'ZPB', name: 'Sachigo Lake, CA (ZPB)' },
        { code: 'ZPC', name: 'Pucon, CL (ZPC)' },
        { code: 'ZPO', name: 'Pine House, CA (ZPO)' },
        { code: 'ZQN', name: 'Queenstown, NZ (ZQN)' },
        { code: 'ZQS', name: 'Queen Charlotte Is, CA (ZQS)' },
        { code: 'ZQW', name: 'Saarbrucken Zweibruecken Airport, DE (ZQW)' },
        { code: 'ZRH', name: 'Zurich Airport, CH (ZRH)' },
        { code: 'ZRI', name: 'Serui, ID (ZRI)' },
        { code: 'ZRJ', name: 'Round Lake, CA (ZRJ)' },
        { code: 'ZRM', name: 'Sarmi, ID (ZRM)' },
        { code: 'ZRO', name: "Reggio Nell' Emilia, IT (ZRO)" },
        { code: 'ZRS', name: 'Zurs/Lech, AT (ZRS)' },
        { code: 'ZSA', name: 'San Salvador, BS (ZSA)' },
        { code: 'ZSJ', name: 'Sandy Lake, CA (ZSJ)' },
        { code: 'ZSP', name: 'St Paul, CA (ZSP)' },
        { code: 'ZST', name: 'Stewart, CA (ZST)' },
        { code: 'ZSW', name: 'Prince Rupert Seal Cove Apt, CA (ZSW)' },
        { code: 'ZTB', name: 'Tete-A-La Baleine, CA (ZTB)' },
        { code: 'ZTH', name: 'Zakinthos, GR (ZTH)' },
        { code: 'ZTM', name: 'Shamattawa, CA (ZTM)' },
        { code: 'ZTR', name: 'Zhitomir, UA (ZTR)' },
        { code: 'ZTS', name: 'Tahsis, CA (ZTS)' },
        { code: 'ZUC', name: 'Ignace, CA (ZUC)' },
        { code: 'ZUD', name: 'Ancud, CL (ZUD)' },
        { code: 'ZUH', name: 'Zhuhai, CN (ZUH)' },
        { code: 'ZUL', name: 'Zilfi, SA (ZUL)' },
        { code: 'ZUM', name: 'Churchill Falls, CA (ZUM)' },
        { code: 'ZVG', name: 'Springvale, AU (ZVG)' },
        { code: 'ZWL', name: 'Wollaston Lake, CA (ZWL)' },
        { code: 'ZXT', name: 'Baku Zabrat Airport, AZ (ZXT)' },
        { code: 'ZYI', name: 'Zunyi, CN (ZYI)' },
        { code: 'ZYL', name: 'Sylhet, BD (ZYL)' },
        { code: 'ZZF', name: 'Mystery Flight, AU (ZZF)' },
    ],
};

// A Vuex instance is created by combining the state, the actions,
// and the mutations. Because the actions and mutations are just
// functions that do not depend on the instance itself, they can
// be easily tested or even hot-reloaded (see counter-hot example).
//
// You can also provide middlewares, which is just an array of
// objects containing some hooks to be called at initialization
// and after each mutation.
export default new Vuex.Store({
    state,
    mutations,
    actions,
    getters,
});

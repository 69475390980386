<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
export default {
    name: 'DeviseRestaurantMenuAdmin',
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ 'devisephp-interface/src/components/admin/workflow/Workflow'
            ),
    },

    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: 'restaurant-main-menu',
                    apiendpoint: '/api/devise/restaurants',
                    component: 'workflow-real-index',
                    message:
                        'Cool! We are working on <strong>Restaurants</strong>.<br> What would you like to do?',
                    columns: [
                        {
                            key: 'position',
                            label: 'Position',
                        },
                        {
                            key: 'label',
                            label: 'Title',
                        },
                        {
                            key: 'translations',
                            label: 'Translations',
                        },
                    ],
                    links: [
                        {
                            label: 'Create a Restaurant',
                            nextStep: 'create-restaurant',
                        },
                    ],
                    actions: [
                        {
                            key: 'edit',
                            label: 'Edit',
                            nextStep: 'edit-restaurant',
                        },
                        {
                            key: 'delete',
                            label: 'Archive',
                            nextStep: 'delete-restaurant',
                        },
                    ],
                },
                {
                    key: 'create-restaurant',
                    component: 'devise-restaurant-create',
                    message:
                        'Fill out the fields below and create the new restaurant menu. Name and Special Name fields are required.',
                    end: true,
                },
                {
                    key: 'edit-restaurant',
                    component: 'devise-restaurant-edit',
                    message: `Time to change things up! Let's edit this restaurant menu.<br> What would you like to edit?`,
                    end: true,
                },
                {
                    key: 'delete-restaurant',
                    component: 'devise-restaurant-delete',
                    message:
                        'This will permanently delete this restaurant menu from the system. Are you sure you want to do this?',
                    end: true,
                },
                {
                    key: 'search-for-restaurant',
                    component: 'workflow-search',
                    message:
                        'Use the form below to search for the restaurant menu you wish to edit',
                    apiendpoint: '/api/devise/restaurants/search',
                    searchPropertyName: 'searchterm',
                    resultsDisplayFields: [
                        {
                            label: 'Name',
                            field: 'name',
                        },
                        {
                            label: 'Specials Title',
                            field: 'specials_title',
                        },
                        {
                            label: 'End Date',
                            field: 'end_date',
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <div v-if="propertyHub.content.length">

            <hero-image
                v-if="propertyHub.site.hasOwnProperty('hotelInfoHeader')"
                :image="propertyHub.site.hotelInfoHeader"
            ></hero-image>

            <hero-image
                v-else
                :image="'/imgs/property-hub/beach-chairs.png'"
            ></hero-image>

            <PromotionSlider class="mt-8" location="Hotel Info" />

            <div class="mt-8">
                <button
                    v-for="content in propertyHub.contentAsMenu"
                    :key="content.id"
                    class="flex justify-between items-center w-full my-3"
                    @click="navigateToContent(content)"
                >
                    <template
                        v-if="content.locale[propertyHub.currentLanguage]"
                    >
                        <div class="flex items-center px-4">
                            <div
                                class="flex-none w-24 h-24 rounded overflow-hidden mr-4"
                            >
                                <img
                                    :src="
                                        content.locale[
                                            propertyHub.currentLanguage
                                        ].image ||
                                        '/imgs/property-hub/beach-chairs.png'
                                    "
                                    :alt="content.label"
                                    class="object-cover w-full h-full"
                                />
                            </div>
                            <span
                                class="text-left"
                                :style="{
                                    color: propertyHub.site.colors.primary,
                                }"
                            >
                                {{
                                    content.locale[propertyHub.currentLanguage]
                                        .title
                                }}
                            </span>
                        </div>

                        <svg
                            class="w-6 h-6 mr-4"
                            :style="{
                                color: propertyHub.site.colors.secondary,
                            }"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </template>
                </button>
            </div>
        </div>
        <div v-if="!propertyHub.content.length && !loading">
            <div
                class="text-center px-8 mt-8"
                :style="{ color: propertyHub.site.colors.primary }"
            >
                <div class="text-xl font-extrabold">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'unable-to-find-resource'
                        ]
                    }}
                </div>
                <div class="mt-4">
                    {{
                        propertyHub.localizations[propertyHub.currentLanguage][
                            'click-back-arrow-ins'
                        ]
                    }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
    name: 'HotelInfoView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
        PromotionSlider: () => import('./components/PromotionSlider.vue'),
    },

    data() {
        return {
            loading: true,
        };
    },

    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
    },

    mounted() {
        document.title = this.propertyHub.site.name + ' Hotel Information';
        this.sendPageView(this.propertyHub.site.name + ' Hotel Information');
        this.requestRetrieveContents();
    },

    methods: {
        ...mapActions(['getContentAsMenu', 'sendPageView']),

        navigateToContent(content) {
            const slug = content.locale[this.propertyHub.currentLanguage].slug;
            this.$router.push(`/property-hub/info/${slug}`);
        },

        requestRetrieveContents() {
            this.loading = true;
            this.getContentAsMenu().finally(() => {
                this.loading = false;
            });
        },
    },
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white min-h-screen"},[_c('PropertyHubNavigation',{ref:"navigation",staticClass:"relative top-0 w-full z-20",attrs:{"display-mode":"bar"}}),(_vm.content && _vm.content.display_type === 'iframe')?_c('iframe',{ref:"reswindow",staticClass:"w-full h-full",attrs:{"src":_vm.content.locale[_vm.propertyHub.currentLanguage].iframe_url}}):_vm._e(),(_vm.content && _vm.content.display_type === 'page')?_c('div',[_c('hero-image',{attrs:{"image":_vm.content.locale[_vm.propertyHub.currentLanguage].image ||
                '/imgs/property-hub/beach-chairs.png'}}),_c('div',{staticClass:"pt-4 px-5 pb-0 text-gray-700 space-y-6"},[_c('div',[_c('div',{staticClass:"font-bold text-lg mb-1",style:({ color: _vm.propertyHub.site.colors.primary })},[_vm._v(" "+_vm._s(_vm.content.locale[_vm.propertyHub.currentLanguage].title)+" ")])]),_c('div',[_c('div',{staticClass:"mb-1 text-gray-700 prose mdown",domProps:{"innerHTML":_vm._s(
                        _vm.markdown(
                            _vm.content.locale[_vm.propertyHub.currentLanguage]
                                .content
                        )
                    )}})])]),(
                _vm.content.locale[_vm.propertyHub.currentLanguage].links.length > 0
            )?_c('div',{staticClass:"mt-4 flex justify-start items-center space-x-3 snap-x px-5 overflow-x-scroll scrollbar-hide md:scrollbar-default"},_vm._l((_vm.content.locale[
                    _vm.propertyHub.currentLanguage
                ].links),function(link,key){return _c('button',{key:key,staticClass:"text-abs-white snap-center rounded-full border border-gray-300 text-sm py-1 px-4 uppercase",style:({
                    'background-color': _vm.propertyHub.site.colors.primary,
                }),on:{"click":function($event){return _vm.clickedLink(link)}}},[_vm._v(" "+_vm._s(link.linktext)+" ")])}),0):_vm._e(),_vm._l((_vm.content.locale[
                _vm.propertyHub.currentLanguage
            ].rich_links),function(richlink,lkey){return _c('div',{key:lkey,staticClass:"my-6"},[_c('img',{staticClass:"w-full",attrs:{"src":richlink.image}}),_c('div',{staticClass:"p-6"},[_c('p',{staticClass:"text-sm"},[_c('span',{staticClass:"underline text-lg my-2"},[_vm._v(_vm._s(richlink.title))]),_c('br'),_vm._v(" "+_vm._s(richlink.description)+" ")]),_c('button',{staticClass:"text-abs-white snap-center rounded-full border border-gray-300 text-sm py-1 px-4 uppercase",style:({
                        'background-color': _vm.propertyHub.site.colors.primary,
                    }),on:{"click":function($event){return _vm.clickedLink(richlink)}}},[_vm._v(" "+_vm._s(richlink.linktext)+" ")])])])}),(
                _vm.content.locale[_vm.propertyHub.currentLanguage].gallery.length
            )?_c('div',{staticClass:"mt-4"},_vm._l((_vm.content.locale[
                    _vm.propertyHub.currentLanguage
                ].gallery),function(image,id){return _c('div',{key:id},[_c('img',{directives:[{name:"devise-image",rawName:"v-devise-image",value:({ image: image }),expression:"{ image: image }"}],staticClass:"w-full mt-5"})])}),0):_vm._e()],2):_vm._e(),(!_vm.content && !_vm.loading)?_c('div',[_c('div',{staticClass:"text-center px-8 mt-8",style:({ color: _vm.propertyHub.site.colors.primary })},[_c('div',{staticClass:"text-xl font-extrabold"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'unable-to-find-resource' ])+" ")]),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'click-back-arrow-ins' ])+" ")])])]):_vm._e(),(
            _vm.content &&
            !_vm.loading &&
            _vm.slugApprovedForEvents
        )?_c('EventsNavigator',{attrs:{"starting-filter":"wellness","show-filters":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
import dayjs from 'dayjs';

export default {
    name: 'DeviseEventAdmin',
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ 'devisephp-interface/src/components/admin/workflow/Workflow'
            ),
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: 'event-main-menu',
                    apiendpoint: '/api/devise/events',
                    component: 'workflow-real-index',
                    message:
                        'Cool! We are working on <strong>Events</strong>.<br> What would you like to do?',
                    columns: [
                        {
                            key: 'label',
                            label: 'Title',
                        },

                        {
                            key: 'start_date',
                            label: 'Start Date',
                            format: (record) => {
                                return dayjs(record.start_date).format(
                                    'MMM D YYYY @ h:mm A'
                                );
                            },
                        },
                        {
                            key: 'repeats',
                            label: 'Repeats',
                            format: (record) => {
                                if (record.repeats === 1) {
                                    return 'Repeats';
                                }
                                return 'Does not repeat';
                            },
                        },
                        {
                            key: 'repeats_on',
                            label: 'Repeats On',
                            format: (record) => {
                                if (record.repeats === 1) {
                                    const days = [
                                        'sunday',
                                        'monday',
                                        'tuesday',
                                        'wednesday',
                                        'thursday',
                                        'friday',
                                        'saturday',
                                    ];
                                    const shortDays = [
                                        'Su',
                                        'M',
                                        'Tu',
                                        'W',
                                        'Th',
                                        'F',
                                        'Sa',
                                    ];

                                    // Find which days it repeats on and add a formatted
                                    // string to repeatInstances
                                    let repeatInstances = [];
                                    days.forEach((day) => {
                                        if (
                                            record.repeat_settings &&
                                            record.repeat_settings.hasOwnProperty(
                                                'repeat_days'
                                            ) &&
                                            record.repeat_settings.repeat_days.hasOwnProperty(
                                                `repeats_${day}`
                                            ) &&
                                            record.repeat_settings.repeat_days[
                                                `repeats_${day}`
                                            ] === true
                                        ) {
                                            repeatInstances.push(
                                                shortDays[days.indexOf(day)]
                                            );
                                        }
                                    });

                                    // Return the final formatted string
                                    if (repeatInstances.length) {
                                        return `${
                                            record.repeat_frequency
                                        } on ${repeatInstances.join(', ')}`;
                                    } else {
                                        return record.repeat_frequency;
                                    }
                                }

                                return '-';
                            },
                        },
                    ],
                    links: [
                        {
                            label: 'Create a Event',
                            nextStep: 'create-event',
                        },
                    ],
                    actions: [
                        {
                            key: 'edit',
                            label: 'Edit',
                            nextStep: 'edit-event',
                        },
                        {
                            key: 'delete',
                            label: 'Remove',
                            nextStep: 'delete-event',
                        },
                    ],
                },
                {
                    key: 'create-event',
                    component: 'devise-event-create',
                    message:
                        'Fill out the fields below and create the new event. Name and Special Name fields are required.',
                    end: true,
                },
                {
                    key: 'edit-event',
                    component: 'devise-event-edit',
                    message: `Time to change things up! Let's edit this event.<br> What would you like to edit?`,
                    end: true,
                },
                {
                    key: 'delete-event',
                    component: 'devise-event-delete',
                    message:
                        'This will permanently delete this event from the system. Are you sure you want to do this?',
                    end: true,
                },
                {
                    key: 'search-for-event',
                    component: 'workflow-search',
                    message:
                        'Use the form below to search for the event you wish to edit',
                    apiendpoint: '/api/devise/events/search',
                    searchPropertyName: 'searchterm',
                    resultsDisplayFields: [
                        {
                            label: 'Name',
                            field: 'name',
                        },
                        {
                            label: 'Specials Title',
                            field: 'specials_title',
                        },
                        {
                            label: 'End Date',
                            field: 'end_date',
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<script>
export default {
    props: {
        w: {
            type: [Number, String],
            default: null
        },
        h: {
            type: [Number, String],
            default: null
        },
        color: {
            type: String,
            default: "currentColor"
        }
    }
};
</script>

<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <hero-image
            v-if="propertyHub.site.hasOwnProperty('eventsHeader')"
            :image="propertyHub.site.eventsHeader"
        ></hero-image>

        <hero-image
            v-else
            :image="'/imgs/property-hub/events-header.png'"
        ></hero-image>

        <EventsNavigator />
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'EventsView',
    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
    },
    mounted: function () {
        document.title =
            this.propertyHub.site.name + ' Activities & Entertainment';
        this.sendPageView(
            this.propertyHub.site.name + ' Activities & Entertainment'
        );
    },
    methods: {
        ...mapActions(['sendPageView']),
    },
    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        HeroImage: () => import('./components/HeroImage.vue'),
        EventsNavigator: () => import('./components/EventsNavigator.vue'),
    },
};
</script>

<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: 'DeviseMenuAdmin',
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ 'devisephp-interface/src/components/admin/workflow/Workflow'
            ),
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: 'menu-main-menu',
                    component: 'workflow-real-index',
                    apiendpoint: '/api/devise/menus',
                    message:
                        'Cool! We are working on <strong>Menus</strong>.<br> What would you like to do?',
                    columns: [
                        {
                            key: 'name',
                            label: 'Menu Name',
                        },
                    ],
                    links: [
                        {
                            label: 'Create a new Menu',
                            nextStep: 'create-menu',
                        },
                    ],
                    actions: [
                        {
                            key: 'edit',
                            label: 'Edit',
                            nextStep: 'edit-menu',
                        },
                        {
                            key: 'delete',
                            label: 'Remove',
                            nextStep: 'delete-menu',
                        },
                    ],
                },
                {
                    key: 'create-menu',
                    component: 'devise-menu-create',
                    message:
                        'Fill out the fields below and create the new menu. Code Value is required.',
                    end: true,
                },
                {
                    key: 'edit-menu',
                    component: 'devise-menu-edit',
                    message: `Time to change things up! Let's edit this menu.<br> What would you like to edit?`,
                    end: true,
                },
                {
                    key: 'delete-menu',
                    component: 'devise-menu-delete',
                    message:
                        'This will permanently delete this menu from the system. Are you sure you want to do this?',
                    end: true,
                },
                {
                    key: 'search-for-menu',
                    component: 'workflow-search',
                    message:
                        'Use the form below to search for the menu you wish to edit',
                    apiendpoint: '/api/devise/menus/search',
                    allRecordsApiendpoint: '/api/devise/menus/search',
                    searchPropertyName: 'searchterm',
                    resultsDisplayFields: [
                        {
                            label: 'Name and Language',
                            field: 'search_label',
                        },
                        {
                            label: 'Site',
                            field: 'site_name',
                        },
                        {
                            label: 'Language',
                            field: 'language_name',
                        },
                    ],
                },
            ],
        };
    },
};
</script>

<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
export default {
    name: "DeviseEventPostAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "event-post-main-menu",
                    component: "workflow-menu",
                    message: "Cool! We are working on <strong>Events</strong>.",
                    options: [
                        {
                            label: "Create a Event",
                            nextStep: "create-event-post"
                        },
                        {
                            label: "Edit a Event",
                            nextStep: "search-for-event-post",
                            stepAfterNext: "edit-event-post"
                        },
                        {
                            label: "Remove a Event",
                            nextStep: "search-for-event-post",
                            stepAfterNext: "delete-event-post"
                        }
                    ]
                },
                {
                    key: "create-event-post",
                    component: "devise-event-post-create",
                    message:
                        "Fill out the fields below and create the event-post. All fields are required.",
                    end: true
                },
                {
                    key: "edit-event-post",
                    component: "devise-event-post-edit",
                    message: 'Edit what you like! Seriously, go crazy!"',
                    end: true
                },
                {
                    key: "delete-event-post",
                    component: "devise-event-post-delete",
                    message:
                        "This will permanently delete this event-post from the system. Are you sure you want to do this?",
                    end: true
                },
                {
                    key: "search-for-event-post",
                    component: "workflow-search",
                    message:
                        "Use the form below to search for the event-post you wish to edit",
                    apiendpoint: "/api/devise/pc-events",
                    allRecordsApiendpoint: "/api/devise/pc-events",
                    searchPropertyName: "title",
                    resultsDisplayFields: [
                        {
                            label: "Title",
                            field: "title"
                        },
                        {
                            label: "Type",
                            field: "category"
                        },
                        {
                            label: "Created At",
                            field: "created_at",
                            dateFormat: "MMMM D, YYYY @ h:mm a"
                        }
                    ]
                }
            ]
        };
    }
};
</script>

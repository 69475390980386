<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        :width="w"
        :height="h"
        viewBox="0 0 23.17 37.83"
        aria-labelledby="RightArrowIcon"
        role="presentation"
    >
        <title id="RightArrowIcon">Right Arrow</title>
        <g :fill="color">
            <polygon
                class="cls-1"
                points="0 2.92 2.92 0 23.17 19.17 3.08 37.83 0 35 17.41 18.92 0 2.92"
            />
        </g>
    </svg>
</template>

<script>
import Icons from "../../mixins/Icons";

export default {
    name: "IconRightArrow",
    mixins: [Icons]
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative w-full"},[_c('swiper',{ref:"mySwiper",attrs:{"slides-per-view":1,"loop":true,"pagination":{
            clickable: true,
        },"centered-slides":false,"navigation":true,"modules":_vm.modules},on:{"activeIndexChange":_vm.onActiveIndexChange}},[_vm._t("default")],2),(_vm.numberOfSlides > 1)?_c('div',{staticClass:"absolute z-10 inset-0 pointer-events-none flex justify-between items-center"},[_c('button',{staticClass:"pointer-events-auto",on:{"click":_vm.prev}},[_c('icon-left-arrow',{attrs:{"color":_vm.arrowColor,"w":50,"h":50}})],1),_c('button',{staticClass:"pointer-events-auto",on:{"click":_vm.next}},[_c('icon-right-arrow',{attrs:{"color":_vm.arrowColor,"w":50,"h":50}})],1)]):_vm._e(),(_vm.numberOfSlides > 1 && _vm.pagination)?_c('div',{staticClass:"absolute z-10 inset-0 pointer-events-none flex justify-center items-end"},[_c('div',{staticClass:"pb-8 flex space-x-2"},_vm._l((_vm.numberOfSlides),function(n){return _c('div',{key:n,staticClass:"w-2 h-2 rounded-full",class:{
                    'bg-white':
                        n === _vm.activeIndex || (_vm.activeIndex === 0 && n === 1),
                    'bg-white opacity-50':
                        n !== _vm.activeIndex && (_vm.activeIndex !== 0 || n !== 1),
                }})}),0)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div class="bg-white min-h-screen">
        <PropertyHubNavigation
            ref="navigation"
            display-mode="bar"
            class="relative top-0 w-full z-20"
        />

        <div>
            <div class="h-64 w-full rounded-b-full overflow-hidden">
                <img
                    src="/imgs/property-hub/hotel-drone-shot.png"
                    alt="Hotel Arial Image"
                    class="object-cover w-full h-full"
                />
            </div>

            <div class="px-8 mt-8">
                <img
                    src="/imgs/property-hub/safe-stay-logo.png"
                    class="w-3/4 mx-auto"
                />
            </div>

            <SafeStayEnglish
                v-if="propertyHub.currentLanguage === 'en'"
                class="mt-8"
            />
            <SafeStayEspanol
                v-if="propertyHub.currentLanguage === 'es'"
                class="mt-8"
            />
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'PlayaSafeStayView',

    components: {
        PropertyHubNavigation: () => import('./PropertyHubNavigation.vue'),
        SafeStayEnglish: () => import('./components/SafeStayEnglish.vue'),
        SafeStayEspanol: () => import('./components/SafeStayEspanol.vue'),
    },
    mounted: function () {
        document.title = this.propertyHub.site.name + ' Safe Stay';
        this.sendPageView(this.propertyHub.site.name + ' Safe Stay');
    },
    computed: {
        ...mapState({
            propertyHub: (state) => state.propertyHub,
        }),
    },
    methods: {
        ...mapActions(['sendPageView']),
    },
};
</script>

import Vue from 'vue';
import VueRouter from 'vue-router';

import MenusAdmin from '../components/devise-admin/menus/Admin';

import BookingCodeTriggersAdmin from '../components/devise-admin/booking-code-triggers/Admin';

import FactSheetsAdmin from '../components/devise-admin/fact-sheets/Admin';

import BrandsAdmin from '../components/devise-admin/brands/Admin';

import ClocksAdmin from '../components/devise-admin/clocks/Admin';

import EventsAdmin from '../components/devise-admin/events/Admin';

import RestaurantsAdmin from '../components/devise-admin/restaurants/Admin';

import ContentSectionAdmin from '../components/devise-admin/content-sections/Admin';

import PromotionsAdmin from '../components/devise-admin/promotions/Admin';

import PostsAdmin from '../components/devise-admin/posts/Admin';

import PlaycaresPostsAdmin from '../components/devise-admin/playacares-posts/Admin';

import EventPostsAdmin from '../components/devise-admin/event-posts/Admin';

import ResortsAdmin from '../components/devise-admin/resorts/Admin';

import SpecialsAdmin from '../components/devise-admin/specials/Admin';
import RouteCacheRefreshAdmin from '../components/devise-admin/route-cache/Admin';
import PropertyHubHomeView from '../components/property-hub/HomeView.vue';
import PropertyHubCabanasView from '../components/property-hub/CabanasView.vue';
import PropertyHubSpaReservationsView from '../components/property-hub/SpaReservationsView.vue';
import PropertyHubDiningReservationsView from '../components/property-hub/DiningReservationsView.vue';
import PropertyHubDiningView from '../components/property-hub/DiningView.vue';
import PropertyHubRestaurantView from '../components/property-hub/RestaurantView.vue';
import PropertyHubMenuView from '../components/property-hub/MenuView.vue';
import PropertyHubHotelInfoView from '../components/property-hub/HotelInfoView.vue';
import PropertyHubContentView from '../components/property-hub/ContentView.vue';
import PropertyHubEventsView from '../components/property-hub/EventsView.vue';
import PropertyHubEventDetailsView from '../components/property-hub/EventDetailsView.vue';
import PropertyHubSafeStayView from '../components/property-hub/PlayaSafeStayView.vue';
import DietaryDishesView from '../components/property-hub/DietaryDishesView.vue';
const UsersPlayaAdmin = () =>
    import('../components/devise-admin/users/Admin.vue');

const routes = [
    // You don't want to remove this. This is the Main administration menu for
    // Devise. This allows you to add new menu items to the primary navigation.
    // {
    //   path: '/devise',
    //   name: 'devise-index',
    //   components: {
    //     'devise': MainMenu
    //   }
    // },
    // Menus
    {
        path: '/devise/menus',
        name: 'devise-menus-admin',
        components: {
            devise: MenusAdmin,
        },
        meta: {
            title: 'Manage Menus',
            allow: ['manage menus'],
        },
    },
    // BookingCodeTriggers
    {
        path: '/devise/booking-code-triggers',
        name: 'devise-booking-code-triggers-admin',
        components: {
            devise: BookingCodeTriggersAdmin,
        },
        meta: {
            title: 'Manage Booking Code Triggers',
        },
    },
    // Brands
    {
        path: '/devise/brands',
        name: 'devise-brands-admin',
        components: {
            devise: BrandsAdmin,
        },
        meta: {
            title: 'Manage Brands',
        },
    },
    // Clocks
    {
        path: '/devise/clocks',
        name: 'devise-clocks-admin',
        components: {
            devise: ClocksAdmin,
        },
        meta: {
            title: 'Manage Clocks',
        },
    },
    // FactSheets
    {
        path: '/devise/fact-sheets',
        name: 'devise-fact-sheets-admin',
        components: {
            devise: FactSheetsAdmin,
        },
        meta: {
            title: 'Manage Fact Sheets',
        },
    },
    // Property Hub Events
    {
        path: '/devise/events',
        name: 'devise-events-admin',
        components: {
            devise: EventsAdmin,
        },
        meta: {
            title: 'Manage Events',
        },
    },
    // Property Hub Restaurants
    {
        path: '/devise/restaurants',
        name: 'devise-restaurants-admin',
        components: {
            devise: RestaurantsAdmin,
        },
        meta: {
            title: 'Manage Restaurants',
        },
    },
    // Property Hub Content Sections
    {
        path: '/devise/content-sections',
        name: 'devise-content-sections-admin',
        components: {
            devise: ContentSectionAdmin,
        },
        meta: {
            title: 'Manage Content Sections',
        },
    },
    // Property Hub Promotions
    {
        path: '/devise/promotions',
        name: 'devise-promotions-admin',
        components: {
            devise: PromotionsAdmin,
        },
        meta: {
            title: 'Manage Promotions',
        },
    },
    // Posts & Press Releases
    {
        path: '/devise/posts',
        name: 'devise-posts-admin',
        components: {
            devise: PostsAdmin,
        },
        meta: {
            title: 'Manage Posts',
        },
    },
    // Playa Cares Posts
    {
        path: '/devise/playacares-posts',
        name: 'devise-playacares-posts-admin',
        components: {
            devise: PlaycaresPostsAdmin,
        },
        meta: {
            title: 'Manage Playa Cares Posts',
        },
    },
    // Playa Cares Event Posts
    {
        path: '/devise/event-posts',
        name: 'devise-event-posts-admin',
        components: {
            devise: EventPostsAdmin,
        },
        meta: {
            title: 'Manage Event Posts',
        },
    },
    // Resorts
    {
        path: '/devise/resorts',
        name: 'devise-resorts-admin',
        components: {
            devise: ResortsAdmin,
        },
        meta: {
            title: 'Manage Resorts',
        },
    },
    // Specials
    {
        path: '/devise/specials',
        name: 'devise-specials-admin',
        components: {
            devise: SpecialsAdmin,
        },
        meta: {
            title: 'Manage Specials',
        },
    },
    // Route Cache Refresh
    {
        path: '/devise/route-cache/refresh',
        name: 'devise-route-refresh-admin',
        components: {
            devise: RouteCacheRefreshAdmin,
        },
        meta: {
            title: 'Route Cache Refresh',
        },
    },
    {
        path: '/property-hub/',
        name: 'property-hub-index',
        component: PropertyHubHomeView,
    },
    {
        path: '/property-hub/cabanas',
        name: 'property-hub-cabanas',
        component: PropertyHubCabanasView,
    },
    {
        path: '/property-hub/spa-reservations',
        name: 'property-hub-spa-reservations',
        component: PropertyHubSpaReservationsView,
    },
    {
        path: '/property-hub/dining-reservations',
        name: 'property-hub-dining-reservations',
        component: PropertyHubDiningReservationsView,
    },
    {
        path: '/property-hub/dining',
        name: 'property-hub-dining',
        component: PropertyHubDiningView,
    },
    {
        path: '/property-hub/dining/:slug',
        name: 'property-hub-restaurant',
        component: PropertyHubRestaurantView,
    },
    {
        path: '/property-hub/dining/:slug/menu/:menu',
        name: 'property-hub-restaurant-menu',
        component: PropertyHubMenuView,
    },
    {
        path: '/property-hub/info',
        name: 'property-hub-hotel-info',
        component: PropertyHubHotelInfoView,
    },
    {
        path: '/property-hub/dietary-dishes',
        name: 'property-hub-dietary-dishes',
        component: DietaryDishesView,
    },
    {
        path: '/property-hub/info/:slug',
        name: 'property-hub-content',
        component: PropertyHubContentView,
    },
    {
        path: '/property-hub/events',
        name: 'property-hub-events',
        component: PropertyHubEventsView,
    },
    {
        path: '/property-hub/events/:slug',
        name: 'property-hub-event',
        component: PropertyHubEventDetailsView,
    },
    {
        path: '/property-hub/playa-safe-stay',
        name: 'property-hub-safe-stay',
        component: PropertyHubSafeStayView,
    },
    // New users admin
    {
        path: '/devise/users/:userId/edit',
        name: 'devise-users-playa-admin',
        components: {
            devise: UsersPlayaAdmin,
        },
        meta: {
            parentRouteName: 'devise-settings',
        },
        props: true,
    },
];

Vue.use(VueRouter);

let mode = 'abstract';
if (
    window.location.origin.includes('https://hub.') ||
    window.location.origin.includes('https://hzlcc.') ||
    window.location.origin.includes('https://wapdc.') ||
    window.location.origin.includes('https://hpdc.') ||
    window.location.origin.includes('https://hzc.') ||
    window.location.origin.includes('https://hzlc.') ||
    window.location.origin.includes('https://hzpv.') ||
    window.location.origin.includes('https://hzvlc.') ||
    window.location.origin.includes('https://hzlrh.') ||
    window.location.origin.includes('https://hzrh.') ||
    window.location.origin.includes('https://hzcc.') ||
    window.location.origin.includes('https://hzrc.') ||
    window.location.origin.includes('https://hrh.') ||
    window.location.origin.includes('https://hlrf.') ||
    window.location.origin.includes('https://hlra.') ||
    window.location.origin.includes('https://ypdc.') ||
    window.location.origin.includes('https://theyucatan.') ||
    window.location.origin.includes('https://jpc.') ||
    window.location.origin.includes('https://jg.') ||
    window.location.origin.includes('https://scc.') ||
    window.location.origin.includes('https://wacun.') ||
    window.location.origin.includes('https://warn.') ||
    window.location.origin.includes('https://hzlrm.') ||
    window.location.origin.includes('https://sdc.') ||
    window.location.origin.includes('https://was.') ||
    window.location.origin.includes('https://waspb.') ||
    window.location.origin.includes('https://dc.') ||
    window.location.origin.includes('https://jpb.')
) {
    mode = 'hash';
}

const router = new VueRouter({
    mode: mode,
    transitionOnLoad: true,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
});

export default router;

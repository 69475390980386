<script>
import IconLeftArrow from "./icons/IconLeftArrow";
import IconRightArrow from "./icons/IconRightArrow";

export default {
    name: "Hero",
    components: {
        IconLeftArrow,
        IconRightArrow
    },
    props: {
        fullHeight: {
            type: Boolean,
            default: false
        },
        isFullScreen: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            loaded: true,
            navigationHeight: 150,
            player: null,
            volume: null,
            showPlayButton: false,
            showModal: false,
            videoPlayCount: 0,
            videoPlayer: null
        };
    },
    computed: {
        ratio: function() {
            return (
                parseInt(this.devise.width.text) /
                parseInt(this.devise.height.text)
            );
        },
        invertedRatio: function() {
            return (
                parseInt(this.devise.height.text) /
                parseInt(this.devise.width.text)
            );
        },
        ratioStyles: function() {
            return {
                height: this.invertedRatio * 100 + "vw",
                minWidth: this.invertedRatio * 100 + "vh"
            };
        },
        videoUrl: function() {
            if (this.devise.cfId) {
                return (
                    "https://iframe.videodelivery.net/" +
                    this.devise.cfId.text +
                    "?muted=true&autoplay=true&controls=false"
                );
            }
            return null;
        }
    },
    watch: {
        breakpoint() {
            this.positionHero();
        }
    },
    mounted() {
        this.positionHero();

        if (this.devise.cfId && this.devise.cfId.text) {
            const self = this;
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.onload = function() {
                if (typeof Stream !== "undefined") {
                    // eslint-disable-next-line no-undef
                    self.videoPlayer = Stream(self.$refs["video"]);
                    self.videoPlayer.addEventListener(
                        "playing",
                        self.onPlayEnded
                    );
                }
            };
            script.src = "https://embed.videodelivery.net/embed/sdk.latest.js";
            this.$refs["video"].appendChild(script);
        }
    },
    methods: {
        onPlayEnded() {
            this.videoPlayCount += 1;
            if (this.videoPlayCount >= 3 && this.videoPlayer) {
                this.videoPlayer.pause();
                this.videoPlayer.currentTime = 2;
            }
        },
        positionHero() {
            setTimeout(() => {
                this.refreshNavigationHeight();
                this.loaded = true;
            }, 500);
        },
        refreshNavigationHeight() {
            const navigationEl = document.getElementById("navigation");
            if (navigationEl) {
                this.navigationHeight = navigationEl.offsetHeight;
            }
        },
        play() {
            this.player.play();
            this.showPlayButton = false;
        },
        pause() {
            this.player.pause();
        },
        setCurrentTime(seconds) {
            this.player.setCurrentTime(seconds);
        },
        turnItUp() {
            this.player.getVolume().then(volume => {
                if (volume < 0.1) {
                    this.player.setVolume(0.5);
                    this.volume = 0.5;
                } else {
                    this.player.setVolume(0);
                    this.volume = 0;
                }
            });
        }
    }
};
</script>

<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: "DeviseSpecialAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "special-main-menu",
                    component: "workflow-menu",
                    message:
                        "Cool! We are working on <strong>Specials</strong>.<br> What would you like to do?",
                    options: [
                        {
                            label: "Create a Special",
                            nextStep: "create-special"
                        },
                        {
                            label: "Edit a Special",
                            nextStep: "search-for-special",
                            stepAfterNext: "edit-special-versions"
                        },
                        {
                            label: "Remove a Special",
                            nextStep: "search-for-special",
                            stepAfterNext: "delete-special"
                        }
                    ]
                },
                {
                    key: "create-special",
                    component: "devise-special-create",
                    message:
                        "Fill out the fields below and create the special. All fields are required.",
                    nextStep: "create-special-images"
                },
                {
                    key: "create-special-images",
                    component: "devise-special-create-images",
                    message:
                        "These are the images that are used on the special",
                    nextStep: "create-special-final-details"
                },
                {
                    key: "create-special-final-details",
                    component: "devise-special-create-final-details",
                    message:
                        "We just need a few final details and we will be good to go!",
                    end: true
                },
                {
                    key: "edit-special-versions",
                    component: "devise-special-edit-versions",
                    message: "Here you can select the version of the special.",
                    nextStep: "edit-special"
                },
                {
                    key: "edit-special",
                    component: "workflow-menu",
                    message: `Time to change things up! Let's edit this special.<br> What would you like to edit?`,
                    options: [
                        {
                            label: "Edit Main Content",
                            nextStep: "edit-special-content",
                            stepAfterNext: "edit-special"
                        },
                        {
                            label: "Edit Images",
                            nextStep: "edit-special-images",
                            stepAfterNext: "edit-special"
                        },
                        {
                            label: "Edit Configuration",
                            nextStep: "edit-special-configuration",
                            stepAfterNext: "edit-special"
                        },
                        {
                            label: "Return to beginning",
                            end: true
                        }
                    ]
                },
                {
                    key: "edit-special-content",
                    component: "devise-special-edit-content",
                    message:
                        "Here you can edit the primary content of the special",
                    nextStep: "edit-special"
                },
                {
                    key: "edit-special-images",
                    component: "devise-special-edit-images",
                    message: "Here you can edit the images of the special",
                    nextStep: "edit-special"
                },
                {
                    key: "edit-special-configuration",
                    component: "devise-special-edit-configuration",
                    message:
                        "Here you can edit the configuration of the special",
                    nextStep: "edit-special"
                },
                {
                    key: "delete-special",
                    component: "devise-special-delete",
                    message:
                        "This will permenenty delete this special from the system. Are you sure you want to do this?",
                    end: true
                },
                {
                    key: "search-for-special",
                    component: "workflow-search",
                    message:
                        "Use the form below to search for the special you wish to edit",
                    apiendpoint: "/api/devise/specials/search",
                    allRecordsApiendpoint: "/api/devise/specials/",
                    searchPropertyName: "searchterm",
                    resultsDisplayFields: [
                        {
                            label: "Name",
                            field: "name"
                        },
                        {
                            label: "Title",
                            field: "title"
                        },
                        {
                            label: "Version",
                            field: "version_name"
                        },
                        {
                            label: "Percent Off",
                            field: "percent_off"
                        }
                    ]
                }
            ]
        };
    }
};
</script>

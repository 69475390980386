var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white min-h-screen"},[_c('PropertyHubNavigation',{ref:"navigation",staticClass:"relative top-0 w-full z-20",attrs:{"display-mode":"bar"}}),(_vm.propertyHub.content.length)?_c('div',[(_vm.propertyHub.site.hasOwnProperty('hotelInfoHeader'))?_c('hero-image',{attrs:{"image":_vm.propertyHub.site.hotelInfoHeader}}):_c('hero-image',{attrs:{"image":'/imgs/property-hub/beach-chairs.png'}}),_c('PromotionSlider',{staticClass:"mt-8",attrs:{"location":"Hotel Info"}}),_c('div',{staticClass:"mt-8"},_vm._l((_vm.propertyHub.contentAsMenu),function(content){return _c('button',{key:content.id,staticClass:"flex justify-between items-center w-full my-3",on:{"click":function($event){return _vm.navigateToContent(content)}}},[(content.locale[_vm.propertyHub.currentLanguage])?[_c('div',{staticClass:"flex items-center px-4"},[_c('div',{staticClass:"flex-none w-24 h-24 rounded overflow-hidden mr-4"},[_c('img',{staticClass:"object-cover w-full h-full",attrs:{"src":content.locale[
                                        _vm.propertyHub.currentLanguage
                                    ].image ||
                                    '/imgs/property-hub/beach-chairs.png',"alt":content.label}})]),_c('span',{staticClass:"text-left",style:({
                                color: _vm.propertyHub.site.colors.primary,
                            })},[_vm._v(" "+_vm._s(content.locale[_vm.propertyHub.currentLanguage] .title)+" ")])]),_c('svg',{staticClass:"w-6 h-6 mr-4",style:({
                            color: _vm.propertyHub.site.colors.secondary,
                        }),attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z","clip-rule":"evenodd"}})])]:_vm._e()],2)}),0)],1):_vm._e(),(!_vm.propertyHub.content.length && !_vm.loading)?_c('div',[_c('div',{staticClass:"text-center px-8 mt-8",style:({ color: _vm.propertyHub.site.colors.primary })},[_c('div',{staticClass:"text-xl font-extrabold"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'unable-to-find-resource' ])+" ")]),_c('div',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.propertyHub.localizations[_vm.propertyHub.currentLanguage][ 'click-back-arrow-ins' ])+" ")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
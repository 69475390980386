var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white min-h-screen"},[_c('PropertyHubNavigation',{ref:"navigation",staticClass:"relative top-0 w-full z-20",attrs:{"display-mode":"bar"}}),_c('PromotionSlider',{staticClass:"mt-8",attrs:{"location":"Dining"}}),_c('div',{staticClass:"mx-4 mt-6 font-bold text-2xl mb-1 text-center",style:({ color: _vm.propertyHub.site.colors.primary })},[(_vm.propertyHub.currentLanguage === 'en')?_c('span',[_vm._v(" Restaurants ")]):_vm._e(),(_vm.propertyHub.currentLanguage === 'es')?_c('span',[_vm._v(" Restaurantes ")]):_vm._e()]),_c('div',{staticClass:"flex flex-col space-y-4 p-4"},_vm._l((_vm.restaurantSection),function(restaurant){return _c('div',{key:restaurant.id,staticClass:"cursor-pointer rounded-3xl overflow-hidden",on:{"click":function($event){return _vm.navigateTo(restaurant)}}},[(restaurant.locale[_vm.propertyHub.currentLanguage])?_c('div',{staticClass:"relative w-full overflow-hidden transition-all duration-1000 ease-out"},[_c('img',{staticClass:"object-cover w-full h-full",attrs:{"src":restaurant.locale[_vm.propertyHub.currentLanguage]
                            .image || '/imgs/property-hub/couple-at-bar.png',"alt":""}}),_c('button',{staticClass:"absolute right-0 left-0 bottom-0 flex justify-between items-center px-6 py-3 text-white text-left uppercase font-light text-sm tracking-wide transition-opacity duration-500",style:({
                        backgroundColor: _vm.propertyHub.site.colors.primary,
                    })},[_c('div',[_c('div',{staticClass:"font-medium text-sm"},[_vm._v(" "+_vm._s(restaurant.locale[ _vm.propertyHub.currentLanguage ].title)+" ")]),_c('div',{staticClass:"font-medium text-xs opacity-75"},[_vm._v(" "+_vm._s(restaurant.locale[ _vm.propertyHub.currentLanguage ].type)+" ")])]),_c('div',{staticClass:"rounded-full flex items-center justify-center border-2 border-white"},[_c('svg',{staticClass:"w-6 h-6",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z","clip-rule":"evenodd"}})])])])]):_vm._e()])}),0),_c('div',{staticClass:"mx-4 mt-6 font-bold text-2xl mb-1 text-center",style:({ color: _vm.propertyHub.site.colors.primary })},[(_vm.propertyHub.currentLanguage === 'en')?_c('span',[_vm._v(" Bars & Lounges ")]):_vm._e(),(_vm.propertyHub.currentLanguage === 'es')?_c('span',[_vm._v(" Bares y Lounges ")]):_vm._e()]),_c('div',{staticClass:"flex flex-col space-y-4 p-4"},_vm._l((_vm.barsSection),function(restaurant){return _c('div',{key:restaurant.id,staticClass:"cursor-pointer rounded-3xl overflow-hidden",on:{"click":function($event){return _vm.navigateTo(restaurant)}}},[(restaurant.locale[_vm.propertyHub.currentLanguage])?_c('div',{staticClass:"relative w-full overflow-hidden transition-all duration-1000 ease-out"},[_c('img',{staticClass:"object-cover w-full h-full",attrs:{"src":restaurant.locale[_vm.propertyHub.currentLanguage]
                            .image || '/imgs/property-hub/couple-at-bar.png',"alt":""}}),_c('button',{staticClass:"absolute right-0 left-0 bottom-0 flex justify-between items-center px-6 py-3 text-white text-left uppercase font-light text-sm tracking-wide transition-opacity duration-500",style:({
                        backgroundColor: _vm.propertyHub.site.colors.primary,
                    })},[_c('div',[_c('div',{staticClass:"font-medium text-sm"},[_vm._v(" "+_vm._s(restaurant.locale[ _vm.propertyHub.currentLanguage ].title)+" ")]),_c('div',{staticClass:"font-medium text-xs opacity-75"},[_vm._v(" "+_vm._s(restaurant.locale[ _vm.propertyHub.currentLanguage ].type)+" ")])]),_c('div',{staticClass:"rounded-full flex items-center justify-center border-2 border-white"},[_c('svg',{staticClass:"w-6 h-6",attrs:{"fill":"currentColor","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z","clip-rule":"evenodd"}})])])])]):_vm._e()])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: "DeviseBrandAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "brand-main-menu",
                    component: "workflow-menu",
                    message:
                        "Cool! We are working on <strong>Brands</strong>.<br> What would you like to do?",
                    options: [
                        {
                            label: "Create a Brand",
                            nextStep: "create-brand"
                        },
                        {
                            label: "Edit a Brand",
                            nextStep: "search-for-brand",
                            stepAfterNext: "edit-brand"
                        }
                    ]
                },
                {
                    key: "create-brand",
                    component: "devise-brand-create",
                    message:
                        "Fill out the fields below and create the new brand. Name is required.",
                    end: true
                },
                {
                    key: "edit-brand",
                    component: "devise-brand-edit",
                    message: `Time to change things up! Let's edit this brand.<br> What would you like to edit?`,
                    end: true
                },
                {
                    key: "search-for-brand",
                    component: "workflow-search",
                    message:
                        "Use the form below to search for the brand you wish to edit",
                    apiendpoint: "/api/devise/brands/search",
                    searchPropertyName: "searchterm",
                    resultsDisplayFields: [
                        {
                            label: "Name",
                            field: "name"
                        },
                        {
                            label: "Created",
                            field: "created_at"
                        }
                    ]
                }
            ]
        };
    }
};
</script>

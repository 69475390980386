<template>
    <devise-workflow :workflow="workflow" />
</template>

<script>
/* eslint-disable max-len */
export default {
    name: "DeviseBookingCodeAdmin",
    components: {
        DeviseWorkflow: () =>
            import(
                /* webpackChunkName: "devise-administration" */ "devisephp-interface/src/components/admin/workflow/Workflow"
            )
    },
    data() {
        return {
            workflow: [
                {
                    index: true,
                    key: "booking-code-trigger-main-menu",
                    component: "workflow-menu",
                    message:
                        "Cool! We are working on<br><strong>Booking Code Triggers</strong>.<br> What would you like to do?",
                    options: [
                        {
                            label: "Create a Booking Code Trigger",
                            nextStep: "create-booking-code-trigger"
                        },
                        {
                            label: "Edit a Booking Code Trigger",
                            nextStep: "search-for-booking-code-trigger",
                            stepAfterNext: "edit-booking-code-trigger"
                        },
                        {
                            label: "Remove a Booking Code Trigger",
                            nextStep: "search-for-booking-code-trigger",
                            stepAfterNext: "delete-booking-code-trigger"
                        }
                    ]
                },
                {
                    key: "create-booking-code-trigger",
                    component: "devise-booking-code-trigger-create",
                    message:
                        "Fill out the fields below and create the new trigger. Code Value is required.",
                    end: true
                },
                {
                    key: "edit-booking-code-trigger",
                    component: "devise-booking-code-trigger-edit",
                    message: `Time to change things up! Let's edit this trigger.<br> What would you like to edit?`,
                    end: true
                },
                {
                    key: "delete-booking-code-trigger",
                    component: "devise-booking-code-trigger-delete",
                    message:
                        "This will permanently delete this trigger from the system. Are you sure you want to do this?",
                    end: true
                },
                {
                    key: "search-for-booking-code-trigger",
                    component: "workflow-search",
                    message:
                        "Use the form below to search for the trigger you wish to edit",
                    apiendpoint: "/api/devise/booking-code-triggers/search",
                    allRecordsApiendpoint:
                        "/api/devise/booking-code-triggers/search",
                    searchPropertyName: "searchterm",
                    resultsDisplayFields: [
                        {
                            label: "Label",
                            field: "magic_label"
                        },
                        {
                            label: "Geocode",
                            field: "geocode_label"
                        },
                        {
                            label: "Value",
                            field: "trigger_value"
                        },
                        {
                            label: "Resorts",
                            field: "abbr_resorts_list"
                        }
                    ]
                }
            ]
        };
    }
};
</script>

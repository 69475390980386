import mezr from "mezr";

export default {
    inserted(el, binding) {
        let hasRun = false;

        const checkPosition = function() {
            const bottomOfScreen = window.scrollY + window.innerHeight;
            const offset = mezr.offset(el, "margin");
            const height = mezr.height(el, "margin");

            if (!hasRun) {
                if (bottomOfScreen > offset.top) {
                    hasRun = true;
                    binding.value.callback();
                }
            }
            if (bottomOfScreen > offset.top + height) {
                window.removeEventListener("scroll", f);
            }
        };

        let f = function() {
            checkPosition();
        };

        window.addEventListener("scroll", f);

        setTimeout(() => {
            checkPosition();
        }, 2000);
    }
};
